import React, { useRef, useEffect } from 'react';

const MSEPlayerComponent = (props) => {
    const ref = useRef();

    let mseQueue = [], mseSourceBuffer, mseStreamingStarted = false, videoSound = false;

    function Utf8ArrayToStr(array) {
        var out, i, len, c;
        var char2, char3;

        out = "";
        len = array.length;
        i = 0;
        while (i < len) {
            c = array[i++];
            switch (c >> 4) {
                case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                    // 0xxxxxxx
                    out += String.fromCharCode(c);
                    break;
                case 12: case 13:
                    // 110x xxxx   10xx xxxx
                    char2 = array[i++];
                    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                    break;
                case 14:
                    // 1110 xxxx  10xx xxxx  10xx xxxx
                    char2 = array[i++];
                    char3 = array[i++];
                    out += String.fromCharCode(((c & 0x0F) << 12) |
                        ((char2 & 0x3F) << 6) |
                        ((char3 & 0x3F) << 0));
                    break;
            }
        }

        return out;
    }

    function pushPacket() {
        if (!mseSourceBuffer.updating) {
            if (mseQueue.length > 0) {
                var packet = mseQueue.shift();
                mseSourceBuffer.appendBuffer(packet);
            } else {
                mseStreamingStarted = false;
            }
        }
        if (videoRef.current.buffered.length > 0) {
            if (typeof document.hidden !== "undefined" && document.hidden && !videoSound) {

                videoRef.current.currentTime = videoRef.current.buffered.end((videoRef.current.buffered.length - 1)) - 0.5;
            }
        }
    }

    function readPacket(packet) {
        if (!mseStreamingStarted) {
            mseSourceBuffer.appendBuffer(packet);
            mseStreamingStarted = true;
            return;
        }
        mseQueue.push(packet);
        if (!mseSourceBuffer.updating) {
            pushPacket();
        }
    }

    const videoRef = useRef(null);
    useEffect(() => {

        let mse = new MediaSource();
        videoRef.current.src = window.URL.createObjectURL(mse);

        //let url = 'ws://localhost:8083/stream/pattern/channel/0/mse?uuid=pattern&channel=0';
        let url = props.url;
        let mimeCodec;

        let ws;


        mse.addEventListener('sourceopen', function () {
            ws = new WebSocket(url);
            ws.binaryType = "arraybuffer";
            ws.onopen = function (event) {
                // console.log('Connect to ws');
            }
            ws.onmessage = function (event) {
                let data = new Uint8Array(event.data);
                if (data[0] == 9) {
                    var decoded_arr = data.slice(1);
                    if (window.TextDecoder) {
                        mimeCodec = new TextDecoder("utf-8").decode(decoded_arr);
                    } else {
                        mimeCodec = Utf8ArrayToStr(decoded_arr);
                    }
                    if (mimeCodec.indexOf(',') > 0) {
                        videoSound = true;
                    }
                    mseSourceBuffer = mse.addSourceBuffer('video/mp4; codecs="' + mimeCodec + '"');
                    mseSourceBuffer.mode = "segments"
                    mseSourceBuffer.addEventListener("updateend", pushPacket);
                } else {
                    readPacket(event.data);
                }
            };
        }, false);

        return () => {
            // console.log("inchid");
           // mse.endOfStream()
           if (ws) {
                ws.close();
           }
            // //videoRef.current.src = null;
            // URL.revokeObjectURL(videoRef.current.src);
            // videoRef.current.removeAttribute('src');
            // videoRef.current.load();
        }

    }, [])

    return (
        <div ref={ref} style={{ width: props.WIDTH, height: "250px", backgroundColor: "transparent", marginLeft: "10px" }}>
            <video muted="muted" ref={videoRef} autoPlay controls width={props.WIDTH} style={{ backgroundColor: "red" }} />
        </div>
    );
}

export default MSEPlayerComponent;