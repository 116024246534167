import styles from '../css/dashboard.module.css'
import { GlobalContext } from "../../../contexts/globalContext";

import { MultitouchComponent } from 'use-gesture-pack';

import { useState, useEffect, useContext, useRef } from 'react'
import FiltersComponent from '../FiltersComponent'
import { translate, compose, toString, identity, fromString } from 'transformation-matrix';
import MeteoItem from './MeteoItem'


const ConnectedMeteoComponent = (props) => {
    const { devices } = useContext(GlobalContext);

    const METEO_TYPE_ID = "652d22bff8cae2bf9c7b5caf";
    const TEMPERATURE_PARAM_CODE = "4b0c08b3-eab6-4290-a311-b8971c47f83b";
    const HUMIDITY_PARAM_CODE = "772223e9-c550-491e-951e-0e21a9fb1d92";
    const AVERAGE_SPEED_PARAM_COE = "61d2c8d3-b721-48fe-a25d-3cd9c4879cc9";
    const MAX_SPEED_PARAM_COE = "85485e3b-4f13-46cf-a3bb-e8ae277d7d27";
    const RAIN_PARAM_CODE = "cdbbd59e-8729-472f-81d6-6624220f4f13";
    const WIND_DIRECTION_PARAM_CODE = "204574ab-d3b2-44fe-bd3c-858c1d65b4c7";
    const [meteoData, setMeteoData] = useState([]);
    const [unfilteredData, setUnfilteredData] = useState([]);
    const ref = useRef();

    //const [filterType, setFilterType] = useState(0);
    var filterType = 0;
    

    const filter_data = (data) => {
        filterType = data;
        FilterData();
    }

    function FilterData() 
    {
        var filteredData = [];
        if (filterType == 0) {
            filteredData["live"] = [];
            filteredData["ready"] = [];
            unfilteredData.map((elem) => {
                if (filteredData[elem.status]) {
                    filteredData[elem.status].push(elem);
                } else {
                    filteredData[elem.status] = [elem];
                }
            });
        } else if (filterType == 1) {
            unfilteredData.map((elem) => {
                if (filteredData[elem.weather]) {
                    filteredData[elem.weather].push(elem);
                } else {
                    filteredData[elem.weather] = [elem];
                }
            });
        } else if (filterType == 3) {
            var aux_data = [];
            unfilteredData.map((elem, index) => {
                var arrayIndex = index % 3;
                if (aux_data[arrayIndex]) {
                    aux_data[arrayIndex].push(elem);
                } else {
                    aux_data[arrayIndex] = [elem];
                }
            });
            filteredData['Raza Mica'] = aux_data[0];
            filteredData['Raza Medie'] = aux_data[1];
            filteredData['Raza Mare'] = aux_data[2];
        } else if (filterType == 2) {
            unfilteredData.map((elem) => {
                if (filteredData[elem.city]) {
                    filteredData[elem.city].push(elem);
                } else {
                    filteredData[elem.city] = [elem];
                }
            });
        }
        setMeteoData(filteredData);
    }

    useEffect(() => {
        FilterData();
    }, [unfilteredData]);

    useEffect(() => {
        const statieMeteoList = devices.filter(device => device.device_type._id == METEO_TYPE_ID);
        setUnfilteredData(DevicesToMeteoElements(statieMeteoList));
    }, [devices]);

    function DevicesToMeteoElements(meteoDevices) {
        var meteoElemnts = [];
        meteoDevices.map((meteoDev) => {
            var meteoElement = {
                id: meteoDev._id,
                location: meteoDev.name,
                celsius: Math.round(meteoDev.data[TEMPERATURE_PARAM_CODE]) + '°',
                max: (Math.round(meteoDev.data[TEMPERATURE_PARAM_CODE]) + 3) + '°',
                min: (Math.round(meteoDev.data[TEMPERATURE_PARAM_CODE]) - 5) + '°',
                humidity: meteoDev.data[HUMIDITY_PARAM_CODE] + '%',
                wind: meteoDev.data[AVERAGE_SPEED_PARAM_COE] + ' m/s  |  ' + meteoDev.data[MAX_SPEED_PARAM_COE] + ' m/s',
                precipitation: meteoDev.data[RAIN_PARAM_CODE] + ' l/zi',
                weather: 'sunny',
                windDirection: meteoDev.data[WIND_DIRECTION_PARAM_CODE],
                pointLat: meteoDev.location[1],
                pointLong: meteoDev.location[0],
                temperatures: [15, 17, 20, 21, 23, 20, 17, 15],
                status: (meteoDev._id == "652d2a7df8cae2bf9c7cae4a") ? "live" : "ready",
                city: "Rasnov"
            };
            meteoElemnts.push(meteoElement);

        });

        FakeData.map((meteoDev) => {
            meteoElemnts.push(meteoDev);
        });

        meteoElemnts = meteoElemnts.sort(function (a, b) {
            return a.location.localeCompare(b.location);
        });

        return meteoElemnts;
    }

    const FakeData = [{
        id: 5,
        location: "Statie meteo Poiana Brasov",
        celsius: "16°",
        max: "21°",
        min: "14°",
        humidity: "55%",
        wind: '1.8 m/s | 3.3 m/s',
        precipitation: '0 mm',
        weather: 'cloudy',
        windDirection: 'NEN',
        temperatures: [20, 18, 16, 18, 2, 14, 17, 20],
        status: "offline",
        city: "Brasov"
    },
    {
        id: 6,
        location: "Statie meteo Azuga",
        celsius: "15°",
        max: "16°",
        min: "9°",
        humidity: "60%",
        wind: '1.2 m/s | 1.4 m/s',
        precipitation: '0 mm',
        weather: 'cloudy',
        windDirection: 'NEN',
        temperatures: [9, 12, 13, 14, 16, 14, 15, 13],
        status: "offline",
        city: "Bucuresti"
    },
    {
        id: 7,
        location: "Statie meteo Busteni",
        celsius: "17°",
        max: "11°",
        min: "18°",
        humidity: "86%",
        wind: '22 km/h | 168 km/h',
        precipitation: '0 mm',
        weather: 'sunny-overcast',
        windDirection: 'NEN',
        temperatures: [15, 12, 17, 18, 17, 16, 15, 15],
        status: "ready",
        city: "Brasov"
    }
    ];

    const onDragInside = ({ dy }) => {
        var mat = identity();

        if (ref.current.style.transform !== '') {
            mat = fromString(ref.current.style.transform);
        }

        var modifier = MapNumber(mat.f, 0, props.height / 4, 1, 0);

        if (modifier == 1) {
            modifier = MapNumber(mat.f,  - (ref.current.offsetHeight - props.height),  - (ref.current.offsetHeight - props.height + (props.height / 4)), 1, 0);
        }    
        
        dy = dy /props.scale_factor

        var trans = translate(0, modifier * dy);
        var final = compose(trans, mat);
        ref.current.style.transform = toString(final);
    }

    const onGestureEnded = () => {
        var mat = identity();

        if (ref.current.style.transform !== '') {
            mat = fromString(ref.current.style.transform);
        }

        var dy = 0;
        if (ref.current.offsetHeight < props.height) {
            dy = -mat.f;
        } else {
            if (mat.f > 0) {
                dy = -mat.f;
            }

            if (mat.f < - (ref.current.offsetHeight - props.height)) {
                dy = -(ref.current.offsetHeight - props.height) - mat.f;
            }
        }

        if (dy != 0) {
            ref.current.style.transition = "transform 0.2s";
            setTimeout(() => {
                ref.current.style.transition = "transform 0.0s";
            }, 200);
        }

        var trans = translate(0, dy);
        var final = compose(trans, mat);
        ref.current.style.transform = toString(final);
    }

    function MapNumber(theValue, theInStart, theInEnd, theOutStart, theOutEnd) {
        var result = theOutStart + (theOutEnd - theOutStart) * ((theValue - theInStart) / (theInEnd - theInStart));
        if (result < 0) {
            return result = 0;
        }
        if (result > 1) {
            return result = 1;
        }
        return result;
    }

    return (
        <MultitouchComponent width={600} height={300} dragGesture={false} pinchGesture={false} touchAndHoldGesture={false} tapGesture={false} onDragInside={onDragInside} onGestureEnded={onGestureEnded}>
            <div className={`${styles.dashboardElements}`} style={{ top: `${props.top}px`, height: `${props.height}px`, transformOrigin:"top left", transform:`scale(${props.scale_factor})` }}>
                <FiltersComponent text={"Weather"} func={filter_data} height={props.height} />
                <div className={`${styles.vehicleBody}`} style={{ height: `${props.height-2}px` }}>
                    <div ref={ref} style={{ position: "absolute", paddingBottom: "20px", paddingTop: "10px" }}>
                    {   Object.keys(meteoData).map((cheie) => (
                            <div key={cheie}>
                            <label className={`${styles.labelActiv}`} style={{bottom:"2px", textTransform:"capitalize"}}> {cheie} </label>
                            
                            {meteoData[cheie].map((meteo) => (
                                <MeteoItem key={meteo.id} meteo={meteo}></MeteoItem>
                            ))}
                            </div>
                        ))}                        
                    </div>
                </div>
                
                <div className={styles.drone_inner_shadow} />
                <div className={styles.drone_inner_shadow_up} />
            </div>
        </MultitouchComponent>
    );

}

export default ConnectedMeteoComponent;