import { useRef, useState, useContext, useEffect } from 'react';
import styles from '../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { GetMaxZIndex } from '../utils/Tools'
import { TransformPoints, PointScreenPosition} from '../utils/Tools'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import { GlobalContext } from "../contexts/globalContext"
import "@fontsource/roboto";
import { ApiDeleteFromProject } from "../utils/RestServices";
import ButtonWithConfirmationComponent from './keyboard/ButtonWithConfirmationComponent'

const DrawFeatureMenu = (props) => {
    // 56, 112, 84
    const ref = useRef(null);
    const [compHeight, setCompHeight] = useState(140)
    // const [deleteButtonPosition, setDeleteButtonPosition] = useState([0,0])

    const {seeElevationGraph, setSeeElevationGraph, setSelectedFeature, socketRef, losMenuPosition, selectedFeature, currentProjectId, seeSpecificLoS, setSeeSpecificLoS, mapRef, drawingsRef, setSeeLoS} = useContext(GlobalContext);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;


    // useEffect(()=>{
    //     if(!selectedFeature){
    //         setSeeElevationGraph(false)
    //     } else{
    //         var bbox = turf.bbox(selectedFeature);
    //         setDeleteButtonPosition(PointScreenPosition([bbox[2], bbox[1]], mapRef))
    //     }
    // }, [selectedFeature])

    

    useEffect(() => {
        ref.current.style.zIndex = GetMaxZIndex() + 1;
        setSeeSpecificLoS("none")
    }, [])

    useEffect(() => {
        if(selectedFeature){
            if(selectedFeature.geometry.type === "LineString"){
                setCompHeight(220)
            } else {
                setCompHeight(140)
            }
        }
        
    }, [seeSpecificLoS])   
    
    const deleteSelectedSymbol = () => {
        
        if (currentProjectId) {
            
            var body = JSON.stringify({ project_id: currentProjectId, drawing: selectedFeature.id });
            ApiDeleteFromProject(body);

            if(selectedFeature.properties.class_id === "red" || selectedFeature.properties.class_id === "red"){
                const features = mapRef.current.queryRenderedFeatures({layers: ["gl-draw-polygon-stroke-inactive.cold"]}); 
                for(let i=0; i<features.length; i++){
                    if(features[i].properties.user_class_id === "green" || features[i].properties.user_class_id === "red"){           
                        drawingsRef.current.delete(features[i].properties.id);  
                    }
                    if(features[i].properties.class_id === "green" || features[i].properties.class_id === "red"){           
                        drawingsRef.current.delete(features[i].properties.id);  
                    }
                }
                const pointFeatures =  mapRef.current.queryRenderedFeatures({layers: ["gl-draw-point-point-stroke-inactive.cold"]})
                drawingsRef.current.delete(pointFeatures[0].properties.id); 
            } else {
                socketRef.current.emit("DRAWING_DELETE", {project_id: currentProjectId, drawing: selectedFeature.id});    
            }

            drawingsRef.current.delete(drawingsRef.current.getSelectedIds());
            //socketRef.current.emit("DRAWING_DELETE", {project_id: currentProjectId, drawing: selectedFeature.id});
        } else {
            if(selectedFeature.properties.class_id === "red" || selectedFeature.properties.class_id === "green"){
                const features = mapRef.current.queryRenderedFeatures({layers: ["gl-draw-polygon-stroke-inactive.cold"]}); 
                for(let i=0; i<features.length; i++){
                    if(features[i].properties.user_class_id === "green" || features[i].properties.user_class_id === "red"){           
                        drawingsRef.current.delete(features[i].properties.id);  
                    } else if(features[i].properties.class_id === "green" || features[i].properties.class_id === "red"){           
                        drawingsRef.current.delete(features[i].properties.id);  
                    }
                }
                const pointFeatures =  mapRef.current.queryRenderedFeatures({layers: ["gl-draw-point-point-stroke-inactive.cold"]})
                if(pointFeatures[0]){
                    drawingsRef.current.delete(pointFeatures[0].properties.id); 
                }
            }
              
            drawingsRef.current.delete(drawingsRef.current.getSelectedIds());            
        }      
        setSelectedFeature(null);
    }

    useGesture(
        {
            onDrag: ({ pinching, cancel, delta: [dx, dy], first, memo }) => {
                if (pinching) {
                    return cancel()
                }
                if (first) {
                    ref.current.style.zIndex = GetMaxZIndex() + 1;
                }
                // var mat = identity();

                // if (ref.current.style.transform !== '') {
                //     mat = fromString(ref.current.style.transform);
                // }

                // var trans = translate(dx, dy);
                // var final = compose(trans, mat);
                // ref.current.style.transform = toString(final);

                return memo
            },
            onPinch: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
                if (first) {
                    memo = [ox, oy, a, s]
                } else {
                    const { width, height, x, y } = ref.current.getBoundingClientRect()

                    // apply translation to center the element at pinch origin
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((ox - memo[0]) / oxDivider, (oy - memo[1]) / oyDivider);

                    // apply inverse transformation to find the rotated pinch origin
                    var inv = inverse(mat);
                    var pointCentruTranslatat = applyToPoint(inv, [(width / 2 + x) / oxDivider, (height / 2 + y) / oyDivider]);
                    var diffCenterX = pointCentruTranslatat[0] - (ref.current.offsetWidth / 2);
                    var diffCenterY = pointCentruTranslatat[1] - (ref.current.offsetHeight / 2);

                    // convert the pinch origin to the original scale
                    var pointOx = applyToPoint(inv, [ox / oxDivider, oy / oyDivider]);

                    // calculate the point to apply transformations
                    var point = applyToPoint(mat, [pointOx[0] - diffCenterX - ref.current.offsetWidth / 2, pointOx[1] - diffCenterY - ref.current.offsetHeight / 2]);

                    // apply rotation and scaling
                    var rotate = rotateDEG(a - memo[2], point[0], point[1]);
                    var scaleFunction = scale((s / memo[3]), (s / memo[3]), point[0], point[1]);

                    var final = compose(rotate, scaleFunction, trans, mat);
                    ref.current.style.transform = toString(final);

                    if (s <= 0.5) {
                        setSeeLoS(false);
                    }
                    
                    memo = [ox, oy, a, s]
                }
                return memo
            },
            onPinchEnd: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
                const { width, height, x, y } = ref.current.getBoundingClientRect();

                if (x + width /2 < 0){
                    ref.current.className = styles.project_component_anim;

                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((-(x + width /2) ) / oxDivider, 0);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.project_component;
                    }, 500);

                }

                if (x + width /2 > window.innerWidth){
                    ref.current.className = styles.project_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(- ((x + width /2) - window.innerWidth) / oxDivider, 0);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.project_component;
                    }, 500);
                }

                if (y + height /2 < 0){
                    ref.current.className = styles.project_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(0, (-(y + height /2) ) / oyDivider);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.project_component;
                    }, 500);
                }

                if (y + height /2 > window.innerHeight){
                    ref.current.className = styles.project_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(0, - ((y + height /2) - window.innerHeight) / oyDivider);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.project_component;
                    }, 500);
                } 

            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
            pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
        }
    )

    return (
        <div className={`${styles.project_component}`} ref={ref} style={{fontFamily:'Roboto', transform: `matrix(1, 0, 0, 1, ${props.x}, ${props.y})`, height: compHeight, width: 280 }}>
            <div style={{ transform: `matrix(1, 0, 0, 1, 20, 20)`, height: compHeight-40, width: 240, fontStyle: 'Roboto', color: 'white', backgroundColor:'black', borderRadius: '8px'}}>
            
                <ButtonWithConfirmationComponent text="Șterge Simbol" x="20" y="20" width="200" height="50" onConfirmed={() => deleteSelectedSymbol()}/>
                {selectedFeature && selectedFeature.geometry.type === "LineString" && 
                    <div className={`${styles.load_project_button_clicked}`} style={{ color:"#E4A83C", transform: `matrix(1, 0, 0, 1, 20, 100)` }} onClick={() => {
                        setSeeElevationGraph(!seeElevationGraph);
                    }}>
                        Afiseaza Elevatia
                    </div>
                } 
            </div>
           
           
        </div>
    );
}

export default DrawFeatureMenu;