import { useRef, useState, useContext, useEffect } from 'react';
import styles from '../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { GetMaxZIndex } from '../utils/Tools'
import { TransformPoints, PointScreenPosition} from '../utils/Tools'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import * as turf from '@turf/turf'
import { GlobalContext } from "../contexts/globalContext"
import "@fontsource/roboto";

const LoSMenu = (props) => {
    // 56, 112, 84
    const ref = useRef(null);
    const [compHeight, setCompHeight] = useState(220)
    const [compPosition, setCompPosition] = useState(220)

    const {losMenuPosition, setLosMenuPosition, seeSpecificLoS, setSeeSpecificLoS, mapRef, drawingsRef, setSeeLoS} = useContext(GlobalContext);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    function addRadialToMapClicked(){
        if(props.currentCircle.length > 1){
            createRectangles(props.radialIntersectionPoints, props.radialCenter, props.rectangleColors)
            setSeeLoS(false);
        }
    }

    function createRectangles(intersectionPoints, center, colors){

        var pointCenter = turf.point(center);
        var pointFirst = intersectionPoints[0].features[0]
        var midpoint = turf.midpoint(pointFirst, pointCenter);

        var coordDif0 = pointFirst.geometry.coordinates[0] - midpoint.geometry.coordinates[0]
        var coordDif1 = pointFirst.geometry.coordinates[1] - midpoint.geometry.coordinates[1]

        let j = 0

        var polygons = []

        for(let i = 0; i < intersectionPoints.length; i++){
            if(intersectionPoints[i].features[0]){
                if(intersectionPoints[i].features[0].geometry.coordinates){
                    var newPoint1 = [intersectionPoints[i].features[0].geometry.coordinates[0]-coordDif0, intersectionPoints[i].features[0].geometry.coordinates[1]-coordDif1]
                    var newPoint2 = [intersectionPoints[i].features[0].geometry.coordinates[0]-coordDif0, intersectionPoints[i].features[0].geometry.coordinates[1]+coordDif1]
                    var newPoint3 = [intersectionPoints[i].features[0].geometry.coordinates[0]+coordDif0, intersectionPoints[i].features[0].geometry.coordinates[1]+coordDif1]
                    var newPoint4 = [intersectionPoints[i].features[0].geometry.coordinates[0]+coordDif0, intersectionPoints[i].features[0].geometry.coordinates[1]-coordDif1]
                    var feature = {
                        type: 'Feature',
                        properties: {
                            "class_id": colors[j]
                        },
                        geometry: { type: 'Polygon', coordinates: [[newPoint1, newPoint2, newPoint3, newPoint4, newPoint1]] }
                    };
                    polygons.push(feature)
                    j+=1
                }
            }
        }

        var fc = turf.featureCollection(polygons);
        drawingsRef.current.add(fc)

        var pointFeature = {
            type: 'Feature',
            properties: {
                "class_id": "red"
            },
            geometry: { type: 'Point', coordinates: pointCenter.geometry.coordinates}
        };

        drawingsRef.current.add(pointFeature)


    }

    useEffect(() => {
        ref.current.style.zIndex = GetMaxZIndex() + 1;
        setSeeSpecificLoS("none")
    }, [])

    useEffect(() => {
        if(seeSpecificLoS === "radial"){
            setCompHeight(285)
        } else {
            setCompHeight(220)
        }
    }, [seeSpecificLoS])    

    useGesture(
        {
            onDrag: ({ pinching, cancel, delta: [dx, dy], first, memo }) => {
                if (pinching) {
                    return cancel()
                }
                if (first) {
                    ref.current.style.zIndex = GetMaxZIndex() + 1;
                }
                // var mat = identity();

                // if (ref.current.style.transform !== '') {
                //     mat = fromString(ref.current.style.transform);
                // }

                // var trans = translate(dx, dy);
                // var final = compose(trans, mat);
                // ref.current.style.transform = toString(final);

                return memo
            },
            onPinch: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
                if (first) {
                    memo = [ox, oy, a, s]
                } else {
                    const { width, height, x, y } = ref.current.getBoundingClientRect()

                    // apply translation to center the element at pinch origin
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((ox - memo[0]) / oxDivider, (oy - memo[1]) / oyDivider);

                    // apply inverse transformation to find the rotated pinch origin
                    var inv = inverse(mat);
                    var pointCentruTranslatat = applyToPoint(inv, [(width / 2 + x) / oxDivider, (height / 2 + y) / oyDivider]);
                    var diffCenterX = pointCentruTranslatat[0] - (ref.current.offsetWidth / 2);
                    var diffCenterY = pointCentruTranslatat[1] - (ref.current.offsetHeight / 2);

                    // convert the pinch origin to the original scale
                    var pointOx = applyToPoint(inv, [ox / oxDivider, oy / oyDivider]);

                    // calculate the point to apply transformations
                    var point = applyToPoint(mat, [pointOx[0] - diffCenterX - ref.current.offsetWidth / 2, pointOx[1] - diffCenterY - ref.current.offsetHeight / 2]);

                    // apply rotation and scaling
                    var rotate = rotateDEG(a - memo[2], point[0], point[1]);
                    var scaleFunction = scale((s / memo[3]), (s / memo[3]), point[0], point[1]);

                    var final = compose(rotate, scaleFunction, trans, mat);
                    ref.current.style.transform = toString(final);

                    if (s <= 0.5) {
                        setSeeLoS(false);
                    }
                    
                    memo = [ox, oy, a, s]
                }
                return memo
            },
            onPinchEnd: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
                const { width, height, x, y } = ref.current.getBoundingClientRect();

                if (x + width /2 < 0){
                    ref.current.className = styles.project_component_anim;

                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((-(x + width /2) ) / oxDivider, 0);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.project_component;
                    }, 500);

                }

                if (x + width /2 > window.innerWidth){
                    ref.current.className = styles.project_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(- ((x + width /2) - window.innerWidth) / oxDivider, 0);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.project_component;
                    }, 500);
                }

                if (y + height /2 < 0){
                    ref.current.className = styles.project_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(0, (-(y + height /2) ) / oyDivider);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.project_component;
                    }, 500);
                }

                if (y + height /2 > window.innerHeight){
                    ref.current.className = styles.project_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(0, - ((y + height /2) - window.innerHeight) / oyDivider);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.project_component;
                    }, 500);
                } 

            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
            pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
        }
    )

    return (
        <div className={`${styles.project_component}`} ref={ref} style={{fontFamily:'Roboto', transform: `matrix(1, 0, 0, 1, ${losMenuPosition[0]+85}, ${losMenuPosition[1]-300})`, height: compHeight, width: 280 }}>
            <div style={{ transform: `matrix(1, 0, 0, 1, 20, 20)`, height: compHeight-40, width: 240, fontStyle: 'Roboto', color: 'white', backgroundColor:'black', borderRadius: '8px'}}>
            
                <div className={`${(seeSpecificLoS === "linear") ? styles.load_project_button_clicked : styles.load_project_button_inactive}`} style={{ transform: `matrix(1, 0, 0, 1, 20, 20)` }} onClick={() => {
                    setSeeSpecificLoS("linear");
                }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" style={{ transform: `matrix(1, 0, 0, 1, -15, 2)`}}>
                        <defs>
                            <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                <stop offset="0" stopColor="#fbfbfb"/>
                                <stop offset="0" stopColor="#e2e2e2"/>
                                <stop offset="1" stopColor="#7e7e7e"/>
                            </linearGradient>
                        </defs>
                        <path d="M12.105,1.895A9.631,9.631,0,0,1,7.378.711a10.122,10.122,0,0,1-3.5-3.151L7.927-6.5,11.085-3.87l5.4-5.4v3.121h1.579v-5.81h-5.81v1.579h3.1L11.02-6.024,7.862-8.656l-4.82,4.81A10.357,10.357,0,0,1,2.35-5.891a9.938,9.938,0,0,1-.245-2.214,9.739,9.739,0,0,1,.787-3.9A10.1,10.1,0,0,1,5.03-15.18,10.1,10.1,0,0,1,8.2-17.318a9.734,9.734,0,0,1,3.9-.788,9.741,9.741,0,0,1,3.9.787,10.1,10.1,0,0,1,3.176,2.137,10.1,10.1,0,0,1,2.138,3.174,9.732,9.732,0,0,1,.788,3.9,9.74,9.74,0,0,1-.788,3.9A10.1,10.1,0,0,1,19.18-1.031a10.1,10.1,0,0,1-3.175,2.138A9.735,9.735,0,0,1,12.105,1.895Z" transform="translate(-2.105 18.105)" fill="url(#a)"/>
                    </svg>
                    Linear
                </div>
                <div className={`${(seeSpecificLoS === "radial") ? styles.load_project_button_clicked : styles.load_project_button_inactive}`} style={{ transform: `matrix(1, 0, 0, 1, 20, 100)` }} onClick={() => {
                    setSeeSpecificLoS("radial");
                }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" style={{ transform: `matrix(1, 0, 0, 1, -15, 2)`}}>
                        <defs>
                            <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                <stop offset="0" stopColor="#fbfbfb"/>
                                <stop offset="0" stopColor="#e2e2e2"/>
                                <stop offset="1" stopColor="#7e7e7e"/>
                            </linearGradient>
                        </defs>
                        <path d="M12.107,1.895a9.759,9.759,0,0,1-3.9-.786A10.1,10.1,0,0,1,5.031-1.024,10.074,10.074,0,0,1,2.893-4.191a9.7,9.7,0,0,1-.788-3.894,10.149,10.149,0,0,1,.207-2.047,10.732,10.732,0,0,1,.6-1.961l1.227,1.227A9.988,9.988,0,0,0,3.8-9.494a8.172,8.172,0,0,0-.118,1.389A8.127,8.127,0,0,0,6.132-2.132,8.127,8.127,0,0,0,12.105.316a8.127,8.127,0,0,0,5.974-2.447,8.127,8.127,0,0,0,2.447-5.974,8.127,8.127,0,0,0-2.447-5.974,8.127,8.127,0,0,0-5.974-2.447,8.111,8.111,0,0,0-1.392.118,9.467,9.467,0,0,0-1.349.339L8.154-17.279a10.679,10.679,0,0,1,1.9-.614,9.2,9.2,0,0,1,1.993-.212,9.871,9.871,0,0,1,3.919.783,10.094,10.094,0,0,1,3.194,2.139A10.162,10.162,0,0,1,21.312-12a9.664,9.664,0,0,1,.794,3.9,9.739,9.739,0,0,1-.787,3.9,10.1,10.1,0,0,1-2.137,3.176,10.1,10.1,0,0,1-3.174,2.138,9.732,9.732,0,0,1-3.9.788ZM5.486-13.47a1.208,1.208,0,0,1-.886-.369,1.208,1.208,0,0,1-.369-.886A1.208,1.208,0,0,1,4.6-15.61a1.208,1.208,0,0,1,.886-.369,1.208,1.208,0,0,1,.886.369,1.208,1.208,0,0,1,.369.886,1.208,1.208,0,0,1-.369.886,1.208,1.208,0,0,1-.886.369Zm.3,5.364a6.091,6.091,0,0,1,1.842-4.474,6.091,6.091,0,0,1,4.474-1.842,6.091,6.091,0,0,1,4.474,1.842,6.091,6.091,0,0,1,1.842,4.474,6.091,6.091,0,0,1-1.842,4.474,6.091,6.091,0,0,1-4.474,1.842A6.091,6.091,0,0,1,7.632-3.632,6.091,6.091,0,0,1,5.789-8.105Z" transform="translate(-2.105 18.105)" fill="url(#a)"/>
                    </svg>
                    Radial
                </div> 
                {seeSpecificLoS === "radial" && <div className={`${(props.currentCircle.length > 1) ? styles.add_los_active : styles.add_los_inactive}`} style={{ transform: `matrix(1, 0, 0, 1, 20, 180)` }} 
                    onClick={() => {
                        addRadialToMapClicked()
                    }}>
                    Add Radial to map
                </div> }
            </div>
           
           
        </div>
    );
}

export default LoSMenu;