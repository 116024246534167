import React from 'react';
import { useRef, useState, useContext } from 'react';
import styles from '../../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { GlobalContext } from '../../../contexts/globalContext';
import uuid from 'react-uuid';
import { TransformPoints} from '../../../utils/Tools'
import * as turf from "@turf/turf"
import { ApiSaveProject } from "../../../utils/RestServices"


const FreeHandAreaModelsDrawLayer = (props) => {
    const [currentLine, setCurrentLine] = useState([]);
    const ref = useRef(null);
    const freeHandRef = useRef(null);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const {mapRef, setCurrentShapeName, currentShape, setDrawingLayer,  drawingsRef, currentDrawingColor, currentProjectId, socketRef } = useContext(GlobalContext);

    function createEndingTextShape(point1, bearing, dist, options){
        var coordinatesText = []

        for(let i = 0; i < currentShape.length; i++){
            let line = []
            for(let j = 0; j < 2; j++){
                let oxDist = dist * currentShape[i][j][0]
                let oyDist = dist * currentShape[i][j][1]
                if(oyDist === 0){
                    var point2 = turf.destination(point1, oxDist, bearing, options)
                    line.push(point2.geometry.coordinates)
                } else if(oyDist > 0){
                    let helpingPoint = turf.destination(point1, oyDist, bearing + 90, options)
                    var point3 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point3.geometry.coordinates)
                } else {
                    let helpingPoint = turf.destination(point1, Math.abs(oyDist), bearing + 270, options)
                    var point4 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point4.geometry.coordinates)
                }
            }
            coordinatesText.push(line)
        }

        return coordinatesText
    }

    useGesture(
    {
        onDrag: ({ xy: [ox, oy] }) => {
            setCurrentLine([...currentLine, [ox, oy]]);
        },
        onDragEnd: () => {
            const points = TransformPoints(currentLine, props.map);
            setCurrentLine([]);
            setDrawingLayer(0)
            setCurrentShapeName("");

            if (currentProjectId == null) {
                points[0].push(points[0][0])
                let feature = {
                    type: 'Feature',
                    properties: {
                        "class_id": currentDrawingColor
                    },
                    geometry: { type: 'LineString', coordinates: points[0] }
                };

                if(points[0].length > 1){
                    let simplified = turf.simplify(feature, {tolerance: 0.0001});
                    var zoom = mapRef.current.getZoom();
                    var zoomDiff = (11 - zoom) / 5

                    if(0.3 + zoomDiff > 0.1){
                        var dist = 0.4 + zoomDiff
                    } else {
                        var dist = 0.1
                    }

                    var chunk = turf.lineChunk(simplified, dist, {units: 'kilometers'});

                    var coordinates = []

                    for(let i = 0; i < chunk.features.length; i++){
                        var chunkPoints = chunk.features[i].geometry.coordinates
                        var firstChunkPoint = chunkPoints[0]
                        var lastChunkPoint = chunkPoints[chunkPoints.length - 1]

                        var bearing = turf.bearing(firstChunkPoint, lastChunkPoint);
                        var options = {units: 'kilometers', bearing};
                        var coordinatesOne = createEndingTextShape(firstChunkPoint, bearing, dist, options);

                        for(let i = 0; i < coordinatesOne.length; i ++){
                            coordinates.push(coordinatesOne[i])
                        }
                    }
                    

                    for(let i = 0; i < simplified.geometry.coordinates.length - 1; i++){
                        coordinates.push([simplified.geometry.coordinates[i], simplified.geometry.coordinates[i+1]])
                    }

                    var multiline = {
                        id: uuid(),
                        type: 'Feature',
                        properties: {
                            "class_id": currentDrawingColor
                        },
                        geometry: { type: 'MultiLineString', coordinates }
                    };

                    drawingsRef.current.add(multiline)

                    
                }

            } else {
                points[0].push(points[0][0])
                let feature = {
                    id: uuid(),
                    type: 'Feature',
                    properties: {
                        "class_id": currentDrawingColor
                    },
                    geometry: { type: 'LineString', coordinates: points[0] }
                };

                if(points[0].length > 1){
                    let simplified = turf.simplify(feature, {tolerance: 0.0001});
                    
                    var zoom = mapRef.current.getZoom();
                    var zoomDiff = (11 - zoom) / 5

                    if(0.3 + zoomDiff > 0.1){
                        var dist = 0.4 + zoomDiff
                    } else {
                        var dist = 0.1
                    }

                    var chunk = turf.lineChunk(simplified, dist, {units: 'kilometers'});

                    var coordinates = []

                    for(let i = 0; i < chunk.features.length; i++){
                        var chunkPoints = chunk.features[i].geometry.coordinates
                        var firstChunkPoint = chunkPoints[0]
                        var lastChunkPoint = chunkPoints[chunkPoints.length - 1]

                        var bearing = turf.bearing(firstChunkPoint, lastChunkPoint);
                        var options = {units: 'kilometers', bearing};
                        var coordinatesOne = createEndingTextShape(firstChunkPoint, bearing, dist, options);

                        for(let i = 0; i < coordinatesOne.length; i ++){
                            coordinates.push(coordinatesOne[i])
                        }
                    }
                    

                    for(let i = 0; i < simplified.geometry.coordinates.length - 1; i++){
                        coordinates.push([simplified.geometry.coordinates[i], simplified.geometry.coordinates[i+1]])
                    }

                    var multiline = {
                        id: uuid(),
                        type: 'Feature',
                        properties: {
                            "class_id": currentDrawingColor
                        },
                        geometry: { type: 'MultiLineString', coordinates }
                    };

                    drawingsRef.current.add(multiline)

                    socketRef.current.emit("DRAWING_UPDATED", {project_id: currentProjectId, drawings: multiline});
                    var body = JSON.stringify({ project_id: currentProjectId, drawing: multiline });
                    ApiSaveProject(body);
                }
            }
        }
    },
    {
        target: ref,
        drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
    })

    function circularLine(){
        if(currentLine.length > 1){
            var initialLine = currentLine.map(([x, y]) => `${x},${y}`).join(' ')
            var finalLine = initialLine + ' ' + `${currentLine[0][0]},${currentLine[0][1]}`
            return finalLine
        }
        return
    }

    return (
        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT)}} >
            <svg style={{ width: '100%', height: '100%' }}>
                {currentLine.length > 1 && <polyline
                    ref={freeHandRef}
                    points={circularLine()}
                    style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4"}}
                />}
            </svg>
        </div>
    );
};

export default FreeHandAreaModelsDrawLayer;