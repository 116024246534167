import { useRef, useState, useContext, useEffect } from 'react';
import styles from '../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { TransformPoints, PointScreenPosition} from '../utils/Tools'
import * as turf from '@turf/turf'
import { GlobalContext } from "../contexts/globalContext"
import LoSMenu from './LoSMenuComponent';

const LoSDrawLayer = (props) => {
    // 56, 112, 84
    // losMenuPosition[0]+85
    // losMenuPosition[1]+55
    const ref = useRef(null);
    const polylineRef = useRef(null);
    const losMenuRef = useRef(null);
    const [movingLinear, setMovingLinear] = useState(0)
    const [movingRadial, setMovingRadial] = useState(0)
    const [isSecondLinear, setIsSecondLinear] = useState(false)
    const [isSecondRadial, setIsSecondRadial] = useState(false)
    //const [seeSpecificLoS, setSeeSpecificLos] = useState("none");

    const {losMenuPosition, seeSpecificLoS, setSeeSpecificLoS, mapRef, drawingsRef, setSeeLoS} = useContext(GlobalContext);

    const [currentPolyline, setCurrentPolyline] = useState([]);    
    const [redLine, setRedLine] = useState([]);   
    const [greenLine, setGreenLine] = useState([]); 
    const [inThen, setInThen] = useState(false)
    
    const [currentCircle, setCurrentCircle] = useState([]);
    const [radius, setRadius] = useState([]);
    const [currentCirclePoints, setCurrentCirclePoints] = useState([]);
    const [rectangleColors, setRectangleColors] = useState([])
    const [isMoving, setIsMoving] = useState(false)
    const [radialCenter, setRadialCenter] = useState([])
    const [radialIntersectionPoints, setRadialIntersectionPoints] = useState([])

    const [radialRadius, setRadialRadius] = useState(false)

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;


    function createRectangles(intersectionPoints, center, colors){

        var pointCenter = turf.point(center);
        var pointFirst = intersectionPoints[0].features[0]
        var midpoint = turf.midpoint(pointFirst, pointCenter);

        var coordDif0 = pointFirst.geometry.coordinates[0] - midpoint.geometry.coordinates[0]
        var coordDif1 = pointFirst.geometry.coordinates[1] - midpoint.geometry.coordinates[1]

        let j = 0

        var polygons = []

        for(let i = 0; i < intersectionPoints.length; i++){
            if(intersectionPoints[i].features[0]){
                if(intersectionPoints[i].features[0].geometry.coordinates){
                    var newPoint1 = [intersectionPoints[i].features[0].geometry.coordinates[0]-coordDif0, intersectionPoints[i].features[0].geometry.coordinates[1]-coordDif1]
                    var newPoint2 = [intersectionPoints[i].features[0].geometry.coordinates[0]-coordDif0, intersectionPoints[i].features[0].geometry.coordinates[1]+coordDif1]
                    var newPoint3 = [intersectionPoints[i].features[0].geometry.coordinates[0]+coordDif0, intersectionPoints[i].features[0].geometry.coordinates[1]+coordDif1]
                    var newPoint4 = [intersectionPoints[i].features[0].geometry.coordinates[0]+coordDif0, intersectionPoints[i].features[0].geometry.coordinates[1]-coordDif1]
                    var feature = {
                        type: 'Feature',
                        properties: {
                            "class_id": colors[j]
                        },
                        geometry: { type: 'Polygon', coordinates: [[newPoint1, newPoint2, newPoint3, newPoint4, newPoint1]] }
                    };
                    polygons.push(feature)
                    j+=1
                }
            }
        }

        var fc = turf.featureCollection(polygons);
        drawingsRef.current.add(fc)

        var pointFeature = {
            type: 'Feature',
            properties: {
                "class_id": "red"
            },
            geometry: { type: 'Point', coordinates: pointCenter.geometry.coordinates}
        };

        drawingsRef.current.add(pointFeature)


    }

    function lineOfSight(updatedPoints){
        
        var points = TransformPoints(updatedPoints, props.map);
        var line = turf.lineString(points[0])
        var length = turf.length(line, {units: 'kilometers'});
        let chunkSize = length / 30;

        var chunk = turf.lineChunk(line, chunkSize, {units: 'kilometers'});
        
        var elevations = []
        var pointString = ""

        for(let i=0; i < chunk.features.length; i++){
            pointString = pointString + chunk.features[i].geometry.coordinates[0] + '|' 
        }

        //elevations.push(mapRef.current.queryTerrainElevation(chunk.features[0].geometry.coordinates[0])+1.5)

        fetch(process.env.REACT_APP_TOPODATA_URL+pointString)
            .then(response => response.json())
            .then((data) => {
                elevations.push(data.results[0].elevation+1.5)
                for(let i=1; i < data.results.length-1; i++){
                    elevations.push(data.results[i].elevation)
                }
                elevations.push(data.results[data.results.length-1].elevation+1.5)
                var elevationDifference = elevations[elevations.length - 1] - elevations[0]
                var elevationRatio = elevationDifference / length
                
                var straightLineElevationValues = []

                for(let i = 1; i < chunk.features.length; i++){
                    var chunkLine = turf.lineString([points[0][0], chunk.features[i].geometry.coordinates[0]])
                    var chunkLength = turf.length(chunkLine, {units: 'kilometers'});
                    var straightElevationValue = elevations[0] + elevationRatio * chunkLength
                    straightLineElevationValues.push(straightElevationValue)

                    if(straightElevationValue < elevations[i]){
                        var lostPoint = PointScreenPosition(chunk.features[i].geometry.coordinates[0], props.map)
                        // adaugat pt async
                        setGreenLine([updatedPoints[0], lostPoint])
                        setRedLine([lostPoint, updatedPoints[1]])
                        return lostPoint
                    }
                }
                setInThen(true)
                
                // adaugat pt async
                setGreenLine([updatedPoints[0], updatedPoints[1]])
                setRedLine([])

                return updatedPoints[1]
        });

        // for(let i=1; i < chunk.features.length; i++){
        //     elevations.push(mapRef.current.queryTerrainElevation(chunk.features[i].geometry.coordinates[0]))
            // fetch(process.env.REACT_APP_TOPODATA_URL+chunk.features[i].geometry.coordinates[0][0]+","+chunk.features[i].geometry.coordinates[0][1])
            //     .then(response => response.json())
            //     .then((data) => {
            //         elevations.push(data.results[0].elevation)
            // });
        // }
        //elevations.push(mapRef.current.queryTerrainElevation(chunk.features[chunk.features.length - 1].geometry.coordinates[1])+1.5)
        // fetch(process.env.REACT_APP_TOPODATA_URL+chunk.features[chunk.features.length - 1].geometry.coordinates[0][0]+","+chunk.features[chunk.features.length - 1].geometry.coordinates[0][1])
        //     .then(response => response.json())
        //     .then((data) => {
        //         elevations.push(data.results[0].elevation)
        // });

    

        // var elevationDifference = elevations[elevations.length - 1] - elevations[0]
        // var elevationRatio = elevationDifference / length
        
        // var straightLineElevationValues = []

        // for(let i = 1; i < chunk.features.length; i++){
        //     var chunkLine = turf.lineString([points[0][0], chunk.features[i].geometry.coordinates[0]])
        //     var chunkLength = turf.length(chunkLine, {units: 'kilometers'});
        //     var straightElevationValue = elevations[0] + elevationRatio * chunkLength
        //     straightLineElevationValues.push(straightElevationValue)

        //     if(straightElevationValue < elevations[i]){
        //         var lostPoint = PointScreenPosition(chunk.features[i].geometry.coordinates[0], props.map)
        //         return lostPoint
        //     }
        // }
        // return updatedPoints[1]
        
    }

    function lineOfSightForRadial(points){
        var line = turf.lineString(points)
        var length = turf.length(line, {units: 'kilometers'});
        
        let chunkSize = length / 30;

        var chunk = turf.lineChunk(line, chunkSize, {units: 'kilometers'});

        var elevations = []
        var pointString = ""

        for(let i=0; i < chunk.features.length; i++){
            pointString = pointString + chunk.features[i].geometry.coordinates[0] + '|' 
        }

        //elevations.push(mapRef.current.queryTerrainElevation(chunk.features[0].geometry.coordinates[0])+1.5)

        fetch(process.env.REACT_APP_TOPODATA_URL+pointString)
            .then(response => response.json())
            .then((data) => {
                elevations.push(data.results[0].elevation+1.5)
                for(let i=1; i < data.results.length-1; i++){
                    elevations.push(data.results[i].elevation)
                }
                elevations.push(data.results[data.results.length-1].elevation+1.5)
                var elevationDifference = elevations[elevations.length - 1] - elevations[0]
                var elevationRatio = elevationDifference / length
                
                var straightLineElevationValues = []

                for(let i = 1; i < chunk.features.length; i++){
                    var chunkLine = turf.lineString([points[0], chunk.features[i].geometry.coordinates[0]])
                    var chunkLength = turf.length(chunkLine, {units: 'kilometers'});
                    var straightElevationValue = elevations[0] + elevationRatio * chunkLength
                    straightLineElevationValues.push(straightElevationValue)

                    if(straightElevationValue < elevations[i]){
                        return "red"
                    }
                }
                return "green"
        });


        // var elevations = []
        // elevations.push(mapRef.current.queryTerrainElevation(chunk.features[0].geometry.coordinates[0])+1.5)

        // for(let i=1; i < chunk.features.length; i++){
        //     elevations.push(mapRef.current.queryTerrainElevation(chunk.features[i].geometry.coordinates[0]))
        // }
        // elevations.push(mapRef.current.queryTerrainElevation(chunk.features[chunk.features.length - 1].geometry.coordinates[1])+1.5)

        // fetch(process.env.REACT_APP_TOPODATA_URL+chunk.features[0].geometry.coordinates[0][0]+","+chunk.features[0].geometry.coordinates[0][1])
        //     .then(response => response.json())
        //     .then((data) => {
        //         elevations.push(data.results[0].elevation)
        // });

        // for(let i=1; i < chunk.features.length; i++){
        //     fetch(process.env.REACT_APP_TOPODATA_URL+chunk.features[i].geometry.coordinates[0][0]+","+chunk.features[i].geometry.coordinates[0][1])
        //         .then(response => response.json())
        //         .then((data) => {
        //             elevations.push(data.results[0].elevation)
        //     });
        // }
        // fetch(process.env.REACT_APP_TOPODATA_URL+chunk.features[chunk.features.length - 1].geometry.coordinates[0][0]+","+chunk.features[chunk.features.length - 1].geometry.coordinates[0][1])
        //     .then(response => response.json())
        //     .then((data) => {
        //         elevations.push(data.results[0].elevation)
        // });
        

        // var elevationDifference = elevations[elevations.length - 1] - elevations[0]
        // var elevationRatio = elevationDifference / length
        
        // var straightLineElevationValues = []

        // for(let i = 1; i < chunk.features.length; i++){
        //     var chunkLine = turf.lineString([points[0], chunk.features[i].geometry.coordinates[0]])
        //     var chunkLength = turf.length(chunkLine, {units: 'kilometers'});
        //     var straightElevationValue = elevations[0] + elevationRatio * chunkLength
        //     straightLineElevationValues.push(straightElevationValue)

        //     if(straightElevationValue < elevations[i]){
        //         return "red"
        //     }
        // }
        // return "green"
        
    }
    
    function  radialLineOfSight(){
        if(currentCircle.length > 1){
            var points = TransformPoints(currentCircle, props.map);
        
            var screenDistance = getRadius()
        } else {
            setCurrentCircle(currentPolyline)
            var points = TransformPoints(currentPolyline, props.map);
            setCurrentPolyline([])
        
            var screenDistance = getRadius()
        }

        setRadialRadius(screenDistance)

        var center = points[0][0];
        var radiusLoS = turf.distance(points[0][0], points[0][1]);
        var steps = 192
        var quarter = steps / 4
        var half = steps / 2
        var options = {steps: steps, units: 'kilometers', properties: {foo: 'bar'}};
        var circle = turf.circle(center, radiusLoS, options);

        var screenPoints = []
        for(let i=0; i < circle.geometry.coordinates[0].length;i++){
            var screenPoint = PointScreenPosition(circle.geometry.coordinates[0][i], props.map)
            screenPoints.push(screenPoint)
        }

        var features = []
        for(let i = 1; i < quarter; i++){
            var pair1 = turf.lineString([circle.geometry.coordinates[0][0+i], circle.geometry.coordinates[0][half-i]])
            var pair2 = turf.lineString([circle.geometry.coordinates[0][half+i], circle.geometry.coordinates[0][steps-i]])
            features.push(pair1, pair2)
        }
        features.push(turf.lineString([circle.geometry.coordinates[0][0], circle.geometry.coordinates[0][half]]))

        for(let i = 1; i < quarter; i++){
            var pair3 = turf.lineString([circle.geometry.coordinates[0][quarter+i], circle.geometry.coordinates[0][quarter+half-i]])
            var pair4 = turf.lineString([circle.geometry.coordinates[0][quarter+half+i], circle.geometry.coordinates[0][quarter-i]])
            features.push(pair3, pair4)
        }
        features.push(turf.lineString([circle.geometry.coordinates[0][quarter], circle.geometry.coordinates[0][quarter+half]]))

        var intersectionPoints = []
        for(let i = 0; i < half - 1; i++){
            for(let j = half - 1; j < steps - 2; j++){
                var intersects = turf.lineIntersect(features[i], features[j]);
                intersectionPoints.push(intersects)
            }
        }
        setRadialIntersectionPoints(intersectionPoints)

        var colors = []
        var colorsPoints = []
        for(let i = 0; i < intersectionPoints.length; i++){
            if(intersectionPoints[i].features[0]){
                if(intersectionPoints[i].features[0].geometry.coordinates){
                    colors.push(lineOfSightForRadial([center, intersectionPoints[i].features[0].geometry.coordinates]))
                    colorsPoints.push(PointScreenPosition(intersectionPoints[i].features[0].geometry.coordinates, props.map))

                }
            }    
        }

        

        setCurrentCirclePoints(colorsPoints)
        setRectangleColors(colors)
        setRadialCenter(center)

    }

    function getRadius() {
        if(movingRadial === 1){
            return radius
        } else if(currentCircle.length > 1){
            return Math.sqrt((currentCircle[1][0] - currentCircle[0][0])**2 + (currentCircle[1][1] - currentCircle[0][1])**2)
        } else{
            return Math.sqrt((currentPolyline[1][0] - currentPolyline[0][0])**2 + (currentPolyline[1][1] - currentPolyline[0][1])**2)
        }
    }

    // useEffect(() => {
    //     if(openLoSRef.current && seeLoS){
    //         setLosMenuPosition([openLoSRef.current.getBoundingClientRect().x / oxDivider, openLoSRef.current.getBoundingClientRect().y / oyDivider])
    //     }

    // }, [seeLoS]);

    useGesture(
        {   
            onDragStart: ({xy: [ox, oy]}) => {
                if(ox - losMenuPosition[0]- 85 <= 280 && ox - losMenuPosition[0]- 85 >= 0 && 
                    oy - losMenuPosition[1] - 55 <= 285 && oy - losMenuPosition[1] - 55 >= 0){
                    // return cancel()
                } else if(seeSpecificLoS === "linear"){
                    const currentPoint = [ox, oy];
                    const firstPoint = currentPolyline[0];
                    const secondPoint = currentPolyline[1]

                    if (firstPoint && secondPoint) {     
                        if (Math.abs(firstPoint[0] - currentPoint[0]) < props.DrawingStopIntervalSize  && Math.abs(firstPoint[1] - currentPoint[1]) < props.DrawingStopIntervalSize) {  
                            setMovingLinear(1) 
                        } else if (Math.abs(secondPoint[0] - currentPoint[0]) < props.DrawingStopIntervalSize && Math.abs(secondPoint[1] - currentPoint[1]) < props.DrawingStopIntervalSize) {
                            setMovingLinear(2) 
                        }      
                    }
                } else if(seeSpecificLoS === "radial"){
                    
                    const currentPoint = [ox, oy];
                    const firstPoint = currentCircle[0];
                    const secondPoint = currentCircle[1]

                    if(firstPoint && !secondPoint){
                        setIsMoving(true)
                    }

                    if (firstPoint && secondPoint) {    
                         
                        if (Math.abs(firstPoint[0] - currentPoint[0]) < props.DrawingStopIntervalSize  && Math.abs(firstPoint[1] - currentPoint[1]) < props.DrawingStopIntervalSize) {  
                            setIsMoving(true)
                            setMovingRadial(1) 
                            setRadius(getRadius())
                            setCurrentCirclePoints([])  
                        } else if (Math.abs(secondPoint[0] - currentPoint[0]) < props.DrawingStopIntervalSize && Math.abs(secondPoint[1] - currentPoint[1]) < props.DrawingStopIntervalSize) {
                            setIsMoving(true)
                            setMovingRadial(2) 
                            setCurrentCirclePoints([])
                        }      
                    }
                }
            },

            onDrag: ({ pinching, cancel, xy: [ox, oy], delta: [dx, dy], first }) => {
                if (pinching) {
                    return cancel()
                }
                
                if(ox - losMenuPosition[0]- 85 <= 280 && ox - losMenuPosition[0]- 85 >= 0 && 
                    oy - losMenuPosition[1] - 55 <= 285 && oy - losMenuPosition[1] - 55 >= 0){
                    return cancel()
                } else if(seeSpecificLoS === "linear"){
                    const currentPoint = [ox, oy];
                    const firstPoint = currentPolyline[0];
                    const secondPoint = currentPolyline[1]

                    if (firstPoint && secondPoint && isSecondLinear) {
                        if (movingLinear === 1) {
                            const updatedPoints = [currentPoint, secondPoint]
                            setCurrentPolyline(updatedPoints);
                            var lost = lineOfSight(updatedPoints)
                            // if (lost !== updatedPoints[1] && inThen){
                            //     setGreenLine([updatedPoints[0], lost])
                            //     setRedLine([lost, updatedPoints[1]])
                            //     setInThen(false)
                            // } else if(inThen){
                            //     setGreenLine([updatedPoints[0], updatedPoints[1]])
                            //     setRedLine([])
                            //     setInThen(false)
                            // }
                        } else if (movingLinear === 2) {
                            const updatedPoints = [firstPoint, currentPoint]
                            setCurrentPolyline(updatedPoints);
                            var lost = lineOfSight(updatedPoints)
                            // if (lost !== updatedPoints[1] && inThen){
                            //     setGreenLine([updatedPoints[0], lost])
                            //     setRedLine([lost, updatedPoints[1]])
                            //     setInThen(false)
                            // } else if(inThen){
                            //     setGreenLine([updatedPoints[0], updatedPoints[1]])
                            //     setRedLine([])
                            //     setInThen(false)
                            // }
                        } 
                    } else if (first) {
                        setCurrentPolyline([...currentPolyline, currentPoint]);
                    } else {
                        const updatedPoints = currentPolyline.slice(0, -1);
                        updatedPoints.push([ox, oy]);
                        setCurrentPolyline(updatedPoints);
                        if(updatedPoints.length === 2){
                            var lost = lineOfSight(updatedPoints)
                            // if (lost !== updatedPoints[1] && inThen){
                            //     setGreenLine([updatedPoints[0], lost])
                            //     setRedLine([lost, updatedPoints[1]])
                            //     setInThen(false)
                            // } else if(inThen) {
                            //     setGreenLine([updatedPoints[0], updatedPoints[1]])
                            //     setRedLine([])
                            //     setInThen(false)
                            // }
                        }
                        
                    }
                    
                    
                } else if(seeSpecificLoS === "radial"){
                    const currentPoint = [ox, oy];
                    const firstPoint = currentCircle[0];
                    const secondPoint = currentCircle[1]


                    if (firstPoint && secondPoint && isSecondRadial) {
                        if (movingRadial === 1) {
                            const translatedSecondPoint = [secondPoint[0]+dx, secondPoint[1]+dy]
                            const updatedPoints = [currentPoint, translatedSecondPoint]
                            setCurrentCircle(updatedPoints);
                        } else if (movingRadial === 2) {
                            const updatedPoints = [firstPoint, currentPoint]
                            setCurrentCircle(updatedPoints);
                        } 
                    } else if (first) {
                        setCurrentCircle([...currentCircle, currentPoint]);
                    } else {
                        const updatedPoints = currentCircle.slice(0, -1);
                        updatedPoints.push([ox, oy]);
                        setCurrentCircle(updatedPoints);
                        
                    }
                }
            },
            onDragEnd: () => {
                
                if(seeSpecificLoS === "linear"){
                    setMovingLinear(0)
                    const secondPoint = currentPolyline[1]
                    if(secondPoint){
                        setIsSecondLinear(true) 
                    }
                } else if(seeSpecificLoS === "radial"){
                    setIsMoving(false)
                    setMovingRadial(0)
                    const secondPoint = currentCircle[1]
                    if(secondPoint){
                        setIsSecondRadial(true) 
                        radialLineOfSight()
                    }
                }
            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
        }
    )


    useEffect(()=>{
        if(seeSpecificLoS === "radial"){
            if(currentPolyline.length === 2){
                setCurrentCircle(currentPolyline)
                setIsSecondRadial(true)
                radialLineOfSight()
            } else {
                setCurrentPolyline([]);
            }
            setIsSecondLinear(false) 
            setRedLine([])
            setGreenLine([])
        } else if(seeSpecificLoS === "linear"){
            if(currentCircle.length ===2){
                setIsSecondLinear(true)
                setCurrentPolyline(currentCircle)
                var lost = lineOfSight(currentCircle)
                if (lost !== currentCircle[1]){
                    setGreenLine([currentCircle[0], lost])
                    setRedLine([lost, currentCircle[1]])
                } else {
                    setGreenLine([currentCircle[0], currentCircle[1]])
                    setRedLine([])
                }
            }
            setCurrentCircle([])
            setCurrentCirclePoints([])
            setRectangleColors([])
        }
    }, [seeSpecificLoS])
    

    const xDrawingPointRectangleSize = props.DrawingPointRectangleSize / oxDivider;
    const yDrawingPointRectangleSize = props.DrawingPointRectangleSize / oyDivider;

    
    function showLatLng(point) {
        const coord = TransformPoints([point], props.map)
        return `${coord[0][0][0].toFixed(3)}, ${coord[0][0][1].toFixed(3)}`
    }
    function showElevationOld(point) {
        const coord = TransformPoints([point], props.map)
        const el = mapRef.current.queryTerrainElevation([coord[0][0][0], coord[0][0][1]])
        return `${el.toFixed(3)}`
    }

    function showElevation(point) {
        const coord = TransformPoints([point], props.map)
        var el = []
        fetch(process.env.REACT_APP_TOPODATA_URL+coord[0][0][0]+","+coord[0][0][1])
            .then(response => response.json())
            .then((data) => {
                el = data.results[0].elevation
                return `${el.toFixed(3)}`
        });
    }

    function addRadialToMapClicked(){
        if(currentCircle.length > 1){
            createRectangles(radialIntersectionPoints, radialCenter, rectangleColors)
            setSeeLoS(false);
        }
        
    }
    return (
        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT) }} >
            {/* <div className={`${(seeSpecificLoS === "linear") ? styles.pressed_functional_button : styles.functional_button}`} style={{ transform: `matrix(1, 0, 0, 1, ${parseInt(process.env.REACT_APP_WIDTH) - 850}, 80)` }} onClick={() => {
                setSeeSpecificLoS("linear");
            }}>
                Linear
            </div>
            <div className={`${(seeSpecificLoS === "radial") ? styles.pressed_functional_button : styles.functional_button}`} style={{ transform: `matrix(1, 0, 0, 1, ${parseInt(process.env.REACT_APP_WIDTH) - 550}, 80)` }} onClick={() => {
                setSeeSpecificLoS("radial");
            }}>
                Radial
            </div> 
            {seeSpecificLoS === "radial" && <div className={`${(seeSpecificLoS === "radial") ? styles.pressed_functional_button : styles.functional_button}`} style={{ transform: `matrix(1, 0, 0, 1, ${parseInt(process.env.REACT_APP_WIDTH) - 550}, 180)` }} 
                onClick={() => {
                    addRadialToMapClicked()
                }}>
                Add Radial to map
            </div> } */}
            <LoSMenu radialIntersectionPoints={radialIntersectionPoints} radialCenter={radialCenter} rectangleColors={rectangleColors} currentCircle={currentCircle} />
            {seeSpecificLoS === "linear" && <svg style={{ width: '100%', height: '100%' }}>
                {greenLine.length < 2 && <polyline
                    ref={polylineRef}
                    points={currentPolyline.map(([x, y]) => `${x},${y}`).join(' ')}
                    style={{ fill: 'none', stroke: 'green', strokeWidth: 4}}
                />}
                {greenLine.length === 2 && <polyline
                    points={greenLine.map(([x, y]) => `${x},${y}`).join(' ')}
                    style={{ fill: 'none', stroke: 'green', strokeWidth: "4" }}
                />}
                {redLine.length === 2 && <polyline
                    points={redLine.map(([x, y]) => `${x},${y}`).join(' ')}
                    style={{ fill: 'none', stroke: 'red', strokeWidth: "4" }}
                />}
                {currentPolyline.map(([x, y], index) => (
                    <g key={index}>
                        {index === 0 && <text x={x-3/2*xDrawingPointRectangleSize} y={y-yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >Origin</text>}
                        {index === 1 && <text x={x-3/2*xDrawingPointRectangleSize} y={y-yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >Target</text>}

                        <text x={x-5*xDrawingPointRectangleSize} y={y+2*yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >{showElevation([x, y])}m, {showLatLng([x, y])}</text>
                        {index === 1 && redLine.length === 2 &&  <circle
                            cx = {x}
                            cy = {y}
                            r = {10}
                            style={{ fill: 'red', stroke: 'white', strokeWidth: 4 }} 
                        />}
                        {index === 1 && redLine.length !== 2 &&  <circle
                            cx = {x}
                            cy = {y}
                            r = {10}
                            style={{ fill: 'green', stroke: 'white', strokeWidth: 4 }} 
                        />}
                        {index === 0 && <circle
                            cx = {x}
                            cy = {y}
                            r = {10}
                            style={{ fill: '#fbb03b', stroke: 'white', strokeWidth: 4 }} 
                        />}
                    </g>
                ))}
                </svg>}
                {/* <defs>
                    <filter id="f1" x="0" y="0">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
                    </filter>
                </defs> */}
            {seeSpecificLoS === "radial" && <svg style={{ width: '100%', height: '100%' }}>
                {currentCircle.length > 1 && isMoving && (
                    <circle 
                        cx = {currentCircle[0][0]}
                        cy = {currentCircle[0][1]}
                        r = {getRadius()}
                        style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4" }} 
                    />
                )}
                
                {currentCirclePoints.map(([x, y], index) => (
                    <g key={index}>{rectangleColors[index]=== "red" && <rect
                            x={x - radialRadius / 84 / oxDivider / 2}
                            y={y - radialRadius / 84 / oyDivider / 2}
                            width={radialRadius / 84 / oxDivider}
                            height={radialRadius / 84 / oyDivider}
                            fill="#FF000022"
                            // filter="url(#f1)"
                            // fill-opacity="0.7"
                        />}
                        {rectangleColors[index]=== "green" && <rect
                            x={x - radialRadius / 84 / oxDivider / 2}
                            y={y - radialRadius / 84 / oyDivider / 2}
                            width={radialRadius / 84 / oxDivider}
                            height={radialRadius / 84 / oyDivider}
                            fill="#00FF0022"
                            // filter="url(#f1)"
                            // fill-opacity="0.7"
                        />}
                    </g>
                ))}
                {currentCircle.map(([x, y], index) => (
                    <g key={index}>
                        <circle
                            cx = {x}
                            cy = {y}
                            r = {10}
                            style={{ fill: '#fbb03b', stroke: 'white', strokeWidth: 4 }} 
                        />
                    </g>
                ))}
                {currentCircle.length>0 && <text x={currentCircle[0][0]-3/2*xDrawingPointRectangleSize} y={currentCircle[0][1]-yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >Origin</text>}
                {currentCircle.length>1 && <text x={currentCircle[1][0]-4*xDrawingPointRectangleSize} y={currentCircle[1][1]-yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >Radial Boundary</text>}
                {currentCircle.length>0 && <text x={currentCircle[0][0]-5*xDrawingPointRectangleSize} y={currentCircle[0][1]+2*yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >{showElevation([currentCircle[0][0], currentCircle[0][1]])}m, {showLatLng([currentCircle[0][0], currentCircle[0][1]])}</text>}
            </svg>}
        </div>
    );
}

export default LoSDrawLayer;