import React, { useRef, useEffect } from 'react';
import styles from '../../mobile.styles.module.css'

const VideoComponent = (props) => {

    const ref = useRef(null);
    const videoRef = useRef(null);

    const isOnPosition = useRef(true);

    useEffect(() => {
        videoRef.current.srcObject = props.stream;

        if (props.is_mine) {
            videoRef.current.muted = true;
        }
        console.log("creat");
    }, [])

    

    return (
        <div className={`${(props.is_mine) ? styles.my_video_component : styles.video_component}`} ref={ref}>
            <video ref={videoRef} playsInline autoPlay style={{ width: "200px", height: "200px" }} />
        </div>
    );

}

export default VideoComponent;