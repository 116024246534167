import React, {  useEffect, useRef, useState } from 'react';
import Flashphoner from "../../node_modules/@flashphoner/websdk/flashphoner.js"

const RtspPlayerComponent = (props) => {
    const streamRef = useRef();
    const thisRef = useRef();
    const ref = useRef();

    const [width, setWidth] = useState();
    const [height, setHeight] = useState();

    useEffect(() => {
        let SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
        let STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;

        Flashphoner.init({});

        var session = Flashphoner.createSession({
            urlServer: "wss://demo.flashphoner.com"
        }).on(SESSION_STATUS.ESTABLISHED, function(session) {
            //publishStream(session);
            // session.createStream({
            //         name: "rtsp://admin:abcd1234@82.78.147.204:7892/cam/realmonitor?channel=1&subtype=0&unicast=true&proto=Onvif", //specify the RTSP stream address
            //         display: document.getElementById("play"),
            //     }).play();
            streamRef.current = session.createStream({
                //name: "rtsp://admin:abcd1234@82.78.147.204:7892/cam/realmonitor?channel=1&subtype=0&unicast=true&proto=Onvif",
                name: props.streamUrl,
                display: thisRef.current,
                
            }).on(STREAM_STATUS.PLAYING, function(stream) {
                document.getElementById(stream.id()).addEventListener('resize', function(event){   
                    let width = props.WIDTH;

                    

                    let height = width /(event.target.videoWidth / event.target.videoHeight);
                   

                    thisRef.current.style.transformOrigin = 'top left';
                    thisRef.current.style.transform = `scale(${width / event.target.videoWidth}, ${height / event.target.videoHeight})`;

                    thisRef.current.style.marginLeft = "10px"
                    //thisRef.current.style.marginTop = "5px"

                    //ref.current.style.width = width;
                    //ref.current.style.height = height;
                    setWidth(width);
                    setHeight(height);                     
                });                
            })           

            streamRef.current.play();            
        });

        return () => {
            if(streamRef.current)
                streamRef.current.stop();
        }
    }, [])

    return (
        <div ref={ref} style={{ width:{width}, height:"326px"}}>
            <div ref={thisRef} id="play" ></div>
        </div>
    );

}

export default RtspPlayerComponent;