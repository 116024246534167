import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from '../styles.module.css'
import Lottie from 'react-lottie-player'
import backBtn from '../sip_components/dialer_button_svg/backspace.svg'
import clearBtn from '../sip_components/dialer_button_svg/clear-btn.svg'



//animation
import buttonAnimation from '../../../graphics/animations/sm-button-tap.json'

const DialerButton = ({ digit, number, setNumber, createCallerName, isBackBtn = false, isClearBtn = false }) => {
    const [isPlayingMediaAnim, setIsPlayingMediaAnim] = useState(false);
    const [isAnimFinished, setIsAnimFinished] = useState(true);

    useEffect(() => {


        setIsPlayingMediaAnim(false);

    }, []);

    const onKeyPressed = (event) => {

        if (isAnimFinished) {
            setIsAnimFinished(false);
            setIsPlayingMediaAnim(true);
            setNumber(number);

            if (number.length < 13) {
                var aux_number = number + digit;
                setNumber(aux_number);

                createCallerName(aux_number);
            }
        }
    };

    const onBack = () => {
        var aux_number = number.substring(0, number.length - 1);
        setNumber(aux_number);

        createCallerName(aux_number);
    };

    const onClear = () => {
        setNumber("");

        createCallerName("");
    };


    return (
        <div>
            {!isBackBtn && !isClearBtn &&
                <div className={styles.lottie_dialer_button} onTouchEnd={(e) => onKeyPressed(e)}>
                    
                    <div className={styles.button_border_gradient}/>
                    <div className={styles.button_border_background}/>
                    <Lottie
                        loop={false}
                        animationData={buttonAnimation}
                        play={isPlayingMediaAnim}
                        onComplete={() => { setIsPlayingMediaAnim(false); setIsAnimFinished(true); }}
                        segments={[1, 31]} 
                        style={{ position: 'absolute' }}
                    />
                    <div className={styles.lottie_dialer_text}>
                        {digit}
                    </div>
                </div>
            }


            {isBackBtn &&
                <div className={styles.lottie_dialer_button} style={{borderWidth: "0"}} onTouchEnd={() => onBack()}>
                    <img draggable={false} src={backBtn} alt={"backBtn"} className={styles.lottie_dialer_text} style={{left: "20px", top: "22px"}} onTouchEnd={() => onBack()} />
                </div>
            }

            {isClearBtn &&
                <div className={styles.lottie_dialer_button} style={{borderWidth: "0"}} onTouchEnd={() => onClear()}>
                    <img draggable={false} src={clearBtn} alt={"clear"} className={styles.lottie_dialer_text} style={{left: "13px", top: "22px"}} onTouchEnd={() => onClear()} />
                </div>
            }

        </div>


    )
}

export default DialerButton;