import { useContext, useState, useEffect, useRef} from 'react';
import styles from './../styles.module.css'
import { GlobalContext } from "../contexts/globalContext"
import jwt_decode from 'jwt-decode'
import InputTextComponent from './keyboard/InputTextComponent'


const LoginComponent = () => {
    const [currentSiteId, setCurrentSiteId] = useState(null)
    const [log, setLog] = useState(false)
    const decoded = useRef()   

    const { socketRef, setLogged, setCurrentUser, mapRef, setCurrentSite, sites, setSites, setHaveSipCredentials } = useContext(GlobalContext);

    const onLoad = async (e) => {
        try {
            var url = e.target.contentWindow.location.href;
            var token = getQueryVariable(url, "token");
            decoded.current = jwt_decode(token)
            loadUser();
        } catch (ex) {
        }
    };

    function getQueryVariable(url, variable) {
        var splits = url.split("?");
        var query = splits[1];
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    function saveUser() { 
        
        var body = JSON.stringify({  name: decoded.current.userName, user_email: decoded.current.email, site_id: currentSiteId});
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body
        };
        fetch(process.env.REACT_APP_API_URL + '/user.save', requestOptions)
            .then(response => response.json())
            .then(data => {
                SetDataAndFly(data);                 
            }
        );
    };

    function SetDataAndFly(data) {
        setCurrentSite(data.site)

        if ("sip_user" in decoded.current && "sip_pass" in decoded.current) {
            data["sip_user"] = decoded.current.sip_user;
            data["sip_pass"] = decoded.current.sip_pass;
            setHaveSipCredentials(true);
        }         
        
        
        setCurrentUser(data)
        
        setLogged(true)

        // const collection = document.getElementsByClassName("mapboxgl-control-container");
        
        // for (let element of collection) {
        //     element.remove();
        // };

       

        if (data.site) {
            mapRef.current.flyTo({
                center: [data.site.location[1], data.site.location[0]],
                zoom: 14,
                essential: true 
            });
        } else {
            socketRef.current.emit("JOIN_ADMINS");

            mapRef.current.flyTo({
                center: [24.746755, 45.984165],
                zoom: 6,
                essential: true 
            });
        }
    }

    function loadUser() {
        fetch(process.env.REACT_APP_API_URL + '/user/' + decoded.current.email)
            .then((response) => response.json())
            .then((data) => {               
                if (data !== null){
                    SetDataAndFly(data);
                } else {
                    if (decoded.current.role == "ADMIN") {
                        saveUser()
                    } else {
                        setLog(true);
                    }                 
                } 
            }
        )
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/sites')
            .then((response) => response.json())
            .then((data) => {
                setSites(data)
            }
        )
    }, [])

    
    function buttonClickHandler() {
        saveUser()
    };

    return (
        <div className={`${styles.login_component}`} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT)}} >
            <div className={`${styles.login_iframe_bck}`}>
                <iframe id="revealPresentation" title="login" className={`${styles.login_iframe}`} src={process.env.REACT_APP_AUTH_URL}  onLoad={onLoad} /> 
            </div>
            {log && <div className={`${styles.sites_component}`}>
                {sites && sites.map(site => (
                    <div className={`${(currentSiteId == site._id) ? styles.site_button_clicked : styles.site_button}`} key={site._id} style={{width: "500px"}} onClick={() => setCurrentSiteId(site._id)}>
                        {site.name}
                    </div>
                ))}
                <button style={{position:"absolute", bottom:"60px", right:"60px", fontSize:"20px"}} onClick={buttonClickHandler}>Confirm</button>  
            </div>}
        </div>
    );
}

export default LoginComponent;