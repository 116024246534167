import React, { useState, useRef } from "react"

export const GlobalContext = React.createContext([{}, () => {}])

export const GlobalProvider = ( props ) => {
    const [drawingLayer, setDrawingLayer] = useState(0); // 1 Rectangle, 2 Polyline, 3 Polygon, 4 Circle, 5 FreeHandDrawing, 6 Parallelogram, 7 Rectangle Graphic
    const [devices, setDevices] = useState([]);
    const [drawings, setDrawings] = useState([]);
    const [symbols, setSymbols] = useState([]);
    const [files, setFiles] = useState([]);
    const [filesToBeOpen, setFilesToBeOpen] = useState([]);    
    const [symbolsData, setSymbolsData] = useState([]);
    const [sites, setSites] = useState([])
    const [alerts, setAlerts] = useState([])

    const [currentShape, setCurrentShape] = useState()
    const [currentRepetition, setCurrentRepetition] = useState(); 
    const [currentSpikes, setCurrentSpikes] = useState(); 

    const [showKeyboard, setShowKeyboard] = useState(false);
    const [confrenceStarted, setConfrenceStarted] = useState(false);

    const [currentProjectId, setCurrentProjectId] = useState(null);
    const [projectSites, setProjectSites] = useState([]);
    
    const [currentUser, setCurrentUser] = useState(null);
    const [currentSite, setCurrentSite] = useState(null);
    const [currentShapeName, setCurrentShapeName] = useState("");
    const [selectedFeature, setSelectedFeature] = useState("");

    const [devicesForComponents, setDevicesForComponents] = useState([]);
    const [symbolsForEditing, setSymbolsForEditing] = useState([]);

    const [devicesForAccesControl, setDevicesForAccesControl] = useState([]);
    const [devicesForFireDetection, setDevicesForFireDetection] = useState([]);
    
    /* Veronika Meteo*/
    const [jsonForMeteoExpand, setJsonForMeteoExpand] = useState([]);

    const [showInviteButton, setShowInviteButton] = useState(false);
    const [seeElevationGraph, setSeeElevationGraph] = useState(false);   

    const [monitorLeftSize, setMonitorLeftSize] = useState(null);
    const [monitorRightSize, setMonitorRightSize] = useState(null);
    const [deviceForLeftMonitor, setDeviceForLeftMonitor] = useState(null);    
    const [deviceForRightMonitor, setDeviceForRightMonitor] = useState(null);    

    const keyboardRef = useRef();
    const socketRef = useRef();
    const mapRef = useRef(null);
    const drawingsRef = useRef();
    
    
    const inputProjectNameRef = useRef();
    const [currentProjectName, setCurrentProjectName] = useState("Proiect");

    const [currentInputForKeyboard, setCurrentInputForKeyboard] = useState(null);


    const [logged, setLogged] = useState(false);
    const [isForDesk, setIsForDesk] = useState(false);

    const [currentDrawingColor, setCurrentDrawingColor] = useState("F");

    const [seeLoS, setSeeLoS] = useState(false);
    const [seeSpecificLoS, setSeeSpecificLoS] = useState(false);
    

    const [projectsMenuOpened, setProjectsMenuOpened] = useState(false);
    const [invitesMenuOpened, setInvitesMenuOpened] = useState(false);
    const [filesMenuOpened, setFilesMenuOpened] = useState(false);
    const [browserMenuOpened, setBrowserMenuOpened] = useState(false);
    const [phoneMenuOpened, setPhoneMenuOpened] = useState(false);
    const [symbolsMenuOpened, setSymbolsMenuOpened] = useState(false);
    const [newProjectsMenuOpened, setNewProjectsMenuOpened] = useState(false);
    const [saveNewProjectsMenuOpened, setSaveNewProjectsMenuOpened] = useState(false);
    const [devicesMenuOpened, setDevicesMenuOpened] = useState(false);
    const [haveSipCredentials, setHaveSipCredentials] = useState(false);
    
    
    const [sipRegistered, setSipRegistered] = useState(false);

    
  
    const [saveNewProjectsMenuPosition, setSaveNewProjectsMenuPosition] = useState(false);
    const [projectsMenuPosition, setProjectsMenuPosition] = useState(false);
    const [losMenuPosition, setLosMenuPosition] = useState(false);
    const [symbolsMenuPosition, setSymbolsMenuPosition] = useState(false);
    const [sitesMenuPosition, setSitesMenuPosition] = useState(false);
    const [devicesMenuPosition, setDevicesMenuPosition] = useState(false);
    const [browserMenuPosition, setBrowserMenuPosition] = useState(false);
    const [closeDashboard, setCloseDashboard] = useState(false);
    // const [newProjectsMenuPosition, setNewProjectsMenuPosition] = useState(false);

    
    
   
    
    
    
    return (
        <GlobalContext.Provider value={{drawingLayer, setDrawingLayer, devices, setDevices, drawings, setDrawings, symbols, setSymbols, 
                                        showKeyboard, setShowKeyboard, 
                                        keyboardRef, inputProjectNameRef, currentInputForKeyboard, setCurrentInputForKeyboard,
                                        currentProjectId, setCurrentProjectId, socketRef,logged, setLogged, confrenceStarted, setConfrenceStarted,
                                        currentUser, setCurrentUser, mapRef,
                                        currentDrawingColor, setCurrentDrawingColor,
                                        currentSite, setCurrentSite, sites, setSites,
                                        alerts, setAlerts, drawingsRef,
                                        devicesForComponents, setDevicesForComponents, jsonForMeteoExpand, setJsonForMeteoExpand, symbolsData, setSymbolsData,
                                        currentShape, setCurrentShape,
                                        currentShapeName, setCurrentShapeName, selectedFeature, setSelectedFeature, showInviteButton, setShowInviteButton, 
                                        projectSites, setProjectSites, seeElevationGraph, setSeeElevationGraph, symbolsForEditing, setSymbolsForEditing,
                                        seeLoS, setSeeLoS, 
                                        monitorLeftSize, setMonitorLeftSize, monitorRightSize, setMonitorRightSize,
                                        deviceForLeftMonitor, setDeviceForLeftMonitor, deviceForRightMonitor, setDeviceForRightMonitor,
                                        files, setFiles, filesToBeOpen, setFilesToBeOpen,
                                        currentRepetition, setCurrentRepetition, currentSpikes, setCurrentSpikes,
                                        projectsMenuOpened, setProjectsMenuOpened,
                                        invitesMenuOpened, setInvitesMenuOpened,
                                        filesMenuOpened, setFilesMenuOpened,
                                        browserMenuOpened, setBrowserMenuOpened,
                                        symbolsMenuOpened, setSymbolsMenuOpened,
                                        newProjectsMenuOpened, setNewProjectsMenuOpened,
                                        currentProjectName, setCurrentProjectName,
                                        saveNewProjectsMenuOpened, setSaveNewProjectsMenuOpened,
                                        saveNewProjectsMenuPosition, setSaveNewProjectsMenuPosition,
                                        projectsMenuPosition, setProjectsMenuPosition,
                                        seeSpecificLoS, setSeeSpecificLoS,
                                        losMenuPosition, setLosMenuPosition,
                                        symbolsMenuPosition, setSymbolsMenuPosition,
                                        sitesMenuPosition, setSitesMenuPosition,
                                        devicesMenuOpened, setDevicesMenuOpened,
                                        devicesMenuPosition, setDevicesMenuPosition,
                                        browserMenuPosition, setBrowserMenuPosition,
                                        sipRegistered, setSipRegistered,
                                        phoneMenuOpened, setPhoneMenuOpened,
                                        haveSipCredentials, setHaveSipCredentials,
                                        closeDashboard, setCloseDashboard,
                                        isForDesk, setIsForDesk,
                                        devicesForAccesControl, setDevicesForAccesControl,
                                        devicesForFireDetection, setDevicesForFireDetection
                                    }}>
            {props.children}
        </GlobalContext.Provider>
    )
}