import { useRef, useState, useContext, useEffect } from 'react';
import styles from '../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { TransformPoints } from '../../utils/Tools'
import { GlobalContext } from "../../contexts/globalContext"
import uuid from 'react-uuid';
import { ApiSaveProject } from "../../utils/RestServices"

const InclinedRectangleDrawLayer = (props) => {
    const ref = useRef(null);
    const rectangleRef = useRef(null);

    const {mapRef, setDrawingLayer, drawingsRef, currentProjectId, socketRef, currentDrawingColor } = useContext(GlobalContext);
    const [currentRectangle, setCurrentRectangle] = useState([]);
    const [currentFourth, setCurrentFourth] = useState([]);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    function RectangleFromThreePoints (points) {
        return [points[0][0] + (points[2][0] - points[1][0]), points[0][1] + (points[2][1] - points[1][1])]
    };

    useGesture(
        {
            onDrag: ({ pinching, cancel, xy: [ox, oy], first }) => {
                if (pinching) {
                    return cancel()
                }

                if (first) {
                    setCurrentRectangle([...currentRectangle, [ox, oy]]);
                } else {
                    const updatedPoints = currentRectangle.slice(0, -1);
                    updatedPoints.push([ox, oy]);
                    setCurrentRectangle(updatedPoints);
                }

                const thirdPoint = currentRectangle[2];

                if (thirdPoint) {
                    const fourthPoint = RectangleFromThreePoints(currentRectangle)
                    setCurrentFourth(fourthPoint)
                }
            },
            onDragEnd: () => {
                const thirdPoint = currentRectangle[2];

                if (thirdPoint) {
                    const fourthPoint = RectangleFromThreePoints(currentRectangle)

                    const updatedPoints = currentRectangle;
                    updatedPoints.push(fourthPoint);

                    setCurrentFourth([])

                    const points = TransformPoints([updatedPoints[0],updatedPoints[1],updatedPoints[2],updatedPoints[3],updatedPoints[0]], props.map);

                    setCurrentRectangle([]);
                    setDrawingLayer(0)

                    const uid = uuid();

                    if (currentProjectId == null) {
                        var feature = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'Polygon', coordinates: points }
                        };
                        drawingsRef.current.add(feature)
                    } else {
                        var feature = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'Polygon', coordinates: points }
                        };

                        drawingsRef.current.add(feature);
                        socketRef.current.emit("DRAWING_UPDATED", {project_id: currentProjectId, drawings: feature});
                        var body = JSON.stringify({ project_id: currentProjectId, drawing: feature });
                        ApiSaveProject(body);                        
                    }
                }
            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
        })


    const xDrawingPointRectangleSize = 10 / oxDivider;
    const yDrawingPointRectangleSize = 10 / oyDivider;

    function showLatLng(point) {
        const coord = TransformPoints([point], props.map)
        return `${coord[0][0][0].toFixed(3)}, ${coord[0][0][1].toFixed(3)}`
    }
    function showElevationOld(point) {
        const coord = TransformPoints([point], props.map)
        const el = mapRef.current.queryTerrainElevation([coord[0][0][0], coord[0][0][1]])
        return `${el.toFixed(3)}`
    }

    const [elevations, setElevations] = useState([]);

    async function showElevation(point) {
        try {
          const coord = TransformPoints([point], props.map);
          const response = await fetch(process.env.REACT_APP_TOPODATA_URL + coord[0][0][0] + "," + coord[0][0][1]);
          const data = await response.json();
          const elevationValue = data.results[0].elevation;
          return elevationValue.toFixed(3)
        } catch (error) {
          console.error("Error fetching elevation data:", error);
        }
    }

    async function fetchElevations(points) {
        const elevations = [];
    
        for (const [x, y] of points) {
            const elevation = await showElevation([x, y]);
            elevations.push(elevation);
        }
    
        return elevations;
    }
    
    
    useEffect(() => {
        if (currentRectangle.length > 0) {
            fetchElevations(currentRectangle).then((elevations) => {
                setElevations(elevations);
            });
        }
    }, [currentRectangle]);

    return (

        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT)}} >
            <svg style={{ width: '100%', height: '100%' }}>
                {currentRectangle.length === 2 &&  (
                    <polyline
                        ref={rectangleRef}
                        points={`${currentRectangle[0][0]},${currentRectangle[0][1]} ${currentRectangle[1][0]},${currentRectangle[1][1]}`}
                        style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4"}}
                    />
                )}
                {currentRectangle.length === 3 && currentFourth.length === 2 &&  (
                    <polygon
                        ref={rectangleRef}
                        points={`${currentRectangle[0][0]},${currentRectangle[0][1]} ${currentRectangle[1][0]},${currentRectangle[1][1]} ${currentRectangle[2][0]},${currentRectangle[2][1]} ${currentFourth[0]},${currentFourth[1]}`}
                        style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4"}}
                    />
                )}
                {currentRectangle.length === 4 &&  (
                    <polygon
                        ref={rectangleRef}
                        points={currentRectangle.map(([x, y]) => `${x},${y}`).join(' ')}
                        style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4" }}
                    />
                )}
                {currentRectangle.map(([x, y], index) => (
                    <g key={index}>
                        <text x={x-5*xDrawingPointRectangleSize} y={y+2*yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >
                            {elevations[index]}m, {showLatLng([x, y])}
                        </text>
                        <circle
                            cx = {x}
                            cy = {y}
                            r = {10}
                            style={{ fill: '#fbb03b', stroke: 'white', strokeWidth: 4 }} 
                        />
                    </g>
                ))}
            </svg>
        </div>
    );
}

export default InclinedRectangleDrawLayer;