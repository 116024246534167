import styles from '../css/dashboard.module.css'
import meteoStyles from '../css/meteolist.module.css'
import Sunny from '../../../graphics/dashboard icons/wi-day-sunny.svg'
import Cloudy from '../../../graphics/dashboard icons/wi-cloudy.svg'
import Humidity from '../../../graphics/dashboard icons/wi-humidity.svg'
import Expand from '../../../graphics/dashboard icons/icons meteo/btn-minimize.svg'
import Wind from '../../../graphics/dashboard icons/wi-strong-wind.svg'
import Precipitation from '../../../graphics/dashboard icons/wi-raindrop.svg'
import WindDirection from '../../../graphics/dashboard icons/wi-wind-deg.svg'
import lottieJson from '../../../graphics/dashboard icons/item-lista-activ.json'
import LocationBtn from '../../../graphics/dashboard icons/icons meteo/btn-location.svg'
import Lottie from 'react-lottie-player'
import lottieJsonReady from '../../../graphics/dashboard icons/item-lista-ready.json'

import SunnyYellow from '../../../graphics/dashboard icons/wi-day-sunny_yellow.svg'
import SunnyOvercast from '../../../graphics/dashboard icons/wi-day-sunny-overcast.svg'
import Snow from '../../../graphics/dashboard icons/wi-snow.svg'
import Rain from '../../../graphics/dashboard icons/wi-rain.svg'
import RainMP4 from '../../../graphics/dashboard icons/icons meteo/wi-rain.mp4'
import CloudyMP4 from '../../../graphics/dashboard icons/icons meteo/wi-cloudy.mp4'
import DaySunny from '../../../graphics/dashboard icons/icons meteo/day-sunny.mp4'

import MeteoGraphSVG from './MeteoGraphSVG'

import SunnyDay from '../../../graphics/dashboard icons/photos/day-sunny.png'
import CloudyDay from '../../../graphics/dashboard icons/photos/wi-cloudy.png'
import RainyDay from '../../../graphics/dashboard icons/photos/wi-rain.png'

import { GlobalContext } from "../../../contexts/globalContext";

/* Import of jsons */

import wWBucuresti from '../jsons/jsonBucuresti.json'
import wWIasi from '../jsons/jsonIasi.json'
import wWCluj from '../jsons/jsonCluj.json'
import wWBusteni from '../jsons/jsonBusteni.json'
import wWConstanta from '../jsons/jsonConstanta.json'
import wWRasnov from '../jsons/jsonRasnov.json'
import wWComun from '../jsons/jsonComun.json'

import { useState, useLayoutEffect, useContext, useEffect } from 'react'


const MeteoExpandComponent = (props) => {
    
    const {jsonForMeteoExpand, setJsonForMeteoExpand, mapRef} = useContext(GlobalContext);
    const [clickedButton, setClickedButton] = useState(0);
    const [clickedDay, setClickedDay] = useState(0);   
    const [sortedWeekWeather, setSortedWeekWeather] = useState([]); 
    const [pozSelectedDay, setPozSelectedDay] = useState(0); 
    const [weekWeather, setWeekWeather] = useState(wWRasnov);


    const [xValues, setXValues] = useState(['02:00', '05:00', '08:00', '11:00', '14:00', '17:00', '20:00', '23:00']); 

    const [yValues, setYValues] = useState([]); 

    const [isCurrent, setIsCurrent] = useState(true);

    const jsonElem = props.meteo;

    const [jsonCurrentElement, setJsonCurrentElement] = useState(jsonElem);

    const goToDevice = meteo => {
            if(meteo.pointLat !== undefined && meteo.pointLong !== undefined) {
            mapRef.current.flyTo({
                center: [meteo.pointLat, meteo.pointLong],
                zoom: 18,
                essential: true
            });
        }
    };

    useEffect(() => {
        const fetchWeekWeather = () => {
            try {
                let locationData;
                if (props.meteo.location.includes('Bucuresti')) {
                    locationData = wWBucuresti;
                } else if (props.meteo.location.includes('Busteni')) {
                    locationData = wWBusteni;
                } else if (props.meteo.location.includes('Iasi')) {
                    locationData = wWIasi;
                } else if (props.meteo.location.includes('Constanta')) {
                    locationData = wWConstanta;
                } else if (props.meteo.location.includes('Cluj')) {
                    locationData = wWCluj;
                } else if (props.meteo.location.includes('Rasnov')) {
                    locationData = wWRasnov;
                } else {
                    // console.log(props)
                    locationData = wWComun;
                }
                // console.log('location', locationData);
                setWeekWeather(locationData);
            } catch (error) {
                console.error('Error fetching week weather:', error);
            }
        };
    
        fetchWeekWeather();
    }, []);
    



    useEffect(() => {
        setYValues(jsonCurrentElement.temperatures);
    }, [jsonCurrentElement]);

    useEffect(() => {
        const currentDate = new Date();
        const idCurrent = currentDate.getDay() - 1;
        // console.log(weekWeather[clickedDay]);

        if(clickedDay === idCurrent) {
            setJsonCurrentElement(jsonElem);
            setIsCurrent(true);
        } else {
            setJsonCurrentElement(weekWeather[clickedDay]);
            setIsCurrent(false);
        }
    }, [clickedDay, weekWeather])

    useEffect(() => {

        const currentDate = new Date();
        const id = currentDate.getDay() - 1;
        setClickedDay(id);

        const index = weekWeather.findIndex(item => item.id === id);
    
        const beforeId = weekWeather.slice(0, index);
        const afterId = weekWeather.slice(index + 1);
        const idElement = weekWeather.slice(index, index + 1);

        setSortedWeekWeather(idElement.concat(afterId).concat(beforeId));

    }, [weekWeather]);

    function getDayName(date) {
        const days = ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata', 'Duminica'];
        return days[date.getDay()-1];
    }

    const getMonthNameRO = (monthIndex) => {
        const monthsRO = [
            'ianuarie', 'februarie', 'martie', 'aprilie', 'mai', 'iunie',
            'iulie', 'august', 'septembrie', 'octombrie', 'noiembrie', 'decembrie'
        ];
        return monthsRO[monthIndex];
    };
    
    const getFormattedDateRO = (date) => {
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const month = getMonthNameRO(monthIndex);
        return `${day} ${month}`;
    };

    return (
        <div className={`${meteoStyles.expandDiv}`}>
        {/* {console.log(props.meteo)} */}
            <div className={meteoStyles.expand_inner_shadow}/>
            <Lottie
                className={`${styles.activAnimation}`}
                loop
                animationData={jsonElem.status === 'live' && isCurrent ? lottieJson : lottieJsonReady}
                play
            />
            <label className={`${styles.nameLabel}`}> {jsonElem.location} </label>
            <label className={`${styles.siteLabel}`}> 15.6451155 25.6554833 </label>
            <div className={`${styles.labelDiv}`} style={{left: '365.25px', top: '21px'}}>
                <label className={`${styles.labelActivVehicle}`} style={{ color: isCurrent &&  jsonElem.status === 'live' ? '#12e5dd' : '#ffffff', textTransform:"capitalize" }}>  
                    {isCurrent ?  jsonElem.status : 'Prognoza'} 
                </label>
            </div>
            <div className={`${styles.callBtnDiv}`} style = {{left: '422.56px', top: '8px', width:"34px", height:"34px"}}
            onTouchEnd={(event) => {setJsonForMeteoExpand(jsonForMeteoExpand => jsonForMeteoExpand.filter(a => a.id !== jsonElem.id));}}>
                <img className={`${styles.btn}`} style={{width:"34px", height:"34px"}} alt="icon" draggable="false" src={Expand}/>
            </div>
            <div className={`${styles.locationBtnDiv}`} style = {{left: '458.64px', top: '8px', width:"34px", height:"34px"}}>
                <img className={`${styles.btn}`} style={{width:"34px", height:"34px"}} alt="icon" draggable="false" onTouchEnd={(event) => {
					event.stopPropagation()
					goToDevice(jsonElem)}} src={LocationBtn}/>
            </div>
            <div style={{ top: '72px',left: '25px', position: 'absolute'}}> 
            {jsonCurrentElement.status !== 'live' && <img className={`${meteoStyles.meteoTip}`} alt="icon" draggable="false" src={(() => {
                    switch (jsonCurrentElement.weather) {
                        case 'sunny':
                            return SunnyYellow;
                        case 'cloudy':
                            return Cloudy;
                        case 'rain':
                            return Rain;
                        default:
                            return SunnyYellow;
                        }
                    })()}/>}
            </div>
            <div style={{position:"absolute", width:"500px", height:"200px", top:"30px"}}>
                <div className={`${meteoStyles.divCelsius}`}>
                    <div className={`${meteoStyles.valCelsius}`}>{Math.round(parseFloat(jsonCurrentElement.celsius))+'°'}</div>
                    <div className={`${meteoStyles.lineCelsius}`}></div>
                    {jsonCurrentElement.status !== 'live' && <>
                    <label className={`${meteoStyles.labelMaxCelsius}`}> {jsonCurrentElement.max} </label>
                    <label className={`${meteoStyles.labelMinCelsius}`}> {jsonCurrentElement.min} </label>
                    </>}
                </div>
                <div className={`${meteoStyles.divHumidity}`}>
                    <img className={`${meteoStyles.IconWeather}`} style={{ height: '12px'}} alt="icon" draggable="false" src={Humidity}/>
                    <label className={`${meteoStyles.labelOptions}`} style={{left: '21px', top: '6px'}}> {jsonCurrentElement.humidity} </label>
                </div>
                <div className={`${meteoStyles.divWind}`}>
                    <img className={`${meteoStyles.IconWeather}`} style={{ height: '12px'}} alt="icon" draggable="false" src={Wind}/>
                    <label className={`${meteoStyles.labelOptions}`} style={{left: '21px', top: '6px'}}> {jsonCurrentElement.wind} </label>
                </div>
                <div className={`${meteoStyles.divPrecipitation}`}>
                    <img className={`${meteoStyles.IconWeather}`} style={{ height: '12px'}} alt="icon" draggable="false" src={Precipitation}/>
                    <label className={`${meteoStyles.labelOptions}`} style={{left: '21px', top: '6px'}}> {jsonCurrentElement.precipitation} </label>
                </div>
                <div className={`${meteoStyles.divNen}`}>
                    <img className={`${meteoStyles.IconWeather}`} style={{ height: '12px', transform: 'rotate(45deg)'}} alt="icon" draggable="false" src={WindDirection}/>
                    <label className={`${meteoStyles.labelOptions}`} style={{left: '21px', top: '6px'}}> {jsonCurrentElement.windDirection} </label>
                </div> 
                {jsonCurrentElement.status !== 'live' && <div> 
                <video
                    key={jsonCurrentElement.id}
                    className={`${meteoStyles.image_expand}`}
                    poster={(() => {
                        switch (jsonCurrentElement.weather) {
                            case 'sunny':
                                return SunnyDay;
                            case 'cloudy':
                                return CloudyDay;
                            case 'rain':
                                return RainyDay;
                            default:
                                return SunnyDay;
                            }
                        })()}
                    width="150px"
                    height="80px"
                    style={{ top: '25px', left: '345px', position: 'absolute' }}
                    playsInline autoPlay loop
                >
                    <source src={(() => {
                    // console.log(jsonCurrentElement.weather);
                    switch (jsonCurrentElement.weather) {
                        case 'sunny':
                            return DaySunny;
                        case 'cloudy':
                            return CloudyMP4;
                        case 'rain':
                            return RainMP4;
                        default:
                            return DaySunny
                            ;
                        }
                    })()} type="video/mp4" />
                </video>
            </div> }
            </div>
            
            <div className={`${meteoStyles.divDate}`}>
            <div className={`${meteoStyles.expandDate}`}>
                {isCurrent ? getFormattedDateRO(new Date()) : ''}
            </div>
            
            <label className={`${meteoStyles.expandDayHour}`}>
                {isCurrent ? getDayName(new Date()) + ' ' + new Date().getHours() + ':00'  : jsonCurrentElement.day}
            </label>
            {jsonCurrentElement.status !== 'live' && <label className={`${meteoStyles.weatherType}`}>
                    {(() => {
                        switch (jsonCurrentElement.weather) {
                            case 'sunny':
                                return "Soare";
                            case 'cloudy':
                                return "Innorat";
                            case 'rain':
                                return "Ploaie";
                            default:
                                return "Soare";
                        }
                    })()}
                </label> }
            </div>
            <div className={`${meteoStyles.divFilter}`}>
                <label className={`${meteoStyles.labelTemperatura}`} style={{left: '0px'}} onTouchEnd={(event) => setClickedButton(0)}> Temperatura </label>
                <label className={`${meteoStyles.labelTemperatura}`} style={{left: '56px'}} onTouchEnd={(event) => setClickedButton(1)}> Precipitatii </label>
                <div className={`${meteoStyles.divBara}`}/>
                <label className={`${meteoStyles.labelTemperatura}`} style={{left: '115px'}} onTouchEnd={(event) => setClickedButton(2)}> Vant </label>    
            </div>

            {/* <canvas id={canvasId} style={{left: '10px', top: '176px', width: '480px', height: '80px', position: 'absolute'}}>
            </canvas> */}

            <MeteoGraphSVG style={{left: '10px', top: '176px', width: '480px', height: '80px', position: 'absolute'}} xValues={xValues} yValues={yValues} />

            <div className={`${meteoStyles.divClicked}`} style={{left:  (() => {
                switch (clickedButton) {
                    case 0:
                        return '10px';
                    case 1:
                        return '66px';
                    case 2:
                        return '125px';
                    default:
                        return '10px'
                    }
                })(),
                width: (() => {
                    switch (clickedButton) {
                        case 0:
                            return '46px';
                        case 1:
                            return '39px';
                        case 2:
                            return '16px';
                        default:
                            return '46px'
                        }
                    })()}}>
            </div>
            <div className={`${meteoStyles.divDays}`} style={{display: 'flex', flexDirection: 'row'}}>
                <div className={`${meteoStyles.divImpartire}`}/>
                <div className={meteoStyles.background_select_day} style={{left: `${pozSelectedDay}px`}}/>
                {sortedWeekWeather.map((meteoElem, index) => (
                    <div key={index} className={`${meteoStyles.divDay}`} onTouchEnd={(event) => {
                        setClickedDay(meteoElem.id)
                        setPozSelectedDay(index * 70)
                    }} >
                        <label className={`${meteoStyles.shortDay}`}> {meteoElem.shortName} </label>
                        <img className={`${meteoStyles.iconWeek}`} alt="icon" draggable="false"
                            src={(() => {
                                switch (meteoElem.weather) {
                                    case 'sunny':
                                        return Sunny;
                                    case 'cloudy':
                                        return Cloudy;
                                    case 'sunny-overcast':
                                        return SunnyOvercast;
                                    case 'snow':
                                        return Snow;
                                    case 'rain': 
                                        return Rain;
                                    default:
                                        return Sunny;
                                    }
                                })
                            ()}
                        />
                     
                        <label className={`${meteoStyles.celsiusWeek}`} style={{left: '15px'}}> {(new Date().getDay() - 1 === meteoElem.id) ?  Math.max(...jsonElem.temperatures) + '°' :  Math.max(...meteoElem.temperatures) + '°'}</label>
                        <label className={`${meteoStyles.celsiusWeek}`} style={{left: '32px'}}> {(new Date().getDay() - 1 === meteoElem.id) ? Math.min(...jsonElem.temperatures) + '°' :  Math.min(...meteoElem.temperatures) + '°'}</label>
                    </div>
                ))}
            </div>
        </div>
    )

}

export default MeteoExpandComponent;