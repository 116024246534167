import { imageOverlay } from 'leaflet';
import styles from '../css/dashboard.module.css'

import FiltersComponent from '../FiltersComponent';

import AlertItem from './AlertItem';

import { translate, compose, toString, identity, fromString } from 'transformation-matrix';
import { useState, useRef, useEffect, useContext } from 'react'
// import Foc from '../../graphics/dashboard icons/
import { MultitouchComponent } from 'use-gesture-pack';

import { GlobalContext } from "../../../contexts/globalContext";

import { ApiGetAllAlerts } from "../../../utils/RestServices"


const AlertsComponent = (props) => {

    const [filterType, setFilterType] = useState(0);
    const [alerts, setAlerts] = useState([]);
    const { socketRef, setDevices } = useContext(GlobalContext);

    const filter_data = (data) => {
        setFilterType(data);
    }

    const ref = useRef();
    const ref0 = useRef();
    // const ref1 = useRef();
    // const ref2 = useRef();
    // const ref3 = useRef();

    useEffect(() => {
        ApiGetAllAlerts(data => {
            setAlerts(data);
        });

        socketRef.current.on("ALERT_ADDED", device => {
            console.log(device);
            setDevices(devices => devices.filter(a => a._id !== device._id));
                setDevices(devices => [...devices, device]);
            ApiGetAllAlerts(data => {
                setAlerts(data);
            });
        })

        socketRef.current.on("ALERT_REMOVED", device => {
            console.log(device);
            setDevices(devices => devices.filter(a => a._id !== device._id));
                setDevices(devices => [...devices, device]);
            ApiGetAllAlerts(data => {
                setAlerts(data);
            });
        })

        return () => {
            socketRef.current.removeAllListeners("ALERT_ADDED");
            socketRef.current.removeAllListeners("ALERT_REMOVED");
        }
    }, [])

    useEffect(() => {
        ref.current = ref0.current;
        // switch (filterType) {
        //     case 0:
        //         ref.current = ref0.current;
        //         break;
        //     case 1:
        //         ref.current = ref1.current;
        //         break;
        //     case 2:
        //         ref.current = ref2.current;
        //         break;
        //     case 3:
        //         ref.current = ref3.current;
        //         break;
        // }

    }, [filterType])

    const onDragInside = ({ dy }) => {
        var mat = identity();

        if (ref.current.style.transform !== '') {
            mat = fromString(ref.current.style.transform);
        }

        var modifier = MapNumber(mat.f, 0, props.height / 4, 1, 0);

        if (modifier == 1) {
            modifier = MapNumber(mat.f,  - (ref.current.offsetHeight - props.height),  - (ref.current.offsetHeight - props.height + (props.height / 4)), 1, 0);
        }      
        dy = dy /props.scale_factor
        var trans = translate(0, modifier * dy);
        var final = compose(trans, mat);
        ref.current.style.transform = toString(final);
    }

    const onGestureEnded = () => {
        var mat = identity();

        if (ref.current.style.transform !== '') {
            mat = fromString(ref.current.style.transform);
        }

        var dy = 0;


        if (ref.current.offsetHeight < props.height) {
            dy = -mat.f;
        } else {
            if (mat.f > 0) {
                dy = -mat.f;
            }

            if (mat.f < - (ref.current.offsetHeight - props.height)) {
                dy = -(ref.current.offsetHeight - props.height) - mat.f;
            }
        }

        if (dy != 0) {
            ref.current.style.transition = "transform 0.2s";
            setTimeout(() => {
                ref.current.style.transition = "transform 0.0s";
            }, 200);
        }

        var trans = translate(0, dy);
        var final = compose(trans, mat);
        ref.current.style.transform = toString(final);
    }

    function MapNumber( theValue,  theInStart,  theInEnd,  theOutStart,  theOutEnd)
        {
            var result = theOutStart + (theOutEnd - theOutStart) * ((theValue - theInStart) / (theInEnd - theInStart));
            if (result <0) {
                return result = 0;
            }
            if (result > 1) {
                return result = 1;
            }
            return result;
        }


    return (
        <MultitouchComponent width={600} height={300} dragGesture={false} pinchGesture={false} touchAndHoldGesture={false} tapGesture={false} onDragInside={onDragInside} onGestureEnded={onGestureEnded}>
            <div className={`${styles.dashboardElements}`} style={{ top: `${props.top}px`, height: `${props.height}px`, transformOrigin:"top left", transform:`scale(${props.scale_factor})` }}>
                <FiltersComponent text={"Alerte"} func={filter_data} height={props.height} />
                {filterType === 0 && <div className={`${styles.vehicleBody}`} style={{ height: `${props.height}px` }}>
                    <div ref={ref0} style={{ position: "absolute", paddingBottom:"20px", paddingTop:"25px" }}>
                        {/* <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Activ </label>
                            <AlertList tip='status' status='activ' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Solutionat </label>
                            <AlertList tip='status' status='solutionat' />
                        </div> */}
                        {alerts.map((alert) => (
                           <AlertItem key={alert._id} alert={alert}></AlertItem>
                        ))}
                    </div>
                </div>}
                {/* {filterType === 1 && <div className={`${styles.vehicleBody}`} style={{ height: `${props.height - 5}px` }}>
                    <div ref={ref1} style={{ position: "absolute" }}>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Incendiu </label>
                            <AlertList tip='type' status='Incendiu' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Cutremur </label>
                            <AlertList tip='type' status='Cutremur' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Inundatie </label>
                            <AlertList tip='type' status='Inundatie' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Poluare </label>
                            <AlertList tip='type' status='Poluare' />
                        </div>
                    </div>
                </div>}
                {filterType === 2 && <div className={`${styles.vehicleBody}`} style={{ height: `${props.height - 5}px` }}>
                    <div ref={ref2} style={{ position: "absolute" }}>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Rasnov </label>
                            <AlertList tip='locatie' status='Rasnov' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Cristian </label>
                            <AlertList tip='locatie' status='Cristian' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Harman </label>
                            <AlertList tip='locatie' status='Harman' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Prejmer </label>
                            <AlertList tip='locatie' status='Prejmer' />
                        </div>
                    </div>
                </div>}
                {filterType === 3 && <div className={`${styles.vehicleBody}`} style={{ height: `${props.height - 5}px` }}>
                    <div ref={ref3} style={{ position: "absolute" }}>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Raza Mica </label>
                            <AlertList tip='proximitate' status='mic' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Raza Medie </label>
                            <AlertList tip='proximitate' status='mediu' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Raza Mare </label>
                            <AlertList tip='proximitate' status='mare' />
                        </div>
                    </div>
                </div>} */}
                <div className={styles.drone_inner_shadow} />
                <div className={styles.drone_inner_shadow_up} />
            </div>
        </MultitouchComponent>
    );
}

export default AlertsComponent;