import React from 'react';
import { useRef, useState, useContext } from 'react';
import styles from '../../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { GlobalContext } from '../../../contexts/globalContext';
import uuid from 'react-uuid';
import { TransformPoints, buffer, between} from '../../../utils/Tools'
import * as turf from "@turf/turf"
import { ApiSaveProject } from "../../../utils/RestServices"


const FreeHandTextGraphicDrawLayer = (props) => {
    const [currentLine, setCurrentLine] = useState([]);
    const ref = useRef(null);
    const freeHandRef = useRef(null);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const {mapRef, setCurrentShapeName, currentShape, setDrawingLayer, drawingsRef, currentDrawingColor, currentProjectId, socketRef } = useContext(GlobalContext);


    function createEndingTextShape(point1, bearing, dist, options){
        var coordinatesText = []

        for(let i = 0; i < currentShape.length; i++){
            let line = []
            for(let j = 0; j < 2; j++){
                let oxDist = dist * currentShape[i][j][0]
                let oyDist = dist * currentShape[i][j][1]
                if(oyDist === 0){
                    var point2 = turf.destination(point1, oxDist, bearing, options)
                    line.push(point2.geometry.coordinates)
                } else if(oyDist > 0){
                    let helpingPoint = turf.destination(point1, oyDist, bearing + 90, options)
                    var point3 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point3.geometry.coordinates)
                } else {
                    let helpingPoint = turf.destination(point1, Math.abs(oyDist), bearing + 270, options)
                    var point4 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point4.geometry.coordinates)
                }
            }
            coordinatesText.push(line)
        }

        return coordinatesText
    }

    useGesture(
    {
        onDrag: ({ xy: [ox, oy] }) => {
            setCurrentLine([...currentLine, [ox, oy]]);
        },
        onDragEnd: () => {
            const points = TransformPoints(currentLine, props.map);
            setCurrentLine([]);

            setDrawingLayer(0);
            setCurrentShapeName("");

            if (currentProjectId == null) {
                let feature = {
                    type: 'Feature',
                    properties: {
                        "class_id": currentDrawingColor
                    },
                    geometry: { type: 'LineString', coordinates: points[0] }
                };

                var zoom = mapRef.current.getZoom();
                var zoomDiff = (11 - zoom) / 5

                if(points[0].length > 1){
                
                    let simplified = turf.simplify(feature, {tolerance: 0.0001});

                    if(0.1 + zoomDiff > 0){
                        var dist = 0.1 + zoomDiff
                    } else {
                        var dist = 0.05
                    }

                    var bearing = turf.bearing(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 2], simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1]);

                    if(bearing >= 0){
                        var options = {units: 'kilometers', bearing};
                        var point1 = turf.destination(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1], dist, bearing, options);
                    } else {
                        var options = {units: 'kilometers', bearing: bearing};
                        var point1 = turf.destination(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1], 4 * dist, bearing, options);
                        bearing = bearing + 180
                    }

                    var bearingBis = turf.bearing(simplified.geometry.coordinates[0], simplified.geometry.coordinates[1]);

                    if(bearingBis >= 0){
                        var optionsBis = {units: 'kilometers', bearing: bearingBis + 180};
                        var point1Bis = turf.destination(simplified.geometry.coordinates[0], 4 * dist, bearingBis + 180, optionsBis);
                    } else {
                        var optionsBis = {units: 'kilometers', bearing: bearingBis + 180};
                        var point1Bis = turf.destination(simplified.geometry.coordinates[0], dist, bearingBis + 180, optionsBis);
                        bearingBis = bearingBis + 180
                    }

                    var coordinatesOne = createEndingTextShape(point1, bearing, dist, options);
                    var coordinatesBis = createEndingTextShape(point1Bis, bearingBis, dist, optionsBis);

                    var coordinates = []

                    for(let i = 0; i < coordinatesOne.length; i ++){
                        coordinates.push(coordinatesOne[i])
                        coordinates.push(coordinatesBis[i])
                    }

                    for(let i = 0; i < simplified.geometry.coordinates.length - 1; i ++){
                        coordinates.push([simplified.geometry.coordinates[i], simplified.geometry.coordinates[i+1]])
                    }

                    var multiline = {
                        id: uuid(),
                        type: 'Feature',
                        properties: {
                            "class_id": currentDrawingColor
                        },
                        geometry: { type: 'MultiLineString', coordinates }
                    };
                    drawingsRef.current.add(multiline)
                }

                
            } else {
                let feature = {
                    id: uuid(),
                    type: 'Feature',
                    properties: {
                        "class_id": currentDrawingColor
                    },
                    geometry: { type: 'LineString', coordinates: points[0] }
                };

                if(points[0].length > 1){

                    let simplified = turf.simplify(feature, {tolerance: 0.0001});
                    
                    let dist = 0.1

                    var bearing = turf.bearing(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 2], simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1]);
                    
                    if(bearing >= 0){
                        var options = {units: 'kilometers', bearing};
                        var point1 = turf.destination(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1], dist, bearing, options);
                    } else {
                        var options = {units: 'kilometers', bearing: bearing};
                        var point1 = turf.destination(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1], 4 * dist, bearing, options);
                        bearing = bearing + 180
                    }

                    var bearingBis = turf.bearing(simplified.geometry.coordinates[0], simplified.geometry.coordinates[1]);

                    if(bearingBis >= 0){
                        var optionsBis = {units: 'kilometers', bearing: bearingBis + 180};
                        var point1Bis = turf.destination(simplified.geometry.coordinates[0], 4 * dist, bearingBis + 180, optionsBis);
                    } else {
                        var optionsBis = {units: 'kilometers', bearing: bearingBis + 180};
                        var point1Bis = turf.destination(simplified.geometry.coordinates[0], dist, bearingBis + 180, optionsBis);
                        bearingBis = bearingBis + 180
                    }

                    var coordinatesOne = createEndingTextShape(point1, bearing, dist, options);
                    var coordinatesBis = createEndingTextShape(point1Bis, bearingBis, dist, optionsBis);

                    var coordinates = []

                    for(let i = 0; i < coordinatesOne.length; i ++){
                        coordinates.push(coordinatesOne[i])
                        coordinates.push(coordinatesBis[i])
                    }

                    for(let i = 0; i < simplified.geometry.coordinates.length - 1; i ++){
                        coordinates.push([simplified.geometry.coordinates[i], simplified.geometry.coordinates[i+1]])
                    }

                    var multiline = {
                        id: uuid(),
                        type: 'Feature',
                        properties: {
                            "class_id": currentDrawingColor
                        },
                        geometry: { type: 'MultiLineString', coordinates }
                    };
                    drawingsRef.current.add(multiline)

                    socketRef.current.emit("DRAWING_UPDATED", {project_id: currentProjectId, drawings: multiline});

                    //var body = JSON.stringify({ project_id: currentProjectId, data: { drawings: drawingsRef.current.getAll(), symbols:symbols } });
                    var body = JSON.stringify({ project_id: currentProjectId, drawing: multiline });
                    //e ok
                    ApiSaveProject(body);
                }                
            }
        }
    },
    {
        target: ref,
        drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
    })

    return (
        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT)}} >
            <svg style={{ width: '100%', height: '100%' }}>
                <polyline
                    ref={freeHandRef}
                    points={currentLine.map(([x, y]) => `${x},${y}`).join(' ')}
                    style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4"}}
                />
            </svg>
        </div>
    );
};

export default FreeHandTextGraphicDrawLayer;