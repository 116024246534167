import styles from '../css/dashboard.module.css'
import { useState, useEffect, useContext, useRef } from 'react'

import { GlobalContext } from "../../../contexts/globalContext";


import ConnectedDroneList from './ConnectedDroneList'
import FiltersComponent from '../FiltersComponent';

import { MultitouchComponent } from 'use-gesture-pack';
import { translate, compose, toString, identity, fromString } from 'transformation-matrix';




const ConnectedDroneComponent = (props) => {
    const [drones, setDrones] = useState([]);

    const { devices } = useContext(GlobalContext);
    const [filterType, setFilterType] = useState(0);

    useEffect(() => {
        const regex = /d.*r.*o.*n.*a/i;
        
        const updatedDrones = [...drones];

        devices.forEach(device => {
            if (regex.test(device.device_type.name) || regex.test(device.name)) {
                const index = updatedDrones.findIndex(drone => drone._id === device._id);
                if (index !== -1) {
                    updatedDrones[index] = { ...updatedDrones[index], ...device };
                } else {
                    updatedDrones.push(device);
                }
            }
        });

        setDrones(updatedDrones)
    }, [devices])

    const filter_data = (data) => {
        setFilterType(data);
    }

    const ref = useRef();
    const ref0 = useRef();
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();

    useEffect(() => {
        switch (filterType) {
            case 0:
                ref.current = ref0.current;
                break;
            case 1:
                ref.current = ref1.current;
                break;
            case 2:
                ref.current = ref2.current;
                break;
            case 3:
                ref.current = ref3.current;
                break;
        }

    }, [filterType])

    const onDragInside = ({ dy }) => {
        var mat = identity();

        if (ref.current.style.transform !== '') {
            mat = fromString(ref.current.style.transform);
        }

        var modifier = MapNumber(mat.f, 0, props.height / 4, 1, 0);

        if (modifier == 1) {
            modifier = MapNumber(mat.f,  - (ref.current.offsetHeight - props.height),  - (ref.current.offsetHeight - props.height + (props.height / 4)), 1, 0);
        }      

        dy = dy /props.scale_factor

        var trans = translate(0, modifier * dy);
        var final = compose(trans, mat);
        ref.current.style.transform = toString(final);
    }

    const onGestureEnded = () => {
        var mat = identity();

        if (ref.current.style.transform !== '') {
            mat = fromString(ref.current.style.transform);
        }

        var dy = 0;
        if (ref.current.offsetHeight < props.height) {
            dy = -mat.f;
        } else {
            if (mat.f > 0) {
                dy = -mat.f;
            }

            if (mat.f < - (ref.current.offsetHeight - props.height)) {
                dy = -(ref.current.offsetHeight - props.height) - mat.f;
            }
        }

        if (dy != 0) {
            ref.current.style.transition = "transform 0.2s";
            setTimeout(() => {
                ref.current.style.transition = "transform 0.0s";
            }, 200);
        }

        var trans = translate(0, dy);
        var final = compose(trans, mat);
        ref.current.style.transform = toString(final);
    }

    function MapNumber(theValue, theInStart, theInEnd, theOutStart, theOutEnd) {
        var result = theOutStart + (theOutEnd - theOutStart) * ((theValue - theInStart) / (theInEnd - theInStart));
        if (result < 0) {
            return result = 0;
        }
        if (result > 1) {
            return result = 1;
        }
        return result;
    }

    return (
        <MultitouchComponent width={600} height={300} dragGesture={false} pinchGesture={false} touchAndHoldGesture={false} tapGesture={false} onDragInside={onDragInside} onGestureEnded={onGestureEnded}>
            <div className={`${styles.dashboardElements}`} style={{top: `${props.top}px`, height:`${props.height}px`, transformOrigin:"top left", transform:`scale(${props.scale_factor})`}}>
                <FiltersComponent text={"Drones"} func={filter_data}  height={props.height}/>
                {filterType === 0 && (
                    <div className={`${styles.vehicleBody}`} style={{height:`${props.height}px`}}>
                        <div ref={ref0} style={{ position: "absolute", paddingBottom:"20px", paddingTop:"10px" }}>
                            <div style={{ position: 'relative' }}>
                                <label className={`${styles.labelActiv}`}> Active </label>
                                <ConnectedDroneList drones={drones} tip='status' status='activ' />
                            </div>
                            <div style={{ position: 'relative' }}>
                                <label className={`${styles.labelActiv}`}> Ready </label>
                                <ConnectedDroneList tip='status' status='ready' />
                            </div>
                            <div style={{ position: 'relative' }}>
                                <label className={`${styles.labelActiv}`}> Offline </label>
                                <ConnectedDroneList tip='status' status='offline' />
                            </div>
                        </div>
                    </div>
                )}
                {filterType === 1 && (
                    <div className={`${styles.vehicleBody}`} style={{height:`${props.height}px`}}>
                        <div ref={ref1} style={{ position: "absolute", paddingBottom:"20px", paddingTop:"10px" }}>
                            <div style={{ position: 'relative' }}>
                                <label className={`${styles.labelActiv}`}> FPV </label>
                                <ConnectedDroneList drones={drones} tip='type' status='FPV' />
                            </div>
                            <div style={{ position: 'relative' }}>
                                <label className={`${styles.labelActiv}`}> VTOL </label>
                                <ConnectedDroneList drones={drones} tip='type' status='VTOL' />
                            </div>
                        </div>
                    </div>
                )}
                {filterType === 2 && (
                    <div className={`${styles.vehicleBody}`} style={{height:`${props.height}px`}}>
                        <div ref={ref2} style={{ position: "absolute", paddingBottom:"20px", paddingTop:"10px" }}>
                            <div style={{ position: 'relative' }}>
                                <label className={`${styles.labelActiv}`}> Rasnov </label>
                                <ConnectedDroneList drones={drones} tip='locatie' status='Rasnov' />
                            </div>
                            <div style={{ position: 'relative' }}>
                                <label className={`${styles.labelActiv}`}> Judet Brasov </label>
                                <ConnectedDroneList tip='locatie' status='Brasov' />
                            </div>
                        </div>
                    </div>
                )}
                {filterType === 3 && (
                    <div className={`${styles.vehicleBody}`} style={{height:`${props.height}px`}}>
                        <div ref={ref3} style={{ position: "absolute", paddingBottom:"20px", paddingTop:"10px" }}>
                            <div style={{ position: 'relative' }}>
                                <label className={`${styles.labelActiv}`}> Raza Mica </label>
                                <ConnectedDroneList drones={drones} tip='proximitate' status='mic' />
                            </div>
                            <div style={{ position: 'relative' }}>
                                <label className={`${styles.labelActiv}`}> Raza Medie </label>
                                <ConnectedDroneList tip='proximitate' status='mediu' />
                            </div>
                            <div style={{ position: 'relative' }}>
                                <label className={`${styles.labelActiv}`}> Raza Mare </label>
                                <ConnectedDroneList tip='proximitate' status='mare' />
                            </div>
                        </div>
                    </div>
                )}
                {/* <div className={`${styles.droneBody}`}  style={{height:`${props.height - 5}px`}}>
                    <div ref={ref} style={{ position: "absolute" }}>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Activ </label>
                            {drones?.length > 0 && <ConnectedDroneList drones={drones} />}
                            {drones?.length === 0 && <DroneList status={"activ"} />}
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Ready </label>
                            <DroneList status={"ready"} />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Offline </label>
                            <DroneList status={"offline"} />
                        </div>
                    </div>
                </div> */}
                <div className={styles.drone_inner_shadow} />
                <div className={styles.drone_inner_shadow_up} />
            </div>
        </MultitouchComponent>
    );
}

export default ConnectedDroneComponent;