import Lottie from 'react-lottie-player'
import styles from './styles.module.css'
import "@fontsource/roboto";
import DeleteAnimation from './sip_components/dialer_button_svg/delete-contact-icon.json'

const DeleteContactComponent = ({contact, setDeleteContact, deleteContactFunction}) => {

    return (
        <div className={styles.sterge_background} style={{fontFamily: 'Roboto', fontStyle: 'Roboto'}}>
            <div className={styles.sterge}>
                <Lottie
                    animationData={DeleteAnimation}
                    play={true}
                    style={{ position:"relative", top:"25px", left:"50%", transform:"translate(-50%,0)", width:"65px", height:"65px"}}
                />
                <div className={styles.sterge_content} >
                    <div style={{paddingBottom:"10px"}}>Vrei să ștergi definitiv contactul?</div>
                    <button className={styles.sterge_bttn} onTouchEnd={() => setDeleteContact(false)}>ANULEAZĂ</button>
                    <button className={styles.sterge_bttn} onTouchEnd={() => {setDeleteContact(false)}}>ȘTERGE</button>
                </div>
            </div>
            
        </div>
    )
}

export default DeleteContactComponent;

