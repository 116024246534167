import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css'
import { useGesture } from '@use-gesture/react'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import { GetMaxZIndex } from '../../utils/Tools'
import Lottie from 'react-lottie-player'

import PhoneNoShadowIcon from '../../graphics/phone/phoneNoShadow.png';
import PhoneIcon from './sip_components/dialer_button_svg/receptor.png';
import undeCall from './sip_components/dialer_button_svg/unda mica.json'
import callingAnim from '../../graphics/animations/sm-sip-calling-anim.json'



const CallDecisionComponent = (props) => {
    const ref = useRef(null);
    const totalDy = useRef(null);
    const [phoneRotation, setPhoneRotation] = useState(35);
    const [moving, setMoving] = useState(false);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const Map = (theValue, theInStart, theInEnd, theOutStart, theOutEnd) => {
        return theOutStart + (theOutEnd - theOutStart) * ((theValue - theInStart) / (theInEnd - theInStart));
    };

    
   
    useGesture(
        { 
            onDragStart: ({}) => {
                totalDy.current = 0;
                setMoving(true)
                // ref.current.style.transform = `matrix(1, 0, 0, 1, ${ref.current.getBoundingClientRect().x / oxDivider}, ${ref.current.getBoundingClientRect().y/oyDivider})`
            },
            onDrag: ({ pinching, cancel, delta: [dx, dy], first, memo }) => {
                if (pinching) {
                    return cancel()
                }
                if (first) {
                    ref.current.style.zIndex = GetMaxZIndex() + 1;
                }

                var mat = identity();

                if (ref.current.style.transform !== '') {
                    mat = fromString(ref.current.style.transform);
                }

                if (totalDy.current + dy > -140 && totalDy.current + dy < 140) {
                    totalDy.current = totalDy.current + dy;
                    var trans = translate(0, dy);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    if (totalDy.current < 0) {
                        props.setAnswerBlur(Map(totalDy.current, 0 , -140, 0, 30));
                        props.setRejectBlur(0);

                        setPhoneRotation(Map(totalDy.current, 0 , -140, 35, -20));
                    }
                    if (totalDy.current > 0) {
                        props.setAnswerBlur(0);
                        props.setRejectBlur(Map(totalDy.current, 0 , 140, 0, 30));
                        setPhoneRotation(Map(totalDy.current, 0 , 140, 35, 120));
                    }
                }
                

                return memo
            },
            onDragEnd: ({}) => {
                

                if (totalDy.current < -80) {
                    props.onAnswer();
                } else if (totalDy.current > 80) {
                    props.onReject();
                } else {
                    // var mat = identity();
                    ref.current.style.transform = "matrix(1,0,0,1,-70,-70)"
                    totalDy.current = 0;
                    props.setAnswerBlur(0);
                    props.setRejectBlur(0);
                    setPhoneRotation(35);
                    setMoving(false)
                }
                
            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] }
        })

    
    return (
        <div ref={ref} width="100px" height="100px" 
        style={{left: "50%", top:"57%", transform:"matrix(1,0,0,1,-70,-70)", position: "absolute", touchAction: 'none'}} 
        >
            {!moving && <>
                <div className={styles.background_green_call} style={{left: "50%", top:"50%", transform:"translate(-50%,-50%)", position: "absolute" }}/>
                <Lottie
                    loop
                    animationData={undeCall}
                    play
                    style={{
                        left: "50%", top:"50%", transform:"translate(-50%,-50%)", position: "absolute", 
                        width: "262px", height: "262px"
                    }}
                />

            </>}
            <img alt={"decision"} draggable={false} src={PhoneIcon} width="140px" height="140px" style={{transform:`rotate(${phoneRotation}deg)`}} />               
        </div>
    );

}

export default CallDecisionComponent;