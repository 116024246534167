import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from './../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { GetMaxZIndex } from '../utils/Tools'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import "@fontsource/roboto";

import { GlobalContext } from "../contexts/globalContext"
import EchelonsJson from '../utils/echelons.json';
import reliabilityRatingsJson from '../utils/reliabilityRatings.json';
import credibilityRatingsJson from '../utils/credibilityRatings.json';

import SelectPickerComponent from "../components/keyboard/SelectPickerComponent";
import NewKeyboardComponent from "../components/keyboard/NewKeyboardComponent";
import NewInputTextComponent from "../components/keyboard/NewInputTextComponent";

import InputTextComponent from "../components/keyboard/InputTextComponent"
import ButtonWithConfirmationComponent from './keyboard/ButtonWithConfirmationComponent'

const EditSymbolComponent = (props) => {
    const ref = useRef();
    const keyboardRef = useRef();
    
    const [top, setTop] = useState(10);
    const [left, setLeft] = useState(10);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [icon, setIcon] = useState(0);
    const [color, setColor] = useState("F");
    //const refCurrentSite = useRef();

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const { mapRef, setSymbolsForEditing, setSymbols, currentProjectId, socketRef } = useContext(GlobalContext);

    useEffect(() => {
        const position = mapRef.current.project([props.symbol.location[1], props.symbol.location[0]]);
        setLeft(position.x / oxDivider);
        setTop(position.y / oyDivider);

        setWidth(800);

        setHeight(770);
       
        var icStr = props.symbol.icon.substring(0, 11)+ "-" + props.symbol.icon.substring(12, 16);// + "-" + props.symbol.icon.substring(11, 14);
        setIcon(process.env.REACT_APP_MILSYMBOLS_URL.replace("{0}", icStr));
        
        var locationString = (Math.round(props.symbol.location[0] * 1000) / 1000) + " " + (Math.round(props.symbol.location[1] * 1000) / 1000);
        locationRef.current.value = locationString;

        reinforcedRef.current.checked = props.symbol.reinforced;
        reducedRef.current.checked = props.symbol.reduced;
    }, [])

    useGesture(
        {
            onDrag: ({ pinching, cancel, delta: [dx, dy], first, memo }) => {
                if (pinching) {
                    return cancel()
                }
                if (first) {
                    ref.current.style.zIndex = GetMaxZIndex() + 1;
                }
            },
            onPinch: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
                if (first) {
                    memo = [ox, oy, a, s]
                } else {
                    const { width, height, x, y } = ref.current.getBoundingClientRect()

                    // apply translation to center the element at pinch origin
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((ox - memo[0]) / oxDivider, (oy - memo[1]) / oyDivider);

                    // apply inverse transformation to find the rotated pinch origin
                    var inv = inverse(mat);
                    var pointCentruTranslatat = applyToPoint(inv, [(width / 2 + x) / oxDivider, (height / 2 + y) / oyDivider]);
                    var diffCenterX = pointCentruTranslatat[0] - (ref.current.offsetWidth / 2);
                    var diffCenterY = pointCentruTranslatat[1] - (ref.current.offsetHeight / 2);

                    // convert the pinch origin to the original scale
                    var pointOx = applyToPoint(inv, [ox / oxDivider, oy / oyDivider]);

                    // calculate the point to apply transformations
                    var point = applyToPoint(mat, [pointOx[0] - diffCenterX - ref.current.offsetWidth / 2, pointOx[1] - diffCenterY - ref.current.offsetHeight / 2]);

                    // apply rotation and scaling
                    var rotate = rotateDEG(a - memo[2], point[0], point[1]);
                    var scaleFunction = scale((s / memo[3]), (s / memo[3]), point[0], point[1]);

                    if (s <= 0.5) {
                        setSymbolsForEditing(symbolsForEditing => symbolsForEditing.filter(a => a.uid !== props.symbol.uid));
                    }

                    var final = compose(rotate, scaleFunction, trans, mat);
                    ref.current.style.transform = toString(final);

                    memo = [ox, oy, a, s]
                }
                return memo
            },
            onPinchEnd: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
                const { width, height, x, y } = ref.current.getBoundingClientRect();

                if (x + width /2 < 0){
                    ref.current.className = styles.edit_symbol_component_anim;

                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((-(x + width /2) ) / oxDivider, 0);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.edit_symbol_component;
                    }, 500);

                }

                if (x + width /2 > window.innerWidth){
                    ref.current.className = styles.edit_symbol_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(- ((x + width /2) - window.innerWidth) / oxDivider, 0);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.edit_symbol_component;
                    }, 500);
                }

                if (y + height /2 < 0){
                    ref.current.className = styles.edit_symbol_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(0, (-(y + height /2) ) / oyDivider);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.edit_symbol_component;
                    }, 500);
                }

                if (y + height /2 > window.innerHeight){
                    ref.current.className = styles.edit_symbol_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(0, - ((y + height /2) - window.innerHeight) / oyDivider);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.edit_symbol_component;
                    }, 500);
                }

            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
            pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
        }
    )   

    const altitudeRef = useRef();
    const locationRef = useRef();
    const uniqueDesignationRef = useRef();
    const speedRef = useRef();
    const reinforcedRef = useRef();
    const reducedRef = useRef();
    
    const staffCommentsRef = useRef();
    const additionalInfoRef = useRef();
    const higherFormationRef = useRef();
    const effectivenessRef = useRef();
    const sifCodeRef = useRef();
    
    const echelonsRef= useRef();
    const reliabilityRatingsRef = useRef();
    const credibilityRatingsRef = useRef();

    
    function deleteSymbol() {
        setSymbolsForEditing(symbolsForEditing => symbolsForEditing.filter(a => a.uid !== props.symbol.uid));

        if (currentProjectId == null) {
            setSymbols(symbols => symbols.filter(a => a.uid !== props.symbol.uid));
        } else {
            socketRef.current.emit("SYMBOL_DELETED", {project_id: currentProjectId, symbol: props.symbol});
        }
    }
    
    function saveSymbol() {
        let symbol = props.symbol;

        var icon = symbol.icon.substring(0, 11) + echelonsRef.current.getAttribute("value") + symbol.icon.substring(12, 16);
        icon = icon.substring(0, 1) + color + icon.substring(2)
        symbol.icon = icon;
        symbol.altitude = (altitudeRef.current.value == "")?null:altitudeRef.current.value;


        var location = locationRef.current.value.replace(",", "");
        var values = location.split(" ");
        symbol.location = [Number(values[0]), Number(values[1])];
        
        symbol.uniqueDesignation = uniqueDesignationRef.current.value;
        symbol.speed = speedRef.current.value;

        symbol.reinforced = reinforcedRef.current.checked;
        symbol.reduced = reducedRef.current.checked;

        symbol.staffComments = staffCommentsRef.current.value;
        symbol.additionalInfo = additionalInfoRef.current.value;
        symbol.higherFormation = higherFormationRef.current.value;

        symbol.reliabilityRatings = (reliabilityRatingsRef.current.getAttribute("value") == "-")?"":reliabilityRatingsRef.current.getAttribute("value");
        symbol.credibilityRatings = (credibilityRatingsRef.current.getAttribute("value") == "-")?"":credibilityRatingsRef.current.getAttribute("value");

        symbol.effectiveness = effectivenessRef.current.value;
        symbol.sifCode = sifCodeRef.current.value;

        if (currentProjectId == null) {
            setSymbols(symbols => symbols.filter(a => a.uid !== symbol.uid));
            setSymbols(symbols => [...symbols, symbol]);    
        } else {
            socketRef.current.emit("SYMBOL_UPDATED", {project_id: currentProjectId, symbol});
        }     
        
        setSymbolsForEditing(symbolsForEditing => symbolsForEditing.filter(a => a.uid !== props.symbol.uid));
    }

    // function onKeyboardVisible() {
    //     setHeight(700);
    // }

    // function onKeyboardClose() {
    //     setHeight(500);
    // }

    function colorButtonClickHandler(number) {    

        var icStr = props.symbol.icon.substring(0, 11)+ "-" + props.symbol.icon.substring(12, 16);// + "-" + props.symbol.icon.substring(11, 14);
        let ns = icStr.substring(0, 1) + number + icStr.substring(2)
        setIcon(process.env.REACT_APP_MILSYMBOLS_URL.replace("{0}", ns));

        setColor(number);        
    };
    
    return (

        <div className={styles.project_component} ref={ref} style={{fontFamily:"Roboto", transform: `matrix(1, 0, 0, 1, ${left}, ${top})`, width: width, height: height }}>
            
            <NewKeyboardComponent visible style={{zIndex:"200", color:"black"}} ref={keyboardRef} x="10" y="500" width="780" height="550"/>

            
            <div style={{ position:'absolute', top:20, left:20, height: 480, width: 760, fontStyle: 'Roboto', color: 'white', backgroundColor:'#0D0D12', borderRadius: '8px'}}>
            <div className={styles.load_project_button_clicked} style={{position:"absolute", color:"#E4A83C", transform: `matrix(1, 0, 0, 1,20, 400)` }} onClick={() => saveSymbol()}>
                SALVEAZA
            </div>

            <div className={styles.load_project_button_clicked} style={{position:"absolute", color:"#A9A9AB", transform: `matrix(1, 0, 0, 1,250, 400)` }} onClick={() => {

                        setSymbolsForEditing(symbolsForEditing => symbolsForEditing.filter(a => a.uid !== props.symbol.uid));
                    }}>
                INCHIDE
            </div> 

            <ButtonWithConfirmationComponent color="#A9A9AB" x="530" y="400" width="200" height="50" text="STERGE" backgroundColor="#18181C" onConfirmed={() => deleteSymbol()}/>
                
                <div style={{ position:'absolute', top:20, left:20, height: 360, width: 720, fontStyle: 'Roboto', color: 'white', backgroundColor:'#383838', borderRadius: '8px'}}>
                    <div className={styles.color_button} style={{ transform: "matrix(1, 0, 0, 1, 0, 10)" }} onClick={() => {
                        colorButtonClickHandler("F");
                    }}>
                        <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff1.png')} width="80px"></img>
                    </div>

                    <div className={styles.color_button} style={{ transform: "matrix(1, 0, 0, 1, 100, 10)" }} onClick={() => {
                        colorButtonClickHandler("A");
                    }}>
                        <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff2.png')} width="80px"></img>
                    </div>

                    <div className={styles.color_button} style={{ transform: "matrix(1, 0, 0, 1, 200, 10)" }} onClick={() => {
                        colorButtonClickHandler("U");
                    }}>
                        <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff3.png')} width="80px"></img>
                    </div>

                    <div className={styles.color_button} style={{ transform: "matrix(1, 0, 0, 1, 300, 10)" }} onClick={() => {
                        colorButtonClickHandler("P");
                    }}>
                        <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff4.png')} width="80px"></img>
                    </div>

                    <div className={styles.color_button} style={{ transform: "matrix(1, 0, 0, 1, 400, 10)" }} onClick={() => {
                        colorButtonClickHandler("N");
                    }}>
                        <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff5.png')} width="80px"></img>
                    </div>

                    <div className={styles.color_button} style={{ transform: "matrix(1, 0, 0, 1, 500, 10)" }} onClick={() => {
                        colorButtonClickHandler("H");
                    }}>
                        <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff6.png')} width="80px"></img>
                    </div>

                    <div className={styles.color_button} style={{ transform: "matrix(1, 0, 0, 1, 600, 10)" }} onClick={() => {
                        colorButtonClickHandler("S");
                    }}>
                        <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff7.png')} width="80px"></img>
                    </div>





                <img src={ icon } width="150px" style={{position:"absolute", transform: `matrix(1, 0, 0, 1, 290, 160)`}}/>

                
                

                <NewInputTextComponent ref={altitudeRef} keyboardRef={keyboardRef} noClose value={(props.symbol.altitude)?props.symbol.altitude:""} width="80" x="20" y="150" fontSize="15" height="25" placeHolder="" position="absolute"></NewInputTextComponent>
                <NewInputTextComponent ref={locationRef} keyboardRef={keyboardRef} noClose value="" width="140" x="120" y="150" fontSize="15" height="25" placeHolder="" position="absolute"></NewInputTextComponent>
                
                <NewInputTextComponent ref={uniqueDesignationRef} keyboardRef={keyboardRef} noClose value={props.symbol.uniqueDesignation} width="140" x="120" y="230" fontSize="15" height="25" placeHolder="" position="absolute"></NewInputTextComponent>
                <NewInputTextComponent ref={speedRef} keyboardRef={keyboardRef} noClose value={props.symbol.speed} width="140" x="120" y="270" fontSize="15" height="25" placeHolder="" position="absolute"></NewInputTextComponent>
                <input ref={reinforcedRef} className={styles.input_checkbox} type="checkbox"  style={{transform: `matrix(1, 0, 0, 1,455, 120)`}} ></input> 
                <span style={{position:"absolute", color:"white", transform: `matrix(1, 0, 0, 1,485, 120)` }}>+</span>
                <input ref={reducedRef} className={styles.input_checkbox} type="checkbox"  style={{transform: `matrix(1, 0, 0, 1,505, 120)` }} ></input>
                <span style={{position:"absolute", color:"white", transform: `matrix(1, 0, 0, 1,535, 120)` }}>-</span>

                <NewInputTextComponent ref={staffCommentsRef} keyboardRef={keyboardRef} noClose value={props.symbol.staffComments} width="140" x="455" y="150" fontSize="15" height="25" placeHolder="" position="absolute"></NewInputTextComponent>
                <NewInputTextComponent ref={additionalInfoRef} keyboardRef={keyboardRef} noClose value={props.symbol.additionalInfo} width="140" x="455" y="190" fontSize="15" height="25" placeHolder="" position="absolute"></NewInputTextComponent>
                <NewInputTextComponent ref={higherFormationRef} keyboardRef={keyboardRef} noClose value={props.symbol.higherFormation} width="140" x="455" y="230" fontSize="15" height="25" placeHolder="" position="absolute"></NewInputTextComponent>

                

                

                <NewInputTextComponent ref={effectivenessRef} keyboardRef={keyboardRef} noClose value={props.symbol.effectiveness} width="50" x="555" y="270" fontSize="15" height="25" placeHolder="" position="absolute"></NewInputTextComponent>
                <NewInputTextComponent ref={sifCodeRef} keyboardRef={keyboardRef} noClose value={props.symbol.sifCode} width="50" x="620" y="270" fontSize="15" height="25" placeHolder="" position="absolute"></NewInputTextComponent>
                
                <SelectPickerComponent fontSize="25px" ref={reliabilityRatingsRef} Items={reliabilityRatingsJson} SelectedValue={(props.symbol.reliabilityRatings === "")?"-":props.symbol.reliabilityRatings} x="440" y="270" VisibleItems="4" Select="value" ></SelectPickerComponent>
                <SelectPickerComponent fontSize="25px" ref={credibilityRatingsRef} Items={credibilityRatingsJson} SelectedValue={(props.symbol.credibilityRatings === "")?"-":props.symbol.credibilityRatings} x="490" y="270" VisibleItems="4" Select="value"></SelectPickerComponent>

                <SelectPickerComponent fontSize="25px" ref={echelonsRef} Items={EchelonsJson} SelectedValue={props.symbol.icon.substring(11, 12)} x="300" y="120" VisibleItems="7" Select="name"></SelectPickerComponent>

            </div>
            
            
            

            

            </div>
                
            
            
            {/* <select style={{position:"absolute", left:"100px", width:"100px", height:"40px"}}>
                {EchelonsJson.map((echelon) => (
                    
                    <option value="A" style={{backgroundImage:"url(echelon.icon)"}}>
                        {echelon.name}
                    </option>
                
                ))}
            </select> */}
        </div>

    );
}

export default EditSymbolComponent;