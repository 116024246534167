import React, { useRef, useEffect } from 'react';
import PosterNoVideo from '../graphics/drona/no-video-drone.png'

const WebRTCPlayerComponent = (props) => {
    const ref = useRef(null);
    const videoRef = useRef(null);

    function startPlay(url) {
        const webrtc = new RTCPeerConnection({
            iceServers: [{
                urls: ['stun:stun.l.google.com:19302']
            }],
            sdpSemantics: 'unified-plan'
        })
        webrtc.ontrack = function (event) {
            // if(videoRef.current){
                console.log("sp", videoRef.current)
                videoRef.current.srcObject = event.streams[0]
                videoRef.current.play().then(_ => {
                    console.log("started")
                })
                
                .catch(error => {
                    console.log("The WEBRTC component was closed")
                });
            // }
            
        }
        webrtc.addTransceiver('video', { direction: 'sendrecv' })
        webrtc.onnegotiationneeded = async function handleNegotiationNeeded() {
            const offer = await webrtc.createOffer()

            await webrtc.setLocalDescription(offer)

            fetch(url, {
                method: 'POST',
                body: new URLSearchParams({ data: btoa(webrtc.localDescription.sdp) })
            })
                .then(response => response.text())
                .then(data => {
                    try {
                        webrtc.setRemoteDescription(
                            new RTCSessionDescription({ type: 'answer', sdp: atob(data) })
                        )
                    } catch (e) {
                        console.warn(e)
                    }
                })
        }

        const webrtcSendChannel = webrtc.createDataChannel('rtsptowebSendChannel')
        webrtcSendChannel.onopen = (event) => {
            webrtcSendChannel.send('ping')
        }
        webrtcSendChannel.onclose = (_event) => {
            startPlay(url)
        }
        webrtcSendChannel.onmessage = event => console.log(event.data)
    }
    
    useEffect(() => {
        startPlay(props.url);
        console.log("ue", videoRef.current)
    }, [])

    // sa nu mai fie clickable componenta de video (touch-action: none)

    return (
        <div ref={ref} style={{ width: props.WIDTH, height: "421px", backgroundColor: "transparent" }}>
            <video muted="muted" poster={PosterNoVideo} ref={videoRef} autoPlay width={props.WIDTH} style={{ backgroundColor: "black", touchAction: "none" }} />
            
        </div>
    );
}

export default WebRTCPlayerComponent;