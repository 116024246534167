import { useRef, useState, useContext, useEffect } from 'react';
import styles from '../../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { TransformPoints, PointScreenPosition } from '../../../utils/Tools'
import { GlobalContext } from "../../../contexts/globalContext"
import uuid from 'react-uuid';
import { ApiSaveProject } from "../../../utils/RestServices"
import * as turf from "@turf/turf"

const LineSemicircleDrawLayer = (props) => {
    const ref = useRef(null);
    const rectangleRef = useRef(null);

    const {currentRepetition, setCurrentRepetition, setCurrentShapeName, currentShape, setCurrentShape, setDrawingLayer, drawingsRef, currentDrawingColor, currentProjectId, socketRef, mapRef } = useContext(GlobalContext);
    const [currentRectangle, setCurrentRectangle] = useState([]);
    const [secondCirclePoint, setSecondCirclePoint] = useState()
    const [circleOrientation, setCircleOrientation] = useState(1)

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    function createEndingTextShape(point1, bearing, dist, options){
        var coordinatesText = []

        for(let i = 0; i < currentShape.length; i++){
            let line = []
            for(let j = 0; j < 2; j++){
                let oxDist = dist * currentShape[i][j][0]
                let oyDist = dist * currentShape[i][j][1]
                if(oyDist === 0){
                    var point2 = turf.destination(point1, oxDist, bearing, options)
                    line.push(point2.geometry.coordinates)
                } else if(oyDist > 0){
                    let helpingPoint = turf.destination(point1, oyDist, bearing + 90, options)
                    var point3 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point3.geometry.coordinates)
                } else {
                    let helpingPoint = turf.destination(point1, Math.abs(oyDist), bearing + 270, options)
                    var point4 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point4.geometry.coordinates)
                }
            }
            coordinatesText.push(line)
        }

        return coordinatesText
    }

    function createMiddleTextShape(point1, bearing, dist, options){
        var coordinatesText = []

        for(let i = 0; i < currentRepetition.length; i++){
            let line = []
            for(let j = 0; j < 2; j++){
                let oxDist = dist * currentRepetition[i][j][0]
                let oyDist = dist * currentRepetition[i][j][1]
                if(oyDist === 0){
                    var point2 = turf.destination(point1, oxDist, bearing, options)
                    line.push(point2.geometry.coordinates)
                } else if(oyDist > 0){
                    let helpingPoint = turf.destination(point1, oyDist, bearing + 90, options)
                    var point3 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point3.geometry.coordinates)
                } else {
                    let helpingPoint = turf.destination(point1, Math.abs(oyDist), bearing + 270, options)
                    var point4 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point4.geometry.coordinates)
                }
            }
            coordinatesText.push(line)
        }

        return coordinatesText
    }

    function circlePoint(){
        const points = TransformPoints(currentRectangle, props.map);
        var lineBearing = turf.bearing(points[0][0], points[0][1])
        var secondLineBearing = turf.bearing(points[0][1], points[0][2])
        var circleBearing = 0

        if(lineBearing >= 0){
            if(secondLineBearing >= lineBearing || secondLineBearing <= lineBearing - 180){
                circleBearing = lineBearing + 90
                setCircleOrientation(1)
            } else {
                circleBearing = lineBearing - 90
                setCircleOrientation(0)
            } 
        } else if(lineBearing < 0){
            if(secondLineBearing >= lineBearing && secondLineBearing <= lineBearing + 180){
                circleBearing = lineBearing + 90
                setCircleOrientation(1)
            } else {
                circleBearing = lineBearing - 90
                setCircleOrientation(0)
            }
        }
        
        var options = {units: 'kilometers'};
        var circleDiameter = turf.distance(points[0][1], points[0][2], options)
        var firstCirclePoint = turf.point(points[0][1]);
        var secondCirclePoint = turf.destination(firstCirclePoint, circleDiameter, circleBearing, options);
        var secondCirclePointScreen = PointScreenPosition(secondCirclePoint.geometry.coordinates, props.map)

        setSecondCirclePoint(secondCirclePointScreen)
    }

    useGesture(
        {
            onDrag: ({ pinching, cancel, xy: [ox, oy], first }) => {
                if (pinching) {
                    return cancel()
                }

                if (first) {
                    setCurrentRectangle([...currentRectangle, [ox, oy]]);
                } else {
                    const updatedPoints = currentRectangle.slice(0, -1);
                    updatedPoints.push([ox, oy]);
                    setCurrentRectangle(updatedPoints);
                }
            
                const thirdPoint = currentRectangle[2];

                if (thirdPoint) {
                    circlePoint()
                }

            },
            onDragEnd: () => {
                const thirdPoint = currentRectangle[2];

                if (thirdPoint) {
                    const points = TransformPoints([currentRectangle[0], currentRectangle[1], secondCirclePoint], props.map);
                    setCurrentRectangle([]);
                    setDrawingLayer(0)
                    setCurrentShapeName("")

                    if (currentProjectId == null) {
                        let center = turf.midpoint(points[0][1], points[0][2])
                        let distance = turf.distance(points[0][1], points[0][2])

                        let bearing1 = turf.bearing(center.geometry.coordinates, points[0][1])
                        let bearing2 = turf.bearing(center.geometry.coordinates, points[0][2])

                        if(circleOrientation === 1){
                            var arc = turf.lineArc(center, distance / 2, bearing1, bearing2);
                        } else {
                            var arc = turf.lineArc(center, distance / 2, bearing2, bearing1);
                        }

                        let zoom = mapRef.current.getZoom();
                        let zoomDiff = (11 - zoom) / 5

                        if(0.3 + zoomDiff > 0.1){
                            var dist = 0.4 + zoomDiff
                        } else {
                            var dist = 0.1
                        }

                        let midPoint = turf.midpoint(points[0][0], points[0][1])
                        let bearing = turf.bearing(points[0][1], points[0][0]);
                        let options1 = {units: 'kilometers', bearing};
                        let destination1 = turf.destination(midPoint, dist, bearing, options1)
                        let destination2 = turf.destination(midPoint, dist, bearing + 180, options1)
                        
                        let coordinates = [[points[0][0], destination1.geometry.coordinates], [points[0][1], destination2.geometry.coordinates], arc.geometry.coordinates]

                        let coordinatesOne = createEndingTextShape(points[0][0], bearing, dist, options1);

                        for(let i = 0; i < coordinatesOne.length; i ++){
                            coordinates.push(coordinatesOne[i])
                        }

                        let coordinatesTwo = createMiddleTextShape(destination1.geometry.coordinates, bearing + 180, dist, options1);

                        for(let i = 0; i < coordinatesTwo.length; i ++){
                            coordinates.push(coordinatesTwo[i])
                        }

                        let multiline = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'MultiLineString', coordinates }
                        };
    
                        drawingsRef.current.add(multiline)
                    } else {
                        let center = turf.midpoint(points[0][1], points[0][2])
                        let distance = turf.distance(points[0][1], points[0][2])

                        let bearing1 = turf.bearing(center.geometry.coordinates, points[0][1])
                        let bearing2 = turf.bearing(center.geometry.coordinates, points[0][2])

                        if(circleOrientation === 1){
                            var arc = turf.lineArc(center, distance / 2, bearing1, bearing2);
                        } else {
                            var arc = turf.lineArc(center, distance / 2, bearing2, bearing1);
                        }
                        
                        let zoom = mapRef.current.getZoom();
                        let zoomDiff = (11 - zoom) / 5

                        if(0.3 + zoomDiff > 0.1){
                            var dist = 0.4 + zoomDiff
                        } else {
                            var dist = 0.1
                        }

                        let midPoint = turf.midpoint(points[0][0], points[0][1])
                        let bearing = turf.bearing(points[0][1], points[0][0]);
                        let options1 = {units: 'kilometers', bearing};
                        let destination1 = turf.destination(midPoint, dist, bearing, options1)
                        let destination2 = turf.destination(midPoint, dist, bearing + 180, options1)
                        
                        let coordinates = [[points[0][0], destination1.geometry.coordinates], [points[0][1], destination2.geometry.coordinates], arc.geometry.coordinates]

                        let coordinatesOne = createEndingTextShape(points[0][0], bearing, dist, options1);

                        for(let i = 0; i < coordinatesOne.length; i ++){
                            coordinates.push(coordinatesOne[i])
                        }

                        let coordinatesTwo = createMiddleTextShape(destination1.geometry.coordinates, bearing + 180, dist, options1);

                        for(let i = 0; i < coordinatesTwo.length; i ++){
                            coordinates.push(coordinatesTwo[i])
                        }
                        
                        let multiline = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'MultiLineString', coordinates }
                        };
    
                        drawingsRef.current.add(multiline)

                        socketRef.current.emit("DRAWING_UPDATED", {project_id: currentProjectId, drawings: multiline});

                        //var body = JSON.stringify({ project_id: currentProjectId, data: { drawings: drawingsRef.current.getAll(), symbols:symbols } });
                        var body = JSON.stringify({ project_id: currentProjectId, drawing: multiline });
                        //e ok
                        ApiSaveProject(body);                        
                    }
                    setCurrentRepetition()
                }
            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
        })


    const xDrawingPointRectangleSize = props.DrawingPointRectangleSize / oxDivider;
    const yDrawingPointRectangleSize = props.DrawingPointRectangleSize / oyDivider;

    function showLatLng(point) {
        const coord = TransformPoints([point], props.map)
        return `${coord[0][0][0].toFixed(3)}, ${coord[0][0][1].toFixed(3)}`
    }
    function showElevationOld(point) {
        const coord = TransformPoints([point], props.map)
        const el = mapRef.current.queryTerrainElevation([coord[0][0][0], coord[0][0][1]])
        return `${el.toFixed(3)}`
    }
    const [elevations, setElevations] = useState([]);

    async function showElevation(point) {
        try {
          const coord = TransformPoints([point], props.map);
          const response = await fetch(process.env.REACT_APP_TOPODATA_URL + coord[0][0][0] + "," + coord[0][0][1]);
          const data = await response.json();
          const elevationValue = data.results[0].elevation;
          return elevationValue.toFixed(3)
        } catch (error) {
          console.error("Error fetching elevation data:", error);
        }
    }

    async function fetchElevations(points) {
        const elevations = [];
    
        for (const [x, y] of points) {
            const elevation = await showElevation([x, y]);
            elevations.push(elevation);
        }
    
        return elevations;
    }
    
    
    useEffect(() => {
        if (currentRectangle.length > 0) {
            fetchElevations(currentRectangle).then((elevations) => {
                setElevations(elevations);
            });
        }
    }, [currentRectangle]);
    function getRadiusDistance(point1, point2){
        var a = point1[0] - point2[0];
        var b = point1[1] - point2[1];

        return Math.sqrt(a*a + b*b )/2;
    }
    
    return (

        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT) }} >
            <svg style={{ width: '100%', height: '100%' }}>
                {currentRectangle.length > 1 && (
                    <polyline
                        ref={rectangleRef}
                        // points={currentRectangle.map(([x, y]) => `${x},${y}`).join(' ')}
                        points={`${currentRectangle[0][0]},${currentRectangle[0][1]} ${currentRectangle[1][0]},${currentRectangle[1][1]}`}
                        style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4"}}
                    /> 
                )}
                {secondCirclePoint && (
                    <path 
                        d={
                            `M${currentRectangle[1][0]},${currentRectangle[1][1]} 
                            A${getRadiusDistance(currentRectangle[1],currentRectangle[2])},${getRadiusDistance(currentRectangle[1],currentRectangle[2])} 0 0 ${circleOrientation}
                            ${secondCirclePoint[0]},${secondCirclePoint[1]}`
                        }
                        style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4"}} 
                    />
                )}
                {currentRectangle.map(([x, y], index) => (
                    <g key={index}>
                        <text x={x-5*xDrawingPointRectangleSize} y={y+2*yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >
                            {elevations[index]}m, {showLatLng([x, y])}
                        </text>
                        <circle
                            cx = {x}
                            cy = {y}
                            r = {10}
                            style={{ fill: '#fbb03b', stroke: 'white', strokeWidth: 4 }} 
                        />
                    </g>
                ))}
            </svg>
        </div>
    );
}

export default LineSemicircleDrawLayer;