import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppComponent from './components/AppComponent';
import RedirectComponent from './components/RedirectComponent';


import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Route, Routes} from "react-router-dom"
import {BrowserView, MobileView} from 'react-device-detect';

import * as process from 'process';

import { GlobalProvider } from "./contexts/globalContext"
import AppMobile from './AppMobile';

window.global = window;
window.process = process;
window.Buffer = [];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    
    <>

            <BrowserView>
                <BrowserRouter>
                    <Routes>       
                        <Route path="/" element={<AppComponent/>}/>
                        <Route path="/redirect" element={<RedirectComponent/>}/>
                    </Routes>
                </BrowserRouter>   
            </BrowserView>
            <MobileView>
                <GlobalProvider>
                    <AppMobile />
                </GlobalProvider>
            </MobileView>
    </>

    
    
);


reportWebVitals();
