import { Joystick } from 'react-joystick-component';
import { useEffect, useRef, useContext, useCallback } from 'react';
import styles from './../styles.module.css'
import { GetMaxZIndex } from '../utils/Tools'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import { useGesture } from '@use-gesture/react'
import { GlobalContext } from "../contexts/globalContext"

const JoystickComponent = (props) => {

    const gamepadRef = useRef(null);
    var oldX = 0, oldY = 0;

    const { socketRef } = useContext(GlobalContext);
    const requestRef = useRef();

    useEffect(() => {
        requestRef.current = requestAnimationFrame(checkForGamepadStatus);

        return () => {
            cancelAnimationFrame(requestRef.current);
            socketRef.current.emit("SEND_COMMAND", { device_uid: props.device_uid, ptz: { x: 0, y: 0, z: 0 } });
        }

    }, [])

    const checkForGamepadStatus = useCallback((time) => {
            const gamepads = navigator.getGamepads();
            gamepadRef.current = gamepads[0];
            
        // if (!gamepadRef.current) {
        //     const gamepads = navigator.getGamepads();

        //     if (gamepads.length > 0) {
        //         gamepadRef.current = gamepads[0];
        //     }
        // } else {
            if (gamepadRef.current){
                console.log("xxx");
            var x = 0;
            var y = 0;
            gamepadRef.current.axes.forEach((axis, i) => {
                //console.log(i);
                if (i == 0) {
                    y = axis;
                } else if (i == 1) {
                    x = axis;
                }
            });

            //console.log("NE:", x, y);

            x = getNormalizedValue(x);
            y = getNormalizedValue(y);

            //console.log(x, y);
    
            if (x != oldX || y != oldY) {
                //console.log("trimite");
                socketRef.current.emit("SEND_COMMAND", { device_uid: props.device_uid, ptz: { x, y, z: 0 } });
            }
    
            oldX = x;
            oldY = y;}
        //}        

        if (requestRef.current !== null) {
            requestRef.current = requestAnimationFrame(checkForGamepadStatus);
        }
    });

    function handleStop() {
        socketRef.current.emit("SEND_COMMAND", { device_uid: props.device_uid, ptz: { x: 0, y: 0, z: 0 } });

        //fetch(props.streamUrl + '?type=ptz&action=ptz_move&x=0&y=0')        
    }

    function handleMove(button) {
        var { x, y } = button;
        x = getNormalizedValue(x);
        y = getNormalizedValue(y);

        if (x != oldX || y != oldY) {
            //fetch(props.streamUrl + '?type=ptz&action=ptz_move&x='+x+'&y=' + y)
            socketRef.current.emit("SEND_COMMAND", { device_uid: props.device_uid, ptz: { x, y, z: 0 } });
        }

        oldX = x;
        oldY = y;
    }

    function getNormalizedValue(value) {
        if (value > 0) {
            if (value > 0.1 && value < 0.25) {
                value = 0.1;
            } else if (value >= 0.25 && value < 0.5) {
                value = 0.25;
            } else if (value >= 0.5 && value < 0.75) {
                value = 0.4;
            } else if (value >= 0.5 && value <= 1) {
                value = 0.55;
            } else {
                value = 0
            }
        }

        if (value < 0) {
            if (value > -0.25 && value < -0.1) {
                value = -0.1;
            } else if (value >= -0.5 && value < -0.25) {
                value = -0.25;
            } else if (value >= -0.75 && value < -0.5) {
                value = -0.40;
            } else if (value >= -1 && value < -0.75) {
                value = -0.55;
            } else {
                value = 0;
            }
        }

        return value;
    }

    return (
        <Joystick className={`${styles.joystick_component}`} size={100} sticky={false} baseColor="red" stickColor="blue" move={handleMove} stop={handleStop}></Joystick>
    );
}

export default JoystickComponent;