import React, {  useEffect, useRef, useState } from 'react';

import '../compass_styles.css';

const PitchScaleComponent = (props) => {

    const [movePitch, setMovePitch] = useState(Map(props.pitchValue, 90, -90, 40, -680));

    function Map( theValue,  theInStart,  theInEnd,  theOutStart,  theOutEnd)
        {
            return theOutStart + (theOutEnd - theOutStart) * ((theValue - theInStart) / (theInEnd - theInStart));
        }

        

    useEffect(() => {

        
        setMovePitch(Map(props.pitchValue, 90, -90, 40, -680));
         
        
        
        
    }, [props.pitchValue])

    
    
    return (
        <div style={{ position:"absolute", width:"250px", height:"125px",  transform:"translate(0px, 84px)", overflow:"hidden"}}>
            {/* <div style={{ transition:"transform 0.3s", width:"250px", height:"125px", transform: `translate(0px, ${movePitch}px)`}} > */}
            <div style={{ transition:"transform 0.3s", width:"250px", height:"125px", transform: `matrix(1, 0, 0, 1, 0, ${movePitch})`}} >

            
                {[...Array(37)].map((k, i) => 
                    (i % 2 != 0)?
                    (
                        <div key={i+100} style={{position:"absolute", width:"20px", height:"1px", transform:`translate(115px, ${i*20}px)`, backgroundColor:"white"}}>

                        </div>
                    ):
                    (
                        <div key={i+200}>
                        <span key={i+200} style={{position:"absolute", fontSize:"10px", right:"10px", transform:`translate(-132px, ${i*20 - 10}px)`}}>
                            {Math.round(Map(i * 5, 0, 180, 90, -90))}
                        </span>
                        <div key={i+300} style={{position:"absolute", width:"30px", height:"1px", transform:`translate(110px, ${i*20}px)`, backgroundColor:"white"}}>
                        </div>
                        <span key={i+400} style={{position:"absolute", fontSize:"10px", left:"10px", transform:`translate(132px, ${i*20 - 10}px)`}}>
                        {Math.round(Map(i * 5, 0, 180, 90, -90))}
                        </span>
                        </div>
                    )
                )}
            </div>
            
        </div>
    );

}

export default PitchScaleComponent;