import styles from '../css/dashboard.module.css'

import { useState, useRef, useEffect, useContext } from 'react'
import Lottie from 'react-lottie-player'
import lottieJsonReady from '../../../graphics/dashboard icons/item-lista-ready.json'
import lottieJsonAlert from '../../../graphics/dashboard icons/item-lista-alerta.json'

import LocationBtn from '../../../graphics/dashboard icons/new icons/btn-location.svg'
import Poster from '../../../graphics/dashboard icons/foc_PosterImage.png'
import FireIcon from '../../../graphics/dashboard icons/fire-icon.svg'
import AlertVehicleList from './AlertVehicleListComponent';
import { GlobalContext } from "../../../contexts/globalContext";


const AlertItem = ({alert}) => {
    const nameRef = useRef()
    const [expandDetails, setExpandDetails] = useState(false);
    const { mapRef } = useContext(GlobalContext);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;

	const [posX, setPosX] = useState(0)

    useEffect(() => {
        setExpandDetails(false);
        if (nameRef.current) {
			setPosX(nameRef.current.getBoundingClientRect().width / oxDivider + 15)
		}
    }, [])

    const fireIcons = Array.from({ length: 6 }, (_, index) => {
		// Calculate the opacity based on the severity level
		const opacity = index < 2 ? 1 : 0.2;
        //const opacity = 1;

		return (
			<img
				key={index}
				className={`${styles.fireIcon}`}
				style={{ width: `${15 + index}px`, height: `${17 + index * 2}px`, opacity }}
				alt="icon"
				draggable="false"
				src={FireIcon}
			/>
		);
	});

    const vehicles = [
        { name: "Vehicle1", location: "Brasov" },
        { name: "Vehicle2", location: "Sibiu" },
        { name: "Vehicle1", location: "Brasov" },
        { name: "Vehicle2", location: "Sibiu" },
        { name: "Vehicle1", location: "Brasov" },
        { name: "Vehicle2", location: "Sibiu" },
    ];

    const GoToLocation = (location) => {
        mapRef.current.flyTo({
            center: [location[1], location[0]],
            zoom: 14,
            essential: true
        });
    }

    return (
        
        <div className={`${ (expandDetails && alert.is_active) ? styles.vehicleDiv_expand : styles.vehicleDiv}`}
            onTouchEnd={() => {
                if (alert.is_active)
                    setExpandDetails(!expandDetails)
            }}
            style={{ height: expandDetails ? '245px' : '54px' }}>
            <Lottie
                className={`${styles.activAnimation}`}
                loop
                animationData={alert.is_active ? lottieJsonAlert : lottieJsonReady}
                play
            />
            <label ref={nameRef} className={`${alert.is_active ?  styles.nameLabel : styles.nameLabel_offline}`}> {alert.message} </label>
            <div className={`${alert.is_active ? styles.labelDiv_live : styles.labelDiv_solutionat}`} style={{ transform: `matrix(1, 0, 0, 1, ${posX}, 0)` }}>
                <label className={`${alert.is_active ? styles.labelLive : styles.labelSolutionat}`}> {`${alert.is_active ? 'Live' : 'Solved'}`} </label>
            </div>
            <label className={`${styles.siteLabel}`}> {alert.site?.name} </label>

            <div className={`${styles.btnsDiv_copy}`}>
                <img className={`${styles.btn_copy}`} alt="icon" draggable="false" src={LocationBtn} 
                    onTouchEnd={(event)=> {
                        GoToLocation(alert.device.location);
                        event.stopPropagation();
                    }} />
            </div>
            {alert.is_active && expandDetails &&
                <>
                    <div style={{ height: "187px", width: "1px", position: "absolute", top: "56px", left: "158px", backgroundColor: "#323136" }}></div>
                    <div className={`${styles.scrollDiv}`}>
                        <label className={`${styles.labelActiv}`} style={{ left: '168.5px' }}> Vehicule in Misiune </label>
                        <AlertVehicleList vehicule={vehicles} />
                    </div>
                    <div className={styles.alerts_expand_inner_shadow_sus} />
                    <div className={styles.alerts_expand_inner_shadow} />

                    <video
                        // poster={Poster}
                        width="131.9471435546875"
                        height="131.9471435546875"
                        style={{ top: '67.3px', left: '13.7px', position: 'absolute' }}
                        playsInline autoPlay loop
                    >
                        <source src="foc.mp4" type="video/mp4" />
                        Sorry, your browser doesn't support HTML5 video tag.
                    </video>

                    <div className={styles.divFoculete}>
                        <label className={styles.labelSeveritate} > Severitate </label>
                        <div className={styles.divIconsFoculete}>
                            {fireIcons}
                        </div>

                    </div>
                </>
            }
        </div>



    );
}

export default AlertItem;