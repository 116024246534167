export function ApiSaveProject(body, callback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };

    fetch(process.env.REACT_APP_API_URL + '/project.save', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiDeleteFromProject(body, callback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };

    fetch(process.env.REACT_APP_API_URL + '/project.delete.item', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiSaveContact(body, callback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };

    fetch(process.env.REACT_APP_API_URL + '/user.saveContact', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiAddCallToHistory(body, callback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };

    fetch(process.env.REACT_APP_API_URL + '/user.addCallToHistory', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiGetAllAlerts(callback) {
    const requestOptions = {
        method: 'GET'
    };

    fetch(process.env.REACT_APP_API_URL + '/allAlerts', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function StartAlert() {
    var body = JSON.stringify({ device_id: "FireSenzor01", fire_sensor_type: "access_control_type", burning: true});
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body
    };

    fetch(process.env.REACT_APP_API_URL + '/sendData', requestOptions);
        // .then(response => response.json())
        // .then(data => {
        //     //callback?.(data);
        //     //console.log(data);
        // });
}

export function StopAlert() {
    var body = JSON.stringify({ device_id: "FireSenzor01", fire_sensor_type: "access_control_type", burning: false});
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body
    };

    fetch(process.env.REACT_APP_API_URL + '/sendData', requestOptions);
}

export function StopCAAlert() {
    var body = JSON.stringify({ device_id: "ACC_CONTROL01", access_control_type: "ac_type", intrusion: false});
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body
    };

    fetch(process.env.REACT_APP_API_URL + '/sendData', requestOptions);
}