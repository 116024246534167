import React from 'react';
import { useRef, useState, useContext } from 'react';
import styles from '../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { GlobalContext } from '../../contexts/globalContext';
import uuid from 'react-uuid';
import { TransformPoints} from '../../utils/Tools'
import * as turf from "@turf/turf"
import { ApiSaveProject } from "../../utils/RestServices"


const FreeHandDrawLayer = (props) => {
    const [currentLine, setCurrentLine] = useState([]);
    const ref = useRef(null);
    const freeHandRef = useRef(null);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const {setDrawingLayer, drawingsRef, currentDrawingColor, currentProjectId, socketRef } = useContext(GlobalContext);

    useGesture(
    {
        onDrag: ({ xy: [ox, oy] }) => {
            setCurrentLine([...currentLine, [ox, oy]]);  
        },
        onDragEnd: () => {
            const points = TransformPoints(currentLine, props.map);
            setCurrentLine([]);
            setDrawingLayer(0)

            if (currentProjectId == null) {
                let feature = {
                    type: 'Feature',
                    properties: {
                        "class_id": currentDrawingColor
                    },
                    geometry: { type: 'LineString', coordinates: points[0] }
                };

                console.log(feature);
                
                if(points[0].length > 1){
                    let simplified = turf.simplify(feature, {tolerance: 0.0001});
                    drawingsRef.current.add(simplified)
                }
            } else {
                let feature = {
                    id: uuid(),
                    type: 'Feature',
                    properties: {
                        "class_id": currentDrawingColor
                    },
                    geometry: { type: 'LineString', coordinates: points[0] }
                };

                if(points[0].length > 1){
                    let simplified = turf.simplify(feature, {tolerance: 0.0001});
                    drawingsRef.current.add(simplified)

                    socketRef.current.emit("DRAWING_UPDATED", {project_id: currentProjectId, drawings: simplified});
                    var body = JSON.stringify({ project_id: currentProjectId, drawing: simplified });
                    ApiSaveProject(body);
                }
            }
        }
    },
    {
        target: ref,
        drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
    })

    return (
        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT)}} >
            <svg style={{ width: '100%', height: '100%' }}>
                <polyline
                    ref={freeHandRef}
                    points={currentLine.map(([x, y]) => `${x},${y}`).join(' ')}
                    style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4"}}
                />
            </svg>
        </div>
    );
};

export default FreeHandDrawLayer;