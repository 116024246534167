import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './styles.module.css'
import { ApiSaveContact } from "../../utils/RestServices.js"
import { GlobalContext } from "../../contexts/globalContext"

import "@fontsource/roboto";

import noContacsImage from "./sip_components/images/img-fara-contacte.png"
import addContactBtn from './sip_components/dialer_button_svg/add-contact-ic.svg';
import callNumber from './sip_components/dialer_button_svg/call.svg';
import callInternal from './sip_components/dialer_button_svg/call-sip.svg';
import editButton from './sip_components/dialer_button_svg/contact-edit-ic.svg';
import deleteButton from './sip_components/dialer_button_svg/contact-delete-ic.svg';

import AddContactFrom from './sip_components/new_contact_form.js';
import IcSIP from './sip_components/dialer_button_svg/ic-nrSIP.svg'
import DeleteContactComponent from './DeleteContactComponent.js';
import { MultitouchComponent } from 'use-gesture-pack';
import { translate, compose, toString, identity, fromString } from 'transformation-matrix';


const ContactsComponent = (props) => {
    const ref = useRef();
    const [contacts, setContacts] = useState("");
    const [contactClicked, setContactClicked] = useState();
    const [addWindowOpened, setAddWindowOpened] = useState(false);
    const [deleteContact, setDeleteContact] = useState(false);
    const [stopTap, setStopTap] = useState(false);
    const { currentUser } = useContext(GlobalContext);
    const keyboardRef = useRef();

    const height = 400

    const options = {
        appWidth: process.env.REACT_APP_WIDTH, appHeight: process.env.REACT_APP_HEIGHT,
        bringToFront: false
	}

    useEffect(() => {
        SortContacts(currentUser.contacts);
        // console.log(firstNameRef.current)
    }, []);


    const onAddContact = () => {

        if (addWindowOpened)
            setAddWindowOpened(false);
        else
            setAddWindowOpened(true);

    };


    const onSaveContactCallback = (firstName, lastName, number, internal) => {
        // internal trebuie facut optional

        // console.log(firstName, lastName, number, internal)

        if (!firstName || !lastName || (!number && !internal)) {
            return;
        }

        var fullName = camelCase(firstName.toLowerCase()) + " " + camelCase(lastName.toLowerCase());


        var body = JSON.stringify({ user_id: currentUser._id, "name": fullName, "number": number ? number : null, "internal": internal ? internal : null });

        ApiSaveContact(body, data => {
            currentUser.contacts = data;

            SortContacts(data);

            setAddWindowOpened(false);
        });

    };

    function camelCase(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const SortContacts = (auxContacts) => {
        var newContacts = [];

        auxContacts.forEach(element => {
            var firstLetter = element.name[0].toUpperCase();
            element.callsCanvasRight = "-120px";
            if (newContacts[firstLetter]) {
                newContacts[firstLetter].push(element);
            } else {
                newContacts[firstLetter] = [element];
            }
        });

        const keysSorted = Object.keys(newContacts).sort(function (a, b) {
            return a.charCodeAt(0) - b.charCodeAt(0)
        });

        var resultContacts = [];
        for (let i = 0; i < keysSorted.length; i++) {
            resultContacts[keysSorted[i]] = newContacts[keysSorted[i]];
        }

        // console.log(resultContacts);
        setContacts(resultContacts);
    }


    const numberSelected = (contact) => {
        props.onSelectedUser({ name: contact.name, number: contact.number });
    }

    const internalSelected = (contact) => {
        props.onSelectedUser({ name: contact.name, number: contact.internal });
    }

    const editContact = (contact) => {

    }

    const deleteContactFunction = (contact) => {
        console.log(contact)
        setContacts(contacts => contacts.filter(a => a._id !== contact._id))
    }

    // const onDragInside = ({ dy }) => {
    //     setStopTap(true)

    //     var mat = identity();

    //     if (ref.current.style.transform !== '') {
    //         mat = fromString(ref.current.style.transform);
    //     }

    //     var trans = translate(0, dy);
    //     var final = compose(trans, mat);
    //     ref.current.style.transform = toString(final);
    // }

    // const onGestureEnded = () => {
    //     if(ref.current){
    //         var mat = identity();

    //         if (ref.current.style.transform !== '') {
    //             mat = fromString(ref.current.style.transform);
    //         }
    
    //         var dy = 0;
    //         if (ref.current.offsetHeight < 375) {
    //             dy = -mat.f;
    //         } else {
    //             if (mat.f > 0) {
    //                 dy = -mat.f;
    //             }
    
    //             if (mat.f < - (ref.current.offsetHeight - (375))) {
    //                 dy = -(ref.current.offsetHeight - (375)) - mat.f;
    //             }
    //         }
            
            
    
    //         if (dy != 0) {
    //             ref.current.style.transition = "transform 0.2s";
    //             setTimeout(() => {
    //                 ref.current.style.transition = "transform 0.0s";
    //             }, 200);
    //         }
    
    //         var trans = translate(0, dy);
    //         var final = compose(trans, mat);
    //         ref.current.style.transform = toString(final);
    //     }
        
    // }

    const onDragInside = ({ dy }) => {
        var mat = identity();

        if (ref.current.style.transform !== '') {
            mat = fromString(ref.current.style.transform);
        }

        var modifier = MapNumber(mat.f, 0, height / 4, 1, 0);

        if (modifier == 1) {
            modifier = MapNumber(mat.f,  - (ref.current.offsetHeight - height),  - (ref.current.offsetHeight - height + (height / 4)), 1, 0);
        }      

        var trans = translate(0, modifier * dy);
        var final = compose(trans, mat);
        ref.current.style.transform = toString(final);
    }

    const onGestureEnded = () => {
        var mat = identity();

        if (ref.current.style.transform !== '') {
            mat = fromString(ref.current.style.transform);
        }

        var dy = 0;


        if (ref.current.offsetHeight < height) {
            dy = -mat.f;
        } else {
            if (mat.f > 0) {
                dy = -mat.f;
            }

            if (mat.f < - (ref.current.offsetHeight - height)) {
                dy = -(ref.current.offsetHeight - height) - mat.f;
            }
        }

        if (dy != 0) {
            ref.current.style.transition = "transform 0.2s";
            setTimeout(() => {
                ref.current.style.transition = "transform 0.0s";
            }, 200);
        }

        var trans = translate(0, dy);
        var final = compose(trans, mat);
        ref.current.style.transform = toString(final);
    }

    function MapNumber(theValue, theInStart, theInEnd, theOutStart, theOutEnd) {
        var result = theOutStart + (theOutEnd - theOutStart) * ((theValue - theInStart) / (theInEnd - theInStart));
        if (result < 0) {
            return result = 0;
        }
        if (result > 1) {
            return result = 1;
        }
        return result;
    }

    useEffect(() => {
        if(contactClicked === undefined && ref.current){
            onGestureEnded()
        }
    }, [contactClicked]);

    return (
        // <MultitouchComponent width={330} height={460} dragGesture={false} pinchGesture={false} touchAndHoldGesture={false} tapGesture={false} onDragInside={onDragInside}>
        <div style={{ width: "330px", height:"460px", fontFamily: 'Roboto', fontStyle: 'Roboto', background: 'transparent', position: 'relative',  borderRight: "1px solid #323136",  transform:"scale(0.93)", transformOrigin:"top left"}}>
            <div style={{display: "flex", width: "330px", height:"460px", justifyContent: 'space-between', flexDirection: "column", background: 'transparent'}}>
            {contacts ?
                <MultitouchComponent width={330} height={460} dragGesture={false} pinchGesture={false} touchAndHoldGesture={false} tapGesture={false} onDragInside={onDragInside} onGestureEnded={onGestureEnded} options={options}>
                
                <div className={styles.lista_contacte} style={{position:"relative", top:"1px"}}>
                    <div ref={ref} style={{ position: "absolute", width: "100%" }}>
                    {Object.keys(contacts).map((letter) => (
                        
                        <div key={letter} style={{marginTop: "5px" }}>
                            <div style={{ color: "white", fontSize: "16px", marginLeft: "21px" }}>{letter}</div>
                            <div >
                                {contacts[letter].map((contact) => (<div key={contact._id}>
                                    {(contactClicked?.number !== contact.number || contactClicked?.internal !== contact.internal) &&
                                    <div style={{ marginTop:"2.5px", marginBottom:"2.5px"}} >
                                        <table style={{ width: "95%", position: "relative", left: "10px" }} >
                                            <tbody style={{ padding: "0px" }}>
                                                <tr>
                                                    <td className={styles.contact_element} onTouchEnd={() => {
                                                            if(!stopTap)
                                                                setContactClicked(contact)
                                                        }} 
                                                        onTouchStart={() => {
                                                            setStopTap(false)
                                                        }} >
                                                        <div style={{ color: "white", fontSize: "16px", marginLeft: "17.5px" }}>{contact.name}</div>
                                                        
                                                        <div className={styles.numbers}>
                                                            {contact.number && <div style={{ color: "#878688", fontSize: "12px", marginLeft: "17.5px", marginTop:"2px", marginBottom: "6px" }}>{contact.number}</div>}
                                                            {contact.internal && <div style={{ color: "#878688", fontSize: "12px", marginLeft: "17.5px", marginTop:"1px", marginBottom: "6px", display: "flex", flexDirection: "row" }}>
                                                                <img draggable={false} alt="icon" src={IcSIP}/> 
                                                                <div style={{ marginLeft: "3px"}}>{contact.internal}</div>
                                                            </div>}
                                                        </div>
                                                        <div className={styles.btns_call}>
                                                            {contact.internal &&<img draggable={false} className = {styles.call_bttn} alt={"call internal"} src={callInternal}
                                                            onTouchEnd={(event) => {
                                                                event.stopPropagation()
                                                                internalSelected(contact)
                                                            }}/>}
                                                            {contact.number && <img draggable={false} className = {styles.call_bttn} alt={"call number"} src={callNumber} 
                                                            onTouchEnd={(event) => {
                                                                event.stopPropagation()
                                                                numberSelected(contact)
                                                            }}/>}
                                                        </div>
                                                        
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>}
                                    {contactClicked?.number === contact.number && contactClicked?.internal === contact.internal &&
                                    <div style={{ marginTop:"2.5px", marginBottom:"2.5px"}} >
                                        <table style={{ width: "95%", position: "relative", left: "10px" }} >
                                            <tbody style={{ padding: "0px" }}>
                                                <tr>
                                                    <td className={styles.contact_element_details} >
                                                        <div className={styles.contact_name_details} style={{ color: "black", fontSize: "22px", paddingLeft: "11px", paddingTop: "11px" }} onTouchEnd={() => setContactClicked()}>
                                                            <b>{contact.name}</b>
                                                        </div>
                                                        
                                                        <div className={styles.numbers_details}>
                                                            {contact.number && <div style={{ color: "#878688", fontSize: "15px", marginLeft: "11px", marginTop:"11px", marginBottom: "15px" }}>
                                                                {contact.number}
                                                                <img draggable={false} className = {styles.call_bttn_details} alt={"call internal"} src={callNumber} 
                                                                    onTouchEnd={(event) => {
                                                                        event.stopPropagation()
                                                                        numberSelected(contact)
                                                                    }}
                                                                />
                                                            </div>}
                                                            {contact.internal && <div style={{ color: "#878688", fontSize: "15px", marginLeft: "11px", marginBottom: "15px", display: "flex", flexDirection: "row" }}>
                                                                <img alt="icon" draggable="false" src={IcSIP}/> 
                                                                <div style={{ marginLeft: "3px"}}>{contact.internal}</div>
                                                                <img draggable={false} className = {styles.call_bttn_details} alt={"call internal"} src={callInternal} 
                                                                    onTouchEnd={(event) => {
                                                                        event.stopPropagation()
                                                                        internalSelected(contact)
                                                                    }} />
                                                            </div>}
                                                            <div style={{ color: "#878688", fontSize: "12px", paddingLeft: "11px", paddingTop: "11px" }}>Gestionează contul</div>
                                                            <div style={{ color: "#FFFFFF", fontSize: "12px", paddingLeft: "11px", paddingTop: "11px", display: "flex", flexDirection: "row" }} onTouchEnd={() => editContact(contact)}>
                                                                <img draggable={false} style={{ paddingRight:"5px"}} alt={"call internal"} src={editButton}/>
                                                                Editează
                                                            </div>
                                                            <div style={{ color: "#FFFFFF", fontSize: "12px", paddingLeft: "11px", paddingTop: "11px", display: "flex", flexDirection: "row" }} onTouchEnd={() => setDeleteContact(true)}>
                                                                <img draggable={false} style={{ paddingRight:"5px"}} alt={"call internal"} src={deleteButton} />
                                                                Șterge
                                                            </div>
                                                        </div>
                                                        
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>}
                                                
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        
                    ))
                    }
                    </div>
                
                </div>
                </MultitouchComponent>
                :
                <div style={{ flex: 3, overflow: "hidden"}}>
                    <img alt={"no contacts"} src={noContacsImage} draggable={false} style={{ top: '50px', width: "100%" }} />
                    <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        <div style={{ color: "white" }}>
                            Nu aveti contacte in agenda
                        </div>
                        <div style={{ font: "normal normal normal 10px/13px Roboto", color: "#A9A9AB" }}>
                            Atingeți butonul + pentru a le crea
                        </div>
                    </div>
                </div>

            }
            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", alignSelf: "flex-end", position: "absolute", bottom:"0px" }}>
                <input style={{ background: "#1C1C1C 0% 0% no-repeat padding-box", border: "solid", borderWidth: "1px", borderColor: "#39383D", borderRadius: "8px", height: "27px", width: "80%", marginBottom:"5px", marginLeft:"5px" }} placeholder='Cauta'></input>
                <div className={styles.lottie_dialer_button} style={{ width: "33px", height: "33px", marginBottom:"10px", backgroundColor:"#18181C"  }} 
                // onClick={() => { onAddContact() }}
                onTouchEnd={() => { onAddContact() }}>
                    <img draggable={false} alt={"add button"} src={addContactBtn} style={{ position: "absolute", top: "50%", left: "50%", transform:"translate(-50%,-50%)" }} />
                </div>
            </div>
            </div>
            {addWindowOpened &&
                <AddContactFrom keyboardRef={keyboardRef} onSaveContactCallback={onSaveContactCallback} onAddContact={onAddContact} />
            }
            {deleteContact && <DeleteContactComponent contact={contactClicked} setDeleteContact={setDeleteContact} deleteContactFunction={deleteContactFunction}/>}
            {!addWindowOpened && !deleteContact && <> 
                <div className={styles.umbra_contacte_jos} style={{zIndex:  + 1}}/>
                <div className={styles.umbra_contacte_sus}/>
            </>}
        </div>
        // </MultitouchComponent>


    );

}

export default ContactsComponent;
