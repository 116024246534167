import { GlobalProvider } from "../contexts/globalContext"
import App from '../App';

const AppComponent = () => {
    return (
        <GlobalProvider>
                <App />
        </GlobalProvider>
    );
}

export default AppComponent;