import styles from './css/dashboard.module.css'
import { useState, useRef } from 'react'

import FiltruHartaIcon from '../../graphics/dashboard icons/filtru-harta.svg'
import FiltruProximitate from '../../graphics/dashboard icons/filtru-proximitate.svg'
import FiltruStare from '../../graphics/dashboard icons/filtru-stare.svg'
import FiltruTip from '../../graphics/dashboard icons/filtru-tip.svg'


import FiltruTipBlack from '../../graphics/dashboard icons/filtru-tip-black.svg'
import FiltruHartaIconBlack from '../../graphics/dashboard icons/filtru-harta-black.svg'
import FiltruProximitateBlack from '../../graphics/dashboard icons/filtru-proximitate-black.svg'
import FiltruStareBlack from '../../graphics/dashboard icons/filtru-stare-black.svg'

import SearchIcon from '../../graphics/dashboard icons/search-ic.svg'

import MeteoIc from '../../graphics/dashboard icons/new icons/main-ic-vreme.svg'
import VehiculeIc from '../../graphics/dashboard icons/new icons/main-ic-vehicule.svg'
import DroneIc from '../../graphics/dashboard icons/new icons/main-ic-drone.svg'
import AlerteIc from '../../graphics/dashboard icons/new icons/main-ic-alerte.svg'




const FiltersComponent = (props) => {
    const [clickedButton, setClickedButton] = useState(0);
    const selectorRef = useRef();

    const onButtonClicked = (buttonNumber) => {
        props.func(buttonNumber);
        setClickedButton(buttonNumber);
        selectorRef.current.style.top = (buttonNumber * 50) + "px";
    }
    
    return (
        <div className={styles.dcLeft} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto', height:`${props.height}px` }}>
            <img className={styles.main_icon}
                alt="icon" 
                draggable="false" 
                src={props.text === "Weather" ? MeteoIc : (props.text === "Vehicles" ? VehiculeIc : (props.text === "Drones" ? DroneIc : AlerteIc)) }
            /> 
            <label className={styles.dcLabel} style={{fontSize: "14px"}}> {props.text} </label>
            <div className={styles.dcFilters}>
                <div ref={selectorRef} className={styles.icons_copy_active} style={{top:"0px"}}></div>
                <img className={styles.icons_copy} 
                    onTouchStart ={(e) => { 
                        e.stopPropagation();
//                        e.preventDefault();
                    }}
                    onTouchMove={(e) => { 
                        e.stopPropagation() 
  //                      e.preventDefault();
                    }}
                    onTouchCancel={(e) => { 
                        e.stopPropagation() 
    //                    e.preventDefault();
                    }}
                    onTouchEnd={(e) => { 
      //                  e.preventDefault()
                        e.stopPropagation();
                        onButtonClicked(0);
                    }}
                    alt="icon" 
                    draggable="false" 
                    src={clickedButton === 0 ? FiltruStareBlack : FiltruStare}
                /> 
                <img className={styles.icons_copy} 
                    onTouchEnd={() => onButtonClicked(1)}
                    alt="icon" 
                    draggable="false" 
                    src={clickedButton === 1 ? FiltruTipBlack : FiltruTip}
                /> 
                <img className={styles.icons_copy} 
                    onTouchEnd={() => onButtonClicked(2)}
                    alt="icon" 
                    draggable="false" 
                    src={clickedButton === 2 ? FiltruHartaIconBlack : FiltruHartaIcon}
                /> 
                <img className={styles.icons_copy} 
                    onTouchEnd={() => onButtonClicked(3)}
                    alt="icon" 
                    draggable="false" 
                    src={clickedButton === 3 ? FiltruProximitateBlack : FiltruProximitate}
                /> 
                
            </div>
            <div className={`${styles.search_copy}`}>
                <img className={`${styles.searchIcon_copy}`} alt="icon" draggable="false" src={SearchIcon}/>
            </div> 
        </div>
    );
}

export default FiltersComponent;