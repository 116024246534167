import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from '../../mobile.styles.module.css'
import { GlobalContext } from "../../contexts/globalContext"

const MobileProjectsComponent = (props) => {
    const ref = useRef();


    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(null);

    const { currentUser, currentSite, socketRef, currentProjectId, setCurrentProjectId } = useContext(GlobalContext);
    
    useEffect(() => {   
        socketRef.current.emit("JOIN_SITES", [currentSite._id]);
        fetch(process.env.REACT_APP_API_URL + '/projects/' + currentSite._id)
            .then((response) => response.json())
            .then((data) => {
                setProjects(data);
                console.log("am incarcat proiectele");
            });
    }, []);

    




    function loadProject() {
        //console.log(selectedProjectId);
        if (selectedProjectId) {
            if (currentProjectId != null)
                socketRef.current.emit("LEAVE_ROOM", currentProjectId);

            socketRef.current.emit("PROJECT_CHANGED", { old_project_id: currentProjectId, new_project_id: selectedProjectId });
            setCurrentProjectId(selectedProjectId);
            socketRef.current.emit("JOIN_ROOM", {roomID: selectedProjectId, name: currentUser.name});    
        }
    }

    return (
        <>
            {currentSite && <div className={`${styles.project_component}`} ref={ref}>
                {/* <div className={`${styles.load_project_button}`} onClick={()=> setLogged(false)}>
                    <>Log out </>
                </div> */}


                <div className={`${styles.projects_list_component}`}>
                    {projects && projects.map(project => (
                        <div className={`${(selectedProjectId == project._id) ? styles.site_button_clicked : styles.site_button}`} key={project._id} onClick={() => setSelectedProjectId(project._id)}>
                            {project.name}
                        </div>
                    ))}
                </div>

                <div className={`${styles.load_project_button}`} onClick={loadProject}>
                    <>ÎNCARCĂ</>
                </div>
            </div>
            }
        </>
    );
}

export default MobileProjectsComponent;