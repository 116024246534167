import React, {useEffect} from 'react';

const MeteoGraphSVG = ({ xValues, yValues }) => {
    const minValue = Math.min(...yValues);
    const maxValue = Math.max(...yValues);
    const step = countStep(minValue, maxValue, 40);
    const width = 480;
    const height = 80;
    const scaleX = width / (xValues.length - 1);
    const scaleY =  (height-40)/ (maxValue - minValue);


    function countStep(min, max, size) {
        const count_values = max - min + 1;
        const step = size / count_values;
        return step;
    }
    
    function countValues(min, value) {
        const count_values = value - min;
        return count_values;
    }

    useEffect(() => {
        console.log(yValues[0]);
    }, [yValues])

    return (
        <svg width={width} height={height} style={{left: '10px', top: '176px', position: 'absolute'}} >
            {/* Draw x-axis labels */}
            {xValues.map((value, index) => (
                <text key={index} x={18.75 + index * scaleX} y={80} fontSize="8px" fontFamily="Roboto" fill="white">
                    {value}
                </text>
            ))}
            {/* Draw temperature line */}
            <polyline
                points={[
                    `${0},${45 - countValues(minValue, yValues[0]) * scaleY + 20}`, // Start point at the beginning of the x-axis
                    ...xValues.map((value, index) => `${18.75 + index * scaleX},${45 - countValues(minValue, yValues[index]) * scaleY + 20}`),
                    `${18.75 + (xValues.length - 1) * scaleX},${45 - countValues(minValue, yValues[xValues.length - 1]) * scaleY + 20}` // End point at the end of the x-axis
                ].join(' ')}
                fill="none"
                stroke="white"
            />
            {/* Draw temperature labels */}
            {yValues.map((value, index) => (
                <text
                    key={index}
                    x={18.75 + index * scaleX}
                    y={56 - countValues(minValue, value) * scaleY}
                    fontSize="8px"
                    fontFamily="Roboto"
                    fill="white"
                    textAnchor="middle"
                >
                    {value}
                </text>
            ))}
        </svg>
    );
    
};

export default MeteoGraphSVG;