import { useRef, useEffect, useContext, useState } from 'react';
import styles from './../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import { GetMaxZIndex } from '../../utils/Tools'
import { GlobalContext } from "../../contexts/globalContext"
import * as XLSX from 'xlsx';
import { DataGrid } from '@mui/x-data-grid';

import calculateSize from "calculate-size"


const ExcelComponent = (props) => {

    

    const ref = useRef(null);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(631);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    const { setFilesToBeOpen } = useContext(GlobalContext);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const [isVisible, setIsVisible] = useState(false);
    const [firstDrag, setFirstDrag] = useState(true);


    useEffect(() => {
        fetch(process.env.REACT_APP_CMS_URL + props.file.path)
            .then(response => response.arrayBuffer())
            .then(data => {
                // console.log(data);
                const workbook = XLSX.read(data, { type: 'buffer' });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const dataXLS = XLSX.utils.sheet_to_json(worksheet);
                //console.log(dataXLS[0]);
                var columnsAux = [];
                let widthAux = 0;
                Object.keys(dataXLS[0]).map((item) => {
                    const size = calculateSize(item, {
                        font: 'Arial',
                        fontSize: '30px'
                    })
                    //console.log(size);
                    widthAux += size.width;
                    columnsAux.push({ field: 'id', field: item, headerName: item, width: size.width });
                });

                setWidth(widthAux +2);
                setColumns(columnsAux);

                // console.log(props.x);
                // console.log(props.y);

                setIsVisible(true)

                setX(props.x - width/2);
                setY(props.y - height/2);

                dataXLS.map((item, index) => {
                    item.id = index;
                }) 

                setRows(dataXLS);
            });
    }, [])



    useGesture(
        {
            onDragStart: () => {
                if(firstDrag){
                    ref.current.style.transition = `none`
                    ref.current.style.transform = `matrix(1, 0, 0, 1, ${ref.current.getBoundingClientRect().x / oxDivider}, ${ref.current.getBoundingClientRect().y/oyDivider})`
                    setFirstDrag(false)
                } 
            },
            onDrag: ({ pinching, cancel, xy: [ox, oy], lastOffset, movement: [mx, my], delta: [dx, dy], first, memo, ...rest }) => {
                if (pinching) {
                    return cancel()
                }

                if (first) {
                    ref.current.style.zIndex = GetMaxZIndex() + 1;
                }

                var mat = identity();
                if (ref.current.style.transform !== '') {
                    mat = fromString(ref.current.style.transform);
                }

                var trans = translate(dx, dy);
                var final = compose(trans, mat);
                ref.current.style.transform = toString(final);

                return memo
            },
            onDragEnd: ({ xy: [ox, oy] }) => {
                if (ox > props.X) {

                    var mat = identity();
                    var trans = translate(props.X, props.Y);

                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);
                }
                const { width, height, x, y } = ref.current.getBoundingClientRect();

                if (x + width /2 < 0){
                    ref.current.className = styles.touch_component_anim;

                    let mat = fromString(ref.current.style.transform);
                    let trans = translate((-(x + width /2) ) / oxDivider, 0);
                    let final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.touch_component;
                    }, 500);

                }

                if (x + width /2 > window.innerWidth){
                    ref.current.className = styles.touch_component_anim;
                    let mat = fromString(ref.current.style.transform);
                    let trans = translate(- ((x + width /2) - window.innerWidth) / oxDivider, 0);
                    let final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.touch_component;
                    }, 500);
                }

                if (y + height /2 < 0){
                    ref.current.className = styles.touch_component_anim;
                    let mat = fromString(ref.current.style.transform);
                    let trans = translate(0, (-(y + height /2) ) / oyDivider);
                    let final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.touch_component;
                    }, 500);
                }

                if (y + height /2 > window.innerHeight){
                    ref.current.className = styles.touch_component_anim;
                    let mat = fromString(ref.current.style.transform);
                    let trans = translate(0, - ((y + height /2) - window.innerHeight) / oyDivider);
                    let final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.touch_component;
                    }, 500);
                }
            },
            onPinch: ({ origin: [ox, oy], first, delta: [dx, dy], offset: [s, a], memo, movement: [ms] }) => {

                if (first) {
                    memo = [ox, oy, a, s]
                } else {
                    const { width, height, x, y } = ref.current.getBoundingClientRect()

                    // apply translation to center the element at pinch origin
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((ox - memo[0]) / oxDivider, (oy - memo[1]) / oyDivider);

                    // apply inverse transformation to find the rotated pinch origin
                    var inv = inverse(mat);
                    var pointCentruTranslatat = applyToPoint(inv, [(width / 2 + x) / oxDivider, (height / 2 + y) / oyDivider]);
                    var diffCenterX = pointCentruTranslatat[0] - (ref.current.offsetWidth / 2);
                    var diffCenterY = pointCentruTranslatat[1] - (ref.current.offsetHeight / 2);

                    // convert the pinch origin to the original scale
                    var pointOx = applyToPoint(inv, [ox / oxDivider, oy / oyDivider]);

                    // calculate the point to apply transformations
                    var point = applyToPoint(mat, [pointOx[0] - diffCenterX - ref.current.offsetWidth / 2, pointOx[1] - diffCenterY - ref.current.offsetHeight / 2]);

                    // apply rotation and scaling
                    var rotate = rotateDEG(a - memo[2], point[0], point[1]);
                    var scaleFunction = scale((s / memo[3]), (s / memo[3]), point[0], point[1]);

                    var final = compose(rotate, scaleFunction, trans, mat);
                    ref.current.style.transform = toString(final);

                    if (s <= 0.5) {
                        setFilesToBeOpen(filesToBeOpen => filesToBeOpen.filter(a => a.uid !== props.file.uid));
                    }

                    memo = [ox, oy, a, s]
                }
                return memo
            },
            onPinchEnd: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
                const { width, height, x, y } = ref.current.getBoundingClientRect();

                if (x + width / 2 < 0) {
                    ref.current.className = styles.touch_component_anim;

                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((-(x + width / 2)) / oxDivider, 0);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.touch_component;
                    }, 500);

                }

                if (x + width / 2 > window.innerWidth) {
                    ref.current.className = styles.touch_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(- ((x + width / 2) - window.innerWidth) / oxDivider, 0);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.touch_component;
                    }, 500);
                }

                if (y + height / 2 < 0) {
                    ref.current.className = styles.touch_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(0, (-(y + height / 2)) / oyDivider);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.touch_component;
                    }, 500);
                }

                if (y + height / 2 > window.innerHeight) {
                    ref.current.className = styles.touch_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(0, - ((y + height / 2) - window.innerHeight) / oyDivider);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.touch_component;
                    }, 500);
                }
            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
            pinch: { rubberband: true },
        }
    )

    const containerStyle = {
        width: `${isVisible ? width : 10}px`, 
        height: `${isVisible ? height : 10}px`,
        opacity: `${isVisible ? 1 : 0}`,
        overflow: 'hidden',
        transition: `width 0.5s ease, height 0.5s ease, transform 0.5s ease`,
        transformOrigin: 'center center',
        transform: `translate(-50%, -50%) matrix(1, 0, 0, 1, ${props.x + 75}, ${ props.y + 60})`,
        borderRadius:"20px", 
        padding:"20px",
    };

    return (
        <div className={styles.touch_component} ref={ref} style={containerStyle}>
            <DataGrid  
                style={{backgroundColor:"white"}}          
                rows={rows}
                columns={columns}
                disableColumnMenu
                hideFooterSelectedRowCount
                rowHeight={52}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10]}
            />
        </div>
    );

}

export default ExcelComponent;