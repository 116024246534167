import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from '../../css/main_menu.module.css'
import { GlobalContext } from "../../contexts/globalContext"
import "@fontsource/oswald/300.css"
import "@fontsource/roboto";

const DreaptaMenuComponent = (props) => {
    const saveNewProjectsRef = useRef()
    const divNameNewProjectsRef = useRef()

    var [date, setDate] = useState(new Date());

    const [projectNameIsOverflowing, setProjectNameIsOverflowing] = useState(false)

    const {currentProjectName} = useContext(GlobalContext);

    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    });

    function check(el) {   
        if(el.current){
            var isOverflowing = el.current.clientWidth < el.current.scrollWidth
            || el.current.clientHeight < el.current.scrollHeight; 
            setProjectNameIsOverflowing(isOverflowing)
            if(!isOverflowing){
                saveNewProjectsRef.current.style.textAlign = "center"
            }
        } 
    }

    useEffect(() => {
        check(divNameNewProjectsRef)
    },[currentProjectName]);

    return (
        <div className={`${styles.main_menu_touch_component}`} style={{ position: "absolute", color: 'white', top:`110px`, left:`-88.35px`, textAlign:"center" }}>
            <svg width="106" height="164" viewBox="0 0 106 164">
                <defs>
                    <linearGradient id="linear-gradient-dreapta" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#efefef"/>
                        <stop offset="0.581"/>
                        <stop offset="1"/>
                    </linearGradient>
                    <filter id="Path_479" x="0" y="0" width="106" height="164" filterUnits="userSpaceOnUse">
                        <feOffset dx="-20" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="10" result="blur"/>
                        <feFlood floodOpacity="0.565"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="menu-label-dreapta" transform="translate(-178.5 -608.5)">
                    <path id="Path_477" data-name="Path 477" d="M54.5,120.5V.5h-30a24.028,24.028,0,0,0-24,24v72a24.028,24.028,0,0,0,24,24Z" transform="translate(220 630)" fill="url(#linear-gradient-dreapta)"/>
                    <path id="Path_478" data-name="Path 478" d="M50.5,4.5h-30a20.023,20.023,0,0,0-20,20v72a20.023,20.023,0,0,0,20,20h30" transform="translate(224 630)" fill="none" stroke="#e8e8e8" strokeWidth="0.5" opacity="0.247"/>
                    <g transform="matrix(1, 0, 0, 1, 178.5, 608.5)" filter="url(#Path_479)">
                        <path id="Path_479-2" data-name="Path 479" d="M46.5,8.5h-30a16,16,0,0,0-16,16v72a16,16,0,0,0,16,16h30Z" transform="translate(49.5 21.5)" fill="#020107"/>
                    </g>
                </g>
            </svg>
            {/* nume */}
            <div ref={saveNewProjectsRef}  className={`${(projectNameIsOverflowing) ? styles.scrollmenu_dreapta : styles.scrollmenu_no_overflow_dreapta}`} 
            style={{whiteSpace:"nowrap", overflow:"hidden", position: "absolute", left: "25px", top: "80px", color: '#E4A83C', fontFamily:"Roboto", fontSize: '8px', textAlign:"center" }} 
            >
                <div ref={divNameNewProjectsRef}>{currentProjectName}</div>
            </div>
            {/* Ceas Ora */}
            <div style={{ position: "absolute", color: 'white', fontSize:"24px", fontFamily:"Oswald", top:"32.5px", left:"60px", textAlign:"center" }} 
            width="50" height="25">
                <p>{date.toLocaleTimeString('en-RO', { hour12: false, hour: '2-digit', minute:'2-digit' }).split(':')[0]}</p>
            </div>
            {/* Ceas Minute */}
            <div style={{ position: "absolute", color: 'white', fontSize:"24px", fontFamily:"Oswald", top:"56.5px", left:"60px", textAlign:"center" }} 
            width="50" height="25">
                <p>{date.toLocaleTimeString('en-RO', { hour12: false, hour: '2-digit', minute:'2-digit' }).split(':')[1]}</p>
            </div>

            {/* Data */}
            <div style={{ position: "absolute", color: '#878688', fontSize:"6px", fontFamily:"Oswald", top:"45px", left:"56px", textAlign:"center" }} 
            width="440" height="440">
                <p>{date.toLocaleDateString('en-RO', { weekday: 'short', day: '2-digit', month: '2-digit' }).replace(/\//g, '.')}.</p>

            </div>

        </div>
    );
}

export default DreaptaMenuComponent;