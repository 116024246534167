import React, { useContext } from 'react';
import { SvgIcon } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';

import styles from '../../styles.module.css'
import SymbolsCategoryComponent from "./SymbolsCategoryComponent";
import { GlobalContext } from "../../contexts/globalContext"

const SymbolsCategoriesComponent = React.memo( () => {

    const { symbolsData } = useContext(GlobalContext);
    const [valueSymbols, setValueSymbols] = React.useState(0);

    const handleChangeSymbols = (event, newValue) => {
        setValueSymbols(newValue);
    };

    let getSvgIcon = (category) => (
        <SvgIcon viewBox={category.icon_viewBox}>
            <path d={category.icon_path} />
        </SvgIcon>
    );

    return (       
        <Box sx={{ width: 'calc(100% - 3px)', bgcolor: 'background.paper' }} component='div'>
            <AppBar position="static">
            <Tabs value={valueSymbols} onChange={handleChangeSymbols} 
                indicatorColor="transparent"
                variant="fullWidth"
                textColor="inherit"
                aria-label="full width tabs example"
                style={{backgroundColor:"#383838"}}
            >

                {symbolsData.map((symbolCategory, index) => (
                    <Tab style={{backgroundColor:"#555555", color:"white"}} key={index} icon={getSvgIcon(symbolCategory)} label={symbolCategory.name} wrapped />
                ))}
            </Tabs>
            </AppBar>

            {symbolsData.map((symbolCategory, index) => (
                <div hidden={valueSymbols !== index} key={index} className={`${styles.tab_panel}`}>
                    <SymbolsCategoryComponent categories={symbolCategory.categories}></SymbolsCategoryComponent>
                </div>
            ))}
        </Box>           
    );
})

export default SymbolsCategoriesComponent;