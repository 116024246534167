import { useState, useRef, useEffect } from 'react'
import styles from '../css/dashboard.module.css'

import FiltersComponent from '../FiltersComponent'

import VehicleList from './VehicleListComponent'
import TypeVehicleList from './TypeVehicleListComponent'
import HartaVehicleList from './HartaVehicleListComponent'
import ApropiereVehicleList from './ApropiereVehicleListComponent'
import { MultitouchComponent } from 'use-gesture-pack';
import { translate, compose, toString, identity, fromString } from 'transformation-matrix';


const VehiclesComponent = (props) => {
    const [filterType, setFilterType] = useState(0);    

    const filter_data = (data) => {
        setFilterType(data);
    }

    const ref = useRef();
    const ref0 = useRef();
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();

    useEffect(() => {
        switch (filterType) {
            case 0:
                ref.current = ref0.current;
                break;
            case 1:
                ref.current = ref1.current;
                break;
            case 2:
                ref.current = ref2.current;
                break;
            case 3:
                ref.current = ref3.current;
                break;
        }

    }, [filterType])

    const onDragInside = ({ dy }) => {
        var mat = identity();

        if (ref.current.style.transform !== '') {
            mat = fromString(ref.current.style.transform);
        }

        var modifier = MapNumber(mat.f, 0, props.height / 4, 1, 0);

        if (modifier == 1) {
            modifier = MapNumber(mat.f,  - (ref.current.offsetHeight - props.height),  - (ref.current.offsetHeight - props.height + (props.height / 4)), 1, 0);
        }    
        
        dy = dy /props.scale_factor

        var trans = translate(0, modifier * dy);
        var final = compose(trans, mat);
        ref.current.style.transform = toString(final);
    }

    const onGestureEnded = () => {
        var mat = identity();

        if (ref.current.style.transform !== '') {
            mat = fromString(ref.current.style.transform);
        }

        var dy = 0;


        if (ref.current.offsetHeight < props.height) {
            dy = -mat.f;
        } else {
            if (mat.f > 0) {
                dy = -mat.f;
            }

            if (mat.f < - (ref.current.offsetHeight - props.height)) {
                dy = -(ref.current.offsetHeight - props.height) - mat.f;
            }
        }

        if (dy != 0) {
            ref.current.style.transition = "transform 0.2s";
            setTimeout(() => {
                ref.current.style.transition = "transform 0.0s";
            }, 200);
        }

        var trans = translate(0, dy);
        var final = compose(trans, mat);
        ref.current.style.transform = toString(final);
    }

    function MapNumber(theValue, theInStart, theInEnd, theOutStart, theOutEnd) {
        var result = theOutStart + (theOutEnd - theOutStart) * ((theValue - theInStart) / (theInEnd - theInStart));
        if (result < 0) {
            return result = 0;
        }
        if (result > 1) {
            return result = 1;
        }
        return result;
    }

    return (
        // <MultitouchComponent pinchGesture={true} dragGesture={false} onDragInside={onDragInside} tapGesture={false} touchAndHoldGestur={false}>
        <MultitouchComponent width={600} height={300} dragGesture={false} pinchGesture={false} touchAndHoldGesture={false} tapGesture={false} onDragInside={onDragInside} onGestureEnded={onGestureEnded}>
            <div className={`${styles.dashboardElements}`} style={{top: `${props.top}px`, height:`${props.height}px`, transformOrigin:"top left", transform:`scale(${props.scale_factor})`}}>
                <FiltersComponent text={"Vehicles"} func={filter_data}  height={props.height}/>
                {filterType === 0 && <div className={`${styles.vehicleBody}`} style={{height:`${props.height}px`}}>
                    <div ref={ref0} style={{ position: "absolute", paddingBottom:"20px", paddingTop:"10px" }}>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Activ </label>
                            <VehicleList status='activ' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Ready </label>
                            <VehicleList status='ready' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Offline </label>
                            <VehicleList status='offline' />
                        </div>
                    </div>
                </div>}
                {filterType === 1 && <div className={`${styles.vehicleBody}`} style={{height:`${props.height}px`}}>
                    <div ref={ref1} style={{ position: "absolute", paddingBottom:"20px", paddingTop:"10px" }}>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Ambulanta </label>
                            <TypeVehicleList type='ambulanta' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Masina de politie </label>
                            <TypeVehicleList type='politie' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Masina de pompieri </label>
                            <TypeVehicleList type='pompieri' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Elicopter </label>
                            <TypeVehicleList type='elicopter' />
                        </div>
                    </div>
                </div>}
                {filterType === 2 && <div className={`${styles.vehicleBody}`} style={{height:`${props.height}px`}}>
                    <div ref={ref2} style={{ position: "absolute", paddingBottom:"20px", paddingTop:"10px" }}>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Rasnov </label>
                            <HartaVehicleList type='harta1' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Ghimbav </label>
                            <HartaVehicleList type='harta2' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Brasov </label>
                            <HartaVehicleList type='harta3' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Codlea </label>
                            <HartaVehicleList type='harta4' />
                        </div>
                    </div>
                </div>}
                {filterType === 3 && <div className={`${styles.vehicleBody}`} style={{height:`${props.height}px`}}>
                    <div ref={ref3} style={{ position: "absolute", paddingBottom:"20px", paddingTop:"10px" }}>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Raza Mica </label>
                            <ApropiereVehicleList type='mic' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Raza Medie </label>
                            <ApropiereVehicleList type='mediu' />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <label className={`${styles.labelActiv}`}> Raza Mare </label>
                            <ApropiereVehicleList type='mare' />
                        </div>
                    </div>
                </div>}
                <div className={styles.drone_inner_shadow} />
                <div className={styles.drone_inner_shadow_up} />
            </div>
        </MultitouchComponent>
    );
}

export default VehiclesComponent;