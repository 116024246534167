import React, { useEffect, useState, useContext } from 'react';
import Typography from '@mui/material/Typography';
import { GlobalContext } from "../../contexts/globalContext"
import uuid from 'react-uuid';
import styles from '../../styles.module.css'

const SymbolsSubcategoriesComponent = React.memo( props => {
   
    const [keys, setKeys] = useState([]);
    const { setCurrentSpikes, setCurrentRepetition, currentShapeName, setCurrentShapeName, mapRef, drawingLayer, setSymbols, currentSite, currentProjectId, socketRef, setDrawingLayer, setCurrentShape} = useContext(GlobalContext);
    
    useEffect(() => {
        setKeys(Object.keys(props.subcategories));
    }, [])

    const addSymbolToMap = (icon) => {
        const uid = uuid();
        var symbol = {
            uid,
            location: [mapRef.current.getMap().getCenter().lat, mapRef.current.getMap().getCenter().lng],
            icon,
            altitude: null,
            uniqueDesignation: "",
            speed: null,
            reinforced: false,
            reduced: false,
            staffComments: "",
            additionalInfo: "",
            higherFormation: "",
            reliabilityRatings: "",
            credibilityRatings: "",
            effectiveness: "",
            sifCode: ""
        };

        if (currentProjectId == null) {
            setSymbols(symbols => [...symbols, symbol]);    
        } else {
            socketRef.current.emit("SYMBOL_UPDATED", {project_id: currentProjectId, symbol});            
        }        
        mapRef.current.getMap().setPitch(0);
    }    

    const startDragSymbol = (event, icon) => {
        event.dataTransfer.setData("icon", icon);
    }

    const drawWithRectangle = (symbol) => {

        if (currentShapeName == symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(7);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            mapRef.current.getMap().setPitch(0);

        }
        
    }

    const drawWithFreehand = (symbol) => {
        // setDrawingLayer(8);
        // setCurrentShape(symbol.shape);
        // mapRef.current.getMap().setPitch(0);
        if (currentShapeName == symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(8);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            mapRef.current.getMap().setPitch(0);
        }
    }

    const drawWithFreehandText = (symbol) => {
        // setDrawingLayer(9);
        // setCurrentShape(shape);
        // mapRef.current.getMap().setPitch(0);
        if (currentShapeName == symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(9);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            mapRef.current.getMap().setPitch(0);
        }
    }

    const drawWithFreehandTextArea = (symbol) => {
        if (currentShapeName === symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(10);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            mapRef.current.getMap().setPitch(0);
        }
    }

    const drawWithFreehandModels = (symbol) => {
        if (currentShapeName === symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(11);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            setCurrentRepetition(symbol.repetition)
            mapRef.current.getMap().setPitch(0);
        }
    }

    const drawWithFreehandMiddleModels = (symbol) => {
        if (currentShapeName === symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(13);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            setCurrentRepetition(symbol.repetition)
            mapRef.current.getMap().setPitch(0);
        }
    }
    const drawWithFreehandAreaModels = (symbol) => {
        if (currentShapeName === symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(14);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            mapRef.current.getMap().setPitch(0);
        }
    }

    const drawWithPolylineGraphic = (symbol) => {
        if (currentShapeName === symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(15);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            mapRef.current.getMap().setPitch(0);
        }
    }

    const drawWithLineGraphic = (symbol) => {
        if (currentShapeName === symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(16);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            mapRef.current.getMap().setPitch(0);
        }
    }

    const drawWithLineSemicircle = (symbol) => {
        if (currentShapeName === symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(17);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            setCurrentRepetition(symbol.repetition)
            mapRef.current.getMap().setPitch(0);
        }
    }

    const drawWithPolylineDrones = (symbol) => {
        if (currentShapeName === symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(18);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            setCurrentRepetition(symbol.repetition)
            mapRef.current.getMap().setPitch(0);
        }
    }
    const drawWithRectangleText = (symbol) => {
        if (currentShapeName === symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(19);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            setCurrentRepetition(symbol.repetition)
            mapRef.current.getMap().setPitch(0);
        }
    }

    const drawWithCircle = (symbol) => {
        if (currentShapeName === symbol.full_name) {
            setDrawingLayer(0);    
            setCurrentShapeName("");
        } else {
            setDrawingLayer(12);
            setCurrentShape(symbol.shape);
            setCurrentShapeName(symbol.full_name);
            setCurrentSpikes(symbol.spikes)
            mapRef.current.getMap().setPitch(0);
        }
    }

    function shapeButtonClickHandler(number) {
        if (drawingLayer == number) {
            setDrawingLayer(0);
        } else {
            setDrawingLayer(number);
            mapRef.current.getMap().setPitch(0);
        }
    };

    const project = (symbol, index) => {
        switch(symbol.type) {
  
            case "symbol":   return <img width="55px" onDragStart={(event)=> startDragSymbol(event, symbol.icon)} key={index} style={{marginRight:"10px"}} src={ process.env.REACT_APP_MILSYMBOLS_URL.replace("{0}", symbol.icon) }/>;

            case "draw_with_rectangle":   
                return <img width="55px"  draggable="false" key={index} onClick={() => drawWithRectangle(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_freehand":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithFreehand(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_freehand_and_text":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithFreehandText(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_freehand_and_text_area":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithFreehandTextArea(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_freehand_and_models":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithFreehandModels(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_freehand_and_middle_model":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithFreehandMiddleModels(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_freehand_and_area_models":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithFreehandAreaModels(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_two_lines":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithPolylineGraphic(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_one_line":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithLineGraphic(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_line_and_semicircle":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithLineSemicircle(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_polyline_drones":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithPolylineDrones(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_rectangle_and_text":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithRectangleText(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "draw_with_circle":   
                return <img width="55px" draggable="false" key={index} onClick={() => drawWithCircle(symbol)} style={{marginRight:"10px"}} src={ symbol.icon } className= {`${(currentShapeName==symbol.full_name)?styles.selected_image:styles.gray_image}`}/>;
            case "generic_graphic":   
                return <img width="55px" draggable="false" key={index} className= {`${(drawingLayer==symbol.shape)?styles.selected_image:styles.gray_image}`} onClick={() => shapeButtonClickHandler(symbol.shape)} style={{marginRight:"10px"}} src={ symbol.icon }/>;
            default:      
                return <h1>No project match</h1>
        }
      }

    return (       
        <>
            {keys.map((keyx, indexKey) => (
                <div  key={indexKey}>
                    <Typography color="gray">
                    {keyx}
                    </Typography>

                    {props.subcategories[keyx].map((symbol, index) => (
                        project(symbol, index)
                            
                            // {(symbol.type === "symbol") && <img width="55px" onClick={() => addSymbolToMap(symbol.icon)} key={index} style={{marginRight:"10px"}} src={ process.env.REACT_APP_MILSYMBOLS_URL.replace("{0}", symbol.icon) }/>}
                            // {(symbol.type === "draw_with_rectangle") && <img width="55px" key={index} style={{marginRight:"10px"}} src={ symbol.icon }/>}
                            
                            
                        
                        // <Typography color="gray">
                        // {symbol.icon}
                        // </Typography>
                    ))}
                </div>
                
            ))}
        </>
               
    );
}
)

 

export default SymbolsSubcategoriesComponent;