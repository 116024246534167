import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from '../css/devices.module.css'
import { useGesture } from '@use-gesture/react'
import { GetMaxZIndex } from '../utils/Tools'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import "@fontsource/roboto";

import { GlobalContext } from "../contexts/globalContext"
import RtspPlayerComponent from '../components/RtspPlayerComponent';
import JoystickComponent from '../components/JoystickComponent';
import CompassAndPYRComponent from './CompassAndPYRComponent';

import MSEPlayerComponent from '../components/MSEPlayerComponent';
import WebRTCPlayerComponent from '../components/WebRTCPlayerComponent';

import minimizeBttn from '../graphics/dashboard icons/icons meteo/btn-minimize.svg'
import locationBttn from '../graphics/dashboard icons/icons meteo/btn-location.svg'
import { StopAlert, StartAlert, StopCAAlert } from "../utils/RestServices"


//import ReactHlsPlayer from 'react-hls-player';

const DeviceComponent = (props) => {
    const ref = useRef();
    const nameRef = useRef();
    const [top, setTop] = useState(820);
    const [left, setLeft] = useState(620);

    const [width, setWidth] = useState(0);
    const [opacity, setOpacity] = useState(0);
    const [height, setHeight] = useState(0);
    const [pyr, setPyr] = useState([]);

    const [siteName, setSiteName] = useState();
    const [posX, setPosX] = useState()
    const [clickedTab, setClickedTab] = useState(0)

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const { sites, mapRef, setDevicesForComponents } = useContext(GlobalContext);

    useEffect(() => {
        //console.log(props.device)
        for (let i = 0; i < sites.length; i++) {
			if (sites[i]._id === props.device.site) {
				setSiteName(sites[i].name)
			}
		}

        if(mapRef.current.getBounds().contains([props.device.location[1], props.device.location[0]])){
            const position = mapRef.current.project([props.device.location[1], props.device.location[0]]);
            setLeft(position.x / oxDivider);
            setTop(position.y / oyDivider);
        } 

        setPosX(props.device.name.length * 15 )

        var new_height = 120;

        if (props.device.speed) {
            new_height +=10;
        }

        if (props.device.altitude) {
            new_height +=10;
        }

        if (props.device.direction) {
            new_height += 300;
        }

        
        if (props.device.data) {
            Object.keys(props.device.data).map((param) => {
                new_height += 40;
                if (getParameterType(param) == "RTSP_STREAM_URL") {
                    new_height += 300;
                }
                if (getParameterType(param) == "MSE_URL") {
                    new_height += 300;
                }
                if (getParameterType(param) == "WEBRTC_URL") {
                    new_height += 300;
                }
                if (getParameterType(param) == "PTZ_URL") {
                    new_height += 50;
                } 
                
                if (getParameterType(param) == "PYR") {
                    setPyr(props.device.data[param])
                }
            });
        }

        if(props.device.name !== "CGT200" && props.device.device_type.name !== "Drona" && props.device.device_type.name !== "Drona DJI"){
            setWidth(600);
            setHeight(new_height);
        } else {
            setWidth(800);
            setHeight(910);
            if(!mapRef.current.getBounds().contains([props.device.location[1], props.device.location[0]])){
                ref.current.style.transformOrigin = `top left`
                ref.current.style.transform = `matrix(0.6, 0, 0, 0.6, ${left}, ${top})`
            }
        }
        setOpacity(1)
        
    }, [])



    useEffect(() => {
        if (props.device.data) {
            Object.keys(props.device.data).map((param) => {
                if (getParameterType(param) == "PYR") {
                    setPyr(props.device.data[param])
                }
            });
        }
    }, [props.device])


    const getParameterName = code => {
        let res = props.parameters.filter(a => a.code === code);
        return res[0].name;
    }

    const getParameterType = code => {
        let res = props.parameters.filter(a => a.code === code);
        return res[0].type;
    }

    const getParameterUM = code => {
        let res = props.parameters.filter(a => a.code === code);
        return res[0].um;
    }

    const formatValue = value => {
        if (typeof value == "boolean") {
            value = (value)?"DA":"NU";
        }
        //let res = props.parameters.filter(a => a.code === code);
        return value;
    }
   

    const getComponentForParameter = (param, newWidth = 800) => {
        switch(getParameterType(param)) {
  
          case "RTSP_STREAM_URL": return <RtspPlayerComponent key={param} streamUrl={props.device.data[param]} WIDTH="580" ></RtspPlayerComponent>;          
          case "MSE_URL": return <MSEPlayerComponent key={param} url={props.device.data[param]} WIDTH="580" ></MSEPlayerComponent>;          
          case "WEBRTC_URL": return <WebRTCPlayerComponent key={param} url={props.device.data[param]} WIDTH={newWidth} ></WebRTCPlayerComponent>;          
        // //  case "HLS_STREAM_URL": return <ReactHlsPlayer key={param} src={props.device.data[param]} className={styles.hls_stream_url} autoPlay={true}></ReactHlsPlayer>;          
          case "PTZ_URL": return <JoystickComponent key={param} device_uid={props.device.uid}></JoystickComponent>
          case "PYR": return null;
          
          default: return <span key={param} className={styles.device_component_properties}><b>{getParameterName(param)}:</b>   {formatValue(props.device.data[param])} {getParameterUM(param)}</span>
        }
    }


    const goToDevice = drone => {
        mapRef.current.flyTo({
            center: [drone.location[1], drone.location[0]],
            zoom: 18,
            essential: true
        });
    };

    return (
        <>
        {(props.device.name !== "CGT200" && props.device.device_type.name !== "Drona" && props.device.device_type.name !== "Drona DJI" && props.device.device_type.name !== "Statie Meteo" && props.device.device_type.name !== "Fire Sensor"&& props.device.device_type.name !== "Access Control") ? 
        <div className={styles.device_component} ref={ref} style={{ transform: `matrix(1, 0, 0, 1, ${left}, ${top})`, width: width, height: height, fontFamily: 'Roboto', fontStyle: 'Roboto', opacity: opacity }}>
            <div className={styles.device_component_back} ref={ref} style={{ width: width, height: height }}>
                <span className={styles.device_component_title} >{props.device.device_type.name}: {props.device.name} </span>
                <span className={styles.device_component_subtitle}></span>

                <span className={styles.device_component_properties}><b>Locatie:</b> {props.device.location[0]}  {props.device.location[1]}</span>

                {(props.device.speed || props.device.speed ==0) && <span className={styles.device_component_properties}><b>Viteza:</b> {props.device.speed}</span>}
                {(props.device.altitude || props.device.altitude ==0) && <span className={styles.device_component_properties}><b>Altitudine:</b> {props.device.altitude}</span>}
                {props.device.direction && <CompassAndPYRComponent key="direction" direction={props.device.direction} pyrValues={pyr}></CompassAndPYRComponent>}
                {props.device.data && Object.keys(props.device.data).map((param) => (
                    <div style={{marginLeft:"10px"}}>
                        
                        {getComponentForParameter(param, 580)}
                    </div>
                ))}

                {/* {props.device.device_type.name == "Fire Sensor" && <div style={{display:"flex", flexDirection:"row-reverse"}}>
                    {props.device.alerts.length > 0 && <button style={{backgroundColor:"green", marginRight:"10px", width:"100px", height:"40px"}} onTouchEnd={
                        () => {
                            StopAlert();
                            setDevicesForComponents(devicesForComponents => devicesForComponents.filter(a => a._id !== props.device._id))
                        }}>Stop Alert</button>
                    }
                    {props.device.alerts.length == 0 &&<button style={{backgroundColor:"red", marginRight:"10px", width:"100px", height:"40px"}} onTouchEnd={
                        () => {
                            StartAlert();
                            setDevicesForComponents(devicesForComponents => devicesForComponents.filter(a => a._id !== props.device._id))
                    }}>Start Alert</button>
                }                
                </div>}

                {props.device.device_type._id == "65fad5f76ffce54c4aa03634" && <div style={{display:"flex", flexDirection:"row-reverse"}}>
                    {props.device.alerts.length > 0 && <button style={{backgroundColor:"green", marginRight:"10px", width:"100px", height:"40px"}} onTouchEnd={
                        () => {
                            StopCAAlert();
                            setDevicesForComponents(devicesForComponents => devicesForComponents.filter(a => a._id !== props.device._id))
                        }}>Stop Alert</button>
                    }                             
                </div>} */}
                
            </div>
        </div> 
        :
        <>{props.device.device_type.name !== "Statie Meteo" && <div className={styles.device_component} ref={ref} 
        style={{ transform: `matrix(1, 0, 0, 1, ${left}, ${top})`, width: width, height: height, fontFamily: 'Roboto', fontStyle: 'Roboto', opacity: opacity }}>
            <div className={styles.drone_component_back} ref={ref} style={{ width: width, height: height}}>
                <div className={styles.drone_header} >
                    <div ref={nameRef} className={styles.drone_component_title} >{props.device.name} </div>
                    <div className={styles.drone_component_subtitle} >{siteName}</div>
                    <div className={styles.live} style={{transform:`matrix(1, 0, 0, 1, ${posX}, 0)`}}><i>Live</i></div>

                    <div className={styles.butoane_drona}>
                        <img style={{width:"56px", height:"56px", marginLeft:"5px"}} alt="icon" draggable="false" src={locationBttn} 
                        onTouchEnd = {() => goToDevice(props.device)}/>
                        <img style={{width:"56px", height:"56px", marginLeft:"5px"}} alt="icon" draggable="false" src={minimizeBttn} 
                        onTouchEnd = {() => setDevicesForComponents(devicesForComponents => devicesForComponents.filter(a => a._id !== props.device._id))}/>
                        <div className={styles.history_drona}>
                            <div className={styles.background_select_history} 
                            style={{
                                left: `${clickedTab === 0 ? 0 : 80}px`,
                                background: `${clickedTab === 0 ? "transparent linear-gradient(180deg, #18BEDD 0%, #9ED688 100%) 0% 0% no-repeat padding-box" : "white"}`
                            }}/>
                            <div className={styles.live_mare} 
                                // onClick = {() => setClickedTab(0)}
                                style={{color: `${clickedTab === 0 ? "black" : "#18BEDD"}`}}>
                                <i><b>Live</b></i>
                            </div>
                            {/* <div className={styles.live_mare} onClick = {() => setClickedTab(1)}
                                style={{transform:`matrix(1, 0, 0, 1, 80, 0)`, color: `${clickedTab === 0 ? "white" : "black"}`, fontSize:"12px"}}>
                                Calendar
                            </div> */}
                            {/* <img/> */}
                        </div>
                    </div>
                </div>
                {props.device.data && Object.keys(props.device.data).map((param) => (
                    getComponentForParameter(param)
                ))}
                <div className={styles.drona_specs} >
                    <div className={styles.drona_specs_category}>
                        Locatie
                        <div className={styles.drona_specs_value}>{parseFloat(props.device.location[0]).toFixed(9)} {parseFloat(props.device.location[1]).toFixed(9)}</div>
                    </div>
                    <div className={styles.drona_specs_category} style={{borderLeft: "2px solid #878688", borderRight: "2px solid #878688", height:"340px"}}>
                        Altitudine
                        <div className={styles.drona_specs_value} style={{width:"180px", marginLeft: "48px"}}>{props.device.altitude ? props.device.altitude : 0}</div>
                    </div>
                    <div className={styles.drona_specs_category} >
                        Viteza
                        <div className={styles.drona_specs_value} style={{width:"180px", marginLeft: "48px"}}>{props.device.speed ? props.device.speed : 0}</div>
                    </div>
                    
                </div>
                
                {/* <div className={styles.circular_div}>
                
                </div> */}
                
                <CompassAndPYRComponent key="direction" speed={props.device.speed ? props.device.speed : 0 } direction={props.device.direction ? props.device.direction : 0 } pyrValues={pyr}></CompassAndPYRComponent>
                  
            </div>
        </div>} </>
        }
        </>
    );
}

export default DeviceComponent;