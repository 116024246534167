import { useRef, useEffect, useContext, useState } from 'react';
import MapComponent from './components/MapComponent';
import ProjectComponent from './components/ProjectComponent';
import KeyboardComponent from './components/keyboard/KeyboardComponent';
import { PointScreenPosition } from './utils/Tools'
import * as turf from "@turf/turf"
import { GlobalContext } from "./contexts/globalContext";
import io from "socket.io-client"
import styles from './styles.module.css'
import "./main.css";

import { MultitouchComponent } from 'use-gesture-pack';
//import  MultitouchComponent  from '../src/components/files/MultitouchComponent';

import LoginComponent from './components/LoginComponent';
import ConferenceComponent from './components/ConferenceComponent';
import AlertsComponent from './components/AlertsComponent';
import DeviceComponent from './components/DeviceComponent';
import FireDetectionComponent from './components/FireDetectionComponent';
import AccesControlComponent from './components/AccesControlComponent';
import EditSymbolComponent from './components/EditSymbolComponent';
import SitesComponent from './components/SitesComponent';
import DevicesComponent from './components/DevicesComponent';
import BrowserComponent from './components/BrowserComponent';
import PopupWindowComponent from './components/PopupWindowComponent';
import DeviceForPopupComponent from './components/DeviceForPopupComponent';
import SymbolsComponent from './components/SymbolsComponent';
import ElevationComponent from './components/ElevationComponent';
import FreeHandGraphicDrawLayer from './components/map/tacticalGraphics/FreeHandGraphicDrawLayer';
import RectangleGraphicDrawLayer from './components/map/tacticalGraphics/RectangleGraphicDrawLayer';
import FreeHandTextGraphicDrawLayer from './components/map/tacticalGraphics/FreeHandTextGraphicDrawLayer';
import FreeHandAreaDrawLayer from './components/map/tacticalGraphics/FreeHandAreaTextDrawLayer';
import FreeHandModelsDrawLayer from './components/map/tacticalGraphics/FreeHandModelsDrawLayer';
import CircleGraphicDrawLayer from './components/map/tacticalGraphics/CircleGraphicDrawLayer';
import FreeHandMiddleModelDrawLayer from './components/map/tacticalGraphics/FreeHandMiddleModelDrawLayer';
import FreeHandAreaModelsDrawLayer from './components/map/tacticalGraphics/FreeHandAreaModelsDrawLayer';
import PolylineGraphicDrawLayer from './components/map/tacticalGraphics/PolylineGraphicDrawLayer';
import LineGraphicDrawLayer from './components/map/tacticalGraphics/LineGraphicDrawLayer';
import LineSemicircleDrawLayer from './components/map/tacticalGraphics/LineSemicircleDrawLayer';
import PolylineDroneDrawLayer from './components/map/tacticalGraphics/PolylineDroneDrawLayer';
import RectangleTextDrawLayer from './components/map/tacticalGraphics/RectangleTextDrawLayer';
import PolylineDrawLayer from './components/map/PolylineDrawLayer';
import RectangleDrawLayer from './components/map/RectangleDrawLayer';
import PolygonDrawLayer from './components/map/PolygonDrawLayer';
import CircleDrawLayer from './components/map/CircleDrawLayer';
import FreeHandDrawLayer from './components/map/FreeHandDrawLayer';
import InclinedRectangleDrawLayer from './components/map/InclinedRectangleDrawLayer';
import SymbolsDataJson from './utils/symbols.json';
import DocFileComponent from './components/files/DocFileComponent';
import PdfFileComponent from './components/files/PdfFileComponent';
import ExcelFileComponent from './components/files/ExcelFileComponent';
import MainMenuComponent from './components/MainMenuComponent';
import LoSDrawLayer from './components/LoSComponent';
import SaveNewProjectComponent from './components/SaveNewProjectComponent';
import DrawFeatureMenu from './components/DrawFeatureMenuComponent';
import NewImageFileComponent from './components/files/NewImageFileComponent';
import NewVideoFileComponent from './components/files/NewVideoFileComponent';
import DashboardComponent from './components/DashboardComponent';
import NewFilePickerComponent from './components/NewFilePickerComponent';
import MeteoExpandComponent from './components/dashboard-components/meteo/MeteoExpandComponent';


function App() {


    const windowRef = useRef();

    const [timetToCloseFilesMenu, setTimeToCloseFilesMenu] = useState(false);

    const [parameters, setParameters] = useState();

    const { isForDesk, setJsonForMeteoExpand, devicesForAccesControl, setDevicesForAccesControl, devicesForFireDetection, setDevicesForFireDetection, setDevicesForComponents, setIsForDesk, jsonForMeteoExpand, filesMenuOpened, devicesMenuPosition, browserMenuPosition, devicesMenuOpened, browserMenuOpened, sitesMenuPosition, symbolsMenuPosition, currentProjectName, saveNewProjectsMenuPosition, saveNewProjectsMenuOpened, projectsMenuPosition, invitesMenuOpened, projectsMenuOpened, symbolsMenuOpened, filesToBeOpen, monitorLeftSize, monitorRightSize, deviceForLeftMonitor, deviceForRightMonitor, seeLoS, symbolsForEditing, setProjectSites, setSelectedFeature, seeElevationGraph, setSeeElevationGraph, selectedFeature, setSymbolsData, devicesForComponents, setAlerts, drawingsRef, drawingLayer, currentProjectId, showKeyboard, socketRef, logged, currentSite, setCurrentSite, mapRef, setDevices } = useContext(GlobalContext);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const optionsMeteo = {
        appWidth: process.env.REACT_APP_WIDTH,
        appHeight: process.env.REACT_APP_HEIGHT,
        maxScale: 2,
        minScale: 0.5
    }
    const optionsDev = {
        appWidth: process.env.REACT_APP_WIDTH,
        appHeight: process.env.REACT_APP_HEIGHT,
        maxScale: 1.5,
        minScale: 0.5
    }

    const options = {
        appWidth: process.env.REACT_APP_WIDTH,
        appHeight: process.env.REACT_APP_HEIGHT
    }

    function getFeaturesFromOptions(options) {
        return "left=" + options.x + ",top=" + options.y +
            ",width=" + options.width + ",height=" + options.height +
            (options.fullscreen ? ",fullscreen" : "");
    }

    async function toggleFullScreenCameras() {
        const permission = await navigator.permissions.query({
            name: 'window-management'
        });

        var screenDetails = await window.getScreenDetails().catch(e => { console.error(e); return null; });

        var camerasScreen = null;

        if (screenDetails.screens.length == 3) {
            camerasScreen = screenDetails.screens[1];
        } else if (screenDetails.screens.length == 4) {
            camerasScreen = screenDetails.screens[1];
        }

        console.log(screenDetails.screens);
        if (camerasScreen) {
            var options = {
                url: 'https://cameras.internal.nextfusion.ro#maintain_focus', fullscreen: true,
                x: camerasScreen.availLeft, y: camerasScreen.availTop,
                width: camerasScreen.availWidth, height: camerasScreen.availHeight
            };
            var features = getFeaturesFromOptions(options);
            if (camerasPopup) {
                camerasPopup.close();
            }            
            camerasPopup = window.open(options.url, '_blank', features);
        }
    }    

    async function toggleFullScreenPhone() {

        const permission = await navigator.permissions.query({
            name: 'window-management'
        });

        var screenDetails = await window.getScreenDetails().catch(e => { console.error(e); return null; });
        var phoneScreen = null;

        if (screenDetails.screens.length == 3) {
            phoneScreen = screenDetails.screens[2];
        } else if (screenDetails.screens.length == 4) {
            phoneScreen = screenDetails.screens[3];
        }

        if (phoneScreen) {
            var options = {
                url: 'https://phone.internal.nextfusion.ro#maintain_focus', fullscreen: true,
                x: phoneScreen.availLeft, y: phoneScreen.availTop,
                width: phoneScreen.availWidth, height: phoneScreen.availHeight
            };
            var features = getFeaturesFromOptions(options);
            if (phonePopup) {
                phonePopup.close();
            }
            phonePopup = window.open(options.url, '_blank', features);
            phonePopup.blur();
            window.focus();
        } 
    }

    var KeyDownListener;
    var camerasPopup;
    var phonePopup;
    useEffect(() => {
        //Check if is Desk and add keyevents
        if (process.env.REACT_APP_FOR_DESK && process.env.REACT_APP_FOR_DESK == "true") {
            KeyDownListener = async (e) => {
                if (e.key === "F2") {
                    await toggleFullScreenCameras();
                }
    
                if (e.key === "F4") {
                    await toggleFullScreenPhone();
                }
            }
    
    
            if ("getScreenDetails" in window) {
                document.addEventListener("keydown", KeyDownListener, false);
            }

            window.onbeforeunload = function () {
                camerasPopup?.close();
                phonePopup?.close();
                return "Dude, are you sure you want to refresh? Think of the kittens!";
            }
        }



        

        setIsForDesk(process.env.REACT_APP_FOR_DESK && process.env.REACT_APP_FOR_DESK == "true");

        setSymbolsData(SymbolsDataJson);
        socketRef.current = io.connect(process.env.REACT_APP_NOTIFICATION_URL, { secure: true, reconnect: true, rejectUnauthorized: false })


        // socketRef.current = io.connect("ws://192.168.5.172:9005", { secure: true, reconnect: true, rejectUnauthorized : false})


        windowRef.current.style.transformOrigin = 'top left';
        windowRef.current.style.transform = `scale(${window.innerWidth / process.env.REACT_APP_WIDTH}, ${window.innerHeight / process.env.REACT_APP_HEIGHT})`;

        const handler = e => e.preventDefault()
        const handlerDrop = e => {
            e.preventDefault()
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        }

        document.addEventListener('gesturestart', handler)
        document.addEventListener('gesturechange', handler)
        document.addEventListener('gestureend', handler)
        window.addEventListener('dragover', handlerDrop)
        window.addEventListener('drop', handlerDrop)
        window.addEventListener('dragenter', handlerDrop)

        

        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });

        fetch(process.env.REACT_APP_API_URL + '/parameters')
            .then((response) => response.json())
            .then((data) => {
                setParameters(data);
            });

        return () => {
            document.removeEventListener('gesturestart', handler)
            document.removeEventListener('gesturechange', handler)
            document.removeEventListener('gestureend', handler)
            window.removeEventListener('dragover', handlerDrop)
            window.removeEventListener('drop', handlerDrop)
            window.removeEventListener('dragenter', handlerDrop)



        }

    }, [])



    useEffect(() => {
        if (!selectedFeature) {
            setSeeElevationGraph(false)
        }
    }, [selectedFeature])

    function deleteButtonPos() {
        var bbox = turf.bbox(selectedFeature)
        var returnValues = PointScreenPosition([bbox[2], bbox[1]], mapRef)
        returnValues[0] = returnValues[0] + 30
        returnValues[1] = returnValues[1] + 30
        return returnValues
    }

    function elevationGraphicPos() {
        var bbox = turf.bbox(selectedFeature)
        var returnValues = PointScreenPosition([bbox[2], bbox[1]], mapRef)
        returnValues[0] = returnValues[0] + 30
        returnValues[1] = returnValues[1] - 500
        return returnValues
    }


    useEffect(() => {
        if (!seeElevationGraph) {
            if (drawingsRef.current) {
                if (drawingsRef.current.getAll().features[drawingsRef.current.getAll().features.length - 1] && drawingsRef.current.getAll().features[drawingsRef.current.getAll().features.length - 1].geometry.type === "Point") {
                    drawingsRef.current.delete(drawingsRef.current.getAll().features[drawingsRef.current.getAll().features.length - 1].id)
                }
            }
        }
    }, [seeElevationGraph])

    useEffect(() => {

        if (drawingLayer !== 0 && selectedFeature) {

            if (drawingsRef.current && drawingsRef.current.getAll().features[drawingsRef.current.getAll().features.length - 1].geometry.type === "Point") {
                drawingsRef.current.delete(drawingsRef.current.getAll().features[drawingsRef.current.getAll().features.length - 1].id)
            }
            setSeeElevationGraph(false)
            mapRef.current.getMap().setLayoutProperty("gl-draw-polygon-and-line-vertex-inactive.cold", 'visibility', 'visible');
            mapRef.current.getMap().setLayoutProperty("gl-draw-polygon-and-line-vertex-stroke-inactive.cold", 'visibility', 'visible');
            drawingsRef.current.changeMode("simple_select");
            setSelectedFeature()
        }
    }, [drawingLayer])

    useEffect(() => {
        if (seeLoS && drawingsRef.current.getSelectedIds() && selectedFeature) {
            drawingsRef.current.changeMode("simple_select");

            setSelectedFeature()
        }
    }, [seeLoS])


    const siteSelected = site => {
        setCurrentSite(site);

        mapRef.current.flyTo({
            center: [site.location[1], site.location[0]],
            zoom: 11,
            essential: true
        });

        fetch(process.env.REACT_APP_API_URL + '/devices/' + site._id)
            .then((response) => response.json())
            .then((data) => {
                var foundAlerts = false;
                data.forEach(device => {
                    if (device.alerts.length > 0) {
                        foundAlerts = true;
                        setAlerts(device.alerts);
                    }
                });

                if (!foundAlerts) {
                    setAlerts([]);
                }

                setDevices(data);
            });
        setProjectSites([site._id]);
    };

    const createFileComponent = file => {
        if (file.mimetype.includes("image/")) {
            return <NewImageFileComponent key={file.uid} file={file} x={file.x} y={file.y} />
        } else if (file.mimetype.includes("word")) {
            return <DocFileComponent key={file.uid} file={file} x={file.x} y={file.y} />
        } else if (file.mimetype.includes("pdf")) {
            return <PdfFileComponent key={file.uid} file={file} x={file.x} y={file.y} />
        } else if (file.mimetype.includes("ms-excel") || file.mimetype.includes("spreadsheetml")) {
            return <ExcelFileComponent key={file.uid} file={file} x={file.x} y={file.y} />
        } else if (file.mimetype.includes("video/")) {
            return <NewVideoFileComponent key={file.uid} file={file} x={file.x} y={file.y} />
        }
    }
    const siteSelectedForInvitation = site => {
        socketRef.current.emit("INVITE_TO_PROJECT", { site_id: site._id, project_id: currentProjectId, project_name: currentProjectName });
    }

    useEffect(() => {
        if (!filesMenuOpened) {
            setTimeout(() => {
                setTimeToCloseFilesMenu(false)
            }, 500);
        } else {
            setTimeToCloseFilesMenu(true)
        }
    }, [filesMenuOpened])

    const onMinScaleReached = (device) => {
        setDevicesForComponents(devicesForComponents => devicesForComponents.filter(a => a._id !== device._id))
        setDevicesForAccesControl(devicesForAccesControl => devicesForAccesControl.filter(a => a._id !== device._id))
        setDevicesForFireDetection(devicesForFireDetection => devicesForFireDetection.filter(a => a._id !== device._id))
    }


    const onMinScaleReachedMeteo = (meteo) => {
        setJsonForMeteoExpand(jsonForMeteoExpand => jsonForMeteoExpand.filter(a => a.id !== meteo.id))
    }


    function getMeteoExpandPos(meteoElem) {
        // console.log(meteoElem);
        //console.log(meteoElem.pointLat, meteoElem.pointLong, meteoElem)
        if (meteoElem.pointLat !== undefined && meteoElem.pointLong !== undefined) {
            if (mapRef.current.getBounds().contains([meteoElem.pointLat, meteoElem.pointLong])) {
                const position = mapRef.current.project([meteoElem.pointLat, meteoElem.pointLong]);
                return [position.x / oxDivider, position.y / oyDivider]
            }
        }

        return [isForDesk ? 800 : 622, 49]
    }

    return (
        <>

            <MapComponent DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" IconSize="40" />
            <div ref={windowRef} className={`${styles.main_window_component}`} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT) }}>
                {(drawingLayer === 1) && <RectangleDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 2) && <PolylineDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 3) && <PolygonDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 4) && <CircleDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 5) && <FreeHandDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 6) && <InclinedRectangleDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 7) && <RectangleGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 8) && <FreeHandGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 9) && <FreeHandTextGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 10) && <FreeHandAreaDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 11) && <FreeHandModelsDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 12) && <CircleGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 13) && <FreeHandMiddleModelDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 14) && <FreeHandAreaModelsDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 15) && <PolylineGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 16) && <LineGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 17) && <LineSemicircleDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 18) && <PolylineDroneDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}
                {(drawingLayer === 19) && <RectangleTextDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}

                {monitorLeftSize && <PopupWindowComponent size={monitorLeftSize}>
                    {deviceForLeftMonitor && <DeviceForPopupComponent device={deviceForLeftMonitor} parameters={parameters} />}
                </PopupWindowComponent>}

                {monitorRightSize && <PopupWindowComponent size={monitorRightSize}>
                    {deviceForRightMonitor && <DeviceForPopupComponent device={deviceForRightMonitor} parameters={parameters} />}
                </PopupWindowComponent>}

                {!logged && <LoginComponent />}

                {logged && <>
                    {/* <AlertsComponent /> */}

                    <ConferenceComponent />

                    {!currentSite &&
                        <MultitouchComponent dragGesture={false} x={-820} y={800} options={options}>
                            <SitesComponent x="0" y="0" width="780" height="300" backgroundColor="white" siteSelected={(site) => siteSelected(site)} />
                        </MultitouchComponent>
                    }

                    {currentSite && <>

                        <MainMenuComponent />
                        <DashboardComponent />

                        {invitesMenuOpened &&
                            <MultitouchComponent dragGesture={false} width={780} height={300} x={sitesMenuPosition[0] + 530} y={sitesMenuPosition[1] - 500} options={options}>
                                <SitesComponent canClose x='0' y='0' width="780" height="300" backgroundColor="white" siteSelected={(site) => siteSelectedForInvitation(site)} />
                            </MultitouchComponent>
                        }

                        {projectsMenuOpened &&
                            <MultitouchComponent dragGesture={false} width={700} height={500} x={projectsMenuPosition[0] + 660} y={projectsMenuPosition[1] - 235} options={options}>
                                <ProjectComponent X={`0`} Y={`0`} />
                            </MultitouchComponent>
                        }

                        {saveNewProjectsMenuOpened &&
                            <MultitouchComponent dragGesture={false} width={1020} height={500} x={saveNewProjectsMenuPosition[0] - 300} y={saveNewProjectsMenuPosition[1] + 200} options={options}>
                                <SaveNewProjectComponent X={`0`} Y={`0`} />
                            </MultitouchComponent>
                        }

                        {symbolsMenuOpened && !seeLoS &&
                            <MultitouchComponent dragGesture={false} width={760} height={650} x={symbolsMenuPosition[0] + 110} y={symbolsMenuPosition[1] - 750} options={options}>
                                <SymbolsComponent X={`0`} Y={`0`} />
                            </MultitouchComponent>
                        }

                        {devicesMenuOpened && !seeLoS && <DevicesComponent x={`${devicesMenuPosition[0] + 210}`} y={`${devicesMenuPosition[1] + 20}`} width="500" />}

                        {seeElevationGraph && selectedFeature && <ElevationComponent X={elevationGraphicPos()[0]} Y={elevationGraphicPos()[1]} />}

                        {seeLoS && <LoSDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />}

                        {devicesForComponents && devicesForComponents.map((device) => (
                            <MultitouchComponent key={device._id} dragGesture={false} x={0} y={0}
                                onMinScaleReached={() => { onMinScaleReached(device) }} options={optionsDev}
                            >
                                <DeviceComponent key={device._id} device={device} parameters={parameters} />
                            </MultitouchComponent>))}

                        {devicesForAccesControl && devicesForAccesControl.map((device) => (
                            <MultitouchComponent key={device._id} dragGesture={false} x={0} y={0}
                                onMinScaleReached={() => { onMinScaleReached(device) }} options={optionsDev}
                            >
                                <AccesControlComponent key={device._id} device={device} parameters={parameters} />
                            </MultitouchComponent>))}

                        {devicesForFireDetection && devicesForFireDetection.map((device) => (<MultitouchComponent key={device._id} dragGesture={false} x={0} y={0}
                            onMinScaleReached={() => { onMinScaleReached(device) }} options={optionsDev}
                        >
                            <FireDetectionComponent key={device._id} device={device} parameters={parameters} />
                        </MultitouchComponent>))}

                        {jsonForMeteoExpand && jsonForMeteoExpand.map((meteo) => (
                            <MultitouchComponent key={meteo.id} dragGesture={false} width={500} height={360} x={getMeteoExpandPos(meteo)[0]} y={getMeteoExpandPos(meteo)[1]} onMinScaleReached={() => { onMinScaleReachedMeteo(meteo) }} options={optionsMeteo}>
                                <MeteoExpandComponent key={meteo.id} meteo={meteo} />
                            </MultitouchComponent>
                        ))}

                        {symbolsForEditing && symbolsForEditing.map((symbol) => (<EditSymbolComponent key={symbol.uid} symbol={symbol} />))}

                        {currentProjectId && timetToCloseFilesMenu && <NewFilePickerComponent filesMenuOpened={filesMenuOpened} width="0" height="150" x="550" y={parseInt(process.env.REACT_APP_HEIGHT) - 210} />}

                        {filesToBeOpen && filesToBeOpen.map((file) => (createFileComponent(file)))}

                        {browserMenuOpened && !seeLoS && <BrowserComponent x={`${browserMenuPosition[0] + 200}`} y={`${browserMenuPosition[1] + 250}`} width="1000" height="800" />}

                        {selectedFeature && <DrawFeatureMenu x={`${deleteButtonPos()[0]}`} y={`${deleteButtonPos()[1]}`} />}



                    </>}

                    {showKeyboard && <KeyboardComponent />}



                </>}

            </div>

            {/* <MapComponent DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" IconSize="40"/>
        <div ref={windowRef} className={`${styles.main_window_component}`} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT) }}>
            {
                (drawingLayer === 1) && <RectangleDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 2) && <PolylineDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 3) && <PolygonDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 4) && <CircleDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 5) && <FreeHandDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 6) && <InclinedRectangleDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 7) && <RectangleGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 8) && <FreeHandGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 9) && <FreeHandTextGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 10) && <FreeHandAreaDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 11) && <FreeHandModelsDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 12) && <CircleGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 13) && <FreeHandMiddleModelDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 14) && <FreeHandAreaModelsDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 15) && <PolylineGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 16) && <LineGraphicDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 17) && <LineSemicircleDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 18) && <PolylineDroneDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }
            {
                (drawingLayer === 19) && <RectangleTextDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef} />
            }


            {logged && <AlertsComponent></AlertsComponent>}
            
            

            {!logged &&<LoginComponent></LoginComponent>}
            

            
            
            <ConferenceComponent></ConferenceComponent>
            {seeElevationGraph && selectedFeature && <ElevationComponent X={elevationGraphicPos()[0]} Y={elevationGraphicPos()[1]}/>}
            {seeLoS &&  <LoSDrawLayer DrawingLineSize="2" DrawingStopIntervalSize="50" DrawingPointRectangleSize="10" map={mapRef}/>}

            {devicesForComponents && devicesForComponents.map((device) => (
                <DeviceComponent key={device._id} device={device} parameters={parameters}/> 
            ))}           

            {symbolsForEditing && symbolsForEditing.map((symbol) => (
                <EditSymbolComponent key={symbol.uid} symbol={symbol}/>
            ))}           
        
            {!currentSite && logged &&
                <SitesComponent x="50" y="1300" width="780" height="300" backgroundColor="white" siteSelected={(site) => siteSelected(site)}></SitesComponent>                   
            }

            {invitesMenuOpened && <SitesComponent canClose x={`${sitesMenuPosition[0]}`} y={`${sitesMenuPosition[1]-30}`}  width="780" height="300" backgroundColor="white" siteSelected={(site) => siteSelectedForInvitation(site)}></SitesComponent>}



            {showKeyboard && <KeyboardComponent />}

            
            {monitorLeftSize && <PopupWindowComponent size={monitorLeftSize}>
                {deviceForLeftMonitor && <DeviceForPopupComponent device={deviceForLeftMonitor} parameters={parameters}></DeviceForPopupComponent>}            
            </PopupWindowComponent> }

            {monitorRightSize && <PopupWindowComponent size={monitorRightSize}>
                {deviceForRightMonitor && <DeviceForPopupComponent device={deviceForRightMonitor} parameters={parameters}></DeviceForPopupComponent>}            
            </PopupWindowComponent> }

            {currentProjectId && timetToCloseFilesMenu && logged && <NewFilePickerComponent filesMenuOpened={filesMenuOpened} width="0" height="150" x="550" y={parseInt(process.env.REACT_APP_HEIGHT) - 160}></NewFilePickerComponent>}

            
            {filesToBeOpen && filesToBeOpen.map((file) => (
                createFileComponent(file)                
            ))}    

            {/* {logged && currentSite &&
            <MultitouchComponent x="200" y="200" width="440px" height="440px">
            </MultitouchComponent>
            }  */}


            {/* {logged && currentSite &&
                <>
                    <MainMenuComponent/>
                    <DashboardComponent/>
                </>
            }


            
            {currentSite && projectsMenuOpened && logged && <ProjectComponent X={`${projectsMenuPosition[0]}`}  Y={`${projectsMenuPosition[1]-550}`}/> }
            {currentSite && saveNewProjectsMenuOpened && logged && <SaveNewProjectComponent X={`${saveNewProjectsMenuPosition[0]-400}`}  Y={`${saveNewProjectsMenuPosition[1]+50}`}/>}
            {currentSite && symbolsMenuOpened && !seeLoS && logged && 
                <MultitouchComponent style={{position: "absolute", top:`${symbolsMenuPosition[1]-750}`, left:`${symbolsMenuPosition[0]+110}`}}> 
                    <SymbolsComponent X={`0`} Y={`0`}/> 
                </MultitouchComponent>
            } */}
            {/* {currentSite && symbolsMenuOpened && !seeLoS && logged && <SymbolsComponent X={`${symbolsMenuPosition[0]+110}`} Y={`${symbolsMenuPosition[1]-750}`}/>} */}

            {/* {currentSite && devicesMenuOpened && !seeLoS && logged && <DevicesComponent x={`${devicesMenuPosition[0]+210}`} y={`${devicesMenuPosition[1]+20}`} width="500"></DevicesComponent>}

            {browserMenuOpened && !seeLoS && logged && <BrowserComponent x={`${browserMenuPosition[0]+200}`} y={`${browserMenuPosition[1]+250}`} width="1000" height="800"></BrowserComponent>}

            {logged && haveSipCredentials && <SipComponent x="200" y="800" width="371" height="569"></SipComponent>} */}
            {/* {logged && haveSipCredentials && <SipComponent x="200" y="800" width="371" height="569"></SipComponent>} */}
            {/* {logged && <SipComponent x="200" y="800" width="371" height="569"></SipComponent>} */}

            {/* {currentSite && selectedFeature && <DrawFeatureMenu  x={`${deleteButtonPos()[0]}`} y={`${deleteButtonPos()[1]}`}/>}
        </div> */}
        </>
    );
}

export default App;
