import React, { useRef, useEffect, useState } from 'react';
import styles from './../styles.module.css'
// import { useGesture } from '@use-gesture/react'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import { GetMaxZIndex } from '../utils/Tools'
import Lottie from 'react-lottie-player'
import bttnSoundLottie from '../utils/conference-utils/ic-volum-on-off.json'
import "@fontsource/oswald/300.css"
import { useGesture } from 'use-gesture-pack'

const VideoComponent = (props) => {

    const ref = useRef(null);
    const muteRef = useRef(null);

    //const divRef = useRef(null);
    const videoRef = useRef(null);
    //const bttnRef = useRef(null);
    const canvasRef = useRef(null);
    const canvasRef2 = useRef(null);
    const contextRef = useRef(null);
    const contextRef2 = useRef(null);

    const requestRef = useRef();
    const nameInsideRef = useRef();
    const muteInsideRef = useRef();
    const soundInsideRef = useRef();


    const yRef = useRef();



    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    //const isOnPosition = useRef(true);
    const [isOnPosition, setIsOnPosition] = useState(true);
    const isOnPositionRef = useRef(false);


    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    //const [isMuted, setIsMuted] = useState(false);

    const [innerWidth, setInnerWidth] = useState();
    const [innerHeight, setInnerHeight] = useState();

    const [muted, setMuted] = useState(false);
    //const [soundBttnX, setSoundBttnX] = useState(100)
    //const [soundBttnY, setSoundBttnY] = useState(100)
    //const [isMuted, setIsMuted] = useState(false);
    const analyser = useRef();;
    //const [isPlaying, setIsPlaying] = useState(false);

    // const [borderSize, setBorderSize] = useState(7.5);
    //const [madeCircle, setMadeCircle] = useState(false);

    useEffect(() => {
        isOnPositionRef.current = isOnPosition;
    }, [isOnPosition])



    useEffect(() => {
        videoRef.current.srcObject = props.stream;

        if (props.is_mine) {
            videoRef.current.muted = true;
        } else {
            //console.log(props.stream);
            videoRef.current.muted = true;



            var audioCtx = new AudioContext();
            analyser.current = audioCtx.createAnalyser();

            var source = audioCtx.createMediaStreamSource(props.stream);

            source.connect(analyser.current);
            analyser.current.connect(audioCtx.destination);

            analyser.current.fftSize = 512;

            requestRef.current = requestAnimationFrame(draw);

            return () => {
                cancelAnimationFrame(requestRef.current);
                audioCtx.close();
            }
        }
    }, [])

    function Map(theValue, theInStart, theInEnd, theOutStart, theOutEnd) {
        return theOutStart + (theOutEnd - theOutStart) * ((theValue - theInStart) / (theInEnd - theInStart));
    }

    const draw = React.useCallback((time) => {
        if (canvasRef.current) {
            contextRef.current = canvasRef.current.getContext('2d');

        }
        if (canvasRef2.current) {
            contextRef2.current = canvasRef2.current.getContext('2d');
        }
        //requestAnimationFrame(draw);
        var freqByteData = new Uint8Array(analyser.current.frequencyBinCount);
        analyser.current.getByteFrequencyData(freqByteData);
        contextRef.current.clearRect(0, 0, 40, 40);
        contextRef2.current.clearRect(0, 0, 40, 40);

        for (var i = 0; i < 5; i++) {
            contextRef.current.save();
            contextRef.current.beginPath();

            var freq = Map(freqByteData[i * 20], 0, 200, 0, 20);

            var dif = (20 - freq) / 2;

            contextRef.current.moveTo(7 + i * 6, 10 + dif);
            contextRef.current.lineTo(7 + i * 6, 30 - dif);
            contextRef.current.lineWidth = 3;
            contextRef.current.strokeStyle = '#32a852';


            contextRef.current.stroke();
            contextRef.current.restore();

            ////222
            contextRef2.current.save();
            contextRef2.current.beginPath();

            //var freq = Map(freqByteData[i * 20], 0, 200, 0, 20);

            //var dif = (20 - freq) / 2;

            contextRef2.current.moveTo(7 + i * 6, 10 + dif);
            contextRef2.current.lineTo(7 + i * 6, 30 - dif);
            contextRef2.current.lineWidth = 3;
            contextRef2.current.strokeStyle = '#32a852';


            contextRef2.current.stroke();
            contextRef2.current.restore();
        }



        // for (var i = 0; i < 20; i++) {
        //     contextRef.current.save();
        //     contextRef.current.beginPath();

        //     //console.log(freqByteData[i]);

        //     var freq = Map(freqByteData[i * 5], 0, 200, 0, 20);



        //     contextRef.current.translate(contextRef.current.canvas.width / 2, contextRef.current.canvas.height / 2);
        //     contextRef.current.rotate(Math.PI / 180 * (angle * i));


        //     contextRef.current.moveTo(0, 69 - freq);
        //     contextRef.current.lineTo(0, 69);
        //     contextRef.current.lineWidth = 10;


        //     contextRef.current.strokeStyle = '#ff0000';
        //     contextRef.current.stroke();

        //     contextRef.current.restore();

        // }

        if (requestRef.current !== null) {
            requestRef.current = requestAnimationFrame(draw);
        }
    });



    useEffect(() => {
        yRef.current = props.Y;
        if (isOnPosition) {
            var mat = identity();
            var trans = translate(props.X, props.Y);

            var final = compose(trans, mat);
            ref.current.style.transform = toString(final);
            // bttnRef.current.style.transform = toString(final);

            mat = identity();
            if (soundInsideRef.current) {
                soundInsideRef.current.style.transform = toString(mat);
            }

            if (nameInsideRef.current) {
                nameInsideRef.current.style.transform = toString(mat);
            }

            if (muteInsideRef.current) {
                muteInsideRef.current.style.transform = toString(mat);
            }



            setWidth(props.HEIGHT);
            setHeight(props.HEIGHT);
            setInnerWidth(props.WIDTH);
            setInnerHeight(props.HEIGHT);
            ref.current.style.borderRadius = "50%";

            // setBorderSize(7.5);
            //setMadeCircle(true)
        } else {
            setWidth(props.WIDTH * 2 - 50);
            setHeight(props.HEIGHT * 2);
            setInnerWidth(props.WIDTH * 2 - 50);
            setInnerHeight(props.HEIGHT * 2);
            ref.current.style.borderRadius = "15px";
            //setSoundBttnX(props.WIDTH * 2 - 100)
            //setSoundBttnY(props.HEIGHT * 2 - 50)
        }
    }, [props.Y])


    function StopAnimation() {
        var computedStyle = window.getComputedStyle(ref.current);
        var aux_transform = computedStyle.getPropertyValue('transform');
        ref.current.style.transform = aux_transform;
        ref.current.style.transition = "";
    }

    useGesture(
        {
            onDragStart: () => {
                StopAnimation();

                setWidth(props.WIDTH * 2 - 50);
                setHeight(props.HEIGHT * 2);
                setInnerWidth(props.WIDTH * 2 - 50);
                setInnerHeight(props.HEIGHT * 2);
                videoRef.current.style.transform = "translate(0, 0)";
                ref.current.style.borderRadius = "15px";
            },
            onPinchStart: () => {
                StopAnimation();
            },
            onDrag: ({ dx, dy, point }) => {
                var mat = identity();
                if (ref.current.style.transform !== '') {
                    mat = fromString(ref.current.style.transform);
                }

                var trans = translate(dx, dy);
                var final = compose(trans, mat);
                ref.current.style.transform = toString(final);

                if (isOnPositionRef.current && (point.screen.x > 0 || point.screen.y > 0)) {
                    setIsOnPosition(false);
                }

            },
            onPinch: ({ dx, dy, angle, centerPoint, scale_factor, transformationPoint, points }) => {
                if (!isOnPositionRef.current) {
                    console.log("pinching");
                    var mat = identity();

                    if (ref.current.style.transform !== '') {
                        mat = fromString(ref.current.style.transform);
                    }

                    var trans = translate(dx, dy);
                    var rotate = rotateDEG(angle, transformationPoint.x, transformationPoint.y);

                    var currentScale = GetMatrixScale(mat)

                    if (currentScale * scale_factor > 2 || currentScale * scale_factor < 1) {
                        scale_factor = 1;
                    }

                    var scaleFunction = scale(scale_factor, scale_factor, transformationPoint.x, transformationPoint.y);

                    var final = compose(rotate, scaleFunction, trans, mat);

                    ref.current.style.transform = toString(final);


                    //name
                    mat = identity();

                    if (nameInsideRef.current.style.transform !== '') {
                        mat = fromString(nameInsideRef.current.style.transform);
                    }

                    var scaleFunction = scale(1 / scale_factor, 1 / scale_factor, -80, 17);
                    var final = compose(scaleFunction, mat);

                    nameInsideRef.current.style.transform = toString(final);

                    //sound
                    if (soundInsideRef.current) {
                        mat = identity();

                        if (soundInsideRef.current.style.transform !== '') {
                            mat = fromString(soundInsideRef.current.style.transform);
                        }

                        var scaleFunction = scale(1 / scale_factor, 1 / scale_factor, 20, 20);
                        var final = compose(scaleFunction, mat);

                        soundInsideRef.current.style.transform = toString(final);
                    }

                    //mute
                    if (muteInsideRef.current) {
                        mat = identity();

                        if (muteInsideRef.current.style.transform !== '') {
                            mat = fromString(muteInsideRef.current.style.transform);
                        }

                        var scaleFunction = scale(1 / scale_factor, 1 / scale_factor, -20, 20);
                        var final = compose(scaleFunction, mat);

                        muteInsideRef.current.style.transform = toString(final);
                    }

                }
            },
            onGestureEnded: ({ velocity, point }) => {
                console.log("g ended");
                if (point.screen.x > props.X - 100) {

                    ref.current.className = styles.video_component_anim;
                    setIsOnPosition(true);

                    var mat = identity();
                    var trans = translate(props.X, yRef.current);

                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);


                    mat = identity();
                    if (soundInsideRef.current) {
                        soundInsideRef.current.style.transform = toString(mat);
                    }

                    if (nameInsideRef.current) {
                        nameInsideRef.current.style.transform = toString(mat);
                    }

                    if (muteInsideRef.current) {
                        muteInsideRef.current.style.transform = toString(mat);
                    }



                    setWidth(props.HEIGHT);
                    setHeight(props.HEIGHT);
                    setInnerWidth(props.WIDTH);
                    setInnerHeight(props.HEIGHT);
                    videoRef.current.style.transform = "translate(-25%, -2.9%)";
                    ref.current.style.borderRadius = "50%";
                    // setBorderSize(7.5);
                    //setMadeCircle(true)
                    setTimeout(() => {
                        ref.current.className = styles.video_component;
                    }, 300);
                } else {
                    setWidth(props.WIDTH * 2 - 50);
                    setHeight(props.HEIGHT * 2);
                    setInnerWidth(props.WIDTH * 2 - 50);
                    setInnerHeight(props.HEIGHT * 2);
                    videoRef.current.style.transform = "translate(0, 0)";
                    ref.current.style.borderRadius = "15px";
                    //setSoundBttnX(props.WIDTH * 2 - 100)
                    //setSoundBttnY(props.HEIGHT * 2 - 50)
                    ApplyInertia(ref, velocity);
                }
            }
        },
        {
            target: ref,
            dragGesture: true,
            swipeGesture: false,
            pinchGesture: true,
            multiGesture: false,
            tapGesture: false,
            touchAndHoldGesture: false,
            appWidth: process.env.REACT_APP_WIDTH,
            appHeight: process.env.REACT_APP_HEIGHT,
            touchAndHoldDuration: 1000,
            bringToFront: true
        }
    )

    function ApplyInertia(target, velocity) {
        var mat = fromString(target.current.style.transform);

        var trans = translate(velocity.x, velocity.y);

        var length = Math.sqrt(velocity.x * velocity.x + velocity.y * velocity.y);

        var animDuration = length * 300 < 300 ? 300 : (length * 300 > 800 ? 800 : length * 300);

        target.current.style.transition = "transform " + animDuration + "ms";
        target.current.style.transitionTimingFunction = "ease-out";

        var final = compose(trans, mat);
        target.current.style.transform = toString(final);


        setTimeout(() => {
            target.current.style.transition = "";
            CheckIfOutsideBounds();
        }, animDuration);
    }

    function CheckIfOutsideBounds() {
        const { width, height, x, y } = ref.current.getBoundingClientRect();

        if (x + width / 2 < 0) {
            ref.current.className = styles.video_component_anim;

            var mat = fromString(ref.current.style.transform);
            var trans = translate((-(x + width / 2)) / oxDivider, 0);
            var final = compose(trans, mat);
            ref.current.style.transform = toString(final);
            // bttnRef.current.style.transform = toString(final);

            setTimeout(() => {
                ref.current.className = styles.video_component;
            }, 300);

        }

        if (x + width / 2 > window.innerWidth - 100) {
            ref.current.className = styles.video_component_anim;
            //video_component_anim
            setIsOnPosition(true);
            //muteRef.current.style.zIndex = ref.current.style.zIndex + 1;


            var mat = identity();
            var trans = translate(props.X, yRef.current);

            var final = compose(trans, mat);
            ref.current.style.transform = toString(final);





            mat = identity();
            if (soundInsideRef.current) {
                soundInsideRef.current.style.transform = toString(mat);
            }

            if (nameInsideRef.current) {
                nameInsideRef.current.style.transform = toString(mat);
            }

            if (muteInsideRef.current) {
                muteInsideRef.current.style.transform = toString(mat);
            }





            // bttnRef.current.style.transform = toString(final);

            setWidth(props.HEIGHT);
            setHeight(props.HEIGHT);
            setInnerWidth(props.WIDTH);
            setInnerHeight(props.HEIGHT);
            videoRef.current.style.transform = "translate(-25%, -2.9%)";
            ref.current.style.borderRadius = "50%";
            // setBorderSize(7.5);
            //setMadeCircle(true)
            setTimeout(() => {
                ref.current.className = styles.video_component;
            }, 300);
        }

        if (y + height / 2 < 0) {
            ref.current.className = styles.video_component_anim;
            var mat = fromString(ref.current.style.transform);
            var trans = translate(0, (-(y + height / 2)) / oyDivider);
            var final = compose(trans, mat);
            ref.current.style.transform = toString(final);
            // bttnRef.current.style.transform = toString(final);

            setTimeout(() => {
                ref.current.className = styles.video_component;
            }, 300);
        }

        if (y + height / 2 > window.innerHeight) {
            ref.current.className = styles.video_component_anim;
            var mat = fromString(ref.current.style.transform);
            var trans = translate(0, - ((y + height / 2) - window.innerHeight) / oyDivider);
            var final = compose(trans, mat);
            ref.current.style.transform = toString(final);
            // bttnRef.current.style.transform = toString(final);

            setTimeout(() => {
                ref.current.className = styles.video_component;
            }, 300);
        }
    }

    function GetMatrixScale(mat) {
        return Math.sqrt(mat.a * mat.a + mat.b * mat.b)
    }


    const handleSound = () => {
        if (!muted) {
            const tracks = props.stream.getAudioTracks();
            tracks.forEach((track) => {
                track.enabled = false;
            });
        } else {
            const tracks = props.stream.getAudioTracks();
            tracks.forEach((track) => {
                track.enabled = true;
            });
        }

        setMuted(!muted)
    }

    // const handleMute = () => {
    //     console.log("clicked mute")
    //     if (props.is_mine) {
    //       const audioTracks = props.stream.getAudioTracks();
    //       audioTracks.forEach(track => {
    //         track.enabled = !isMuted;
    //       });
    //       setIsMuted(!isMuted);
    //     }
    // };

    return (
        <div>

            <div className={styles.video_component} ref={ref} style={{ width: width, height: height, border: `7.5px solid rgba(2,1,7,0.3)` }}>

                <video className={styles.inner_video_component} ref={videoRef} playsInline autoPlay style={{ transform: "translate(-25%, -2.9%)", margin: "auto", width: innerWidth, height: innerHeight + 20 }} />
                {/* <canvas ref={canvasRef} style={{ position: "absolute", width: width, height: height }}></canvas> */}

                <div ref={nameInsideRef} style={{ textAlign: "center", background: "black", fontFamily: "Oswald", color: "white", backgroundColor: "black", opacity: "0.8", fontSize: "17px", borderRadius: "15px", width: "160px", height: "35px", top: "220px", left: "10px", position: "relative" }}>
                    <span style={{ verticalAlign: "middle" }}>{props.name}</span>
                </div>

                {!props.is_mine && <div ref={muteInsideRef} className={styles.video_component_button} style={{ backgroundColor: `${muted ? "#FF0000FF" : "#000000FF"}`, verticalAlign: "middle", textAlign: "center", color: "white", opacity: "0.8", fontSize: "17px", borderRadius: "20px", width: "40px", height: "40px", top: "215px", left: "420px", position: "absolute" }} onTouchEnd={() => handleSound()}>
                    <div style={{ verticalAlign: "middle", padding: "7px" }}>
                        {!muted &&
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={25} height={25} fill='white'><path d="M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z" /></svg>
                        }
                        {muted &&
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={25} height={25} fill='white'><path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z" /></svg>

                        }
                    </div>
                </div>}

                {!props.is_mine && <div ref={soundInsideRef} className={styles.video_component_button} style={{ overflow: "hidden", verticalAlign: "middle", textAlign: "center", color: "white", opacity: `${muted ? 0 : 1}`, fontSize: "17px", borderRadius: "20px", width: "40px", height: "40px", top: "215px", left: "360px", position: "absolute" }}>
                    <canvas ref={canvasRef2} style={{ backgroundColor: "#00000099" }}></canvas>
                </div>}


                {/* {!props.is_mine && 
            <button ref={bttnRef} className={(muted) ? styles.sound_off : styles.sound_on} style={{height:"50px", width:"50px", transform: `translate(${props.X}px, ${props.Y}px)`, position:"absolute", zIndex:"105"}}
                onClick={() => handleSound()}
            >
                S
            </button> 
        } */}
                {/* <Lottie
                    loop={true}
                    style={{height:"50px", width:"50px", transform: `matrix(1, 0, 0, 1, ${soundBttnX}, ${soundBttnY})`}}
                    animationData={bttnSoundLottie}
                    play={isPlaying}
                    onClick={() => {
                        handleMute(false)
                    }}
            /> */}
                {/* {props.is_mine && <button className={(isMuted) ? styles.sound_off : styles.sound_on} style={{height:"50px", width:"50px", transform: `matrix(1, 0, 0, 1, 0, 0)`}}
            >
                M
            </button>} */}
            </div>
            {<div style={{ transition: `opacity ${isOnPosition ? 0.5 : 0.1}s, top ${isOnPosition ? 0.5 : 0.1}s`, textAlign: "center", background: "black", fontFamily: "Oswald", color: "white", backgroundColor: "black", opacity: `${isOnPosition ? 0.9 : 0}`, fontSize: "17px", borderRadius: "15px", position: "absolute", width: "160px", height: "30px", top: `${isOnPosition ? props.Y + 145 : props.Y + 200}px`, left: `${props.X - 10}px` }}>
                <span>{props.name}</span>
            </div>}

            {!props.is_mine && <div ref={muteRef} className={styles.video_component_button} style={{ transition: `opacity ${isOnPosition ? 0.5 : 0.1}s, left ${isOnPosition ? 0.5 : 0.1}s`, zIndex: `${(ref.current) ? ref.current.style.zIndex + 1 : 10}`, backgroundColor: `${muted ? "#FF0000FF" : "#000000FF"}`, verticalAlign: "middle", textAlign: "center", color: "white", opacity: `${isOnPosition ? 0.9 : 0}`, fontSize: "17px", borderRadius: "20px", position: "absolute", width: "40px", height: "40px", top: `${props.Y + 10}px`, left: `${isOnPosition ? props.X + 110 : props.X + 160}px` }} onTouchEnd={() => handleSound()}>
                <div style={{ verticalAlign: "middle", margin: "7px" }}>
                    {!muted &&
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={25} height={25} fill='white'><path d="M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z" /></svg>
                    }
                    {muted &&
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={25} height={25} fill='white'><path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z" /></svg>

                    }
                </div>
            </div>}

            {!muted && !props.is_mine &&
                <div className={styles.video_component_button} style={{ transition: `opacity ${isOnPosition ? 0.5 : 0.1}s, left ${isOnPosition ? 0.5 : 0.1}s`, overflow: "hidden", opacity: `${isOnPosition ? 1 : 0}`, zIndex: `${(ref.current) ? ref.current.style.zIndex + 1 : 10}`, verticalAlign: "middle", textAlign: "center", color: "white", fontSize: "17px", borderRadius: "20px", position: "absolute", width: "40px", height: "40px", top: `${props.Y + 95}px`, left: `${isOnPosition ? props.X : props.X - 50}px` }} >
                    <canvas ref={canvasRef} style={{ backgroundColor: "#00000099", width: "40x", height: "40x" }}></canvas>
                </div>
            }
            {/* <div ref={bttnRef} className={styles.sound_bttn_video_component} style={{height:"50px", width:"50px", transform: `translate(${props.X}px, ${props.Y}px)`, position:"absolute", zIndex:"105"}}>
            {!props.is_mine && 
                <button  className={(muted) ? styles.sound_off : styles.sound_on} style={{height:"50px", width:"50px"}}
                    onClick={() => handleSound()}
                >
                    S
                </button> 
            }
        </div> */}

        </div>





    );

}

export default VideoComponent;