import { useRef, useState, useContext, useEffect } from 'react';
import styles from '../../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { TransformPoints, PointCoordinates, PointScreenPosition, nearestCoordinateOnSegment, generatePathData } from '../../../utils/Tools'
import { GlobalContext } from "../../../contexts/globalContext"
import uuid from 'react-uuid';
import * as turf from "@turf/turf"
import { ApiSaveProject } from "../../../utils/RestServices"

const RectangleGraphicDrawLayer = (props) => {
    const ref = useRef(null);
    const rectangleRef = useRef(null);

    const {mapRef, setCurrentShapeName, setDrawingLayer, currentShape, drawingsRef, currentProjectId, socketRef, currentDrawingColor } = useContext(GlobalContext);
    const [currentRectangle, setCurrentRectangle] = useState([]);
    const [currentFourth, setCurrentFourth] = useState([]);
    const [pointSnapped, setPointSnapped] = useState()

    const [currentSecond, setCurrentSecond] = useState([]);

    const [currentCoordinates, setCurrentCoordinates] = useState([]);
    const [currentAB, setCurrentAB] = useState([]);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    function RectangleFromThreePoints (points) {
        return [points[0][0] + (points[2][0] - points[1][0]), points[0][1] + (points[2][1] - points[1][1])]
    };

    function rotatedLines(line, angle){
        var options = {pivot: PointCoordinates([0, 0], props.map)};
        var newAngle = angle + 180
        var scaledLine = turf.transformRotate(line, newAngle, options)
        return scaledLine
    }

    function scaledLines(lines, scaleX, scaleY){
        var rotatedLines = lines

        var transfLines = []

        for(let i = 0; i < rotatedLines.length; i++){
            var transfLine = []
            for(let j = 0; j < 2; j++){
                var transformedPoint1 = rotatedLines[i][j][0] * scaleX
                var transformedPoint2 = rotatedLines[i][j][1] * scaleY
                transfLine.push([transformedPoint1, transformedPoint2])
            }
            transfLines.push(transfLine)
        }
        return transfLines
    }

    function translatedLines(features, dx, dy){
        var rotatedLines = []

        for(let i = 0; i < features.features.length; i++){
            var point1 = PointScreenPosition(features.features[i].geometry.coordinates[0], props.map)
            var point2 = PointScreenPosition(features.features[i].geometry.coordinates[1], props.map)
            rotatedLines.push([point1, point2])
        }

        var transfLines = []

        for(let i = 0; i < rotatedLines.length; i++){
            var transfLine = []
            for(let j = 0; j < 2; j++){
                var transformedPoint1 = rotatedLines[i][j][0] + dx
                var transformedPoint2 = rotatedLines[i][j][1] + dy
                transfLine.push([transformedPoint1, transformedPoint2])
            }
            transfLines.push(transfLine)
        }

        return transfLines
    }

    function transformationMatrix (final,line){
        const dx = currentRectangle[0][0] - currentShape[0][0][0];
        const dy = currentRectangle[0][1] - currentShape[0][0][1];

        var bearing = turf.bearing(currentAB.geometry.coordinates[0], currentAB.geometry.coordinates[1]);

        var scaleX = Math.sqrt((currentFourth[0] - currentRectangle[0][0]) ** 2 + (currentFourth[1] - currentRectangle[0][1])** 2) / 40;
        var scaleY = Math.sqrt((currentSecond[1] - currentRectangle[0][1]) ** 2 + (currentSecond[0] - currentRectangle[0][0]) ** 2) / 26;

        if(bearing <= 90 && bearing >= 0){
            if (currentSecond[0] >= currentRectangle[0][0] &&currentSecond[1] <= currentRectangle[0][1] && currentFourth[0] >= currentRectangle[0][0]  && currentFourth[1] >= currentRectangle[0][1]){
                scaleX = - scaleX
            }
            if (currentSecond[0] <= currentRectangle[0][0] && currentSecond[1] >= currentRectangle[0][1] && currentFourth[0] >= currentRectangle[0][0] &&  currentFourth[1] >= currentRectangle[0][1]){
                scaleY = - scaleY
                scaleX = - scaleX
            }
            if (currentSecond[0] <= currentRectangle[0][0] && currentSecond[1] >= currentRectangle[0][1] && currentFourth[0] <= currentRectangle[0][0] &&  currentFourth[1] <= currentRectangle[0][1]){
                scaleY = - scaleY
            }
        }
        if(bearing >= -90 && bearing <= 0){
            if (currentSecond[0] <= currentRectangle[0][0] && currentSecond[1] <= currentRectangle[0][1] &&  currentFourth[0] >= currentRectangle[0][0] &&  currentFourth[1] <= currentRectangle[0][1]){
                scaleX = - scaleX
            }
            if (currentSecond[0] >= currentRectangle[0][0] && currentSecond[1] >= currentRectangle[0][1] &&  currentFourth[0] >= currentRectangle[0][0] &&  currentFourth[1] <= currentRectangle[0][1]){
                scaleX = - scaleX
                scaleY = - scaleY
            }
            if (currentSecond[0] >= currentRectangle[0][0] && currentSecond[1] >= currentRectangle[0][1] &&  currentFourth[0] <= currentRectangle[0][0] &&  currentFourth[1] >= currentRectangle[0][1]){
                scaleY = - scaleY
            }
        }
        if(bearing >= 90 && bearing <= 180){
            if (currentSecond[0] >= currentRectangle[0][0] && currentSecond[1] >= currentRectangle[0][1] &&  currentFourth[0] <= currentRectangle[0][0] &&  currentFourth[1] >= currentRectangle[0][1]){
                scaleX = - scaleX
            }
            if (currentSecond[0] <= currentRectangle[0][0] && currentSecond[1] <= currentRectangle[0][1] &&  currentFourth[0] >= currentRectangle[0][0] &&  currentFourth[1] <= currentRectangle[0][1]){
                scaleY = - scaleY
            }
            if (currentSecond[0] <= currentRectangle[0][0] && currentSecond[1] <= currentRectangle[0][1] &&  currentFourth[0] <= currentRectangle[0][0] &&  currentFourth[1] >= currentRectangle[0][1]){
                scaleX = - scaleX
                scaleY = - scaleY
            }
        }
        if(bearing >= -180 && bearing <= -90){
            if (currentSecond[0] >= currentRectangle[0][0] &&currentSecond[1] <= currentRectangle[0][1] && currentFourth[0] >= currentRectangle[0][0]  && currentFourth[1] >= currentRectangle[0][1]){
                scaleY = - scaleY
            }
            if (currentSecond[0] >= currentRectangle[0][0] && currentSecond[1] <= currentRectangle[0][1] && currentFourth[0] <= currentRectangle[0][0] &&  currentFourth[1] <= currentRectangle[0][1]){
                scaleY = - scaleY
                scaleX = - scaleX
            }
            if (currentSecond[0] <= currentRectangle[0][0] && currentSecond[1] >= currentRectangle[0][1] && currentFourth[0] <= currentRectangle[0][0] &&  currentFourth[1] <= currentRectangle[0][1]){
                scaleX = - scaleX
            }

        }

        if(final === 1){
            return scaledLines(line, scaleX, scaleY)
        } else if(final === 2){
            return rotatedLines(line, bearing)
        } else if (final === 3){
            return translatedLines(line, dx, dy)
        }

        return `translate(${dx}, ${dy}) rotate(${bearing + 180}) scale(${scaleX}, ${scaleY })`
    }


    useGesture(
        {
            onDrag: ({ pinching, cancel, xy: [ox, oy], first }) => {
                
                if (pinching) {
                    return cancel()
                }

                if (first) {
                    setCurrentRectangle([...currentRectangle, [ox, oy]]);

                    var currentPointCoordinates = PointCoordinates([ox, oy], props.map)
                    setCurrentCoordinates([...currentCoordinates, currentPointCoordinates])
                } else {
                    const updatedPoints = currentRectangle.slice(0, -1);
                    updatedPoints.push([ox, oy]);
                    setCurrentRectangle(updatedPoints);

                    currentPointCoordinates = PointCoordinates([ox, oy], props.map)

                    const updatedPointsCoord = currentCoordinates.slice(0, -1);
                    updatedPointsCoord.push(currentPointCoordinates);
                    setCurrentCoordinates(updatedPointsCoord);
                }

                const thirdPoint = currentRectangle[2];
                let thirdPointCoord = currentCoordinates[2]

                if (thirdPoint) {
                    var C = turf.point(thirdPointCoord);
                    var snapped = nearestCoordinateOnSegment(C.geometry.coordinates, currentAB.geometry.coordinates[0], currentAB.geometry.coordinates[1])
                    setPointSnapped(snapped)

                    var newSecondPoint = PointScreenPosition(snapped, props.map)
                    setCurrentSecond(newSecondPoint)
                    
                    let fourthPoint = RectangleFromThreePoints([currentRectangle[0], newSecondPoint, thirdPoint])
                    setCurrentFourth(fourthPoint)
                }
            },
            onDragEnd: () => {
                const thirdPoint = currentRectangle[2];

                const firstPointCoord = currentCoordinates[0]
                const secondPointCoord = currentCoordinates[1]

                if(firstPointCoord && secondPointCoord){
                    var AB = turf.lineString([firstPointCoord,secondPointCoord])

                    var scaledLine = turf.transformScale(AB, 12);

                    setCurrentAB(scaledLine)
                }

                if (thirdPoint) {
                    const fourthPoint = RectangleFromThreePoints([currentRectangle[0], currentSecond, thirdPoint])

                    const updatedPoints = [currentRectangle[0], currentSecond, thirdPoint];
                    updatedPoints.push(fourthPoint);

                    setCurrentCoordinates([])
                    setCurrentAB([])
                    setCurrentSecond([])
                    setCurrentFourth([])
                    setCurrentRectangle([]);

                    if(fourthPoint[0]){

                        setDrawingLayer(0);
                        setCurrentShapeName("")

                        if (currentProjectId == null) {
                            
                            var scaledDrawings = transformationMatrix(1, currentShape)
                            var rotatedLinesArray = []

                            for(let i = 0; i < scaledDrawings.length; i++){
                                const drawingsPoints = TransformPoints(scaledDrawings[i], props.map)
                                let line = {
                                    type: 'Feature',
                                    properties: {},
                                    geometry: { type: 'LineString', coordinates: drawingsPoints[0] }
                                };
                                var rotatedLine = transformationMatrix(2,line)
                                rotatedLinesArray.push(rotatedLine)
                            }

                            var rotatedLines = turf.featureCollection(rotatedLinesArray);
                            var translatedDrawings = transformationMatrix(3, rotatedLines)
                            

                            var coordinates = [];

                            for(let i = 0; i < translatedDrawings.length; i++){
                                const drawingsPoints = TransformPoints(translatedDrawings[i], props.map);
                                coordinates.push(drawingsPoints[0]);
                            }

                            var multiline = {
                                id: uuid(),
                                    type: 'Feature',
                                    properties: {
                                        "class_id": currentDrawingColor
                                    },
                                    geometry: { type: 'MultiLineString', coordinates }
                                };
                            drawingsRef.current.add(multiline)

                        } else {
                            var scaledDrawings = transformationMatrix(1, currentShape)
                            var rotatedLinesArray = []

                            for(let i = 0; i < scaledDrawings.length; i++){
                                const drawingsPoints = TransformPoints(scaledDrawings[i], props.map)
                                let line = {
                                    type: 'Feature',
                                    properties: {},
                                    geometry: { type: 'LineString', coordinates: drawingsPoints[0] }
                                };
                                var rotatedLine = transformationMatrix(2,line)
                                rotatedLinesArray.push(rotatedLine)
                            }

                            var rotatedLines = turf.featureCollection(rotatedLinesArray);
                            var translatedDrawings = transformationMatrix(3, rotatedLines)
                            

                            var coordinates = [];

                            for(let i = 0; i < translatedDrawings.length; i++){
                                const drawingsPoints = TransformPoints(translatedDrawings[i], props.map);
                                coordinates.push(drawingsPoints[0]);
                            }

                            var multiline = {
                                    id: uuid(),
                                    type: 'Feature',
                                    properties: {
                                        "class_id": currentDrawingColor
                                    },
                                    geometry: { type: 'MultiLineString', coordinates }
                                };
                            drawingsRef.current.add(multiline)


                            socketRef.current.emit("DRAWING_UPDATED", {project_id: currentProjectId, drawings: multiline});
                            var body = JSON.stringify({ project_id: currentProjectId, drawing: multiline });
                            ApiSaveProject(body);                            
                        }
                    } else {
                        // console.log("error", currentRectangle)
                    }
                }
            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
        })


    const xDrawingPointRectangleSize = 10 / oxDivider;
    const yDrawingPointRectangleSize = 10 / oyDivider;

    function showLatLng(point) {
        const coord = TransformPoints([point], props.map)
        return `${coord[0][0][0].toFixed(3)}, ${coord[0][0][1].toFixed(3)}`
    }
    function showElevationOld(point) {
        const coord = TransformPoints([point], props.map)
        const el = mapRef.current.queryTerrainElevation([coord[0][0][0], coord[0][0][1]])
        return `${el.toFixed(3)}`
    }
    const [elevations, setElevations] = useState([]);

    async function showElevation(point) {
        try {
          const coord = TransformPoints([point], props.map);
          const response = await fetch(process.env.REACT_APP_TOPODATA_URL + coord[0][0][0] + "," + coord[0][0][1]);
          const data = await response.json();
          const elevationValue = data.results[0].elevation;
          return elevationValue.toFixed(3)
        } catch (error) {
          console.error("Error fetching elevation data:", error);
        }
    }

    async function fetchElevations(points) {
        const elevations = [];
    
        for (const [x, y] of points) {
            const elevation = await showElevation([x, y]);
            elevations.push(elevation);
        }
    
        return elevations;
    }
    
    
    useEffect(() => {
        if (currentRectangle.length > 0) {
            fetchElevations(currentRectangle).then((elevations) => {
                setElevations(elevations);
            });
        }
    }, [currentRectangle]);
    return (

        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT)}} >
            <svg style={{ width: '100%', height: '100%' }}>
                {currentRectangle.length === 2 &&  (
                    <polyline
                        ref={rectangleRef}
                        points={`${currentRectangle[0][0]},${currentRectangle[0][1]} ${currentRectangle[1][0]},${currentRectangle[1][1]}`}
                        style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4"}}
                    />
                )}
                {currentRectangle.map(([x, y], index) => {
                    if(index === 1 && currentRectangle.length > 2){
                        return <></>
                    }
                    else{
                        return <g key={index}>
                            <text x={x-5*xDrawingPointRectangleSize} y={y+2*yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >
                                {elevations[index]}m, {showLatLng([x, y])}
                            </text> 
                            <circle
                                cx = {x}
                                cy = {y}
                                r = {10}
                                style={{ fill: '#fbb03b', stroke: 'white', strokeWidth: 4 }} 
                            />
                        </g>
                    }
                })}
                {currentRectangle.length === 3 && currentFourth.length === 2 &&  (
                    <path d={generatePathData(currentShape)} stroke="#fbb03b" strokeWidth="0.5" strokeDasharray="1" fill="none" transform={transformationMatrix(0,[])} />
                )}
            </svg>
        </div>
    );
}

export default RectangleGraphicDrawLayer;


