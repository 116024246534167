import styles from '../css/dashboard.module.css'


const CircleProgressBar = ({ percentage}) => {
	const circumference = 2 * Math.PI * 50;
	const dashOffset = circumference - (percentage / 100) * circumference;

	return (
		<div className={styles.circle_box}>
			<svg className={styles.svg}>
				<circle className={styles.circle}
					cx="55"
					cy="55"
					r="50"
					style={{ strokeDashoffset: dashOffset }}
				/>
			</svg>
		</div>
	);
}

export default CircleProgressBar;