import { useRef, useContext, useState, useEffect } from 'react';
import styles from './../../styles.module.css'
import Lottie from 'react-lottie-player'

// import { useGesture } from '@use-gesture/react'
// import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
// import { GetMaxZIndex } from '../../utils/Tools'

import { GlobalContext } from "../../contexts/globalContext"
import VideoIcon from '../../graphics/file-picker/file-ic-video.svg'
import LoadingJson from '../../graphics/file-picker/spinner-loading.json'
import { MultitouchComponent } from 'use-gesture-pack';

const NewVideoFileComponent = (props) => {

    const ref = useRef(null);
    const videoRef = useRef(null);
    const videoSourceRef = useRef(null);
    const { setFilesToBeOpen } = useContext(GlobalContext);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const [isVisible, setIsVisible] = useState(false);
    const [firstDrag, setFirstDrag] = useState(true);
    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });

    const startingHeight = 600

    const options = {
        appWidth: process.env.REACT_APP_WIDTH, 
        appHeight: process.env.REACT_APP_HEIGHT,
		maxScale: process.env.REACT_APP_HEIGHT / startingHeight,
        minScale: 0.5
	}   

    useEffect(() => {
        const handleMetadataLoaded = () => {
            const videoWidth = videoRef.current.offsetWidth;
            const videoHeight = videoRef.current.offsetHeight;
            setVideoDimensions({ width: videoWidth, height: videoHeight });
            setIsVisible(true);
        };

        videoRef.current.addEventListener('loadedmetadata', handleMetadataLoaded);

        return () => {
            // videoRef.current.removeEventListener('loadedmetadata', handleMetadataLoaded);
        };
    }, []);


    const calculateWidth = () => {
        if (ref.current) {
            const { width: videoWidth, height: videoHeight } = videoDimensions;
            const containerHeight = startingHeight;
            const videoAspectRatio = videoHeight / videoWidth;
            const containerWidth = containerHeight / videoAspectRatio;

            return containerWidth;
        }

        return 0;
    };

    const containerStyle = {
        width: `${isVisible ? calculateWidth() : 60}px`,
        height: `${isVisible ? startingHeight : 75}px`,
        // opacity: `${isVisible ? 1 : 0}`,
        overflow: 'hidden',
        transition: `width 0.5s ease, height 0.5s ease, transform 0.5s ease`,
        borderRadius: '15px',
        padding: `${isVisible ? '7.5px' : '0px'}`,
        transformOrigin: 'center center',
        transform: `translate(-50%, -50%) matrix(1, 0, 0, 1, 0, 0)`,
        backdropFilter: `${isVisible ? 'blur(10px)' : ''}`,
        backgroundColor: `${isVisible ? 'rgba(0, 0, 0, 0.3)' : 'transparent'}`
    };

    const onMinScaleReached = () => {
        setFilesToBeOpen(filesToBeOpen => filesToBeOpen.filter(a => a.uid !== props.file.uid));
    }

    return (
        <MultitouchComponent dragGesture={false} x={props.x + 75} y={props.y + 40} width={isVisible ? calculateWidth() : 60} height={isVisible ? startingHeight : 75} onMinScaleReached={onMinScaleReached} options={options}>
            <div ref={ref} className={`${styles.zoom_in_div}  ${isVisible ? 'visible' : ''}`} style={containerStyle}>
                <Lottie
                    loop
                    animationData={LoadingJson}
                    play
                    speed={1.9}
                    style={{ position:"absolute", left:"-13.5px", top:"-13.5px", width: 85, height: 100, opacity: `${isVisible ? 0 : 1}`  }}
                />
                <img style={{ position:"absolute", width: 60, height: 75, opacity: `${isVisible ? 0 : 1}`}}  alt="icon" draggable="false" src={VideoIcon} />
                <video ref={videoRef} autoPlay controls src={process.env.REACT_APP_CMS_URL + props.file.path} style={{ borderRadius: "7.5px", height: startingHeight, opacity: `${isVisible ? 1 : 0}` }}>
                    <source ref={videoSourceRef} src={process.env.REACT_APP_CMS_URL + props.file.path} />
                </video>
                
                {/* <img style={{ width: "90px", height: "90px", top: "10px", left: "10px", position: "absolute", opacity: `${isVisible ? 0 : 1}` }} alt="loading" draggable="false" src={LoadingSvg} /> */}
            </div>
        </MultitouchComponent>
    );

}

export default NewVideoFileComponent;