import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css'
import "@fontsource/roboto";

import { GlobalContext } from "../../contexts/globalContext"

import { SipProvider } from 'react-sip';
import DialerComponent from './DialerComponent';
import ContactsComponent from './ContactsComponent';
import CallsHistoryComponent from './CallsHistoryComponent';




import {
    SIP_STATUS_CONNECTED,
    SIP_STATUS_REGISTERED,
    //SIP_STATUS_...,
    CALL_STATUS_IDLE,
    //CALL_STATUS_...,
    SIP_ERROR_TYPE_CONFIGURATION,
    //SIP_ERROR_TYPE_...,
    CALL_DIRECTION_INCOMING,
    CALL_DIRECTION_OUTGOING,
} from "react-sip";

const SipComponent = (props) => {
    const provref = useRef(null);

    const { currentUser, phoneMenuOpened, setPhoneMenuOpened, sipRegistered, setSipRegistered } = useContext(GlobalContext);

    const [selectedUser, setSelectedUser] = useState(null);





    useEffect(() => {
        // console.log(provref.current.state.sipStatus);
        // console.log(currentUser.sip_user);
        // console.log(currentUser.sip_pass);
        if (provref.current.state.sipStatus === SIP_STATUS_CONNECTED || provref.current.state.sipStatus === SIP_STATUS_REGISTERED) {
            setSipRegistered(true);
        } else {
            setSipRegistered(false);
        }
    }, [provref.current?.state.sipStatus]);


    useEffect(() => {
        // console.log(phoneMenuOpened,provref.current.state.callStatus)
        if(provref.current.state.callStatus === "callStatus/STARTING" && phoneMenuOpened === false){
            setPhoneMenuOpened(true)
        }
    }, [provref.current?.state.callStatus]);

    const onTabIndexChanged = (newTebIndex) => {
        setSelectedUser(null);
        // setCurrentTabIndex(newTebIndex);
    };

    const onSelectedUser = (sUser)  => {
        setSelectedUser(sUser);
        // setCurrentTabIndex(0);
        
        // tabmenuRef.current.goToDial();
    }



    return (
        <SipProvider
            host="pbx.internal.nextfusion.ro"
            port={8089}
            ref={provref}
            pathname="/ws" // Path in socket URI (e.g. wss://sip.example.com:7443/ws); "" by default
            user={currentUser.sip_user}
            password={currentUser.sip_pass}  // usually required (e.g. from ENV or props)
            autoRegister={true} // true by default, see jssip.UA option register
            autoAnswer={false} // automatically answer incoming calls; false by default
            iceRestart={false} // force ICE session to restart on every WebRTC call; false by default
            sessionTimersExpires={120} // value for Session-Expires header; 120 by default
            // extraHeaders={{ // optional sip headers to send
            //     register: ['X-Foo: foo', 'X-Bar: bar'],
            //     invite: ['X-Foo: foo2', 'X-Bar: bar2']
            // }}
            // iceServers={[ // optional
            //     { urls: ['stun:a.example.com', 'stun:b.example.com'] },
            //     { urls: 'turn:example.com', username: 'foo', credential: '1234' }
            // ]}
            debug={false} // whether to output events to console; false by default
        >
        

            <div style={{ position: "absolute", left:"6px", 
            bottom: "8px",
            width:"580px", height:"510px",
            backgroundColor:"black",
            borderRadius: "10px"}}>
                {/* <div className={styles.sip_call_history} style={{fontFamily: 'Roboto', fontStyle: 'Roboto'}}> */}
                <CallsHistoryComponent onSelectedUser={(sUser) => onSelectedUser(sUser)}/>
                {/* </div> */}
                <div className={styles.sip_component}  style={{display: "flex", flexDirection: "row", justifyContent: 'space-around', position: "absolute", left:"0px", top: "82px"}}> 
                    <ContactsComponent onSelectedUser={(sUser) => onSelectedUser(sUser)}/>
                    <DialerComponent selectedUser={selectedUser} providerRef={provref}/>
                </div>
            </div>
            
            
        </SipProvider>
    );

}

export default SipComponent;