import React from 'react';
import { useRef, useState, useContext } from 'react';
import styles from '../../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { GlobalContext } from '../../../contexts/globalContext';
import uuid from 'react-uuid';
import { TransformPoints, buffer, between } from '../../../utils/Tools'
import * as turf from "@turf/turf"
import { ApiSaveProject } from "../../../utils/RestServices"

const FreeHandGraphicDrawLayer = (props) => {
    const [currentLine, setCurrentLine] = useState([]);
    const ref = useRef(null);
    const freeHandRef = useRef(null);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const { mapRef, setCurrentShapeName, currentShape, setCurrentShape, setDrawingLayer, drawingsRef, currentDrawingColor, currentProjectId, socketRef } = useContext(GlobalContext);


    function createEndingTextShape(point1, bearing, dist, options){
        var coordinatesText = []

        for(let i = 0; i < currentShape.length; i++){
            let line = []
            for(let j = 0; j < 2; j++){
                let oxDist = dist * currentShape[i][j][0]
                let oyDist = dist * currentShape[i][j][1]
                if(oyDist === 0){
                    var point2 = turf.destination(point1, oxDist, bearing, options)
                    line.push(point2.geometry.coordinates)
                } else if(oyDist > 0){
                    let helpingPoint = turf.destination(point1, oyDist, bearing + 90, options)
                    var point3 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point3.geometry.coordinates)
                } else {
                    let helpingPoint = turf.destination(point1, Math.abs(oyDist), bearing + 270, options)
                    var point4 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point4.geometry.coordinates)
                }
            }
            coordinatesText.push(line)
        }

        return coordinatesText
    }

    useGesture(
    {
        onDrag: ({ xy: [ox, oy] }) => {
            setCurrentLine([...currentLine, [ox, oy]]);  
            setCurrentShape(currentShape)
        },
        onDragEnd: () => {
            const points = TransformPoints(currentLine, props.map);
            setCurrentLine([]);
            setDrawingLayer(0);
            setCurrentShapeName("");

            if (currentProjectId == null) {
                let feature = {
                    type: 'Feature',
                    properties: {
                        "class_id": currentDrawingColor
                    },
                    geometry: { type: 'LineString', coordinates: points[0] }
                };

                var zoom = mapRef.current.getZoom();
                var zoomDiff = (11 - zoom) / 5

                if(points[0].length > 1){

                    let simplified = turf.simplify(feature, {tolerance: 0.0001});
                    
                    if(0.3 + zoomDiff > 0.1){
                        var dist = 0.4 + zoomDiff
                    } else {
                        var dist = 0.1
                    }

                    let buffered = buffer(simplified, dist, {units:'kilometers'});
                    var coordinates = [];

                    for(var i =0; i < buffered.geometry.coordinates[0].length - 1;i++) {
                        if ( between(simplified.geometry.coordinates[0][0], buffered.geometry.coordinates[0][i][0], buffered.geometry.coordinates[0][i+1][0] )
                            && between(simplified.geometry.coordinates[0][1], buffered.geometry.coordinates[0][i][1], buffered.geometry.coordinates[0][i+1][1] )
                        )  {
                            
                        } else if ( between(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1][0], buffered.geometry.coordinates[0][i][0], buffered.geometry.coordinates[0][i+1][0] )
                        && between(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1][1], buffered.geometry.coordinates[0][i][1], buffered.geometry.coordinates[0][i+1][1] )
                        ) {
                            var bearing = turf.bearing(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 2], simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1]);

                            var options = {units: 'kilometers', bearing};
                            var point1 = simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1]
                            

                            var coordinatesOne = createEndingTextShape(point1, bearing, dist, options);


                            for(let i = 0; i < coordinatesOne.length; i ++){
                                coordinates.push(coordinatesOne[i])
                            }
                        }
                        else{
                            coordinates.push([buffered.geometry.coordinates[0][i], buffered.geometry.coordinates[0][i+1]])  
                        }
                    }


                    var multiline = {
                        id: uuid(),
                        type: 'Feature',
                        properties: {
                            "class_id": currentDrawingColor
                        },
                        geometry: { type: 'MultiLineString', coordinates }
                    };

                    drawingsRef.current.add(multiline)

                }
            } else {
                
                let feature = {
                    
                    type: 'Feature',
                    properties: {
                        "class_id": currentDrawingColor
                    },
                    geometry: { type: 'LineString', coordinates: points[0] }
                };

                var zoom = mapRef.current.getZoom();
                var zoomDiff = (11 - zoom) / 5

                if(points[0].length > 1){
                    let simplified = turf.simplify(feature, {tolerance: 0.0001});
                    
                    if(0.3 + zoomDiff > 0.1){
                        var dist = 0.4 + zoomDiff
                    } else {
                        var dist = 0.1
                    }

                    let buffered = buffer(simplified, dist, {units:'kilometers'});
                    var coordinates = [];

                    for(var i =0; i < buffered.geometry.coordinates[0].length - 1;i++) {
                        if ( between(simplified.geometry.coordinates[0][0], buffered.geometry.coordinates[0][i][0], buffered.geometry.coordinates[0][i+1][0] )
                            && between(simplified.geometry.coordinates[0][1], buffered.geometry.coordinates[0][i][1], buffered.geometry.coordinates[0][i+1][1] )
                        )  {
                            
                        } else if ( between(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1][0], buffered.geometry.coordinates[0][i][0], buffered.geometry.coordinates[0][i+1][0] )
                        && between(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1][1], buffered.geometry.coordinates[0][i][1], buffered.geometry.coordinates[0][i+1][1] )
                        ) {
                            var bearing = turf.bearing(simplified.geometry.coordinates[simplified.geometry.coordinates.length - 2], simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1]);

                            var options = {units: 'kilometers', bearing};
                            var point1 = simplified.geometry.coordinates[simplified.geometry.coordinates.length - 1]
                            

                            var coordinatesOne = createEndingTextShape(point1, bearing, dist, options);


                            for(let i = 0; i < coordinatesOne.length; i ++){
                                coordinates.push(coordinatesOne[i])
                            }
                        }
                        else{
                            coordinates.push([buffered.geometry.coordinates[0][i], buffered.geometry.coordinates[0][i+1]])  
                        }
                    }

                    var multiline = {
                        id: uuid(),
                        type: 'Feature',
                        properties: {
                            "class_id": currentDrawingColor
                        },
                        geometry: { type: 'MultiLineString', coordinates }
                    };

                    drawingsRef.current.add(multiline)

                    socketRef.current.emit("DRAWING_UPDATED", {project_id: currentProjectId, drawings: multiline});

                    //var body = JSON.stringify({ project_id: currentProjectId, data: { drawings: drawingsRef.current.getAll(), symbols:symbols } });
                    var body = JSON.stringify({ project_id: currentProjectId, drawing: multiline });
                    //e ok
                    ApiSaveProject(body);  
                }              
            }
        }
    },
    {
        target: ref,
        drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
    })

    return (
        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT)}} >
            <svg style={{ width: '100%', height: '100%' }}>
                <polyline
                    ref={freeHandRef}
                    points={currentLine.map(([x, y]) => `${x},${y}`).join(' ')}
                    style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4"}}
                />
            </svg>
        </div>
    );
};

export default FreeHandGraphicDrawLayer;