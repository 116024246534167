import React, { useState, useEffect, useContext, useRef } from 'react';
import Lottie from 'react-lottie-player'
import NewInputTextComponent from '../../../components/keyboard/NewInputTextComponent'
import styles from '../styles.module.css'
import "@fontsource/roboto";

const SipTextImput = ({ placeholder, setData}) => {
    const placeholderText = useRef();

    const [inputText, setInputText] = useState("");

    //Trebuie textul scalat mai mic cand input e pe focus si sa nu mai fie touchable


    const setText = (e) => {
        setInputText(e.target.value);
        setData(e.target.value)
    }

    return (
        <div style={{ background: "black", margin: "8px", position: "relative" }}>
            <input
                value={inputText}
                className={styles.lottie_text_input}
                onFocus={() => {
                    placeholderText.current.style.top = '1px';
                    placeholderText.current.style.color = '#41cdcd';
                    placeholderText.current.style.fontSize = '9px'
                }}

                onBlur={() => {
                    if (!inputText) {
                        placeholderText.current.style.top = '30%';
                    }
                    placeholderText.current.style.color = '#AAAAAA';
                    placeholderText.current.style.fontSize = '15px'
                }}

                onChange={(e) =>  setText(e)}

            // onChange={onChangeInput}
            />
            <div ref={placeholderText} className={styles.lottie_text_placeholder} tyle={{fontFamily: 'Roboto', fontStyle: 'Roboto', color: "#AAAAAA"}}>
                <text>{placeholder}</text>
            </div>
        </div>
    )
}

export default SipTextImput;