import { useEffect, useState, useContext, useRef } from 'react';
import styles from '../css/dashboard.module.css'
import "@fontsource/roboto";
import "@fontsource/oswald/300.css"
import { GlobalContext } from "../../../contexts/globalContext";
import DroneImage1 from '../../../graphics/dashboard icons/drona1.png'
import DroneImage2 from '../../../graphics/dashboard icons/drona2.png'
import DroneImage3 from '../../../graphics/dashboard icons/drona3.png'
import LocationBtn from '../../../graphics/dashboard icons/new icons/btn-location.svg'
import ExpandBtn from '../../../graphics/dashboard icons/new icons/btn-expand.svg'
import Compass40 from '../../../graphics/dashboard icons/-40-Current View.png'
import Sageata from '../../../graphics/dashboard icons/pointer.svg'
import Baterie from '../../../graphics/dashboard icons/battery.svg'

import Lottie from 'react-lottie-player'
import lottieJson from '../../../graphics/dashboard icons/item-lista-activ.json'
import DroneItemJson from './DroneListComponent';

import CircleProgressBar from './CircleProgressBar';


const ConnectedDroneList = (props) => {
	
	const { drones, status, tip } = props

    const jsonData = {
        status: {
            activ: [
                {
                    id: "1hq",
                    name: "DJI 6201",
                    site: "ISU Brasov",
                    viteza: "60",
                    altitudine: "140",
                    baterie: "64",
                    status: 'activ'
                },
                {
                    id: "2ns",
                    name: "CGT205",
                    site: "ISU Brasov",
                    viteza: "80",
                    altitudine: "2340",
                    baterie: "99",
                    status: 'activ'
                },
                {
                    id: "3cl",
                    name: "DJI 6231",
                    site: "Ghimbav",
                    viteza: "120",
                    altitudine: "4517",
                    baterie: "87",
                    status: 'activ'
                }
            ],	
            ready: [
                {
                    id: "1gp",
                    name: "CGT215",
                    site: "ISU Cristian",
                    status: 'ready'
                },
                {
                    id: "2nd",
                    name: "DJI 6241",
                    site: "ISU Brasov",
                    status: 'ready'
                }
            ],
            offline: [
                {
                    id: "1sl",
                    name: "CGT265",
                    site: "ISU Brasov",
                    status: 'offline'
                },
                {
                    id: "2hc",
                    name: "DJI 6250",
                    site: "ISU Brasov",
                    status: 'offline'
                }
            ]
        },
        tip: {
            FPV: [
                {
                    id: "1sl",
                    name: "CGT265",
                    site: "ISU Brasov",
                    status: 'offline'
                },
                {
                    id: "1hq",
                    name: "DJI 6201",
                    site: "ISU Brasov",
                    viteza: "60",
                    altitudine: "140",
                    baterie: "64",
                    status: 'activ'
                },
                {
                    id: "2ns",
                    name: "CGT205",
                    site: "ISU Brasov",
                    viteza: "80",
                    altitudine: "2340",
                    baterie: "99",
                    status: 'activ'
                },
                {
                    id: "2hc",
                    name: "DJI 6250",
                    site: "ISU Brasov",
                    status: 'offline'
                }
            ],
            VTOL: [
                {
                    id: "1gp",
                    name: "CGT215",
                    site: "ISU Cristian",
                    status: 'ready'
                },
                {
                    id: "3cl",
                    name: "DJI 6231",
                    site: "Ghimbav",
                    viteza: "120",
                    altitudine: "4517",
                    baterie: "87",
                    status: 'activ'
                },
                {
                    id: "2nd",
                    name: "DJI 6241",
                    site: "ISU Brasov",
                    status: 'ready'
                },
            ]
            
        },
        locatie: {
            brasov: [
                {
                    id: "1hq",
                    name: "DJI 6201",
                    site: "ISU Brasov",
                    viteza: "60",
                    altitudine: "140",
                    baterie: "64",
                    status: 'activ'
                },
                {
                    id: "2ns",
                    name: "CGT205",
                    site: "ISU Brasov",
                    viteza: "80",
                    altitudine: "2340",
                    baterie: "99",
                    status: 'activ'
                },
                {
                    id: "2nd",
                    name: "DJI 6241",
                    site: "ISU Brasov",
                    status: 'ready'
                },
                {
                    id: "1sl",
                    name: "CGT265",
                    site: "ISU Brasov",
                    status: 'offline'
                },
                {
                    id: "2hc",
                    name: "DJI 6250",
                    site: "ISU Brasov",
                    status: 'offline'
                }
            ],
            ghimbav: [
                {
                    id: "3cl",
                    name: "DJI 6231",
                    site: "Ghimbav",
                    viteza: "120",
                    altitudine: "4517",
                    baterie: "87",
                    status: 'activ'
                },
            ],
            cristian: [
                {
                    id: "1gp",
                    name: "CGT215",
                    site: "ISU Cristian",
                    status: 'ready'
                },
            ]
        },
        proximitate: {
            razaMica: [
                {
                    id: "3cl",
                    name: "DJI 6231",
                    site: "Ghimbav",
                    viteza: "120",
                    altitudine: "4517",
                    baterie: "87",
                    status: 'activ'
                },
    
            ],
            razaMedie: [
                {
                    id: "1gp",
                    name: "CGT215",
                    site: "ISU Cristian",
                    status: 'ready'
                },
            ],
            razaMare: [
                {
                    id: "1hq",
                    name: "DJI 6201",
                    site: "ISU Brasov",
                    viteza: "60",
                    altitudine: "140",
                    baterie: "64",
                    status: 'activ'
                },
                {
                    id: "2ns",
                    name: "CGT205",
                    site: "ISU Brasov",
                    viteza: "80",
                    altitudine: "2340",
                    baterie: "99",
                    status: 'activ'
                },
                {
                    id: "2nd",
                    name: "DJI 6241",
                    site: "ISU Brasov",
                    status: 'ready'
                },
                {
                    id: "1sl",
                    name: "CGT265",
                    site: "ISU Brasov",
                    status: 'offline'
                },
                {
                    id: "2hc",
                    name: "DJI 6250",
                    site: "ISU Brasov",
                    status: 'offline'
                }
            ]
        }
    }
    

    function jsonArrayFunction(){
        if (tip === "status" && status === 'activ') {  
            if (drones && drones.length > 0) {
            } else {
                return jsonData.status.activ;
            } 
        } else if (tip === "status" && status === 'ready') {
            return jsonData.status.ready;
        } else if (tip === "status" && status === 'offline') {
            return jsonData.status.offline;
        } else if (tip === 'type' &&  status === 'FPV') {
            return jsonData.tip.FPV;
        } else if (tip === 'type' &&  status === 'VTOL') {
            return jsonData.tip.VTOL;
        } else if (tip === 'locatie' &&  status === 'Brasov') {
            return jsonData.locatie.brasov;
        } else if (tip === 'locatie' && status === 'Rasnov') {
            // return [jsonData.locatie.Rasnov];
        } else if (tip === 'proximitate' && status === 'mic') {
            return jsonData.proximitate.razaMica;
        } else if (tip === 'proximitate' && status === 'mediu') {
            return jsonData.proximitate.razaMedie;
        } else {
            return jsonData.proximitate.razaMare;
        }
        
    }

    function vehicleArrayFunction() {
        if (tip === "status" && status === 'activ') {  
            if (drones && drones.length > 0) {
                return drones
            } 
        } else if (tip === "status" && status === 'ready') {
            return []
        } else if (tip === "status" && status === 'offline') {
            return [] 
        } else if (tip === 'type' &&  status === 'FPV') {
            if (drones) {
                const filteredDrones = drones.filter(drone => drone.name !== "CGT200");
                return filteredDrones
            }
        } else if (tip === 'type' &&  status === 'VTOL') {
            if (drones) {
                const filteredDrones = drones.filter(drone => drone.name === 'CGT200');
                return filteredDrones
            }
        } else if (tip === 'locatie' &&  status === 'Brasov') {
            return [] 
        } else if (tip === 'locatie' && status === 'Rasnov') {
            if (drones) {
                return drones
            }
        } else if (tip === 'proximitate' && status === 'mic') {
            if (drones) {
                return drones 
            }
        } else if (tip === 'proximitate' && status === 'mediu') {
            return [] 
        } else {
            return [] 
        }
    }
	return (
		<div style={{ display: "flex", flexDirection: 'column', top: '10px', position: "relative" }}>
			{vehicleArrayFunction() && vehicleArrayFunction().map((drone) => (
				<DroneItem key={drone._id} drone={drone}/>
			))}

			{jsonArrayFunction() && jsonArrayFunction().map((drone) => (
				<DroneItemJson key={drone.id} {...drone}/>
			))}
		</div>
	);
};


const DroneItem = ({ drone }) => {
	const nameRef = useRef()

	const [expandDetails, setExpandDetails] = useState(false);

	const [bordValuesSpeed, setBordValuesSpeed] = useState()
	const [speed, setSpeed] = useState()

	const [bordValuesAltitude, setBordValuesAltitude] = useState()
	const [altitude, setAltitude] = useState()

	const [viteza, setViteza] = useState(0);
	const [altitudine, setAltitudine] = useState(0);
	const [baterie, setBaterie] = useState(99);
	const [siteName, setSiteName] = useState();

	const [posX, setPosX] = useState(0)

	const { sites, mapRef, devicesForComponents, setDevicesForComponents } = useContext(GlobalContext);

	const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;

	useEffect(() => {
		if (drone.speed !== viteza && drone.speed !== undefined) setViteza(drone.speed)
		if (drone.altitude !== altitudine && drone.altitude !== undefined) setAltitudine(drone.altitude)
		if (drone.baterie !== baterie && drone.baterie !== undefined) setBaterie(drone.baterie)

		for (let i = 0; i < sites.length; i++) {
			if (sites[i]._id === drone.site) {
				setSiteName(sites[i].name)
			}
		}
	}, [drone])

	useEffect(() => {
		const parsedValue = parseFloat(viteza);
		const remainder = parsedValue % 20;
		let result;
		if (remainder <= 10) {
			result = parsedValue - remainder;
		} else {
			result = parsedValue + (20 - remainder);
		}
		if (result !== speed) {
			setSpeed(result)
			if (result >= 60) {
				setBordValuesSpeed([result + 60, result + 40, result + 20, result, result - 20, result - 40, result - 60])
			} else if (result === 40) {
				setBordValuesSpeed([result + 60, result + 40, result + 20, result, result - 20, result - 40])
			} else if (result === 20) {
				setBordValuesSpeed([result + 60, result + 40, result + 20, result, result - 20])
			} else {
				setBordValuesSpeed([result + 60, result + 40, result + 20, result])
			}

		}
	}, [viteza])

	useEffect(() => {
		const parsedValue = parseFloat(altitudine);
		const remainder = parsedValue % 20;
		let result;
		if (remainder <= 10) {
			result = parsedValue - remainder;
		} else {
			result = parsedValue + (20 - remainder);
		}
		if (result !== altitude) {
			setAltitude(result)
			if (result >= 60) {
				setBordValuesAltitude([result + 60, result + 40, result + 20, result, result - 20, result - 40, result - 60])
			} else if (result === 40) {
				setBordValuesAltitude([result + 60, result + 40, result + 20, result, result - 20, result - 40])
			} else if (result === 20) {
				setBordValuesAltitude([result + 60, result + 40, result + 20, result, result - 20])
			} else {
				setBordValuesAltitude([result + 60, result + 40, result + 20, result])
			}
		}
	}, [altitudine])

	const goToDevice = drone => {
        mapRef.current.flyTo({
            center: [drone.location[1], drone.location[0]],
            zoom: 18,
            essential: true
        });
    };

	useEffect(() => {
		if(nameRef.current){
			setPosX(nameRef.current.getBoundingClientRect().width / oxDivider + 15)
		}
    }, [])


	return (
		<div className={`${expandDetails ? styles.vehicleDiv_expand : styles.vehicleDiv}`}
			onTouchEnd={(event) => {
				setExpandDetails(!expandDetails)
			}}>
			<Lottie
				className={`${styles.activAnimation}`}
				loop
				animationData={lottieJson}
				play
			/>
			<label ref={nameRef}  className={`${styles.nameLabel}`}> {drone.name} </label>
			<div className={`${styles.labelDiv_live}`} style={{transform:`matrix(1, 0, 0, 1, ${posX}, 0)`}}>
				<label className={`${styles.labelLive}`} > Live </label>
			</div>
			<label className={`${styles.siteLabel}`}> {siteName} </label>
			<div className={`${styles.btnsDiv_copy}`}>
                <img className={`${styles.btn_copy}`} alt="icon" draggable="false" src={LocationBtn} onTouchEnd={(event) => {
					event.stopPropagation()
					goToDevice(drone)}}
				/>
				<img className={`${styles.btn_copy}`} alt="icon" draggable="false" src={ExpandBtn} onTouchEnd={(event) => {
					event.stopPropagation()
					var alreadyExists = devicesForComponents.filter(a => a._id === drone._id);
                    if (alreadyExists.length > 0) {
                        setDevicesForComponents(devicesForComponents => devicesForComponents.filter(a => a._id !== drone._id));
                    } else {
                        setDevicesForComponents([...devicesForComponents, drone])
                    }
				}}/>
			</div>
			{expandDetails && <div className={`${styles.drone_expand}`}>
				<div className={styles.drona_baterie} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto' }}>{baterie}</div>
				<CircleProgressBar percentage={baterie}/>
				<img className={styles.baterie_img} alt="baterie" draggable="false" src={Baterie} />
				{/* <img className={styles.drona_img} alt="drona" draggable="false" src={DroneImage} /> */}
				<img className={styles.drona_img} alt="drona" draggable="false" 
					src={drone.name === "CGT200" ? DroneImage2 : (drone.name === "DJI 6000" ? DroneImage3 : DroneImage1 )} 
				/>
				<div className={styles.drona_baterie} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto', opacity: "0.3" }}>{baterie}</div>
				<div className={styles.specs_container}>
					<div className={styles.specs_title} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto', fontSize: "10px" }}>Altitudine</div>
					<div style={{ position: "absolute", top: "10px", left: "0px", overflow: "hidden", width: "60px", height: "118px" }}>
						{bordValuesAltitude.map((value) => (
							<div key={value}>
								<div className={styles.specs_board} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto' }}>{value}</div>
								<div className={styles.specs_line}></div>
							</div>
						))}
						<div className={styles.specs_value} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto' }}>{altitudine}</div>
						<div className={styles.specs_unit} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto' }}>m</div>
						<img className={styles.specs_sageata} alt="sageata" draggable="false" src={Sageata} />
						<div className={styles.specs_shadow1} />
						<div className={styles.specs_shadow2} />
					</div>
				</div>
				<div className={styles.specs_container} style={{ left: "275px" }}>
					<div className={styles.specs_title} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto', fontSize: "10px" }}>Viteza</div>
					<div style={{ position: "absolute", top: "10px", left: "0px", overflow: "hidden", width: "60px", height: "118px" }}>
						{bordValuesSpeed.map((value) => (
							<div key={value}>
								<div className={styles.specs_board} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto' }}>{value}</div>
								<div className={styles.specs_line}></div>
							</div>

						))}
						<div className={styles.specs_value} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto' }}>{viteza}</div>
						<div className={styles.specs_unit} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto' }}>km/h</div>
						<img className={styles.specs_sageata} alt="sageata" draggable="false" src={Sageata} />
						<div className={styles.specs_shadow1} />
						<div className={styles.specs_shadow2} />
					</div>
				</div>
				<img className={styles.compass_img} alt="compass" draggable="false" src={Compass40} />
				<div className={styles.compass_img}>
					<div className={styles.compass_directions} style={{ fontSize: "15px", fontFamily: "Oswald", top: "-17px" }}>N</div>
					<div className={styles.compass_directions} style={{ fontSize: "15px", fontFamily: "Oswald", top: "32px", right: "-55px" }}>E</div>
					<div className={styles.compass_directions} style={{ fontSize: "15px", fontFamily: "Oswald", top: "90px" }}>S</div>
					<div className={styles.compass_directions} style={{ fontSize: "15px", fontFamily: "Oswald", top: "32px", left: "-55px" }}>W</div>
				</div>
			</div>}

		</div>
	);
};

export default ConnectedDroneList;