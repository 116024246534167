import React, { useEffect, useRef, useState } from 'react';
import styleNormalizer from 'react-style-normalizer';
import "@fontsource/oswald/300.css"
import '../compass_styles.css';
import PitchScaleComponent from "./PitchScaleComponent"
import styles from '../css/compass.module.css'

import locatieBgr from "../graphics/drona/locatie-bgr.svg"
import locatieInel from "../graphics/drona/locatie-inel-top.svg"
import vitezaRing from "../graphics/drona/viteza-cerc.svg"
import AltitudeRing from "../graphics/drona/alt-inel.svg"


const CompassAndPYRComponent = (props) => {

    const [direction, setDirection] = useState(0);
    const [name, setName] = useState("");
    const [directionNames, setDirectionNames] = useState(['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']);
    const oldAngle = useRef(0);;

    const [roll, setRoll] = useState("");
    const [pitch, setPitch] = useState("");
    const [movePitch, setMovePitch] = useState(50);

    const normalizeAngle = (direction) => {
        console.log(direction);
        let newAngle = direction,
            rot = oldAngle.current || 0,
            ar = rot % 360;

        while (newAngle < 0) { newAngle += 360; }
        while (newAngle > 360) { newAngle -= 360; }
        while (rot < 0) { rot += 360; }
        while (rot > 360) { rot -= 360; }

        if (ar < 0) { ar += 360; }
        if (ar < 180 && newAngle > ar + 180) { rot -= 360; }
        if (ar >= 180 && newAngle <= ar - 180) { rot += 360; }

        rot += newAngle - ar;
        oldAngle.current = rot;

        return Math.round(rot);
    }

    const directionName = (dir) => {
        let sections = directionNames.length,
            sect = 360 / sections,
            x = Math.floor((dir + sect / 2) / sect);

        x = x >= sections ? 0 : x;

        return directionNames[x];
    }


    useEffect(() => {
        setDirection(normalizeAngle(props.direction));
        setName(directionName(props.direction));
    }, [props.direction])

    useEffect(() => {
        setDirection(normalizeAngle(props.direction));
        setName(directionName(props.direction));        
    }, [])


    useEffect(() => {
        if (props.pyrValues) {
            setRoll(Number(props.pyrValues[2]) * 180 / Math.PI);
            let degreePitch = Number(props.pyrValues[0]) * 180 / Math.PI;
            let moveForOneDegree = 125 / 20;
            setPitch(degreePitch);
            setMovePitch(125 + moveForOneDegree * degreePitch);
        }
    }, [props.pyrValues])

    useEffect(() => {
        let pathElement = document.getElementById('arc-path');

        pathElement.setAttribute('d', describeArc());
    }, [props.speed])



    function describeArc() {
        const maxSpeed = 150;
        const percentage = 100 * props.speed / maxSpeed

        const angle = Math.PI - percentage / 100 * Math.PI * 1.5

        const endPointX = 100 + Math.cos(angle) * 100;
        const endPointY = 100 - Math.sin(angle) * 100;

        var d = [
            'M', 0, 100,
            'A', 100, 100, 0, props.speed < 100 ? 0 : 1, 1, endPointX, endPointY
        ].join(' ');

        return d;
    }


    // Construct the path for the arc
    // const pathData = `
    //     M 100,0
    //     A 100,100 0,${100 > 50 ? 1 : 0},1 ${endPointX},${endPointY}
    // `;

    return (
        <div style={{ width: "800px", height: "265px", marginTop: "6px", marginBottom: "5px", display: "flex", flexDirection: "row" }}>


            {/* <div className="compass" style={{ position: "absolute",width:"250px", height:"250px", margin:"10px"}}>
                <div className="compass__windrose"
                    style={styleNormalizer({ transform: `rotate(-${props.direction}deg)` })}
                    >
                    <img draggable="false" alt="compass" src={CompassWindrose} style={{ position: "absolute",left:"-22px", top:"-21px", width:"250px", height:"250px", margin:"10px"}}/>

                </div>

                <div className="compass__arrow-container">
                    <div className="compass__arrow"></div>
                    <div className="compass__labels">
                        <span>{name}</span>
                        <span>{direction}<sup>o</sup></span>
                    </div>
                </div>
            </div> */}

            {/* <svg viewBox="-3 -3 200 200" width={200} height={200} style={{position: "relative", top:"100px", left: "100px"}}>
                <path
                    fill="none"
                    strokeWidth={6}
                    stroke="#12E5DD"
                    id="arc-path"/>
            </svg> */}

            <div className={styles.circular_div}>
                <div className={styles.circular_div_background}>
                    <div style={{ transform: "matrix(0.96, 0, 0, 0.96, -20, -15)" }}>
                        <div style={styleNormalizer({transition:"transform 0.3s",  transform: `rotate(${props.direction}deg)`, width: "267px", height: "267px", transformOrigin: "center", position: "absolute" })}>
                            <img className={styles.compass_sageata} alt="icon" draggable="false" src={locatieBgr} />
                        </div>
                        <img className={styles.compass_ring} style={{}} alt="icon" draggable="false" src={locatieInel} />
                        <div className={styles.compass_values} style={{ fontFamily: "Oswald" }}>
                            <span>{direction}<sup>o</sup></span>
                            <div style={{ position: "relative", width: "100%", fontSize: "8px", color: "#E8E8E8" }}>{name}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.circular_div}>
                <div className={styles.circular_div_background}>

                    <div className="pyr_component" style={{ transform: "scale(0.8)", top: "-10px", left: "-10px" }} >
                        <div className="pyr_rotating_canvas" style={{ transition:"transform 0.3s",  transform: `rotate(${roll}deg) ` }}>
                            <div className="pyr_green" style={{ transition:"transform 0.3s",  transform: `translate(0px, ${movePitch}px)` }} />
                            <PitchScaleComponent key={props.direction} pitchValue={pitch} />

                        </div>
                    </div>
                    <img draggable="false" alt="pyr" src={AltitudeRing}
                        style={{ position: "absolute", left: "-12px", top: "-12px", width: "260px", height: "260px", transform: `rotate(${roll}deg) ` }}
                    />
                </div>
            </div>
            <div className={styles.circular_div}>
                <div className={styles.circular_div_background}>
                    <img style={{ width: "280px", height: "280px", left: "-26px", top: "-12px", position: "absolute" }} alt="icon" draggable="false" src={vitezaRing} />

                    <svg viewBox="-3 -3 208 208" width={200} height={200} style={{ position: "relative", top: "44px", left: "44px", width: "140px", height: "140px", transform: "rotate(-45deg)" }}>
                        <path
                            fill="none"
                            strokeWidth={8}
                            stroke="#12E5DD"
                            id="arc-path" />
                    </svg>

                    <div className={styles.compass_values} style={{ fontFamily: "Oswald", fontSize: "56px", color: "#FFFFFF", left: "0px", top: "-85px" }}>
                        <span style={{ color: "white", fontSize: "15px" }}>{Math.round(Number(props.speed) * 100) / 100}</span>
                        <div style={{ position: "relative", width: "100%", fontSize: "12px", top: "-7px", color: "#E8E8E8" }}>km/h</div>
                    </div>

                </div>
            </div>



        </div>
    );

}

export default CompassAndPYRComponent;