import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from '../css/devices.module.css'
import { useGesture } from '@use-gesture/react'
import { GetMaxZIndex } from '../utils/Tools'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import "@fontsource/roboto";

import { GlobalContext } from "../contexts/globalContext"
import RtspPlayerComponent from '../components/RtspPlayerComponent';
import JoystickComponent from '../components/JoystickComponent';
import CompassAndPYRComponent from './CompassAndPYRComponent';

import MSEPlayerComponent from '../components/MSEPlayerComponent';
import WebRTCPlayerComponent from '../components/WebRTCPlayerComponent';

import minimizeBttn from '../graphics/dashboard icons/icons meteo/btn-minimize.svg'
import locationBttn from '../graphics/dashboard icons/icons meteo/btn-location.svg'
import { StartAlert, StopAlert } from "../utils/RestServices"

import AlertActiv from '../graphics/sensor-activ.mp4'

import SensorNormal from '../graphics/sensor-normal.mp4'


//import ReactHlsPlayer from 'react-hls-player';

const FireDetectionComponent = (props) => {
    const ref = useRef();
    const nameRef = useRef();
    const [top, setTop] = useState(820);
    const [left, setLeft] = useState(620);
    const [yesNo, setYesNo] = useState(props.device.alerts.length > 0 ? true : false);

    const [width, setWidth] = useState(0);
    const [opacity, setOpacity] = useState(0);
    const [height, setHeight] = useState(0);
    const [pyr, setPyr] = useState([]);

    const [siteName, setSiteName] = useState();
    const [posX, setPosX] = useState()
    const [clickedTab, setClickedTab] = useState(0)

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const { sites, mapRef, setDevicesForFireDetection } = useContext(GlobalContext);

    useEffect(() => {
        //console.log(props.device)
        for (let i = 0; i < sites.length; i++) {
			if (sites[i]._id === props.device.site) {
				setSiteName(sites[i].name)
			}
		}

        if(mapRef.current.getBounds().contains([props.device.location[1], props.device.location[0]])){
            const position = mapRef.current.project([props.device.location[1], props.device.location[0]]);
            setLeft(position.x / oxDivider);
            setTop(position.y / oyDivider);
        } 

        setPosX(props.device.name.length * 15 )

        var new_height = 120;

        if (props.device.speed) {
            new_height +=10;
        }

        if (props.device.altitude) {
            new_height +=10;
        }

        if (props.device.direction) {
            new_height += 300;
        }

        
       

        if(props.device.name !== "CGT200" && props.device.device_type.name !== "Drona" && props.device.device_type.name !== "Drona DJI"){
            setWidth(600);
            setHeight(new_height);
        } else {
            setWidth(800);
            setHeight(910);
            if(!mapRef.current.getBounds().contains([props.device.location[1], props.device.location[0]])){
                ref.current.style.transformOrigin = `top left`
                ref.current.style.transform = `matrix(0.6, 0, 0, 0.6, ${left}, ${top})`
            }
        }
        setOpacity(1)
        
    }, [])



    useEffect(() => {
        if (props.device.data) {
            Object.keys(props.device.data).map((param) => {
                if (getParameterType(param) == "PYR") {
                    setPyr(props.device.data[param])
                }
            });
        }
    }, [props.device])


    const getParameterName = code => {
        let res = props.parameters.filter(a => a.code === code);
        return res[0].name;
    }

    const getParameterType = code => {
        let res = props.parameters.filter(a => a.code === code);
        return res[0].type;
    }

    const getParameterUM = code => {
        let res = props.parameters.filter(a => a.code === code);
        return res[0].um;
    }

    const formatValue = value => {
        if (typeof value == "boolean") {
            value = (value)?"DA":"NU";
        }
        //let res = props.parameters.filter(a => a.code === code);
        return value;
    }
   

    const getComponentForParameter = (param) => {
        <span key={param} className={styles.device_component_properties}><b>{getParameterName(param)}:</b>   {formatValue(props.device.data[param])} {getParameterUM(param)}</span>
    }


    const goToDevice = drone => {
        mapRef.current.flyTo({
            center: [drone.location[1], drone.location[0]],
            zoom: 18,
            essential: true
        });
    };

    return (     
        <div className={styles.div_common} ref={ref} style={{ transform: `matrix(1, 0, 0, 1, ${left}, ${top})`, fontFamily: 'Roboto', fontStyle: 'Roboto', opacity: opacity }}>
                 <span className={styles.labelType} >{props.device.device_type.name} </span>

                 <span className={styles.labelAlert} >{props.device.name} </span>

                 <span className={styles.labelAlertLocation} > Location </span>
                 
                 <div className={styles.positionAlert}>
                    <span className={styles.posLat}>
                        {parseFloat(props.device.location[0]).toFixed(9)}
                    </span>

                    <span className={styles.posLong}>
                        {parseFloat(props.device.location[1]).toFixed(9)}
                    </span>
                </div>

                <span className={styles.detectedLabel}>Intrusion detected</span>

                <video
                    className={`${styles.videoAnimationAlert}`}
                    playsInline autoPlay loop
                >
                    <source src={ yesNo ? AlertActiv : SensorNormal}
                    type="video/mp4" />
                </video>

                
                    <label className={styles.yes_no_label} style={{ color: yesNo ? '#EC4828' : '#12E5DD'}}> {yesNo ? "YES" : "NO"} </label>

                {props.device.alerts.length > 0 && 
                <div className={styles.buttonAlert} onTouchEnd={(event) => {
                            event.stopPropagation();
                            console.log('here');
                            setYesNo(!yesNo);
                            StopAlert();
                            setDevicesForFireDetection(devicesForFireDetection => devicesForFireDetection.filter(a => a._id !== props.device._id));
                        }}>
                <span className={styles.labelButtonAlert}>
                        Stop Alert
                    </span>
                </div> }

                {props.device.alerts.length == 0 && 
                <div className={styles.buttonAlert} style={{ background: 'transparent linear-gradient(90deg, #DD0000 0%, #EF5731 100%) 0% 0% no-repeat padding-box', boxShadow: '0px 0px 16px #E0250080' }} onTouchEnd={(event) => {
                    event.stopPropagation();
                    console.log('here');
                    setYesNo(!yesNo);
                    StartAlert();
                    setDevicesForFireDetection(devicesForFireDetection => devicesForFireDetection.filter(a => a._id !== props.device._id));
                }}>
                <span className={styles.labelButtonAlert}>
                        Start Alert
                    </span>
                </div> } 
        </div> 
        
    );
}

export default FireDetectionComponent;