import React, { useEffect, useRef, useState } from 'react';
import styles from '../css/dashboard.module.css'
import "@fontsource/roboto";
import "@fontsource/oswald/300.css"

import LocationBtn from '../../../graphics/dashboard icons/new icons/btn-location.svg'
import ExpandBtn from '../../../graphics/dashboard icons/new icons/btn-expand.svg'
import DroneImage1 from '../../../graphics/dashboard icons/drona1.png'
import DroneImage2 from '../../../graphics/dashboard icons/drona2.png'
import DroneImage3 from '../../../graphics/dashboard icons/drona3.png'
import Compass40 from '../../../graphics/dashboard icons/-40-Current View.png'
import Sageata from '../../../graphics/dashboard icons/pointer.svg'
import Baterie from '../../../graphics/dashboard icons/battery.svg'

import Lottie from 'react-lottie-player'
import lottieJson from '../../../graphics/dashboard icons/item-lista-activ.json'
import lottieJsonReady from '../../../graphics/dashboard icons/item-lista-ready.json'
import lottieJsonOffline from '../../../graphics/dashboard icons/item-lista-offline.json'

import CircleProgressBar from './CircleProgressBar';



const jsonDataActiv = [
	{
		id: "1hq",
		name: "DJI 6201",
		site: "ISU Brasov",
		viteza: "60",
		altitudine: "140",
		baterie: "64"
	},
	{
		id: "2ns",
		name: "CGT205",
		site: "ISU Brasov",
		viteza: "80",
		altitudine: "2340",
		baterie: "99"
	},
	{
		id: "3cl",
		name: "DJI 6231",
		site: "Ghimbav",
		viteza: "120",
		altitudine: "4517",
		baterie: "87"
	}
];

const jsonDataReady = [
	{
		id: "1gp",
		name: "CGT215",
		site: "ISU Cristian"
	},
	{
		id: "2nd",
		name: "DJI 6241",
		site: "ISU Brasov"
	}
]

const jsonDataOffline = [
	{
		id: "1sl",
		name: "CGT265",
		site: "ISU Brasov"
	},
	{
		id: "2hc",
		name: "DJI 6250",
		site: "ISU Brasov"
	}
]


const DroneList = (props) => {
	const { status } = props
	const [vehicles, setVehicles] = useState(status === "activ" ? jsonDataActiv : (status === "ready" ? jsonDataReady : jsonDataOffline));

	return (
		<div style={{ display: "flex", flexDirection: 'column', top: '10px', position: "relative" }}>
			{vehicles.map((vehicle) => (
				<DroneItemJson status={status} key={vehicle.id} {...vehicle} />
			))}
		</div>
	);
};

export const DroneItemJson = ({ status, id, name, site, viteza, altitudine, baterie }) => {
	const nameRef = useRef()
	
	const [expandDetails, setExpandDetails] = useState(false);
	const [bordValuesSpeed, setBordValuesSpeed] = useState()
	const [speed, setSpeed] = useState()
	const [bordValuesAltitude, setBordValuesAltitude] = useState()
	const [altitude, setAltitude] = useState()
	const [posX, setPosX] = useState(0)

	const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;

	useEffect(() => {
		const parsedValue = parseFloat(viteza);
		const remainder = parsedValue % 20;
		let result;
		if (remainder <= 10) {
			result = parsedValue - remainder;
		} else {
			result = parsedValue + (20 - remainder);
    	}
		if(result !== speed){
			setSpeed(result)
        	if(result >= 60){
				setBordValuesSpeed([result + 60, result + 40, result + 20, result, result - 20, result - 40, result - 60])
			} else if(result === 40){
				setBordValuesSpeed([result + 60, result + 40, result + 20, result, result - 20, result - 40])
			} else if(result === 20){
				setBordValuesSpeed([result + 60, result + 40, result + 20, result, result - 20])
			} else{
				setBordValuesSpeed([result + 60, result + 40, result + 20, result])
			}
		}
    }, [viteza])

	useEffect(() => {
		const parsedValue = parseFloat(altitudine);
		const remainder = parsedValue % 20;
		let result;
		if (remainder <= 10) {
			result = parsedValue - remainder;
		} else {
			result = parsedValue + (20 - remainder);
    	}
		if(result !== altitude){
			setAltitude(result)
        	if(result >= 60){
				setBordValuesAltitude([result + 60, result + 40, result + 20, result, result - 20, result - 40, result - 60])
			} else if(result === 40){
				setBordValuesAltitude([result + 60, result + 40, result + 20, result, result - 20, result - 40])
			} else if(result === 20){
				setBordValuesAltitude([result + 60, result + 40, result + 20, result, result - 20])
			} else{
				setBordValuesAltitude([result + 60, result + 40, result + 20, result])
			}
		}
    }, [altitudine])

	useEffect(() => {
		
		if(nameRef.current){
			setPosX(nameRef.current.getBoundingClientRect().width / oxDivider + 15)
		}
    }, [])



	return (
		<div className={`${expandDetails? styles.vehicleDiv_expand : styles.vehicleDiv}`} 
			onTouchEnd={() => {
				if(status === "activ")
					setExpandDetails(!expandDetails)
			}}>
			<Lottie
				className={`${styles.activAnimation}`}
				loop
				animationData={status === "activ" ? lottieJson : (status === "ready" ? lottieJsonReady : lottieJsonOffline)}
				play
			/>
			<label ref={nameRef} className={`${status === "offline" ? styles.nameLabel_offline : styles.nameLabel}`}> {name} </label>
			<div className={`${status === "activ" ? styles.labelDiv_live : (status === "ready" ? styles.labelDiv_copy : styles.labelDiv_offline) }`} style={{transform:`matrix(1, 0, 0, 1, ${posX}, 0)`}}>
				<label className={`${status === "activ" ? styles.labelLive : (status === "ready" ? styles.labelActivVehicle : styles.labelOffline)}`} > {status === "activ" ? 'Live' : (status === "ready" ? 'Ready' : 'Offline')} </label>
			</div>
			<label className={`${styles.siteLabel}`}> {site} </label>
			
			<div className={`${styles.btnsDiv_copy}`}>
				<img className={`${styles.btn_copy}`} style={{opacity: `${status === "activ" ? 1 : 0.5}`}} alt="icon" draggable="false" src={LocationBtn} />
				<img className={`${styles.btn_copy}`} style={{opacity: `${status === "activ" ? 1 : 0.5}`}} alt="icon" draggable="false" src={ExpandBtn}/>
			</div>
			{expandDetails && <div className={`${styles.drone_expand}`}>
				<div className={styles.drona_baterie} style={{fontFamily:'Roboto', fontStyle: 'Roboto'}}>{baterie}</div>
				<CircleProgressBar percentage={baterie}/>
				<img className={styles.baterie_img} alt="baterie" draggable="false" src={Baterie} />
				<img className={styles.drona_img} alt="drona" draggable="false" 
					src={id === "1hq" ? DroneImage1 : (id === "2ns" ? DroneImage2 : DroneImage3 )} 
				/>
				<div className={styles.drona_baterie} style={{ fontFamily: 'Roboto', fontStyle: 'Roboto', opacity: "0.3" }}>{baterie}</div>
				<div className={styles.specs_container}>
					<div className={styles.specs_title} style={{fontFamily:'Roboto', fontStyle: 'Roboto', fontSize:"10px"}}>Altitudine</div>
					<div style={{position:"absolute", top:"10px", left:"0px", overflow:"hidden", width:"60px", height:"118px"}}>
						{bordValuesAltitude.map((value) => (
							<div key={value}>
							<div className={styles.specs_board} style={{fontFamily:'Roboto', fontStyle: 'Roboto'}}>{value}</div>
							<div className={styles.specs_line}></div>
							</div>
						))}
						<div className={styles.specs_value} style={{fontFamily:'Roboto', fontStyle: 'Roboto'}}><b>{altitudine}</b></div>
						<div className={styles.specs_unit} style={{fontFamily:'Roboto', fontStyle: 'Roboto'}}>m</div>
						<img className={styles.specs_sageata} alt="sageata" draggable="false" src={Sageata}/>
						<div className={styles.specs_shadow1}/>
						<div className={styles.specs_shadow2}/>
					</div>
				</div>
				<div className={styles.specs_container} style={{left:"275px"}}>
					<div className={styles.specs_title} style={{fontFamily:'Roboto', fontStyle: 'Roboto', fontSize:"10px"}}>Viteza</div>
					<div style={{position:"absolute", top:"10px", left:"0px", overflow:"hidden", width:"60px", height:"118px"}}>
						{bordValuesSpeed.map((value) => (
							<div key={value}>
							<div className={styles.specs_board} style={{fontFamily:'Roboto', fontStyle: 'Roboto'}}>{value}</div>
							<div className={styles.specs_line}></div>
							</div>
							
						))}
						<div className={styles.specs_value} style={{fontFamily:'Roboto', fontStyle: 'Roboto'}}><b>{viteza}</b></div>
						<div className={styles.specs_unit} style={{fontFamily:'Roboto', fontStyle: 'Roboto'}}>km/h</div>
						<img className={styles.specs_sageata} alt="sageata" draggable="false" src={Sageata}/>
						<div className={styles.specs_shadow1}/>
						<div className={styles.specs_shadow2}/>
					</div>
				</div>
				<img className={styles.compass_img} alt="compass" draggable="false" src={Compass40} />
				<div className={styles.compass_img}>
					<div className={styles.compass_directions} style={{fontSize:"15px", fontFamily:"Oswald", top:"-17px"}}>N</div>
					<div className={styles.compass_directions} style={{fontSize:"15px", fontFamily:"Oswald", top:"32px", right:"-55px"}}>E</div>
					<div className={styles.compass_directions} style={{fontSize:"15px", fontFamily:"Oswald", top:"90px"}}>S</div>
					<div className={styles.compass_directions} style={{fontSize:"15px", fontFamily:"Oswald", top:"32px", left:"-55px"}}>W</div>
				</div>
			</div>}
			
		</div>
	);
};

export default DroneItemJson;