import React, { useRef, useContext, useEffect } from 'react';
import styles from '../css/browser.module.css'
import { useGesture } from '@use-gesture/react'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import { GetMaxZIndex } from '../utils/Tools'

import { GlobalContext } from "../contexts/globalContext"

const BrowserComponent = (props) => {
    const ref = useRef(null);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const { setBrowserMenuOpened } = useContext(GlobalContext);
 
    useEffect(() => {
        if(ref.current){
            ref.current.style.zIndex = GetMaxZIndex() + 1;
        }
    }, []);

    useGesture(
        {
            onDrag: ({ pinching, cancel, delta: [dx, dy], first, memo }) => {
                if (pinching) {
                    return cancel()
                }
                if (first) {
                    ref.current.style.zIndex = GetMaxZIndex() + 1;
                }

                var mat = identity();
                
                if (ref.current.style.transform !== '') {
                    mat = fromString(ref.current.style.transform);
                }
    
                var trans = translate(dx, dy);
                var final = compose(trans, mat);
                ref.current.style.transform = toString(final);

                return memo
            },
            onPinch: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
                if (first) {
                    memo = [ox, oy, a, s]
                } else {
                    const { width, height, x, y } = ref.current.getBoundingClientRect()
    
    
                    // apply translation to center the element at pinch origin
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((ox - memo[0]) / oxDivider, (oy - memo[1]) / oyDivider);
    
                    // apply inverse transformation to find the rotated pinch origin
                    var inv = inverse(mat);
                    var pointCentruTranslatat = applyToPoint(inv, [(width / 2 + x) / oxDivider, (height / 2 + y) / oyDivider]);
                    var diffCenterX = pointCentruTranslatat[0] - (ref.current.offsetWidth / 2);
                    var diffCenterY = pointCentruTranslatat[1] - (ref.current.offsetHeight / 2);
    
                    // convert the pinch origin to the original scale
                    var pointOx = applyToPoint(inv, [ox / oxDivider, oy / oyDivider]);
    
                    // calculate the point to apply transformations
                    var point = applyToPoint(mat, [pointOx[0] - diffCenterX - ref.current.offsetWidth / 2, pointOx[1] - diffCenterY - ref.current.offsetHeight / 2]);
    
                    // apply rotation and scaling
                    var rotate = rotateDEG(a - memo[2], point[0], point[1]);
                    var scaleFunction = scale((s / memo[3]), (s / memo[3]), point[0], point[1]);
    
                    var final = compose(rotate, scaleFunction, trans, mat);
                    ref.current.style.transform = toString(final);
    
                    if (s <= 0.5) {
                        setBrowserMenuOpened(false);
                    }
                    
                    memo = [ox, oy, a, s]
                }
                return memo
            },
            onPinchEnd: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
                const { width, height, x, y } = ref.current.getBoundingClientRect();

                if (x + width /2 < 0){
                    ref.current.className = styles.browser_component_anim;

                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((-(x + width /2) ) / oxDivider, 0);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.browser_component;
                    }, 500);

                }

                if (x + width /2 > window.innerWidth){
                    ref.current.className = styles.browser_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(- ((x + width /2) - window.innerWidth) / oxDivider, 0);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.browser_component;
                    }, 500);
                }

                if (y + height /2 < 0){
                    ref.current.className = styles.browser_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(0, (-(y + height /2) ) / oyDivider);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.browser_component;
                    }, 500);
                }

                if (y + height /2 > window.innerHeight){
                    ref.current.className = styles.browser_component_anim;
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate(0, - ((y + height /2) - window.innerHeight) / oyDivider);
                    var final = compose(trans, mat);
                    ref.current.style.transform = toString(final);

                    setTimeout(() => {
                        ref.current.className = styles.browser_component;
                    }, 500);
                }

            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
            pinch: { scaleBounds: { min: 0.5, max: 4 }, rubberband: true },
        })

    return (
        <div className={styles.browser_component} ref={ref} style={{transform: `matrix(1, 0, 0, 1, ${props.x}, ${props.y-props.height})`, width: `${props.width}px`, height: `${props.height}px`, padding:"70px" }}>
            <iframe src="https://cms.internal.nextfusion.ro" style={{ width: `${props.width }px`, height: `${props.height}px`, borderRadius:"20px" }}></iframe>
        </div>
    );

}

export default BrowserComponent;