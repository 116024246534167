import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './styles.module.css'
import Lottie from 'react-lottie-player'
import "@fontsource/roboto";

import lottieJson from './sip_components/dialer_button_svg/animatie voce.json'
import undeCall from './sip_components/dialer_button_svg/unda mica.json'

import numpad from './sip_components/dialer_button_svg/call-numpad.svg'
import answerReject from './sip_components/dialer_button_svg/answer-reject.svg'
import mic from './sip_components/dialer_button_svg/mic.svg'
import volum from './sip_components/dialer_button_svg/volum.svg'

import CallingPhoneIcon from '../../graphics/phone/callingPhone.png';
import PhoneIcon from '../../graphics/phone/phone.js';
import CallDecisionComponent from './CallDecisionComponent.js';
import { GlobalContext } from "../../contexts/globalContext";
import { ApiAddCallToHistory } from "../../utils/RestServices.js"
import DialerButton from './sip_components/dialer_button.js';

//animation
// import buttonAnimation from '../../graphics/animations/sm-button-tap.json'

const DialerComponent = (props) => {
    const [callStatus, setCallStatus] = useState("");
    const [callDirection, setCallDirection] = useState("");

    const [speaker, setSpeaker] = useState(null);

    const [number, setNumber] = useState("");
    const [callerName, setCallerName] = useState("");

    const [answerBlur, setAnswerBlur] = useState(0);
    const [rejectBlur, setRejectBlur] = useState(0);



    const { currentUser } = useContext(GlobalContext);



    //animation
    const [isPlayingMediaAnim, setIsPlayingMediaAnim] = useState(false);
    const [segmentAnim, setSegmentAnim] = useState(0);

    useEffect(() => {
        if (props.selectedUser) {
            setNumber(props.selectedUser.number);
            setCallerName(props.selectedUser.name);
        }

        setIsPlayingMediaAnim(false);

    }, [props.selectedUser]);

    // callDirection/OUTGOING
    useEffect(() => {
        setCallStatus(props.providerRef.current.state.callStatus);

        if (props.providerRef.current.state.callStatus == "callStatus/ACTIVE") {
            var aux_speaker = speaker;
            aux_speaker.startTime = Date.now();
            aux_speaker.answered = true;
            setSpeaker(aux_speaker);
        } else if (props.providerRef.current.state.callStatus == "callStatus/IDLE" && speaker != null) {
            var aux_speaker = speaker;
            var time = 0;
            if (aux_speaker.answered) {
                time = Date.now() - aux_speaker.startTime;
            }
            var body = JSON.stringify({ user_id: currentUser._id, name: speaker.name, number: speaker.number, incoming: speaker.incoming, answered: speaker.answered, time });

            ApiAddCallToHistory(body, data => {
                currentUser.callsHistory = data;
            });

            setSpeaker(null);

        }
    }, [props.providerRef.current?.state.callStatus]);


    useEffect(() => {
        //console.log(props.providerRef.current.state);
        setCallDirection(props.providerRef.current.state.callDirection);
        if (props.providerRef.current.state.callDirection == "callDirection/INCOMING") {
            let startIndex = props.providerRef.current.state.callCounterpart.indexOf("<")
            let callerNameAux = props.providerRef.current.state.callCounterpart.substring(1, startIndex - 2);

            startIndex = props.providerRef.current.state.callCounterpart.indexOf(":");
            let endIndex = props.providerRef.current.state.callCounterpart.indexOf("@");

            // console.log(startIndex);
            // console.log(endIndex);

            let number = props.providerRef.current.state.callCounterpart.substring(startIndex + 1, endIndex);
            // console.log(number);

            setSpeaker({ name: callerNameAux, number, incoming: true, answered: false });
        }
    }, [props.providerRef.current?.state.callDirection]);

    const onCall = () => {

        if (number.startsWith("07")) {
            props.providerRef.current.startCall("9" + number);
        } else {
            props.providerRef.current.startCall(number);
        }

        setSpeaker({ name: callerName ? callerName : "Numar Necunoscut", number, incoming: false, answered: false });
    };



    const onAnswer = () => {
        //setNumber(number.substring(0, number.length - 1));
        props.providerRef.current.answerCall();
    };

    const onReject = () => {
        console.log('rej')
        props.providerRef.current.stopCall();
    };


    const onKeyPressed = (event) => {

        setIsPlayingMediaAnim(true);

        if (number.length < 13) {
            var aux_number = number + event.target.innerText;
            setNumber(aux_number);

            createCallerName(aux_number);
        }
    };

    function createCallerName(aux_number) {
        var name = "";

        if (aux_number == "*43") {
            name = "Echo Test";
        } else {
            var foundContact = currentUser.contacts.find((contact) => (contact.internal === aux_number && contact.internal != "") || (contact.number === aux_number && contact.number != ""));
            if (foundContact) {
                name = foundContact.name;
            }
        }
        setCallerName(name);
    }

    return (


        <div style={{fontFamily: 'Roboto', fontStyle: 'Roboto',  display: 'flex', flexDirection: 'column', background: 'transparent', width: "269px", height: "460px", transform:"matrix(0.93, 0, 0, 0.93, -12, 0)", transformOrigin:"top left" }}>
            <div className={styles.input_number}>
                {number}
            </div>

            <div style={{ position: "absolute", color: "#A9A9AB", textAlign: "center", width:"100%", top:"50px", fontSize:"11px" }}>{callerName}</div>

            <table >
                <tbody>
                    <tr >
                        <td>
                            <DialerButton digit={1} number={number} setNumber={setNumber} createCallerName={createCallerName} />
                        </td>
                        <td>
                            <DialerButton digit={2} number={number} setNumber={setNumber} createCallerName={createCallerName} />
                        </td>
                        <td>
                            <DialerButton digit={3} number={number} setNumber={setNumber} createCallerName={createCallerName} />
                        </td>
                    </tr>
                    <tr >
                        <td>
                            <DialerButton digit={4} number={number} setNumber={setNumber} createCallerName={createCallerName} />
                        </td>
                        <td>
                            <DialerButton digit={5} number={number} setNumber={setNumber} createCallerName={createCallerName} />
                        </td>
                        <td>
                            <DialerButton digit={6} number={number} setNumber={setNumber} createCallerName={createCallerName} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <DialerButton digit={7} number={number} setNumber={setNumber} createCallerName={createCallerName} />
                        </td>
                        <td>
                            <DialerButton digit={8} number={number} setNumber={setNumber} createCallerName={createCallerName} />
                        </td>
                        <td>
                            <DialerButton digit={9} number={number} setNumber={setNumber} createCallerName={createCallerName} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <DialerButton digit={0} number={number} setNumber={setNumber} createCallerName={createCallerName} isClearBtn={true} /> 
                        </td>
                        <td>
                            <DialerButton digit={0} number={number} setNumber={setNumber} createCallerName={createCallerName} />
                        </td>
                        <td>
                            <DialerButton digit={0} number={number} setNumber={setNumber} createCallerName={createCallerName} isBackBtn={true} />

                        </td>
                    </tr>
                    <tr>

                        <td colSpan={3}>
                            {number ?
                                <div className={styles.call_button} style={{opacity:"1",marginTop: "15px", marginRight: "5px", background: "transparent linear-gradient(91deg, #18BEDD 0%, #9ED688 100%) 0% 0% no-repeat padding-box" }} onTouchEnd={() => onCall()}>
                                    <PhoneIcon fillColor="black"></PhoneIcon>
                                </div>
                                :
                                <div className={styles.call_button} style={{ marginRight: "5px", marginTop: "15px" }}>
                                    <PhoneIcon fillColor="#878688"></PhoneIcon>
                                </div>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            {callStatus === "callStatus/STARTING" && callDirection === "callDirection/OUTGOING" &&
                <div className={styles.calling_div}>
                    <span style={{ color: "#A9A9AB" }}>Apelează</span><br />
                    <span style={{ color: "white", fontSize: "30px"  }}>{speaker?.number}</span><br />
                    <span style={{ color: "#A9A9AB" }}>{speaker?.name}</span><br />
                    <div className={styles.background_green_call} style={{left: "50%", top:"50%", transform:"translate(-50%,-50%)", position: "absolute" }}/>
                    <Lottie
                        loop
                        animationData={undeCall}
                        play
                        style={{
                            left: "50%", top:"50%", transform:"translate(-50%,-50%)", position: "absolute", 
                            width: "262px", height: "262px"
                        }}
                    />
                    
                    <img alt ={"iconita call"} draggable={false} src={CallingPhoneIcon} 
                    style={{
                        left: "50%", top:"50%", transform:"translate(-50%,-50%)", position: "absolute", 
                        width: "262px", height: "262px"
                    }}/>


                    <div className={styles.stop_button} onTouchEnd={() => onReject()}>
                        <PhoneIcon fillColor="white" angle="0"/>
                    </div>
                </div>
            } 

            {callStatus === "callStatus/ACTIVE" &&
                <div className={styles.calling_div}>
                    <span style={{ color: "#A9A9AB" }}>Apel în curs</span><br />
                    <span style={{ color: "white", fontSize: "30px"  }}>123{speaker?.number}</span><br />
                    <span style={{ color: "#A9A9AB" }}>Mada{speaker?.name}</span><br />
                    <Lottie
                        loop
                        animationData={lottieJson}
                        play
                        style={{
                            left: "50%", top:"52%", transform:"translate(-50%,-50%)", position: "absolute", 
                            width: "190px", height: "190px"
                        }}
                    />
                    <div className={styles.background_green_call_incall} style={{left: "50%", top:"52%", transform:"translate(-50%,-50%)", position: "absolute", }}/>

                    <img draggable={false} alt={"numpad"} src={numpad} 
                        style={{
                            left: "50%", top:"52%", transform:"translate(-50%,-50%)", position: "absolute", 
                            width: "20px", height: "20px"
                        }}
                    />
                    <div className={styles.stop_button} style={{ marginRight: "5px", marginTop: "30px"}} onTouchEnd={() => onReject()}>
                        <PhoneIcon fillColor="white" angle="135" style={{ transform: "rotate(135deg)" }}></PhoneIcon>
                    </div>
                    <div className={styles.buton_call_sunet_div} >
                        <img className={styles.buton_call_sunet} draggable={false} src={mic}/>
                    </div>
                    <div className={styles.buton_call_sunet_div} style={{top:"81%"}}>
                        <img className={styles.buton_call_sunet} draggable={false} src={volum}/>
                    </div>
                </div>
            } 

            {callStatus === "callStatus/STARTING" && callDirection === "callDirection/INCOMING" &&
                <div className={styles.calling_div}>
                    <span style={{ color: "#A9A9AB" }}>Te apelează</span><br />
                    <span style={{ color: "white", fontSize: "30px"  }}>{speaker?.number}</span><br />
                    <span style={{ color: "#A9A9AB" }}>{speaker?.name}</span><br />
                    {/* <div className={styles.background_green_call} style={{left: "50%", top:"57%", transform:"translate(-50%,-50%)", position: "absolute" }}/> */}
                    
                    <img alt ={"iconita call"} draggable={false} src={answerReject} style={{left: "50%", top:"57%", transform:"translate(-50%,-50%)", position: "absolute", width: "53px", height: "327px"}}/>
                    <div className={styles.background_red_reject} style={{left: "50%", top:"85%", transform:"translate(-50%,-50%)", position: "absolute" }}/>
                    <div className={styles.background_green_answer} style={{left: "50%", top:"30%", transform:"translate(-50%,-50%)", position: "absolute" }}/>
                    
                    {/* <div className={styles.answerCircle} style={{ boxShadow: `0 0 50px ${answerBlur}px #7c9b8f` }}></div>
                    <div className={styles.answerCircle2}></div> */}
                    {/* <img src={AnswerImage} height="300px" style={{marginTop:"25px", marginBottom:"25px"}}></img> */}

                    {/* <div className={styles.rejectCircle} style={{ boxShadow: `0 0 50px ${rejectBlur}px #9e4a4a` }}></div>
                    <div className={styles.rejectCircle2}></div> */}

                    <PhoneIcon fillColor="white" angle="135" style={{ position: "absolute", left: "50.5%", top:"30%", transform:"translate(-50%,-50%) scale(0.85)" }}></PhoneIcon>
                    <PhoneIcon fillColor="white" angle="135" style={{ position: "absolute", left: "50.5%", top: "85%", transform: "translate(-50%,-50%) scale(0.85) rotate(135deg)" }}></PhoneIcon>

                    <CallDecisionComponent setAnswerBlur={setAnswerBlur} setRejectBlur={setRejectBlur} onAnswer={onAnswer} onReject={onReject}></CallDecisionComponent>
                </div>
            } 

        </div>


    );

}

export default DialerComponent;