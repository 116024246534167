import Sunny from '../../../graphics/dashboard icons/wi-day-sunny.svg'
import Cloudy from '../../../graphics/dashboard icons/wi-cloudy.svg'
import Humidity from '../../../graphics/dashboard icons/wi-humidity.svg'
import Expand from '../../../graphics/dashboard icons/new icons/btn-expand.svg'
import Wind from '../../../graphics/dashboard icons/wi-strong-wind.svg'
import Precipitation from '../../../graphics/dashboard icons/wi-raindrop.svg'
import WindDirection from '../../../graphics/dashboard icons/wi-wind-deg.svg'
import SunnyDay from '../../../graphics/dashboard icons/photos/day-sunny.png'
import CloudyDay from '../../../graphics/dashboard icons/photos/wi-cloudy.png'
import Rain from '../../../graphics/dashboard icons/wi-rain.svg'
import RainyDay from '../../../graphics/dashboard icons/photos/wi-rain.png'
import meteoStyles from '../css/meteolist.module.css'

import styles from '../css/dashboard.module.css'

import { useState, useRef, useEffect, useContext } from 'react'

import Lottie from 'react-lottie-player'
import lottieJson from '../../../graphics/dashboard icons/item-lista-activ.json'
import lottieJsonReady from '../../../graphics/dashboard icons/item-lista-ready.json'
import lottieJsonOffline from '../../../graphics/dashboard icons/item-lista-offline.json'


import LocationBtn from '../../../graphics/dashboard icons/new icons/btn-location.svg'

import RainMP4 from '../../../graphics/dashboard icons/icons meteo/wi-rain.mp4'
import CloudyMP4 from '../../../graphics/dashboard icons/icons meteo/wi-cloudy.mp4'
import DaySunny from '../../../graphics/dashboard icons/icons meteo/day-sunny.mp4'
import { GlobalContext } from "../../../contexts/globalContext";


const MeteoItem = ({ meteo }) => {
    const [expandDetails, setExpandDetails] = useState(false);
    const { jsonForMeteoExpand, setJsonForMeteoExpand, mapRef } = useContext(GlobalContext);
    const [isActiv, setIsActiv] = useState(meteo.status === 'live' ? true : false);


    const nameRef = useRef()
    const [posX, setPosX] = useState(0)

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;


    const goToDevice = meteo => {
        if (meteo.pointLat || meteo.pointLong) {
            mapRef.current.flyTo({
                center: [meteo.pointLat, meteo.pointLong],
                zoom: 18,
                essential: true
            });
        }
    };

    function isRaining(humidity, precipitation) {
        const humidityThreshold = 80; 
        const precipitationThreshold = 0.1; 
    
        if (humidity > humidityThreshold && precipitation > precipitationThreshold) {
            return true; 
        } else {
            return false;
        }
    }
    

    useEffect(() => {
        if (nameRef.current) {
            setPosX(nameRef.current.getBoundingClientRect().width / oxDivider + 15)
        }
    }, [])

    // useEffect(() => {
    //     console.log(jsonForMeteoExpand)
    // }, [jsonForMeteoExpand])

    return (
        <div className={`${expandDetails && isActiv ? styles.vehicleDiv_expand : styles.vehicleDiv}`}
            onTouchEnd={(event) => { isActiv && setExpandDetails(!expandDetails) }}
        >
            <Lottie
                className={`${styles.activAnimation}`}
                loop
                animationData={isActiv ? lottieJson : (meteo.status === "ready" ? lottieJsonReady : lottieJsonOffline)}
                play
            />
            <label ref={nameRef} className={`${meteo.status === "offline" ? styles.nameLabel_offline : styles.nameLabel}`} style={{ fontSize: "16px" }}> {meteo.location} </label>
            <div className={`${styles.labelDiv_live}`} style={{ transform: `matrix(1, 0, 0, 1, ${posX}, 0)`,
                                                                width: (() => {
                                                                    if (meteo.status === 'live') return '34px';
                                                                    else if (meteo.status === 'ready') return '50px';
                                                                    else return '45px';
                                                                })()
                                                            }}>
                <label className={`${isActiv ? styles.labelLive : (meteo.status === "ready" ? styles.labelActivVehicle : styles.labelOffline) }`} >
                    {meteo.status}
                </label>
            </div>
            <label className={`${styles.siteLabel}`} style={{ fontSize: "12px", }}> Statie Meteo </label>

            {!expandDetails &&
                <>
                    <div className={`${styles.meteoTipStart}`}>
                        {meteo.status !== 'live' && <img className={`${styles.btn}`} style={{ width: '24px', left: '314.25px', top: '11px' }} alt="icon" draggable="false"
                            src={(() => {
                                if(meteo.status === 'live') {
                                        return Rain;
                                } else {
                                    switch (meteo.weather) {
                                        case 'sunny':
                                            return Sunny;
                                        case 'cloudy':
                                            return Cloudy;
                                        case 'rain':
                                            return Rain;
                                        default:
                                            return Sunny;
                                    }
                                }
                            })()} />}
                    </div>
                    <label className={`${meteoStyles.celsiusStart}`} style={{ fontSize: '23px' }}> {meteo.celsius} </label>
                </>}
            <div className={`${styles.btnsDiv_copy}`}>
                <img className={`${styles.btn_copy}`} alt="icon" draggable="false" onTouchEnd={(event) => {
                    event.stopPropagation()
                    goToDevice(meteo)
                }} src={LocationBtn} />
                <img className={`${styles.btn_copy}`} alt="icon" draggable="false" src={Expand}
                    onTouchEnd={(event) => {
                        event.stopPropagation()
                        var alreadyExists = jsonForMeteoExpand.filter(a => a.id === meteo.id);
                        if (alreadyExists.length > 0) {
                            setJsonForMeteoExpand(jsonForMeteoExpand => jsonForMeteoExpand.filter(a => a.id !== meteo.id));
                        } else {
                            setJsonForMeteoExpand([...jsonForMeteoExpand, meteo]);
                        }
                    }} />
            </div>
            {expandDetails &&
                <div className={styles.drone_expand}>
                    <div style={{ top: '42px', left: '15px', position: 'absolute' }}>
                    {meteo.status !== 'live' && <img className={`${meteoStyles.meteoTip}`} alt="icon" draggable="false" src={(() => {
                            switch (meteo.weather) {
                                case 'sunny':
                                    return Sunny;
                                case 'cloudy':
                                    return Cloudy;
                                case 'rain':
                                    return Rain;
                                default:
                                    return Sunny;
                            }
                        })()} /> }
                    </div>
                    <div className={`${meteoStyles.divCelsius}`}>
                        <label className={`${meteoStyles.valCelsius}`}> {meteo.celsius} </label>
                        <div className={`${meteoStyles.lineCelsius}`}></div>
                        {meteo.status !== 'live' && <>
                        <label className={`${meteoStyles.labelMaxCelsius}`}> {meteo.max} </label>
                        <label className={`${meteoStyles.labelMinCelsius}`}> {meteo.min} </label>
                        </>}
                    </div>
                    <div className={`${meteoStyles.divHumidity}`}>
                        <img className={`${meteoStyles.IconWeather}`} style={{ height: '12px' }} alt="icon" draggable="false" src={Humidity} />
                        <label className={`${meteoStyles.labelOptions}`} style={{ left: '21px', top: '6px' }}> {meteo.humidity} </label>
                    </div>
                    <div className={`${meteoStyles.divWind}`}>
                        <img className={`${meteoStyles.IconWeather}`} style={{ height: '12px' }} alt="icon" draggable="false" src={Wind} />
                        <label className={`${meteoStyles.labelOptions}`} style={{ left: '20px', top: '6px' }}> {meteo.wind} </label>
                    </div>
                    <div className={`${meteoStyles.divPrecipitation}`}>
                        <img className={`${meteoStyles.IconWeather}`} style={{ height: '12px' }} alt="icon" draggable="false" src={Precipitation} />
                        <label className={`${meteoStyles.labelOptions}`} style={{ left: '21px', top: '6px' }}> {meteo.precipitation} </label>
                    </div>
                    <div className={`${meteoStyles.divNen}`}>
                        <img className={`${meteoStyles.IconWeather}`} style={{ height: '12px', transform: 'rotate(45deg)' }} alt="icon" draggable="false" src={WindDirection} />
                        <label className={`${meteoStyles.labelOptions}`} style={{ left: '21px', top: '6px' }}> {meteo.windDirection} </label>
                    </div>
                    {meteo.status !== 'live' && <div>
                        <video
                            className={`${meteoStyles.imageAnimation}`}
                            poster={(() => {
                                switch (meteo.weather) {
                                    case 'sunny':
                                        return SunnyDay;
                                    case 'cloudy':
                                        return CloudyDay;
                                    case 'rain':
                                        return RainyDay;
                                    default:
                                        return SunnyDay;
                                }
                            })()}
                            playsInline autoPlay loop
                        >
                            <source src={(() => {
                                switch (meteo.weather) {
                                    case 'sunny':
                                        return DaySunny;
                                    case 'cloudy':
                                        return CloudyMP4;
                                    case 'rain':
                                        return RainMP4;
                                    default:
                                        return DaySunny;
                                }
                            })()} type="video/mp4" />
                        </video>
                    </div> }
                </div>}
        </div>
    );
}

export default MeteoItem;