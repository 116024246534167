import { useContext, useState, useEffect, useRef} from 'react';
import styles from '../../mobile.styles.module.css'
import { GlobalContext } from "../../contexts/globalContext"
import jwt_decode from 'jwt-decode'


const MobileLoginComponent = () => {
    const [currentSiteId, setCurrentSiteId] = useState(null)
    const [log, setLog] = useState(false)
    const decoded = useRef()

    

    const { socketRef, setLogged, setCurrentUser, mapRef, setCurrentSite, sites, setSites } = useContext(GlobalContext);

    // useEffect(() => {
    //     setProjectId(currentProjectId);
    //     console.log("currentProjectId" + currentProjectId);
    // }, [currentProjectId])

    const onLoad = async (e) => {
        try {
            var url = e.target.contentWindow.location.href;
            var token = getQueryVariable(url, "token");
            decoded.current = jwt_decode(token)
            loadUser();
        } catch (ex) {
            console.log(ex.message);
        }
    };

    function getQueryVariable(url, variable) {
        var splits = url.split("?");
        var query = splits[1];
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    function saveUser() { 
        if (currentSiteId) {
            var body = JSON.stringify({  name: decoded.current.userName, user_email: decoded.current.email, site_id: currentSiteId});
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: body
            };

            fetch(process.env.REACT_APP_API_URL + '/user.save', requestOptions)
                .then(response => response.json())
                .then(data => {
                    SetDataAndFly(data);                 
                }
            );
        }
    };

    function SetDataAndFly(data) {
        if (data.site) {
            setCurrentSite(data.site)
            setCurrentUser(data)
            
            setLogged(true) 
        }       
    }

    function loadUser() {
        fetch(process.env.REACT_APP_API_URL + '/user/' + decoded.current.email)
            .then((response) => response.json())
            .then((data) => {               
                if (data !== null){
                    SetDataAndFly(data);
                } else {
                    if (decoded.current.role == "ADMIN") {
                        saveUser()
                    } else {
                        console.log("trec pe aici");
                        setLog(true);
                    }                 
                } 
            }
        )
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/sites')
            .then((response) => response.json())
            .then((data) => {
                setSites(data)
            }
        )
        
    }, [])

    
    function buttonClickHandler() {
        saveUser()
    };

    return (
        <div className={`${styles.login_component}`} >
            {!log &&<iframe title="login" className={`${styles.login_iframe}`} src={process.env.REACT_APP_AUTH_URL}  onLoad={onLoad} /> }
            {log && <div className={`${styles.sites_component}`}>
                {sites && sites.map(site => (
                    <div className={`${(currentSiteId == site._id) ? styles.site_button_clicked : styles.site_button}`} key={site._id} onClick={() => setCurrentSiteId(site._id)}>
                        {site.name}
                    </div>
                ))}
                <button className={`${styles.site_confirm}`} onClick={buttonClickHandler}>Alege</button>  
                
            </div>}
        </div>
    );
}

export default MobileLoginComponent;