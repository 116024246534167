import { useState, useEffect, forwardRef, useRef} from 'react';
import styles from './styles.module.css'

const ButtonWithConfirmationComponent = forwardRef((props, ref) => {
    const [onConfirmation, setOnConfirmation] = useState(false);

    function clickHandler() {
        if (onConfirmation) {
            props.onConfirmed();
            setOnConfirmation(false);
        } else {
            setOnConfirmation(true);
            setTimeout(() => {
                setOnConfirmation(false);
            }, 2000);
        }

    }

    return (    
        <div ref={ref} className={`${styles.button_with_confirmation}`}  
                style={{fontSize:"21px", color:props.color?props.color:"white", transform: `matrix(1, 0, 0, 1, ${props.x}, ${props.y})`, width: Number(props.width), height: Number(props.height), backgroundColor:props.backgroundColor }}
                onClick={() => clickHandler()} >
            {props.text}
            <div className={`${styles.confirmation_layer}`} style={{fontSize:"21px", width: (onConfirmation)?Number(props.width):0, height: Number(props.height) }}>
                CONFIRMĂ
            </div>
        </div> 
        
    );
})

export default ButtonWithConfirmationComponent;
