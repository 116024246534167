import { useRef, useState, useContext, useEffect } from 'react';
import styles from '../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { TransformPoints } from '../../utils/Tools'
import { GlobalContext } from "../../contexts/globalContext"
import uuid from 'react-uuid';
import { ApiSaveProject } from "../../utils/RestServices"

const PolygonDrawLayer = (props) => {
    const ref = useRef(null);
    const polygonRef = useRef(null);

    const {setDrawingLayer, drawingsRef, mapRef, currentDrawingColor, currentProjectId, socketRef } = useContext(GlobalContext);
    const [currentPolygon, setCurrentPolygon] = useState([]);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    useGesture(
        {
            onDrag: ({ pinching, cancel, xy: [ox, oy], first }) => {
                if (pinching) {
                    return cancel()
                }
                if (first) {
                    setCurrentPolygon([...currentPolygon, [ox, oy]]);
                } else {
                    const updatedPoints = currentPolygon.slice(0, -1);
                    updatedPoints.push([ox, oy]);
                    setCurrentPolygon(updatedPoints);
                }
            },
            onDragEnd: ({ xy: [ox, oy] }) => {

                const currentPoint = [ox, oy];
                const firstPoint = currentPolygon[0];

                if (currentPolygon.length > 1 && firstPoint && Math.abs(firstPoint[0] - currentPoint[0]) < props.DrawingStopIntervalSize && Math.abs(firstPoint[1] - currentPoint[1]) < props.DrawingStopIntervalSize) {
                    const points = TransformPoints(currentPolygon, mapRef);

                    setCurrentPolygon([]);
                    setDrawingLayer(0)

                    if (currentProjectId == null) {
                        var feature = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'Polygon', coordinates: points }
                        };
                        drawingsRef.current.add(feature)

                    } else {
                        var feature = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'Polygon', coordinates: points }
                        };

                        drawingsRef.current.add(feature);

                        socketRef.current.emit("DRAWING_UPDATED", { project_id: currentProjectId, drawings: feature });
                        var body = JSON.stringify({ project_id: currentProjectId, drawing: feature });
                        ApiSaveProject(body);
                    }
                }
            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
        }
    )

    const xDrawingPointRectangleSize = props.DrawingPointRectangleSize / oxDivider;
    const yDrawingPointRectangleSize = props.DrawingPointRectangleSize / oyDivider;

    function showLatLng(point) {
        const coord = TransformPoints([point], props.map)
        return `${coord[0][0][0].toFixed(3)}, ${coord[0][0][1].toFixed(3)}`
    }
    function showElevationOld(point) {
        const coord = TransformPoints([point], props.map)
        const el = mapRef.current.queryTerrainElevation([coord[0][0][0], coord[0][0][1]])
        return `${el.toFixed(3)}`
    }

    const [elevations, setElevations] = useState([]);

    async function showElevation(point) {
        try {
          const coord = TransformPoints([point], props.map);
          const response = await fetch(process.env.REACT_APP_TOPODATA_URL + coord[0][0][0] + "," + coord[0][0][1]);
          const data = await response.json();
          const elevationValue = data.results[0].elevation;
          return elevationValue.toFixed(3)
        } catch (error) {
          console.error("Error fetching elevation data:", error);
        }
    }

    async function fetchElevations(points) {
        const elevations = [];
    
        for (const [x, y] of points) {
            const elevation = await showElevation([x, y]);
            elevations.push(elevation);
        }
    
        return elevations;
    }
    
    
    useEffect(() => {
        if (currentPolygon.length > 0) {
            fetchElevations(currentPolygon).then((elevations) => {
                setElevations(elevations);
            });
        }
    }, [currentPolygon]);

    return (
        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT) }} >
            <svg style={{ width: '100%', height: '100%' }}>
                <polygon
                    ref={polygonRef}
                    points={currentPolygon.map(([x, y]) => `${x},${y}`).join(' ')}
                    style={{ fill: 'none',  stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4" }}
                />
                {currentPolygon.map(([x, y], index) => (
                    <g key={index}>
                        <text x={x-5*xDrawingPointRectangleSize} y={y+2*yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >
                            {elevations[index]}m, {showLatLng([x, y])}
                        </text>
                        {index === 0 && <circle
                            cx = {x}
                            cy = {y}
                            r = {15}
                            style={{ fill: '#fb613b', stroke: 'white', strokeWidth: 4 }} 
                        />}
                        {index > 0 && <circle
                            cx = {x}
                            cy = {y}
                            r = {10}
                            style={{ fill: '#fbb03b', stroke: 'white', strokeWidth: 4 }} 
                        />}
                        <text x={x - xDrawingPointRectangleSize / 3+1} y={y + yDrawingPointRectangleSize / 3-1} fontSize={xDrawingPointRectangleSize-2} fontWeight="bold" fill="black">{index + 1}</text>
                    </g>
                ))}
            </svg>
        </div>
    );
}

export default PolygonDrawLayer;