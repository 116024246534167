import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './styles.module.css'

import { GlobalContext } from "../../contexts/globalContext"
import callNumber from './sip_components/dialer_button_svg/call.svg';

import { MultitouchComponent } from 'use-gesture-pack';
import { scale, rotateDEG, translate, compose, toString, identity, fromString } from 'transformation-matrix';


const CallsHistoryComponent = (props) => {
    const ref = useRef();
    const { currentUser } = useContext(GlobalContext);
    const [history, setHistory] = useState([]);

    const width = 612

    useEffect(() => {
        let currentHistory = currentUser.callsHistory
        let currentHistoryReverse = currentHistory.reverse()
        setHistory(currentHistoryReverse);
    }, [currentUser.callsHistory]);

    const numberSelected = (contact) => {
        props.onSelectedUser({name:contact.name, number:contact.number});        
    }

    function formatDate(inputDate) {
        const currentDate = new Date();
        const inputDateObj = new Date(inputDate);
    
        if (
            inputDateObj.getDate() === currentDate.getDate() &&
            inputDateObj.getMonth() === currentDate.getMonth() &&
            inputDateObj.getFullYear() === currentDate.getFullYear()
        ) {
            return "Atăzi";
        } else {
            const day = inputDateObj.getDate().toString().padStart(2, '0');
            const month = (inputDateObj.getMonth() + 1).toString().padStart(2, '0');
            const year = inputDateObj.getFullYear().toString();
            return `${day}.${month}.${year}`;
        }
    }

    function formatTime(inputDate) {
        const inputDateObj = new Date(inputDate);
    
        const hour = inputDateObj.getHours().toString();
        const minute = inputDateObj.getMinutes().toString().padStart(2, '0');
        return `${hour}:${minute}`;
        
    }

    const options = {
        appWidth: process.env.REACT_APP_WIDTH, appHeight: process.env.REACT_APP_HEIGHT,
        bringToFront: false
	}

    const onGestureEnded = () => {
        if(ref.current){
            var mat = identity();

            if (ref.current.style.transform !== '') {
                mat = fromString(ref.current.style.transform);
            }
    
            var dx = 0;
            if (ref.current.offsetWidth < width) {
                dx = -mat.e;
            } else {
                if (mat.e > 0) {
                    dx = -mat.e;
                }
    
                if (mat.e < - (ref.current.offsetWidth - width)) {
                    dx = -(ref.current.offsetWidth - width) - mat.e;
                }
            }
            
            
    
            if (dx != 0) {
                ref.current.style.transition = "transform 0.2s";
                setTimeout(() => {
                    ref.current.style.transition = "transform 0.0s";
                }, 200);
            }
    
            var trans = translate(dx, 0);
            var final = compose(trans, mat);
            ref.current.style.transform = toString(final);
        }
        
    }

    const onDragInside = ({ dx }) => {
        var mat = identity();

        if (ref.current.style.transform !== '') {
            mat = fromString(ref.current.style.transform);
        }

        var modifier = MapNumber(mat.e, 0, width / 4, 1, 0);

        if (modifier == 1) {
            modifier = MapNumber(mat.e,  - (ref.current.offsetWidth - width),  - (ref.current.offsetWidth - width + (width / 4)), 1, 0);
        }      

        var trans = translate(modifier * dx, 0);
        var final = compose(trans, mat);
        ref.current.style.transform = toString(final);
    }


    function MapNumber(theValue, theInStart, theInEnd, theOutStart, theOutEnd) {
        var result = theOutStart + (theOutEnd - theOutStart) * ((theValue - theInStart) / (theInEnd - theInStart));
        if (result < 0) {
            return result = 0;
        }
        if (result > 1) {
            return result = 1;
        }
        return result;
    }

    return (
        <MultitouchComponent width={612} height={78} dragGesture={false} pinchGesture={false} touchAndHoldGesture={false} tapGesture={false} onDragInside={onDragInside} onGestureEnded={onGestureEnded} options={options}>

        <div className={styles.sip_call_history} style={{fontFamily: 'Roboto', fontStyle: 'Roboto', color:"#555555"}}>
            Apeluri recente
            <div className={styles.sip_call_history_div}>
                <div className={styles.lista_contacte_history} style={{display:"flex", flexDirection:"row"}}>
                <div ref={ref} style={{ position: "absolute", display:"flex", flexDirection:"row"}}>
                {history.map((contact, index) => (
                    <div key={index} className={styles.contact_history}>
                        <div>
                            <div style={{ color: "white", fontSize: "16px", marginLeft: "17.5px", marginTop:"9px", whiteSpace:"nowrap", overflow:"hidden" }}>{(contact.name !== "Numar Necunoscut") ? contact.name : contact.number}</div>
                            <div style={{ color: "#A9A9AB", fontSize: "12px", marginLeft: "17.5px"}}>
                                {!contact.incoming && contact.answered &&
                                    <svg height="20" viewBox="0 -960 960 960" width="20"><path fill="green" d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z"/></svg>
                                }
                                {!contact.incoming && !contact.answered &&
                                    <svg height="20" viewBox="0 -960 960 960" width="20"><path fill="red" d="M480-263 120-623l56-57 304 304 224-224H520v-80h320v320h-80v-183L480-263Z"/></svg>
                                }

                                {contact.incoming && contact.answered &&
                                    <svg height="20" viewBox="0 -960 960 960" width="20"><path  fill="green" d="M200-200v-400h80v264l464-464 56 56-464 464h264v80H200Z"/></svg>
                                }

                                {contact.incoming && !contact.answered &&
                                    <svg height="20" viewBox="0 -960 960 960" width="20"><path fill="red" d="M480-263 200-543v183h-80v-320h320v80H256l224 224 304-304 56 57-360 360Z"/></svg>
                                }
                                <span style={{position:"relative", left:`${(contact.name !== "Numar Necunoscut") ? "5px" : "25px" }`, top:`${(contact.name !== "Numar Necunoscut") ? "-7px" : "-20px"}`, display:"inline-block",  whiteSpace:"nowrap", overflow:"hidden"}}>
                                    {(contact.name !== "Numar Necunoscut") ? (contact.number ? contact.number : contact.internal) : contact.name}
                                </span>
                            </div>
                        </div>
                        <div className={styles.call_time}>
                            <div>{formatDate(contact.createdAt)}</div>
                            <div>{formatTime(contact.createdAt)}</div>
                        </div>
                        {contact.number && <div style={{ color: "#878688", marginLeft: "15px", marginTop:"17px", marginRight:"17px" }}>
                            <img draggable={false} alt={"call internal"} src={callNumber} 
                                onTouchEnd={(event) => {
                                    event.stopPropagation()
                                    numberSelected(contact)
                                    console.log("click")
                                }}
                            />
                        </div>}
                    </div>
                ))
                }
                </div>
                </div>
            </div>
            <div className={styles.umbra_dreapta}/>
            <div className={styles.umbra_stanga}/>
        </div>
        </MultitouchComponent>

    );
}

export default CallsHistoryComponent;