import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from './../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { GetMaxZIndex } from '../utils/Tools'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import "@fontsource/roboto";

import { GlobalContext } from "../contexts/globalContext"
import { ApiSaveProject } from "../utils/RestServices"
import NewInputTextComponent from './keyboard/NewInputTextComponent'
import ButtonWithConfirmationComponent from './keyboard/ButtonWithConfirmationComponent'
import NewKeyboardComponent from './keyboard/NewKeyboardComponent'

import SitesComponent from './SitesComponent';


const ProjectComponent = (props) => {
    const ref = useRef();
    const kybRef = useRef();   
    const kybSearchRef = useRef();   

    const deviceForLeftMonitorRef = useRef();   
    const deviceForRightMonitorRef = useRef();   
     

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const [compHeight, setCompHeight] = useState(150);
    const [listHeight, setListHeight] = useState(150);
    const [divHeight, setDivHeight] = useState(150);
    const [buttonsHeight, setButtonsHeight] = useState(150);
    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [projectsOpened, setProjectsOpened] = useState(true);
    // const projectSitesRef = useRef();

    // const [keyboardOpened, setKeyboardOpened] = useState(false);

    const {setFilesMenuOpened, setBrowserMenuOpened, setSymbolsForEditing, setFilesToBeOpen, setSaveNewProjectsMenuOpened, currentProjectName, setCurrentProjectName, setProjectsMenuOpened, setFiles, deviceForLeftMonitor, setDeviceForLeftMonitor, deviceForRightMonitor, setDeviceForRightMonitor, devicesForComponents, setDevicesForComponents, inputProjectNameRef, sites, projectSites, setProjectSites, symbols, setAlerts, drawingsRef, setSymbols, setDrawingLayer, currentSite, currentUser, setDevices, socketRef, showInviteButton, setShowKeyboard, currentProjectId, setCurrentProjectId, setShowInviteButton } = useContext(GlobalContext);
    
    useEffect(() => {   
        deviceForLeftMonitorRef.current = deviceForLeftMonitor;
        deviceForRightMonitorRef.current = deviceForRightMonitor;
    }, [deviceForLeftMonitor, deviceForRightMonitor]);

    useEffect(() => {
        ref.current.style.zIndex = GetMaxZIndex() + 1;
    }, [])


    // useGesture(
    //     {
    //         onDrag: ({ pinching, cancel, delta: [dx, dy], first, memo }) => {
    //             if (pinching) {
    //                 return cancel()
    //             }
    //             if (first) {
    //                 ref.current.style.zIndex = GetMaxZIndex() + 1;
    //             }
    //             // var mat = identity();

    //             // if (ref.current.style.transform !== '') {
    //             //     mat = fromString(ref.current.style.transform);
    //             // }

    //             // var trans = translate(dx, dy);
    //             // var final = compose(trans, mat);
    //             // ref.current.style.transform = toString(final);

    //             return memo
    //         },
    //         onPinch: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
    //             if (first) {
    //                 memo = [ox, oy, a, s]
    //             } else {
    //                 const { width, height, x, y } = ref.current.getBoundingClientRect()


    //                 // apply translation to center the element at pinch origin
    //                 var mat = fromString(ref.current.style.transform);
    //                 var trans = translate((ox - memo[0]) / oxDivider, (oy - memo[1]) / oyDivider);

    //                 // apply inverse transformation to find the rotated pinch origin
    //                 var inv = inverse(mat);
    //                 var pointCentruTranslatat = applyToPoint(inv, [(width / 2 + x) / oxDivider, (height / 2 + y) / oyDivider]);
    //                 var diffCenterX = pointCentruTranslatat[0] - (ref.current.offsetWidth / 2);
    //                 var diffCenterY = pointCentruTranslatat[1] - (ref.current.offsetHeight / 2);

    //                 // convert the pinch origin to the original scale
    //                 var pointOx = applyToPoint(inv, [ox / oxDivider, oy / oyDivider]);

    //                 // calculate the point to apply transformations
    //                 var point = applyToPoint(mat, [pointOx[0] - diffCenterX - ref.current.offsetWidth / 2, pointOx[1] - diffCenterY - ref.current.offsetHeight / 2]);

    //                 // apply rotation and scaling
    //                 var rotate = rotateDEG(a - memo[2], point[0], point[1]);
    //                 var scaleFunction = scale((s / memo[3]), (s / memo[3]), point[0], point[1]);

    //                 var final = compose(rotate, scaleFunction, trans, mat);
    //                 ref.current.style.transform = toString(final);

    //                 if (s <= 0.5) {
    //                     setProjectsMenuOpened(false);
    //                 }
                    
    //                 memo = [ox, oy, a, s]
    //             }
    //             return memo
    //         },
    //         onPinchEnd: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
    //             const { width, height, x, y } = ref.current.getBoundingClientRect();

    //             if (x + width /2 < 0){
    //                 ref.current.className = styles.project_component_anim;

    //                 var mat = fromString(ref.current.style.transform);
    //                 var trans = translate((-(x + width /2) ) / oxDivider, 0);
    //                 var final = compose(trans, mat);
    //                 ref.current.style.transform = toString(final);

    //                 setTimeout(() => {
    //                     ref.current.className = styles.project_component;
    //                 }, 500);

    //             }

    //             if (x + width /2 > window.innerWidth){
    //                 ref.current.className = styles.project_component_anim;
    //                 var mat = fromString(ref.current.style.transform);
    //                 var trans = translate(- ((x + width /2) - window.innerWidth) / oxDivider, 0);
    //                 var final = compose(trans, mat);
    //                 ref.current.style.transform = toString(final);

    //                 setTimeout(() => {
    //                     ref.current.className = styles.project_component;
    //                 }, 500);
    //             }

    //             if (y + height /2 < 0){
    //                 ref.current.className = styles.project_component_anim;
    //                 var mat = fromString(ref.current.style.transform);
    //                 var trans = translate(0, (-(y + height /2) ) / oyDivider);
    //                 var final = compose(trans, mat);
    //                 ref.current.style.transform = toString(final);

    //                 setTimeout(() => {
    //                     ref.current.className = styles.project_component;
    //                 }, 500);
    //             }

    //             if (y + height /2 > window.innerHeight){
    //                 ref.current.className = styles.project_component_anim;
    //                 var mat = fromString(ref.current.style.transform);
    //                 var trans = translate(0, - ((y + height /2) - window.innerHeight) / oyDivider);
    //                 var final = compose(trans, mat);
    //                 ref.current.style.transform = toString(final);

    //                 setTimeout(() => {
    //                     ref.current.className = styles.project_component;
    //                 }, 500);
    //             } 

    //         }
    //     },
    //     {
    //         target: ref,
    //         drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
    //         pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
    //     }
    // )


    function loadProject() {
        if (projectsOpened) {
            if (selectedProjectId) {
                setDrawingLayer(0);
                setFilesMenuOpened(false)

                if (currentProjectId != null) {
                    socketRef.current.emit("LEAVE_ROOM", currentProjectId);
                }

                fetch(process.env.REACT_APP_API_URL + '/project/' + selectedProjectId)
                    .then((response) => response.json())
                    .then((data) => {
                        
                        setCurrentProjectName(data.name)

                        const old_project_id = currentProjectId;

                        setCurrentProjectId(data._id)

                        socketRef.current.emit("LEAVE_SITES", projectSites);
                        setProjectSites(data.sites);

                        setShowInviteButton(data.author == currentUser._id);

                        socketRef.current.emit("PROJECT_CHANGED", { old_project_id, new_project_id: data._id });
                        setCompHeight(150);

                        socketRef.current.emit("JOIN_ROOM", {roomID:data._id, name: currentUser.name});

                        if (data.data) {
                            drawingsRef.current.set(JSON.parse(data.data)["drawings"]);
                            setSymbols(JSON.parse(data.data)["symbols"]);
                            setFiles(data.files);
                        }

                        setFilesToBeOpen([]);
                        setSymbolsForEditing([]);
                        setBrowserMenuOpened(false)
                    }
                )
                setProjectsMenuOpened(false)
            }
        } else {
            socketRef.current.emit("INVITE_TO_PROJECT", { site_id: selectedProjectId, project_id: currentProjectId, project_name: currentProjectName });            
        }
    }



    useEffect(() => {
        setProjectsOpened(true);
            fetch(process.env.REACT_APP_API_URL + '/projects/' + currentSite._id)
                .then((response) => response.json())
                .then((data) => {
                    setProjects(data);
                    if(data.length >= 6){
                        setCompHeight(500);
                        setListHeight(315)
                        setDivHeight(460)
                        setButtonsHeight(400)
                    } else {
                        setCompHeight(185 + data.length * 52.5)
                        setListHeight(data.length * 52.5)
                        setDivHeight(145 + data.length * 52.5)
                        setButtonsHeight(85 + data.length * 52.5)
                    }
                    
                });
        setSaveNewProjectsMenuOpened(false)
    }, [])


    
    return (
        <>
            <div className={`${styles.project_component}`} ref={ref} style={{fontFamily:'Roboto', transform: `matrix(1, 0, 0, 1, ${props.X}, ${props.Y})`, height: compHeight, width: 700 }}>

                <div style={{ transform: `matrix(1, 0, 0, 1, 20, 20)`, height: divHeight, width: 660, fontStyle: 'Roboto', color: 'white', backgroundColor:'black', borderRadius: '8px'}}>
                    <p style = {{ top: 5, left: 20, position: 'absolute', fontSize:22}}>DESCHIDE PROIECT</p>
                    {(projects)  &&<div className={`${styles.projects_list_component}`} style={{ transform: "matrix(1, 0, 0, 1, 20, 65)" ,  backgroundColor:'black', height: listHeight}}>
                        {projects && projects.map(project => (
                            <div className={`${(selectedProjectId == project._id) ? styles.site_button_clicked : styles.site_button}`} key={project._id} onClick={() => setSelectedProjectId(project._id)}>
                                {project.name}
                            </div>
                        ))} 
                    </div>
                    }
                    {projectsOpened && projects.length >= 1 && 
                        <div className={`${(selectedProjectId) ? styles.load_project_button_clicked : styles.load_project_button_notclicked}`} style={{fontWeight:500, width: 200, height: 40, transform: `matrix(1, 0, 0, 1, 20, ${buttonsHeight})` }} onClick={loadProject}>
                            ÎNCARCĂ
                        </div>
                    }
                    {projectsOpened && 
                        <div className={`${styles.load_project_button_anulare}`} style={{fontWeight:500, width: 200, height: 40, transform: `matrix(1, 0, 0, 1, ${(projects.length >= 1) ? 270 : 20}, ${buttonsHeight})` }} onClick={() => setProjectsMenuOpened(false)}>
                            ANULARE
                        </div>
                    }
                </div>


                
                             
            </div>
            
        </>
    );
}

export default ProjectComponent;