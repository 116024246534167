import React, {useRef, useState, useEffect,useContext} from 'react';
import styles from '../css/sites.module.css'
import { useGesture } from '@use-gesture/react'
import { GetMaxZIndex } from '../utils/Tools'
import { GlobalContext } from "../contexts/globalContext"
import {scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString} from 'transformation-matrix';
import "@fontsource/roboto";

import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import NewInputTextComponent from './keyboard/NewInputTextComponent'
import NewKeyboardComponent from './keyboard/NewKeyboardComponent';

import Lottie from 'react-lottie-player'
import btnInvitaLottie from '../utils/btn-invita-conf-notxt.json'

const categories =
{
	"DIRECȚII": {
		"Direcția personal și mobilizare": [],
		"Direcția operații": [],
		"Direcția logistică": [],
		"Direcția planificare strategică": [],
		"Direcția comunicații și tehnologia informațiilor": [],
		"Direcția instruire și doctrină": [],
		"Direcția structuri și planificare înzestrare": [],
		"Direcția medicală": []
    },
	"CATEGORII DE FORȚE": {
		"S.M.F.T.": {
            "M.U. și U. LUPTĂ": [], 
            "M.U. și U. SPRIJIN LUPTĂ": [], 
            "M.U. și U. SPRIJIN LOGISTIC": [] 
        },
		"S.M.F.A.": {
            "M.U. și U. LUPTĂ": [], 
            "M.U. și U. SPRIJIN LUPTĂ": [], 
            "M.U. și U. SPRIJIN LOGISTIC": [] 
        },
		"S.M.F.N.": {
            "M.U. și U. LUPTĂ": [], 
            "M.U. și U. SPRIJIN LUPTĂ": [], 
            "M.U. și U. SPRIJIN LOGISTIC": [] 
        }
	},
	"COMANDAMENTE": {
		"Comandamentul Național Militar de Comandă": [],
		"Comandamentul Apărării Cibernetice": [],
		"Comandamentul Comunicații și Tehnologia Infrastructurii": [],
		"Comandamentul Forțelor Întrunite": [],
		"Comandamentul Forțelor pentru Operații Speciale": [],
		"Comandamentul Logistic Întrunit": []
    },
	"REPREZENTANȚE": []
};

const SitesComponent = (props) => {
    const ref = useRef();
    const sitesRef = useRef();
    const searchRef = useRef();
    
    const {setCloseDashboard, projectSites, sites, invitesMenuOpened, currentSite, isForDesk } = useContext(GlobalContext);

    const kybRef = useRef();

    const [contentCreated, setContentCreated] = useState(false);
    const [canTakeData, setCanTakeData] = useState(true);
    const [inviteID, setInviteID] = useState();

    const [searchingResults, setSearchingResults] = useState([]);

    
    const [isPlaying, setIsPlaying] = useState(false);
    const [siteIsInProject, setSiteIsInProject] = useState();
    const [showInviteLetters, setShowInviteLetters] = useState();


    function getElementsFor(category, name) {
        Object.keys(category).map((key) => {
            var category_to_find = (name)?name +";"+ key: key;

            if (Array.isArray(category[key])) {
                let sites_to_add = sitesRef.current.filter((element) => element.category == category_to_find);

                if (sites_to_add) {
                    category[key] = Array.isArray(sites_to_add)?sites_to_add:[sites_to_add];
                } else {
                    category[key] = [];    
                }
                
            } else {
                getElementsFor(category[key], category_to_find);
            }    
        })
    }

    const siteSelected = site => {
        setCloseDashboard(false)      
        props.siteSelected(site);
    };

    const handleButtonClick = (site) => {
        let ok = 0
        for(let i = 0; i < projectSites.length; i++){
            if(site._id === projectSites[i]){
                ok = 1
                setSiteIsInProject(site._id)
            }
        }
        if(ok===0){
            siteSelected(site);
        }
        
        setIsPlaying(true);
        // setTimeout(() => {
        //     setIsPlaying(false);
        // }, 2000);

    };

    const Fragment = (props) => {
        if (Array.isArray(props.categories)) {
            return  <>          
                {invitesMenuOpened && props.categories.map((site, index) => (
                    <div className={`${(inviteID === site._id) ? styles.search_result_button_clicked : ((index === props.categories.length - 1) ? styles.search_result_button_last : styles.search_result_button)}`} key={index} onClick={() => setInviteID(site._id)}> 
                        {site.name}
                        {(site._id === inviteID) &&
                            <>
                            <Lottie
                                loop={false}
                                style={{width: 150, height: 45, transform: `matrix(1, 0, 0, 1, 530, -35)`}}
                                animationData={btnInvitaLottie}
                                play={isPlaying}
                                onClick={() => {
                                    handleButtonClick(site)
                                    setShowInviteLetters(site._id)
                                }}
                            />
                            {(showInviteLetters !== site._id) && <div style={{fontWeight:500, position: "absolute", transform: `matrix(1, 0, 0, 1, 570.5, -72.5)`, color:"#E4A83C"}} 
                            onClick={() => {
                                handleButtonClick(site)
                                setShowInviteLetters(site._id)
                            }}>
                                INVITA
                            </div>}
                            {(siteIsInProject=== site._id) && <div className={`${styles.load_project_button_clicked}`} style={{fontWeight:500, transform: `matrix(1, 0, 0, 1, 528, -80)`}}>
                                DEJA INVITAT
                            </div>
                            }
                            </>
                        }
                    </div>
                ))}

                {!invitesMenuOpened && props.categories.map((site, index) => (
                    <div className={`${(index === props.categories.length - 1) ? styles.search_result_button_last : styles.search_result_button}`} key={index} onClick={() => siteSelected(site)}> 
                        {site.name}
                        
                    </div>
                ))}
            </>
        } else {
            return <>    
              { Object.keys(props.categories).map((key, index) => (      
                <Accordion expanded={subExpanded === key || props.level === 1} onClick={()=> setSubExpanded(key)} key={key}  style={{ marginTop:"2px", marginBottom:"0px", borderRadius:"8px", backgroundColor:"#1C1C1C", boxShadow:"0px 0px 0px white"}} >
                    <AccordionSummary style={{paddingTop:"2px", paddingBottom:"2px"}}expandIcon={<ExpandMoreIcon  style={{color:"white"}} />}>
                        <Typography style={{color:"#AAAAAA"}}>{key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{paddingTop:"0px"}}>
                        <Fragment categories={props.categories[key]} level={props.level + 1}></Fragment>
                        {/* {createContent(categories[key])} */}
                    </AccordionDetails>           
                </Accordion>
                ))}
            </>
        }
    }    

    useEffect(() => {
        if (sites.length > 0 && canTakeData) {
            sitesRef.current = sites;
            setCanTakeData(false);
            getElementsFor(categories, "");

            setContentCreated(true);
        }
        
    }, [sites])

    useEffect(() => {
        ref.current.style.zIndex = GetMaxZIndex() + 1;
    }, [])

    function onTextChanged() {
        if (searchRef.current.value) {
            let aux_sites = sitesRef.current;
            let result = aux_sites.filter(el=> el.name.toLowerCase().includes(searchRef.current.value.toLowerCase()));
            setSearchingResults(result);
        } else {
            setSearchingResults([]);
        }
    }  

    useEffect(() => {
        setIsPlaying(false)
        setSiteIsInProject()
        setShowInviteLetters()
    }, [inviteID])

    const [expanded, setExpanded] = useState();
    const [subExpanded, setSubExpanded] = useState();

    return (      
        <div className={`${currentSite ? styles.search_sites_component : styles.search_sites_component_animation}`} ref={ref} style={{fontFamily:"Roboto", transform: `matrix(1, 0, 0, 1, ${props.x}, ${props.y})`, width: Number(props.width)}} >   
            
            {(searchingResults.length === 0) && contentCreated &&  Object.keys(categories).map((key, index) => (
                <Accordion expanded={expanded === key} key={index} onClick={()=> setExpanded(key)} style={{borderRadius:"8px", marginTop:"8px", backgroundColor:"black"}}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{color:"white"}} />} >
                        <Typography style={{color:"white"}}>{key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Fragment categories={categories[key]} level={0}></Fragment>
                    </AccordionDetails>           
                </Accordion>
            ))}

            {invitesMenuOpened && (searchingResults.length > 0) && contentCreated &&  searchingResults.map((site) => (
                <div className={`${(inviteID === site._id) ? styles.search_result_button_clicked : styles.search_result_button_classic}`} key={site._id} 
                onClick={() => {
                    setInviteID(site._id)
                    // setIsPlaying(false)
                    // setSiteIsInProject()
                }} 
                >
                    {site.name}
                    {(site._id === inviteID) && <>
                        
                        <Lottie
                            loop={false}
                            style={{width: 150, height: 45, transform: `matrix(1, 0, 0, 1, 625, -34)`}}
                            animationData={btnInvitaLottie}
                            play={isPlaying}
                            onClick={() => {
                                handleButtonClick(site)
                                setShowInviteLetters(site._id)
                            }}
                        />
                        {(showInviteLetters !== site._id) && <div style={{fontWeight:500, position: "absolute", transform: `matrix(1, 0, 0, 1, 666.5, -72.5)`, color:"#E4A83C"}} 
                        onClick={() => {
                            handleButtonClick(site)
                            setShowInviteLetters(site._id)
                        }}>
                            INVITA
                        </div>}
                        {(siteIsInProject === site._id) && <div className={`${styles.load_project_button_clicked}`} style={{fontWeight:500, transform: `matrix(1, 0, 0, 1, 623.5, -80.5)`}}>
                            DEJA INVITAT
                        </div>
                        }
                    </>   
                    }
                </div>
            ))
            }

            {!invitesMenuOpened && (searchingResults.length > 0) && contentCreated &&  searchingResults.map((site) => (
                <div className={`${styles.search_result_button_classic}`} key={site._id} onClick={() => siteSelected(site)}>
                    {site.name}
                </div>
            ))}

        <NewInputTextComponent  ref={searchRef} keyboardOpenAt={props.keyboardOpenAt} keyboardRef={kybRef} x="0" y="0" onTextChanged={onTextChanged} value="" width={Number(props.width)} height="60" fontSize="25" placeHolder="Caută"></NewInputTextComponent>
        <NewKeyboardComponent visible ref={kybRef} position="relative" x="-10" y="5" width="800" height={isForDesk?0:255}/>
            
        </div>
    );
}

export default SitesComponent;