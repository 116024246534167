import React, { useState, useEffect, useContext, useRef } from 'react';
// import Lottie from 'react-lottie-player'
// import NewInputTextComponent from '../../../components/keyboard/NewInputTextComponent'
import SipTextImput from './sip_text_imput';
import styles from '../styles.module.css'
import "@fontsource/roboto";
import IcSIP from './dialer_button_svg/ic-nrSIP.svg'

const AddContactFrom = ({ keyboardRef, onSaveContactCallback, onAddContact}) => {
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [number, setNumber] = useState()
    const [internal, setInternal] = useState()

    return (
        <div style={{width: "330px", overflow: "hidden", background: "black", height: "100%", position: "absolute", top: "0px", display: "flex", flexDirection: 'column', justifyContent: 'space-around', borderRight: 'solid 1px #323136', marginRight: '5px'  }}>
            <div style={{ textAlign: "center", position:"relative" }}>
                <div style={{fontFamily: 'Roboto', fontStyle: 'Roboto', fontSize: "22px", color: "white" }}>Contact Nou</div>
                <div style={{fontFamily: 'Roboto', fontStyle: 'Roboto', fontSize: "11px", color: "#41CDCD" }}>Creează contact</div>
            </div>

            <div>
                <SipTextImput placeholder={'Prenume'} setData={setFirstName}/>
                <SipTextImput placeholder={'Nume'} setData={setLastName}/>
                <SipTextImput placeholder={'Numar telefon'} setData={setNumber}/>
                <SipTextImput placeholder={'Interior'} setData={setInternal}/>
                <img style={{ position:'relative', top:"-11px", left:"15px" }} alt="icon" draggable="false" src={IcSIP}/> 
                <div style={{fontFamily: 'Roboto', fontStyle: 'Roboto', fontSize: "12px", color: "#707070", position:'relative', top:"-27px", left:"30px" }}>Opțional</div>
            </div>

            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                <div style={{ background: `${(!firstName || !lastName|| (!number && !internal)) ? "#383838 0% 0% no-repeat padding-box" : "transparent linear-gradient(91deg, #18BEDD 0%, #9ED688 100%) 0% 0% no-repeat padding-box"}`, border: "solid", opacity:`${(!firstName || !lastName|| (!number && !internal)) ? "0.5" : "1"}`, borderWidth: "1px", borderColor: "#39383D", borderRadius: "8px", height: "53px", width: "80%" }}
                    onTouchEnd={() => onSaveContactCallback(firstName, lastName, number, internal)}>
                    <div style={{ fontFamily: 'Roboto', opacity: "1", fontStyle: 'Roboto',textAlign:"center", color: `${(!firstName || !lastName|| (!number && !internal)) ? "#AAAAAA" : "#000000"}`, fontSize:"22px", position:"relative", top:"50%", left:"50%", transform:"translate(-50%, -50%)" }}><b>SALVEAZA</b></div>
                </div>
                <div className={styles.lottie_x_button} onTouchEnd={() =>  onAddContact() }>
                    <div style={{ position: "absolute", top: "50%", left: "50%", transform:"translate(-50%,-50%)" }}>X</div>
                    
                </div>
            </div>
        </div>
    )
}

export default AddContactFrom;