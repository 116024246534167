import {useRef, useContext, useEffect, useState} from 'react';
import styles from './../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { GetMaxZIndex } from '../utils/Tools'
import {scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, fromString} from 'transformation-matrix';
import { GlobalContext } from "../contexts/globalContext";
import * as turf from '@turf/turf'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
  

const ElevationComponent = (props) => {
    const ref = useRef();
    const [currentPoint, setCurrentPoint] = useState()
    const [labels, setLabels] = useState([]);
    const [elevationValues, setElevationValues] = useState([])
    const [isPolygon, setIsPolygon] = useState(true)

    const {drawingsRef, selectedFeature, mapRef} = useContext(GlobalContext);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const chunk = useRef(null);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
    );

    const options = {
        responsive: true,
        events: ['mousemove', 'mouseout', 'click', 'touchstart'],
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
                labels:{
                    usePointStyle: true
                },
            },
            title: {
                display: true,
                text: 'Elevation',
            }

        },
        onHover: function(evt, element) {
            if(element.length > 0) {
                var index = element[0].index
                if(index === chunk.current.features.length){
                    var point = turf.point(chunk.current.features[index-1].geometry.coordinates[1])
                } else{ 
                    var point = turf.point(chunk.current.features[index].geometry.coordinates[0])
                }
                if(currentPoint){
                    if(currentPoint.geometry.coordinates !== point.geometry.coordinates){
                        drawingsRef.current.delete(drawingsRef.current.getAll().features[drawingsRef.current.getAll().features.length - 1].id)
                        drawingsRef.current.add(point)
                        setCurrentPoint(point)
                    }
                } else {
                    drawingsRef.current.add(point)
                    setCurrentPoint(point)
                }
                
            }
        }
    };


    useEffect(() => {
        if(selectedFeature.geometry.type !== "Polygon"){
            //mapRef.current.getMap().hideLayer("gl-draw-polygon-and-line-vertex-inactive.cold");
            //mapRef.current.getMap().hideLayer("gl-draw-polygon-and-line-vertex-stroke-inactive.cold");
            //console

            mapRef.current.getMap().setLayoutProperty("gl-draw-polygon-and-line-vertex-inactive.cold", 'visibility', 'none');
            mapRef.current.getMap().setLayoutProperty("gl-draw-polygon-and-line-vertex-stroke-inactive.cold", 'visibility', 'none');

            // mapRef.current.getMap().addLayer("gl-draw-polygon-and-line-vertex-inactive.cold");
            // mapRef.current.getMap().addLayer("gl-draw-polygon-and-line-vertex-stroke-inactive.cold");
                                                
            //mapRef.current.getMap().removeLayer("gl-draw-polygon-and-line-vertex-inactive.hot");

                //     "filter": ["all", ["==", "meta", "vertex"],
                //     ["==", "$type", "Point"],
                //     ["!=", "mode", "static"]
                //   ]
            // var vertices = mapRef.current.getMap().queryRenderedFeatures({filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"],
            // ["!=", "mode", "static"]]});          
            setIsPolygon(false)

            var length = turf.length(selectedFeature, {units: 'kilometers'});
            let chunkSize = length / 30;

            chunk.current = turf.lineChunk(selectedFeature, chunkSize, {units: 'kilometers'});

            //var chunk = turf.lineChunk(selectedFeature, 0.3, {units: 'kilometers'});

            var points = ""
            var labelPoints = []

            for(let i=0; i < chunk.current.features.length; i++){
                points = points + chunk.current.features[i].geometry.coordinates[0] + '|' 
                labelPoints.push("")
            }
            setLabels(labelPoints)
            
            fetch(process.env.REACT_APP_TOPODATA_URL+points)
                .then(response => response.json())
                .then((data) => {
                    var elevationPoints = []
                    for(let i=0; i < data.results.length; i++){
                        elevationPoints.push(data.results[i].elevation)
                    }
                    setElevationValues(elevationPoints)
            });


            // var labelPoints = [];
            
            // var elevations = []

            // for(let i=0; i < chunk.current.features.length; i++){
            //     elevations.push(mapRef.current.queryTerrainElevation(chunk.current.features[i].geometry.coordinates[0]))
            //     labelPoints.push("")
            // }
            // elevations.push(mapRef.current.queryTerrainElevation(chunk.current.features[chunk.current.features.length - 1].geometry.coordinates[1]))
            // labelPoints.push("")

            // setLabels(labelPoints)
            
            // setElevationValues(elevations)
        
        }
    }, [])

    const data = {
        labels,
        datasets: [
          {
            data: elevationValues,
            backgroundColor: 'rgba(21, 172, 79, 0.5)',
            borderColor: 'rgba(38, 129, 86, 1)',
            fill: true,
            pointRadius: 4,
            hoverRadius: 10,
          }
        ],
    };

    useGesture(
        {
            onDrag: ({ pinching, cancel,  lastOffset, delta: [dx, dy], first, memo}) => {
                if (pinching) {
                    return cancel()
                }
                if (first) {
                    ref.current.style.zIndex = GetMaxZIndex() + 1;
                }
                // var mat = identity();

                // if (ref.current.style.transform !== '') {
                //     mat = fromString(ref.current.style.transform);
                // }
                
                // var trans = translate(dx, dy);
                // var final = compose(trans, mat);
                // ref.current.style.transform = toString(final);

                // return memo
            },
            onPinch: ({ origin: [ox, oy], first, offset: [s, a], memo}) => {        
                if (first) {      
                    memo = [ox, oy, a, s]  
                } else {
                    const { width, height, x, y } = ref.current.getBoundingClientRect()
                    
                    // apply translation to center the element at pinch origin
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((ox - memo[0]) / oxDivider, (oy - memo[1]) / oyDivider);
        
                    // apply inverse transformation to find the rotated pinch origin
                    var inv = inverse(mat);
                    var pointCentruTranslatat = applyToPoint(inv, [(width / 2 + x) / oxDivider , (height /2 + y) / oyDivider]);
                    var diffCenterX = pointCentruTranslatat[0] - (ref.current.offsetWidth / 2);
                    var diffCenterY = pointCentruTranslatat[1] - (ref.current.offsetHeight / 2);
    
                    // convert the pinch origin to the original scale
                    var pointOx = applyToPoint(inv, [ox / oxDivider, oy / oyDivider]);
            
                    // calculate the point to apply transformations
                    var point = applyToPoint(mat, [pointOx[0] -diffCenterX - ref.current.offsetWidth / 2, pointOx[1] -diffCenterY - ref.current.offsetHeight / 2]);

                    // apply rotation and scaling
                    var rotate = rotateDEG(a - memo[2], point[0], point[1]);
                    var scaleFunction = scale((s / memo[3]), (s / memo[3] ), point[0], point[1]);
    
                    var final = compose( rotate, scaleFunction, trans, mat   );
                    ref.current.style.transform = toString(final);
                    
                    memo = [ox, oy, a, s]
                }
                return memo
            },
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
            pinch: { scaleBounds: { min: 0.5, max: 5 }, rubberband: true },
        }
    )

    return (
        <div className={`${styles.project_component}`} ref={ref} style={{fontFamily:'Roboto', transform: `matrix(1, 0, 0, 1, ${props.X}, ${props.Y})`, height: 340, width: 640 }}>
            <div className={`${styles.touch_component}`} ref={ref} style={{transform: `matrix(1, 0, 0, 1, 20, 20)`, width:600, height:300, backgroundColor:"white", borderRadius:"10px"}}  >  
                {!isPolygon && <Line options={options} data={data} style={{margin:"10px"}} />}
                {isPolygon && <div>This shape has no elevation feature</div>}
            </div>  
        </div>   
    );
}

export default ElevationComponent;