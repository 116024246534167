import { useRef, useState, useContext, useEffect } from 'react';
import styles from '../../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { TransformPoints, between, buffer} from '../../../utils/Tools'
import { GlobalContext } from "../../../contexts/globalContext"
import uuid from 'react-uuid';
import { ApiSaveProject } from "../../../utils/RestServices"
import * as turf from "@turf/turf"

const PolylineDroneDrawLayer = (props) => {
    const ref = useRef(null);
    const polylineRef = useRef(null);

    const {currentRepetition, setCurrentShapeName, currentShape, setDrawingLayer, drawingsRef, currentProjectId, socketRef, currentDrawingColor, mapRef } = useContext(GlobalContext);
    const [currentPolyline, setCurrentPolyline] = useState([]);    

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    function createEndingTextShape(point1, bearing, dist, options, number){
        var coordinatesText = []

        for(let i = 0; i < currentShape[number].length; i++){
            let line = []
            for(let j = 0; j < 2; j++){
                let oxDist = dist * currentShape[number][i][j][0]
                let oyDist = dist * currentShape[number][i][j][1]
                if(oyDist === 0){
                    var point2 = turf.destination(point1, oxDist, bearing, options)
                    line.push(point2.geometry.coordinates)
                } else if(oyDist > 0){
                    let helpingPoint = turf.destination(point1, oyDist, bearing + 90, options)
                    var point3 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point3.geometry.coordinates)
                } else {
                    let helpingPoint = turf.destination(point1, Math.abs(oyDist), bearing + 270, options)
                    var point4 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point4.geometry.coordinates)
                }
            }
            coordinatesText.push(line)
        }

        return coordinatesText
    }

    function createRepetitionTextShape(point1, bearing, dist, options){
        var coordinatesText = []

        for(let i = 0; i < currentRepetition.length; i++){
            let line = []
            for(let j = 0; j < 2; j++){
                let oxDist = dist * currentRepetition[i][j][0]
                let oyDist = dist * currentRepetition[i][j][1]
                if(oyDist === 0){
                    var point2 = turf.destination(point1, oxDist, bearing, options)
                    line.push(point2.geometry.coordinates)
                } else if(oyDist > 0){
                    let helpingPoint = turf.destination(point1, oyDist, bearing + 90, options)
                    var point3 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point3.geometry.coordinates)
                } else {
                    let helpingPoint = turf.destination(point1, Math.abs(oyDist), bearing + 270, options)
                    var point4 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point4.geometry.coordinates)
                }
            }
            coordinatesText.push(line)
        }

        return coordinatesText
    }

    useGesture(
        {
            onDrag: ({ pinching, cancel, xy: [ox, oy], first }) => {
                if (pinching) {
                    return cancel()
                }
                if (first) {
                    setCurrentPolyline([...currentPolyline, [ox, oy]]);
                } else {
                    const updatedPoints = currentPolyline.slice(0, -1);
                    updatedPoints.push([ox, oy]);
                    setCurrentPolyline(updatedPoints);
                }
            },
            onDragEnd: ({ xy: [ox, oy] }) => {
                const currentPoint = [ox, oy];
                const previousPoint = currentPolyline[currentPolyline.length - 2];

                if (previousPoint && Math.abs(previousPoint[0] - currentPoint[0]) < props.DrawingStopIntervalSize && Math.abs(previousPoint[1] - currentPoint[1]) < props.DrawingStopIntervalSize) {
                    const points = TransformPoints(currentPolyline.slice(0,-1), props.map);
                    
                    setCurrentPolyline([]);
                    setDrawingLayer(0)
                    setCurrentShapeName("");
                    
                    if (currentProjectId == null) {
                        var feature = {
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'LineString', coordinates: points[0] }
                        };

                        var zoom = mapRef.current.getZoom();
                        var zoomDiff = (11 - zoom) / 2

                        if(1 + zoomDiff > 0.1){
                            var dist = 1 + zoomDiff
                        } else {
                            var dist = 0.1
                        }

                        let coordinates = []

                        for(let i =0; i < points[0].length - 1; i++) {
                            let lastLine = turf.lineString([points[0][i],points[0][i+1]])
                            let buffered2 = buffer(lastLine, dist, {units:'kilometers'});
                            
                            for(let i =0; i < buffered2.geometry.coordinates[0].length - 1;i++) {
                                if ( between(lastLine.geometry.coordinates[0][0], buffered2.geometry.coordinates[0][i][0], buffered2.geometry.coordinates[0][i+1][0] )
                                    && between(lastLine.geometry.coordinates[0][1], buffered2.geometry.coordinates[0][i][1], buffered2.geometry.coordinates[0][i+1][1] )
                                )  {
                                    
                                } else if ( between(lastLine.geometry.coordinates[lastLine.geometry.coordinates.length - 1][0], buffered2.geometry.coordinates[0][i][0], buffered2.geometry.coordinates[0][i+1][0] )
                                && between(lastLine.geometry.coordinates[lastLine.geometry.coordinates.length - 1][1], buffered2.geometry.coordinates[0][i][1], buffered2.geometry.coordinates[0][i+1][1] )
                                ) {
                                
                                }
                                else{
                                    coordinates.push([buffered2.geometry.coordinates[0][i], buffered2.geometry.coordinates[0][i+1]])  
                                }
                            }

                            let bearing1 = turf.bearing(points[0][i],points[0][i+1])
                            let midpoint = turf.midpoint(points[0][i],points[0][i+1])
                            let options3 = {units: 'kilometers', bearing: bearing1};
                            let coordinatesOne1 = createRepetitionTextShape(midpoint.geometry.coordinates, bearing1, dist/2, options3);

                            for(let i = 0; i < coordinatesOne1.length; i ++){
                                coordinates.push(coordinatesOne1[i])
                            }
                        }

                        if(points[0].length > 2){
                            let lastLine = turf.lineString([points[0][0],points[0][points[0].length -1]])
                        
                            let buffered2 = buffer(lastLine, dist, {units:'kilometers'});

                            for(let i =0; i < buffered2.geometry.coordinates[0].length - 1;i++) {
                                if ( between(lastLine.geometry.coordinates[0][0], buffered2.geometry.coordinates[0][i][0], buffered2.geometry.coordinates[0][i+1][0] )
                                    && between(lastLine.geometry.coordinates[0][1], buffered2.geometry.coordinates[0][i][1], buffered2.geometry.coordinates[0][i+1][1] )
                                )  {
                                    
                                } else if ( between(lastLine.geometry.coordinates[lastLine.geometry.coordinates.length - 1][0], buffered2.geometry.coordinates[0][i][0], buffered2.geometry.coordinates[0][i+1][0] )
                                && between(lastLine.geometry.coordinates[lastLine.geometry.coordinates.length - 1][1], buffered2.geometry.coordinates[0][i][1], buffered2.geometry.coordinates[0][i+1][1] )
                                ) {
                                
                                }
                                else{
                                    coordinates.push([buffered2.geometry.coordinates[0][i], buffered2.geometry.coordinates[0][i+1]])  
                                }
                            }

                            let bearing1 = turf.bearing(points[0][0],points[0][points[0].length -1])
                            let midpoint = turf.midpoint(points[0][0],points[0][points[0].length -1])
                            let options3 = {units: 'kilometers', bearing: bearing1};
                            let coordinatesOne1 = createRepetitionTextShape(midpoint.geometry.coordinates, bearing1, dist/2, options3);

                            // console.log(coordinatesOne1)

                            for(let i = 0; i < coordinatesOne1.length; i ++){
                                coordinates.push(coordinatesOne1[i])
                            }
                        }

                        

                        for(let i = 0; i < points[0].length; i++){
                            let steps = 16
                            let options = {steps: steps, units: 'kilometers', properties: {foo: 'bar'}};
                            let circle = turf.circle(points[0][i], dist, options);
                            coordinates.push(circle.geometry.coordinates[0]) 

                            let options2 = {units: 'kilometers', bearing: 90};
                            var coordinatesOne = createEndingTextShape(points[0][i], 90, dist, options2, i);

                            for(let i = 0; i < coordinatesOne.length; i ++){
                                coordinates.push(coordinatesOne[i])
                            }
                        }
                        
    
                        var multiline = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'MultiLineString', coordinates }
                        };
    
                        drawingsRef.current.add(multiline)
    

                    } else {
                        var feature = {
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'LineString', coordinates: points[0] }
                        };

                        var zoom = mapRef.current.getZoom();
                        var zoomDiff = (11 - zoom) / 2

                        if(1 + zoomDiff > 0.1){
                            var dist = 1 + zoomDiff
                        } else {
                            var dist = 0.1
                        }

                        let coordinates = []

                        for(let i =0; i < points[0].length - 1; i++) {
                            let lastLine = turf.lineString([points[0][i],points[0][i+1]])
                            let buffered2 = buffer(lastLine, dist, {units:'kilometers'});
                            
                            for(let i =0; i < buffered2.geometry.coordinates[0].length - 1;i++) {
                                if ( between(lastLine.geometry.coordinates[0][0], buffered2.geometry.coordinates[0][i][0], buffered2.geometry.coordinates[0][i+1][0] )
                                    && between(lastLine.geometry.coordinates[0][1], buffered2.geometry.coordinates[0][i][1], buffered2.geometry.coordinates[0][i+1][1] )
                                )  {
                                    
                                } else if ( between(lastLine.geometry.coordinates[lastLine.geometry.coordinates.length - 1][0], buffered2.geometry.coordinates[0][i][0], buffered2.geometry.coordinates[0][i+1][0] )
                                && between(lastLine.geometry.coordinates[lastLine.geometry.coordinates.length - 1][1], buffered2.geometry.coordinates[0][i][1], buffered2.geometry.coordinates[0][i+1][1] )
                                ) {
                                
                                }
                                else{
                                    coordinates.push([buffered2.geometry.coordinates[0][i], buffered2.geometry.coordinates[0][i+1]])  
                                }
                            }

                            let bearing1 = turf.bearing(points[0][i],points[0][i+1])
                            let midpoint = turf.midpoint(points[0][i],points[0][i+1])
                            let options3 = {units: 'kilometers', bearing: bearing1};
                            let coordinatesOne1 = createRepetitionTextShape(midpoint.geometry.coordinates, bearing1, dist/2, options3);

                            for(let i = 0; i < coordinatesOne1.length; i ++){
                                coordinates.push(coordinatesOne1[i])
                            }
                        }

                        if(points[0].length > 2){
                            let lastLine = turf.lineString([points[0][0],points[0][points[0].length -1]])
                        
                            let buffered2 = buffer(lastLine, dist, {units:'kilometers'});

                            for(let i =0; i < buffered2.geometry.coordinates[0].length - 1;i++) {
                                if ( between(lastLine.geometry.coordinates[0][0], buffered2.geometry.coordinates[0][i][0], buffered2.geometry.coordinates[0][i+1][0] )
                                    && between(lastLine.geometry.coordinates[0][1], buffered2.geometry.coordinates[0][i][1], buffered2.geometry.coordinates[0][i+1][1] )
                                )  {
                                    
                                } else if ( between(lastLine.geometry.coordinates[lastLine.geometry.coordinates.length - 1][0], buffered2.geometry.coordinates[0][i][0], buffered2.geometry.coordinates[0][i+1][0] )
                                && between(lastLine.geometry.coordinates[lastLine.geometry.coordinates.length - 1][1], buffered2.geometry.coordinates[0][i][1], buffered2.geometry.coordinates[0][i+1][1] )
                                ) {
                                
                                }
                                else{
                                    coordinates.push([buffered2.geometry.coordinates[0][i], buffered2.geometry.coordinates[0][i+1]])  
                                }
                            }

                            let bearing1 = turf.bearing(points[0][0],points[0][points[0].length -1])
                            let midpoint = turf.midpoint(points[0][0],points[0][points[0].length -1])
                            let options3 = {units: 'kilometers', bearing: bearing1};
                            let coordinatesOne1 = createRepetitionTextShape(midpoint.geometry.coordinates, bearing1, dist/2, options3);

                            //console.log(coordinatesOne1)

                            for(let i = 0; i < coordinatesOne1.length; i ++){
                                coordinates.push(coordinatesOne1[i])
                            }
                        }
                        

                        for(let i = 0; i < points[0].length; i++){
                            let steps = 16
                            let options = {steps: steps, units: 'kilometers', properties: {foo: 'bar'}};
                            let circle = turf.circle(points[0][i], dist, options);
                            coordinates.push(circle.geometry.coordinates[0]) 

                            let options2 = {units: 'kilometers', bearing: 90};
                            var coordinatesOne = createEndingTextShape(points[0][i], 90, dist, options2, i);

                            for(let i = 0; i < coordinatesOne.length; i ++){
                                coordinates.push(coordinatesOne[i])
                            }
                        }
                        
    
                        var multiline = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'MultiLineString', coordinates }
                        };
    
                        drawingsRef.current.add(multiline)

                        socketRef.current.emit("DRAWING_UPDATED", {project_id: currentProjectId, drawings: multiline});
                        var body = JSON.stringify({ project_id: currentProjectId, drawing: multiline });
                        ApiSaveProject(body);                        
                    }                    
                } 
            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
        }
    )

    const xDrawingPointRectangleSize = props.DrawingPointRectangleSize / oxDivider;
    const yDrawingPointRectangleSize = props.DrawingPointRectangleSize / oyDivider;

    function showLatLng(point) {
        const coord = TransformPoints([point], props.map)
        return `${coord[0][0][0].toFixed(3)}, ${coord[0][0][1].toFixed(3)}`
    }
    function showElevationOld(point) {
        const coord = TransformPoints([point], props.map)
        const el = mapRef.current.queryTerrainElevation([coord[0][0][0], coord[0][0][1]])
        return `${el.toFixed(3)}`
    }
    const [elevations, setElevations] = useState([]);

    async function showElevation(point) {
        try {
          const coord = TransformPoints([point], props.map);
          const response = await fetch(process.env.REACT_APP_TOPODATA_URL + coord[0][0][0] + "," + coord[0][0][1]);
          const data = await response.json();
          const elevationValue = data.results[0].elevation;
          return elevationValue.toFixed(3)
        } catch (error) {
          console.error("Error fetching elevation data:", error);
        }
    }

    async function fetchElevations(points) {
        const elevations = [];
    
        for (const [x, y] of points) {
            const elevation = await showElevation([x, y]);
            elevations.push(elevation);
        }
    
        return elevations;
    }
    
    
    useEffect(() => {
        if (currentPolyline.length > 0) {
            fetchElevations(currentPolyline).then((elevations) => {
                setElevations(elevations);
            });
        }
    }, [currentPolyline]);

    return (
        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT) }} >
            <svg style={{ width: '100%', height: '100%' }}>
                <polygon
                    ref={polylineRef}
                    points={currentPolyline.map(([x, y]) => `${x},${y}`).join(' ')}
                    style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4"}}
                />

                {currentPolyline.map(([x, y], index) => (
                    <g key={index}>
                        <text x={x-5*xDrawingPointRectangleSize} y={y+2*yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >
                            {elevations[index]}m, {showLatLng([x, y])}
                        </text>
                        {index === currentPolyline.length - 1 && <circle
                            cx = {x}
                            cy = {y}
                            r = {15}
                            style={{ fill: '#fb613b', stroke: 'white', strokeWidth: 4 }} 
                        />}
                        {index < currentPolyline.length - 1 && <circle
                            cx = {x}
                            cy = {y}
                            r = {10}
                            style={{ fill: '#fbb03b', stroke: 'white', strokeWidth: 4 }} 
                        />}
                        <text x={x - xDrawingPointRectangleSize / 3+1} y={y + yDrawingPointRectangleSize / 3-1} fontSize={xDrawingPointRectangleSize-2} fontWeight= "bold" fill="black">{index+1}</text>
                    </g>
                ))}
            </svg>
        </div>
    );
}

export default PolylineDroneDrawLayer;