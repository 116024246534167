import { useState, useEffect, forwardRef, useRef} from 'react';
import styles from './styles.module.css'


const SelectPickerMapComponent = forwardRef((props, ref) => {

    const [selectedValue, setSelectedValue] = useState();
    const [compHeight, setCompHeight] = useState(0);
    // const [titleIcon, setTitleIcon] = useState();
    const [title, setTitle] = useState();

    // const [haveImage, setHaveImage] = useState(false);

    const pickerRef = useRef();

    //const [selectedEchelon, setSelectedEchelon] = useState();

    useEffect(() => {
        setSelectedValue(props.SelectedValue);
        props.Items.map(item => {
            if (props.SelectedValue === item.value) {
                // setTitleIcon(item.icon);
                if (!item.icon) {
                    setTitle(item[props.Select]);
                } else {
                    setTitle("");
                }
            }
        });
        // setHaveImage(props.Items[1].icon?true:false);   

        if (props.direction && props.direction === "UP") {
            pickerRef.current.style.bottom = "25px";
        }

        if (props.VisibleItems >= props.Items.length) {
            pickerRef.current.style.overflow = "hidden";
        }
        
        
    }, [])

    return (     
        <div ref={ref} value={selectedValue} style={{ position:"absolute", transform: `matrix(1, 0, 0, 1, ${props.x}, ${props.y})`, }}>
            <div onClick={() => {
                        setCompHeight((compHeight === (props.VisibleItems * 36))?0:(props.VisibleItems * 36 - 6));
                    }}>
                <div style={{ transform: `matrix(1, 0, 0, 1, 0, 0)`, borderRadius:props.borderRadius, color:props.color, height: "20px", fontSize: props.fontSize, width:props.width, backgroundColor:props.backgroundColor, textAlign: props.textAlign }}>{title}</div>
                {/* {haveImage && <img alt={"img"} src={ titleIcon } width="100px"/>}  */}
            </div>
            <div ref={pickerRef} className={`${styles.select_picker_maps}`} style={{ height: compHeight, width:"100px" }}>
                <div style={{ position:"absolute", top:"2px", left:"2px", width:"96px"}}>
                {props.Items && props.Items.map(item => (
                    <div className={`${(selectedValue === item.value) ? styles.select_item_clicked_maps : styles.select_item_maps}`} key={item.value} onClick={() => {
                        setSelectedValue(item.value)
                        // setTitleIcon(item.icon);
                        if (!item.icon) {
                            setTitle(item[props.Select]);
                        } else {
                            setTitle("");
                        }
                        setCompHeight(0);

                        if (props.onChanged)
                            props.onChanged(item.value);
                    }}>
                        {/* {item.icon && <img alt = {"img"} src={ item.icon } width="100px"/> }  */}
                        {item.name}
                    </div>
                    ))}
                </div>
                
            </div>
        </div> 
        
    );
})

export default SelectPickerMapComponent;