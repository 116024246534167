import React, { useRef, useContext, useState, useEffect } from 'react';
import styles from '../css/symbols.module.css'
import { useGesture } from '@use-gesture/react'
import { GetMaxZIndex } from '../utils/Tools'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import { SvgIcon } from '@mui/material';
import "@fontsource/roboto";

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { GlobalContext } from "../contexts/globalContext"
import NewKeyboardComponent from "./keyboard/NewKeyboardComponent"
import NewInputTextComponent from './keyboard/NewInputTextComponent'

import "../utils/DragDropTouch"


import SymbolsCategoriesComponent from './symbols/SymbolsCategoriesComponent';

const SymbolsComponent = React.memo(props => {
    const ref = useRef();
    const kybSearchRef = useRef();
    const serachTermsRef = useRef();
    const [compHeight, setCompHeight] = useState(500);
    const [results, setResults] = useState([]);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;
    const { currentSite } = useContext(GlobalContext);

    const { setSymbolsMenuOpened,symbolsMenuOpened, symbolsData, setSymbolsData, currentDrawingColor, setCurrentDrawingColor } = useContext(GlobalContext);

    // useGesture(
    //     {
    //         onDrag: ({ pinching, cancel, lastOffset, delta: [dx, dy], first, memo }) => {
    //             if (pinching) {
    //                 return cancel()
    //             }
    //             if (first) {
    //                 ref.current.style.zIndex = GetMaxZIndex() + 1;
    //             }
    //             var mat = identity();

    //             if (ref.current.style.transform !== '') {
    //                 mat = fromString(ref.current.style.transform);
    //             }

    //             var trans = translate(dx, dy);
    //             var final = compose(trans, mat);
    //             ref.current.style.transform = toString(final);

    //             return memo
    //         },
    //         onPinch: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
    //             if (first) {
    //                 memo = [ox, oy, a, s]
    //             } else {
    //                 const { width, height, x, y } = ref.current.getBoundingClientRect()

    //                 // apply translation to center the element at pinch origin
    //                 var mat = fromString(ref.current.style.transform);
    //                 var trans = translate((ox - memo[0]) / oxDivider, (oy - memo[1]) / oyDivider);

    //                 // apply inverse transformation to find the rotated pinch origin
    //                 var inv = inverse(mat);
    //                 var pointCentruTranslatat = applyToPoint(inv, [(width / 2 + x) / oxDivider, (height / 2 + y) / oyDivider]);
    //                 var diffCenterX = pointCentruTranslatat[0] - (ref.current.offsetWidth / 2);
    //                 var diffCenterY = pointCentruTranslatat[1] - (ref.current.offsetHeight / 2);

    //                 // convert the pinch origin to the original scale
    //                 var pointOx = applyToPoint(inv, [ox / oxDivider, oy / oyDivider]);

    //                 // calculate the point to apply transformations
    //                 var point = applyToPoint(mat, [pointOx[0] - diffCenterX - ref.current.offsetWidth / 2, pointOx[1] - diffCenterY - ref.current.offsetHeight / 2]);

    //                 // apply rotation and scaling
    //                 var rotate = rotateDEG(a - memo[2], point[0], point[1]);
    //                 var scaleFunction = scale((s / memo[3]), (s / memo[3]), point[0], point[1]);

    //                 var final = compose(rotate, scaleFunction, trans, mat);
    //                 ref.current.style.transform = toString(final);

    //                 if (s <= 0.5) {
    //                     setSymbolsMenuOpened(false);
    //                 }

    //                 memo = [ox, oy, a, s]
    //             }
    //             return memo
    //         },
    //         onPinchEnd: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {

                
                   

    //             const { width, height, x, y } = ref.current.getBoundingClientRect();

    //             if (x + width /2 < 0){
    //                 ref.current.className = styles.symbols_component_anim;

    //                 var mat = fromString(ref.current.style.transform);
    //                 var trans = translate((-(x + width /2) ) / oxDivider, 0);
    //                 var final = compose(trans, mat);
    //                 ref.current.style.transform = toString(final);

    //                 setTimeout(() => {
    //                     ref.current.className = styles.symbols_component;
    //                 }, 500);

    //             }

    //             if (x + width /2 > window.innerWidth){
    //                 ref.current.className = styles.symbols_component_anim;
    //                 var mat = fromString(ref.current.style.transform);
    //                 var trans = translate(- ((x + width /2) - window.innerWidth) / oxDivider, 0);
    //                 var final = compose(trans, mat);
    //                 ref.current.style.transform = toString(final);

    //                 setTimeout(() => {
    //                     ref.current.className = styles.symbols_component;
    //                 }, 500);
    //             }

    //             if (y + height /2 < 0){
    //                 ref.current.className = styles.symbols_component_anim;
    //                 var mat = fromString(ref.current.style.transform);
    //                 var trans = translate(0, (-(y + height /2) ) / oyDivider);
    //                 var final = compose(trans, mat);
    //                 ref.current.style.transform = toString(final);

    //                 setTimeout(() => {
    //                     ref.current.className = styles.symbols_component;
    //                 }, 500);
    //             }

    //             if (y + height /2 > window.innerHeight){
    //                 ref.current.className = styles.symbols_component_anim;
    //                 var mat = fromString(ref.current.style.transform);
    //                 var trans = translate(0, - ((y + height /2) - window.innerHeight) / oyDivider);
    //                 var final = compose(trans, mat);
    //                 ref.current.style.transform = toString(final);

    //                 setTimeout(() => {
    //                     ref.current.className = styles.symbols_component;
    //                 }, 500);
    //             }

    //         }
    //     },
    //     {
    //         target: ref,
    //         drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
    //         pinch: { scaleBounds: { min: 0.5, max: 5 }, rubberband: true },
    //     }
    // )

    const [value, setValue] = React.useState(0);

    function colorButtonClickHandler(number) {        
        setCurrentDrawingColor(number);        
        var sd = symbolsData;

        // sd.forEach(element => {
        //     var catKeys = Object.keys(element.categories);
        //     catKeys.forEach(catKey => {
        //         var subKeys = Object.keys(element.categories[catKey]);
        //         subKeys.forEach(subKey => {
        //             element.categories[catKey][subKey].forEach(symbol => {
        //                 if (symbol.type == "symbol") {
        //                     let ns = symbol.icon.substring(0, 1) + number + symbol.icon.substring(2)
        //                     symbol.icon = ns;
        //                 }
        //             });
        //         });
        //     });
            
        // });

        // setSymbolsData(sd);      
    };

    const handleChange = (event, newValue) => {
      //  kybSearchRef.current.hideIt();
        setValue(newValue);
    };

    useEffect(() => {
        if (value === 0) {
            setCompHeight(650);
        } else {
            setCompHeight(830);
        }
        
    }, [value])

    // function onKeyboardClose() {
    //     setCompHeight(600);
    // }

    // function onKeyboardVisible() {
    //     // setKeyboardOpened(true);
        
    //     setCompHeight(750);
    // }

    function onTextChanged() {
        // console.log(serachTermsRef.current.value);
        var terms = serachTermsRef.current.value.toLowerCase().split(" ");
        // console.log(terms);
        var sd = symbolsData;
        var rezultat = [];

        sd.forEach(element => {
            var catKeys = Object.keys(element.categories);
            catKeys.forEach(catKey => {
                var subKeys = Object.keys(element.categories[catKey]);
                subKeys.forEach(subKey => {
                    element.categories[catKey][subKey].forEach(symbol => {
                        if (symbol.type == "symbol") {
                            // let ns = symbol.icon.substring(0, 1) + number + symbol.icon.substring(2)
                            // symbol.icon = ns;
                            if (symbol.ro) {
                                var found = true;
                                terms.map((term) => {
                                    if (!symbol.ro.full_name.toLowerCase().includes(term)) {
                                        found = false;
                                    }
                                });

                                if (found) {
                                    rezultat.push(symbol);
                                }
                            }
                            //console.log(symbol);
                        }
                    });
                });
            });
            
        });

        setResults(rezultat);
    }  

    const startDragSymbol = (event, icon) => {
        event.dataTransfer.setData("icon", icon);
    }

    // const addSymbolToMap = (icon) => {
    //     const uid = uuid();
    //     var symbol = {
    //         uid,
    //         location: [mapRef.current.getMap().getCenter().lat, mapRef.current.getMap().getCenter().lng],
    //         icon,
    //         altitude: null,
    //         uniqueDesignation: "",
    //         speed: null,
    //         reinforced: false,
    //         reduced: false,
    //         staffComments: "",
    //         additionalInfo: "",
    //         higherFormation: "",
    //         reliabilityRatings: "",
    //         credibilityRatings: "",
    //         effectiveness: "",
    //         sifCode: ""
    //     };

    //     if (currentProjectId == null) {
    //         setSymbols(symbols => [...symbols, symbol]);    
    //     } else {
    //         socketRef.current.emit("SYMBOL_UPDATED", {project_id: currentProjectId, symbol});            
    //     }        
    //     mapRef.current.getMap().setPitch(0);
    // }    

    return (
        <>
             <div className={`${styles.symbols_component}`} ref={ref} style={{fontFamily:'Roboto', padding:"10px", transform: `matrix(1, 0, 0, 1, ${props.X}, ${props.Y})`, width: 760, height: compHeight }} >
                {value == 0 && <div className={`${(currentDrawingColor == "F") ? styles.color_button_clicked : styles.color_button}`} style={{ transform: "matrix(1, 0, 0, 1, 21, 540)" }} onClick={() => {
                    colorButtonClickHandler("F");
                }}>
                    <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff1.png')} width="80px"></img>
                </div>}

                {value == 0 && <div className={`${(currentDrawingColor == "A") ? styles.color_button_clicked : styles.color_button}`} style={{ transform: "matrix(1, 0, 0, 1, 125, 540)" }} onClick={() => {
                    colorButtonClickHandler("A");
                }}>
                    <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff2.png')} width="80px"></img>
                </div>}

                {value == 0 && <div className={`${(currentDrawingColor == "U") ? styles.color_button_clicked : styles.color_button}`} style={{ transform: "matrix(1, 0, 0, 1, 229, 540)" }} onClick={() => {
                    colorButtonClickHandler("U");
                }}>
                    <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff3.png')} width="80px"></img>
                </div> }

                {value == 0 && <div className={`${(currentDrawingColor == "P") ? styles.color_button_clicked : styles.color_button}`} style={{ transform: "matrix(1, 0, 0, 1, 334, 540)" }} onClick={() => {
                    colorButtonClickHandler("P");
                }}>
                    <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff4.png')} width="80px"></img>
                </div>}

                {value == 0 && <div className={`${(currentDrawingColor == "N") ? styles.color_button_clicked : styles.color_button}`} style={{ transform: "matrix(1, 0, 0, 1, 438, 540)" }} onClick={() => {
                    colorButtonClickHandler("N");
                }}>
                    <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff5.png')} width="80px"></img>
                </div>}

                {value == 0 && <div className={`${(currentDrawingColor == "H") ? styles.color_button_clicked : styles.color_button}`} style={{ transform: "matrix(1, 0, 0, 1, 544, 540)" }} onClick={() => {
                    colorButtonClickHandler("H");
                }}>
                    <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff6.png')} width="80px"></img>
                </div>}

                {value == 0 && <div className={`${(currentDrawingColor == "S") ? styles.color_button_clicked : styles.color_button}`} style={{ transform: "matrix(1, 0, 0, 1, 649, 540)" }} onClick={() => {
                    colorButtonClickHandler("S");
                }}>
                    <img draggable="false" className={`${styles.not_draggable_image}`} src={require('../graphics/aff7.png')} width="80px"></img>
                </div>}

                
                <Box sx={{ bgcolor: 'transparent', width: "720px", marginLeft:"20px", marginTop:"20px" }}>
                    <AppBar position="static">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="transparent"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            style={{backgroundColor:"#383838"}}
                        >
                            <Tab style={{backgroundColor:"#000000"}} label="Galerie Simboluri"  />
                            <Tab style={{backgroundColor:"#000000"}} label="Cautare Simbol"  />
                        </Tabs>
                    </AppBar>

                    <div hidden={value !== 0} style={{backgroundColor:"transparent"}}>
                        <SymbolsCategoriesComponent></SymbolsCategoriesComponent>
                    </div>

                    <div hidden={value !== 1} style={{backgroundColor:"transparent"}}>
                    
                        <NewInputTextComponent onTextChanged={onTextChanged} ref={serachTermsRef} keyboardRef={kybSearchRef} value="" x="0" y="10" width="720" height="40" fontSize="25" placeHolder="Caută"></NewInputTextComponent>
                    
                        <div className={`${styles.projects_list_component}`} style={{ marginTop:"10px", marginLeft:"0px", width:"715px", backgroundColor:"#E8E8E8", transform: "matrix(1, 0, 0, 1, 4, 20)" }}>
                        {results.map((symbol, index) => (
                            <img width="55px" draggable="true" onDragStart={(event)=> startDragSymbol(event, symbol.icon)} key={index} style={{margin:"8px"}} src={ process.env.REACT_APP_MILSYMBOLS_URL.replace("{0}", symbol.icon) }/>
                        ))}
                        </div>
                        <NewKeyboardComponent visible style={{zIndex:"200"}} ref={kybSearchRef} x="-7" y="440" width="740" height="450" />
                    </div>                      
                </Box>
                
            </div>
            
        </>
    );
})

export default SymbolsComponent;