import { useRef, useContext, useEffect, forwardRef} from 'react';
import styles from './styles.module.css'
import { GlobalContext } from "../../contexts/globalContext"
import uuid from 'react-uuid';


const NewInputTextComponent = forwardRef((props, ref) => {
    // const { keyboardRef, setCurrentInputForKeyboard, setShowKeyboard } = useContext(GlobalContext);
    const keyboardRef = useRef();
    
    useEffect(() => {
        

        ref.current.setAttribute("name", uuid());
        ref.current.value = props.value;

        ref.current.onTextChanged = () => {
            if (props.onTextChanged)
                props.onTextChanged();
        }

        // ref.current.onClose = () => {
        //     if (props.onTextChanged)
        //         props.onTextChanged();
        // }

        ref.current.keyboardOpenAt = props.keyboardOpenAt;
        // ref.current.y = props.y;
        // ref.current.heightAux = props.height;
    }, [])

    useEffect(() => {
        keyboardRef.current = props.keyboardRef.current;
        if (keyboardRef.current) {
            keyboardRef.current.setInputElement(ref.current);
            
        }
        // console.log("modificat");
    }, [props.keyboardRef.current])
    
    const onChangeInput = event => {
        keyboardRef.current.setInput(event.target.value);
        ref.current.onTextChanged();
    };

    const onClosePressed = () => {
        keyboardRef.current.hideIt();
    };

    return (  
        <div style={{ marginTop:"10px", display: "flex", position: props.position, transform: `matrix(1, 0, 0, 1, ${props.x}, ${props.y})`, width: Number(props.width), height: Number(props.height) }}>   
        <input
            ref={ref}
            className={`${styles.input_text}`}
            style={{  width: Number(props.width), height: Number(props.height), fontSize:`${props.fontSize}px` }}
            placeholder={props.placeHolder}
            onFocus={() => { 
                if (keyboardRef.current) {
                    keyboardRef.current.setInputElement(ref.current);
                }
            }}
            
            onChange={onChangeInput}
        />
        {/* {!props.noClose && <div className={`${styles.close_button}`} style={{ transform: `matrix(1, 0, 0, 1, ${props.width - 50}, ${Number(props.height)/2 -  20})` }} onClick={() => onClosePressed()}> X</div>} */}
        </div>       
    );
})

export default NewInputTextComponent;

