import styles from '../css/dashboard.module.css'
import { useState, useRef, useEffect } from 'react';
import CallBtn from '../../../graphics/dashboard icons/new icons/btn-call.svg'
import LocationBtn from '../../../graphics/dashboard icons/new icons/btn-location.svg'
import VideocallBtn from '../../../graphics/dashboard icons/new icons/btn-videocam.svg'


import Lottie from 'react-lottie-player'
import lottieJson from '../../../graphics/dashboard icons/item-lista-activ.json'
import lottieJsonReady from '../../../graphics/dashboard icons/item-lista-ready.json'
import lottieJsonOffline from '../../../graphics/dashboard icons/item-lista-offline.json'

const razaMica = [
  {
    id: 1,
    name: "Rescue Helicopter 909",
    status: "ready",
    site: "Air Rescue Base"
  }
];

const razaMedie = [
  {
    id: 3,
    name: "Police Car 456",
    status: "activ",
    site: "Local Police Station"
  },
  {
    id: 6,
    name: "Ambulance 456",
    status: "offline",
    site: "City Medical Center"
  },
  {
    id: 5,
    name: "Police Car 789",
    status: "offline",
    site: "City Police Department"
  },
  ]

  const razaMare = [
    {
      id: 1,
      name: "Ambulanta 773",
      status: "activ",
      site: "ISU Brasov"
    },
    {
      id: 4,
      name: "Fire Truck 123",
      status: "activ",
      site: "City Fire Station"
    },
    {
      id: 3,
      name: "Security Vehicle 987",
      status: "ready",
      site: "Corporate Security Office"
    },
    
    {
      id: 2,
      name: "Emergency Medical Service 555",
      status: "ready",
      site: "EMS Station Central"
    },
    {
      id: 5,
      name: "Fire Truck 456",
      status: "offline",
      site: "City Fire Station"
    }
  ];
  

const ApropiereVehicleList = (props) => {
  const {type} = props
  const [vehicles, setVehicles] = useState(type === "mic" ? razaMica : (type === "mediu" ? razaMedie : razaMare))

  return (
    <div style={{display: "flex", flexDirection: 'column', top: '10px', position: "relative"}}>
      {vehicles.map((vehicle) => (
        <VehicleItem status= {vehicle.status} key={vehicle.id} {...vehicle} />
      ))}
    </div>
  );
};

const VehicleItem = ( {status, id, name, site}) => {
  const nameRef = useRef()

  const [posX, setPosX] = useState(0)

	const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;

  useEffect(() => {
		if(nameRef.current){
			setPosX(nameRef.current.getBoundingClientRect().width / oxDivider + 15)
		}
    }, [])

  return (
        <div className={`${styles.vehicleDiv}`}>
         <Lottie
            className={`${styles.activAnimation}`}
            loop
            animationData={status === "activ" ? lottieJson : (status === "ready" ? lottieJsonReady : lottieJsonOffline)}
            play
        />
        <label ref={nameRef} className={`${status === "offline" ? styles.nameLabel_offline : styles.nameLabel}`}> {name} </label>
        <div className={`${status === "activ" ? styles.labelDiv_live : (status === "ready" ? styles.labelDiv_copy : styles.labelDiv_offline) }`} style={{transform:`matrix(1, 0, 0, 1, ${posX}, 0)`}}>
          <label className={`${status === "activ" ? styles.labelLive : (status === "ready" ? styles.labelActivVehicle : styles.labelOffline)}`} > {status === "activ" ? 'Live' : (status === "ready" ? 'Ready' : 'Offline')} </label>
        </div>
        <label className={`${styles.siteLabel}`}> {site} </label>
        <div className={`${styles.btnsDiv_copy}`}>
          <img className={`${styles.btn_copy}`} alt="icon" draggable="false" src={LocationBtn} />
          <img className={`${styles.btn_copy}`} alt="icon" draggable="false" src={CallBtn}/>
          <img className={`${styles.btn_copy}`} alt="icon" draggable="false" src={VideocallBtn}/>
        </div>
        {/* <div className={`${styles.btnsDiv}`}>
            <div className={`${styles.cameraBtnDiv}`}>
                <img className={`${styles.btn}`} alt="icon" draggable="false" src={VideocallBtn}/>
            </div>
            <div className={`${styles.callBtnDiv}`}>
                <img className={`${styles.btn}`} alt="icon" draggable="false" src={CallBtn}/>
            </div>
            <div className={`${styles.locationBtnDiv}`}>
                <img className={`${styles.btn}`} alt="icon" draggable="false" src={LocationBtn}/>
            </div>
        </div> */}
    </div>
  );
};

export default ApropiereVehicleList;
