import { useRef, useState, useContext, useEffect } from 'react';
import styles from '../../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { TransformPoints } from '../../../utils/Tools'
import { GlobalContext } from "../../../contexts/globalContext"
import uuid from 'react-uuid';
import { ApiSaveProject } from "../../../utils/RestServices"
import * as turf from "@turf/turf"

const RectangleTextDrawLayer = (props) => {
    const ref = useRef(null);
    const rectangleRef = useRef(null);

    const {setCurrentShapeName, currentShape, setDrawingLayer, drawingsRef, currentDrawingColor, currentProjectId, socketRef, mapRef } = useContext(GlobalContext);
    const [currentRectangle, setCurrentRectangle] = useState([]);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    function createEndingTextShape(point1, bearing, dist, options){
        var coordinatesText = []

        for(let i = 0; i < currentShape.length; i++){
            let line = []
            for(let j = 0; j < 2; j++){
                let oxDist = dist * currentShape[i][j][0]
                let oyDist = dist * currentShape[i][j][1]
                if(oyDist === 0){
                    var point2 = turf.destination(point1, oxDist, bearing, options)
                    line.push(point2.geometry.coordinates)
                } else if(oyDist > 0){
                    let helpingPoint = turf.destination(point1, oyDist, bearing + 90, options)
                    var point3 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point3.geometry.coordinates)
                } else {
                    let helpingPoint = turf.destination(point1, Math.abs(oyDist), bearing + 270, options)
                    var point4 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point4.geometry.coordinates)
                }
            }
            coordinatesText.push(line)
        }

        return coordinatesText
    }

    useGesture(
        {
            onDrag: ({ pinching, cancel, xy: [ox, oy], first }) => {
                if (pinching) {
                    return cancel()
                }

                if (first) {
                    setCurrentRectangle([...currentRectangle, [ox, oy]]);
                } else {
                    const updatedPoints = currentRectangle.slice(0, -1);
                    updatedPoints.push([ox, oy]);
                    setCurrentRectangle(updatedPoints);
                }
            },
            onDragEnd: () => {
                const secondPoint = currentRectangle[1];

                if (secondPoint) {
                    const points = TransformPoints([currentRectangle[0], [currentRectangle[0][0], currentRectangle[1][1]], currentRectangle[1], [currentRectangle[1][0], currentRectangle[0][1]], currentRectangle[0]], props.map);
                    setCurrentRectangle([]);
                    setDrawingLayer(0)
                    setCurrentShapeName("")

                    if (currentProjectId == null) {
                        var feature = {
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'LineString', coordinates: points[0] }
                        };

                        var zoom = mapRef.current.getZoom();
                        var zoomDiff = (11 - zoom) / 5

                        if(0.3 + zoomDiff > 0.001){
                            var dist = 0.3 + zoomDiff
                        } else {
                            var dist = 0.001
                        }

                        let coordinates = []

                        for(let i = 0; i < 4; i++){
                            let point1 = []
                            let point2 = []
                            if(i < 3){
                                point1 = points[0][i]
                                point2 = points[0][i+1]
                            } else {
                                point1 = points[0][i]
                                point2 = points[0][0]
                            }
                            let midpoint = turf.midpoint(point1, point2);
                            let bearing = turf.bearing(point1, point2);
                            let options = {units: 'kilometers', bearing};
                            let midStopper1 = turf.destination(midpoint, dist * 2.5, bearing, options)
                            let midStopper2 = turf.destination(midpoint, dist * 2.5, bearing + 180, options)

                            coordinates.push([point1, midStopper2.geometry.coordinates])
                            coordinates.push([point2, midStopper1.geometry.coordinates])
                            
                            let options1 = {units: 'kilometers', bearing: 90};
                            var coordinatesOne = createEndingTextShape(midpoint, 90, dist, options1);

                            for(let i = 0; i < coordinatesOne.length; i ++){
                                coordinates.push(coordinatesOne[i])
                            }
                        }

                        var multiline = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'MultiLineString', coordinates }
                        };
    
                        drawingsRef.current.add(multiline)

                    } else {
                        var feature = {
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'LineString', coordinates: points[0] }
                        };

                        var zoom = mapRef.current.getZoom();
                        var zoomDiff = (11 - zoom) / 5

                        if(0.3 + zoomDiff > 0.1){
                            var dist = 0.3 + zoomDiff
                        } else {
                            var dist = 0.1
                        }

                        let coordinates = []

                        for(let i = 0; i < 4; i++){
                            let point1 = []
                            let point2 = []
                            if(i < 3){
                                point1 = points[0][i]
                                point2 = points[0][i+1]
                            } else {
                                point1 = points[0][i]
                                point2 = points[0][0]
                            }
                            let midpoint = turf.midpoint(point1, point2);
                            let bearing = turf.bearing(point1, point2);
                            let options = {units: 'kilometers', bearing};
                            let midStopper1 = turf.destination(midpoint, dist * 2.5, bearing, options)
                            let midStopper2 = turf.destination(midpoint, dist * 2.5, bearing + 180, options)

                            coordinates.push([point1, midStopper2.geometry.coordinates])
                            coordinates.push([point2, midStopper1.geometry.coordinates])
                            
                            let options1 = {units: 'kilometers', bearing: 90};
                            var coordinatesOne = createEndingTextShape(midpoint, 90, dist, options1);

                            for(let i = 0; i < coordinatesOne.length; i ++){
                                coordinates.push(coordinatesOne[i])
                            }
                        }

                        var multiline = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'MultiLineString', coordinates }
                        };
    
                        drawingsRef.current.add(multiline)


                        socketRef.current.emit("DRAWING_UPDATED", {project_id: currentProjectId, drawings: multiline});

                        //var body = JSON.stringify({ project_id: currentProjectId, data: { drawings: drawingsRef.current.getAll(), symbols:symbols } });
                        var body = JSON.stringify({ project_id: currentProjectId, drawing: multiline });
                        //e ok
                        ApiSaveProject(body);                        
                    }
                }
            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
        })


    const xDrawingPointRectangleSize = props.DrawingPointRectangleSize / oxDivider;
    const yDrawingPointRectangleSize = props.DrawingPointRectangleSize / oyDivider;

    function showLatLng(point) {
        const coord = TransformPoints([point], props.map)
        return `${coord[0][0][0].toFixed(3)}, ${coord[0][0][1].toFixed(3)}`
    }
    function showElevationOld(point) {
        const coord = TransformPoints([point], props.map)
        const el = mapRef.current.queryTerrainElevation([coord[0][0][0], coord[0][0][1]])
        return `${el.toFixed(3)}`
    }
    const [elevations, setElevations] = useState([]);

    async function showElevation(point) {
        try {
          const coord = TransformPoints([point], props.map);
          const response = await fetch(process.env.REACT_APP_TOPODATA_URL + coord[0][0][0] + "," + coord[0][0][1]);
          const data = await response.json();
          const elevationValue = data.results[0].elevation;
          return elevationValue.toFixed(3)
        } catch (error) {
          console.error("Error fetching elevation data:", error);
        }
    }

    async function fetchElevations(points) {
        const elevations = [];
    
        for (const [x, y] of points) {
            const elevation = await showElevation([x, y]);
            elevations.push(elevation);
        }
    
        return elevations;
    }
    
    
    useEffect(() => {
        if (currentRectangle.length > 0) {
            fetchElevations(currentRectangle).then((elevations) => {
                setElevations(elevations);
            });
        }
    }, [currentRectangle]);
    
    return (

        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT) }} >
            <svg style={{ width: '100%', height: '100%' }}>
                {currentRectangle.length > 1 && (
                    <rect
                        ref={rectangleRef}
                        x={Math.min(currentRectangle[0][0], currentRectangle[1][0])}
                        y={Math.min(currentRectangle[0][1], currentRectangle[1][1])}
                        width={Math.abs(currentRectangle[1][0] - currentRectangle[0][0])}
                        height={Math.abs(currentRectangle[1][1] - currentRectangle[0][1])}
                        style={{ fill: 'none',stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4" }}
                    />
                )}
                {currentRectangle.map(([x, y], index) => (
                    <g key={index}>
                        <text x={x-5*xDrawingPointRectangleSize} y={y+2*yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >
                            {elevations[index]}m, {showLatLng([x, y])}
                        </text>
                        <circle
                            cx = {x}
                            cy = {y}
                            r = {10}
                            style={{ fill: '#fbb03b', stroke: 'white', strokeWidth: 4 }} 
                        />
                    </g>
                ))}
            </svg>
        </div>
    );
}

export default RectangleTextDrawLayer;