import React, { useRef, useState, useContext, useEffect, forwardRef } from 'react';
import styles from '../../styles.module.css'
import Keyboard from 'react-simple-keyboard';
import { useGesture } from '@use-gesture/react'
import { GetMaxZIndex } from '../../utils/Tools'
import useSound from 'use-sound';

import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, identity, fromString } from 'transformation-matrix';
import 'react-simple-keyboard/build/css/index.css';
import './keyboard.css';
import clickSound from "../../sounds/click.wav";

const NewKeyboardComponent = forwardRef((props, ref) => {
    const currentInputForKeyboard = useRef();
    const compRef = useRef();
    // const [isMoving, setIsMoving] = useState(false);

    const [layoutName, setLayoutName] = useState("default");
    const [inputName, setInputName] = useState("default");
    // const [previousPosition, setPreviousPosition] = useState();
    // const [previousInput, setPreviousInput] = useState();

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const [playSound] = useSound(clickSound);

    useEffect(() => {
        // setPreviousPosition([compRef.current.getBoundingClientRect().x, compRef.current.getBoundingClientRect().y])
        ref.current.setInputElement = (element) => {
            currentInputForKeyboard.current = element;
            // setPreviousInput(element.value)

            setInputName(currentInputForKeyboard.current.getAttribute("name"));

            setWidth(Number(props.width));
            setHeight(Number(props.height));

            if (props.onVisible) {
                props.onVisible();
            }

            if (currentInputForKeyboard.current.keyboardOpenAt) {
                let splt = currentInputForKeyboard.current.keyboardOpenAt.split(",");
                let xx = Number(splt[0]);
                let yy = Number(splt[1]);
                compRef.current.style.transform = `matrix(1, 0, 0, 1, ${xx},  ${yy})`;
            }
        }

        ref.current.hideIt = () => {
            setWidth(0);
            setHeight(0);
            if (props.onClose) {
                props.onClose();
            }
        }

        if (props.visible) {
            setWidth(Number(props.width));
            setHeight(Number(props.height));
        }
    }, [])

    useEffect(() => {
        if (inputName && inputName !== "default") {
            ref.current.setInput(currentInputForKeyboard.current.value);
        }
    }, [inputName])

    // useGesture(
    //     {
    //         onDrag: ({ pinching, cancel}) => {
    //             if (pinching) {
    //                 setIsMoving(true);
    //                 return cancel()
    //             }
    //         },
    //         onPinchEnd: () => {
    //             console.log("s-a mutat")
    //             setIsMoving(false);
    //         },
    //     },
    //     {
    //         target: compRef,
    //     }
    // )

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();

        playSound();
        // if(ref){
        //     setPreviousInput()
        //     if(previousPosition[0]!== compRef.current.getBoundingClientRect().x || previousPosition[1]!== compRef.current.getBoundingClientRect().y){
        //         console.log("modificat", currentInputForKeyboard.current.value, previousInput)
        //         setPreviousPosition([compRef.current.getBoundingClientRect().x, compRef.current.getBoundingClientRect().y])
        //         setIsMoving(true)
        //     } else {
        //         setPreviousInput(currentInputForKeyboard.current.value)
        //         setIsMoving(false)
        //     }

        // }
    };

    const handleShift = () => {
        setLayoutName((layoutName === "default") ? "shift" : "default");
    };

    const onChangeAll = inputs => {
        // if (isMoving) {
        //     ref.current.setInput(currentInputForKeyboard.value);
        // } else {
        //     currentInputForKeyboard.current.value = inputs[currentInputForKeyboard.current.getAttribute("name")];
        //     currentInputForKeyboard.current.onTextChanged();
            
        // }
        currentInputForKeyboard.current.value = inputs[currentInputForKeyboard.current.getAttribute("name")];
        currentInputForKeyboard.current.onTextChanged();
    };



    return (

        <div ref={compRef} className={`${styles.keyboard_component}`} style={{  position:"relative", transform: `matrix(1, 0, 0, 1, ${props.x}, ${props.y})`, width: `${width}px`, height: `${height}px` }}>
            <Keyboard
                keyboardRef={r => (ref.current = r)}
                layoutName={layoutName}
                inputName={inputName}
                onKeyPress={onKeyPress}
                onChangeAll={onChangeAll}
                theme="hg-theme-default hg-layout-default myTheme"
                buttonTheme={[
                    {
                        class: "hg-light-gray",
                        buttons: "{shift}"
                    },
                    {
                        class: "hg-enter",
                        buttons: "{enter}"
                    },
                    {
                        class: "hg-gray",
                        buttons: "` 1 2 3 4 5 6 7 8 9 0 - = q w e r t y u i o p [ ] \\ a s d f g h j k l ; '  z x c v b n m , . / @ A B C D E F G H I J K L M N O P Q R S T U W V X Y Z < > ? ~ ! # $ % ^ & * ( ) { } | + : _"
                    },
                    {
                        class: "hg-gray",
                        buttons: '"'
                    },
                    {
                        class: "hg-space",
                        buttons: "{space}"
                    },
                    {
                        class: "hg-dark_gray",
                        buttons: "{bksp} {tab} {lock} .com"
                    }
                ]}
            />
        </div>

    );
})


export default NewKeyboardComponent;
