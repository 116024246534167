import React, { useRef, useContext, useState, useEffect } from 'react';
import MobileVideoComponent from './MobileVideoComponent';
import Peer from "simple-peer";
import { GlobalContext } from "../../contexts/globalContext"
import styles from '../../mobile.styles.module.css'


const MobileConferenceComponent = (props) => {
    const { socketRef, currentProjectId, setCurrentProjectId } = useContext(GlobalContext);
    const [streams, setStreams] = useState([]);
    const streamRef = useRef([]);

    const [myStream, setMyStream] = useState(null);

    //Per To Perr comm
    const [peers, setPeers] = useState([]);
    const peersRef = useRef([]);
    const VideoComponentSize = [300, 200, parseInt(process.env.REACT_APP_WIDTH) - 350];
    //Peer to Peer comm

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({video: {
            width: { ideal: 1280 },
            height: { ideal: 1024 },
            facingMode: "environment"
        }, audio: true }).then((stream) => {
            setMyStream({ stream, "userId": socketRef.current.id, "is_mine": true });
            //setStreams([{ stream, "userId": socketRef.current.id, "is_mine": true }]);
            //SetStreamsAndRecalculatePosition({ stream, "userId": socketRef.current.id, "is_mine": true });
            const track = stream.getVideoTracks()[0];
            const constraints = track.getConstraints();
            console.log('Result constraints: ' + JSON.stringify(constraints));


            socketRef.current.on("ALL_USERS", users => {
                console.log("ALL_USERS");
                const peers = [];
                users.forEach(user => {
                    const peer = createPeer(user.socket_id, socketRef.current.id, stream);
                    peersRef.current.push({
                        peerID: user.socket_id,
                        peer,
                    })

                    peer.on("stream", stream => {
                        //setStreams(streams => [...streams, { stream, "userId": userID, "is_mine": false }]);
                        SetStreamsAndRecalculatePosition({ stream, "userId": user.socket_id, "is_mine": false });

                    })

                    peers.push(peer);
                })
                setPeers(peers);
            })

            socketRef.current.on("USER_JOINED", payload => {
                const peer = addPeer(payload.signal, payload.callerID, stream);
                peersRef.current.push({
                    peerID: payload.callerID,
                    peer,
                })

                peer.on("stream", stream => {
                    //setStreams(streams => [...streams, { stream, "userId": payload.callerID, "is_mine": false }]);
                    SetStreamsAndRecalculatePosition({ stream, "userId": payload.callerID, "is_mine": false });
                })

                setPeers(peers => [...peers, peer]);
            });

            socketRef.current.on("RECEIVING_RETURNED_SIGNAL", payload => {
                const item = peersRef.current.find(p => p.peerID === payload.id);
                item.peer.signal(payload.signal);
            });

            socketRef.current.on('USER_DISCONECTED', (userId) => {
                if (userId == socketRef.current.id) {
                    console.log("me disconnected");

                    streamRef.current.forEach(stream => {
                        stream.stream.getTracks().forEach((track) => {
                            track.stop();
                        });
                    })

                    peersRef.current.forEach(peer => {
                        peer.peer.destroy();
                    })


                    setStreams([]);

                    peersRef.current = [];

                    streamRef.current = [];


                } else {
                    console.log("other disconnected");

                    const otherStream = streamRef.current.find(pr => pr.userId === userId);
                    if (otherStream && otherStream.stream) {
                        otherStream.stream.getTracks().forEach((track) => {
                            track.stop();
                        });
                    }


                    const peer = peersRef.current.find(p => p.peerID === userId);
                    if (peer && peer.peer)
                        peer.peer.destroy();

                    setStreams(streams => streams.filter(stre => stre.userId !== userId));

                    peersRef.current = peersRef.current.filter(pr => pr.peerID !== userId);

                    //streamRef
                    streamRef.current = streamRef.current.filter(pr => pr.userId !== userId);
                    SetStreamsAndRecalculatePosition();
                }


            });
        });
    }, [])

    function SetStreamsAndRecalculatePosition(new_stream_obj) {
        //const screenHeight = parseInt(process.env.REACT_APP_HEIGHT);
        if (new_stream_obj != null)
            streamRef.current.push(new_stream_obj);
        // var stream_no = streamRef.current.length;

        // const total_height = stream_no * VideoComponentSize[1] + (stream_no - 1) * 10;

        // var first_y = (screenHeight - total_height) / 2;

        // streamRef.current.forEach((stream_aux, index) => {
        //     stream_aux.X = VideoComponentSize[2];
        //     stream_aux.Y = first_y + (index * VideoComponentSize[1]) + 10 * (index - 1);
        //     stream_aux.WIDTH = VideoComponentSize[0];
        //     stream_aux.HEIGHT = VideoComponentSize[1];
        // });

        setStreams([...streamRef.current]);
    }

    function createPeer(userToSignal, callerID, stream) {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream,
        });

        peer.on("signal", signal => {
            socketRef.current.emit("SENDING_SIGNAL", { userToSignal, callerID, signal })
        })

        return peer;
    }

    function back() {

        //socketRef.current.emit("JOIN_SITES", [currentSite._id]);




        const old_project_id = currentProjectId;
        socketRef.current.emit("PROJECT_CHANGED", { old_project_id, new_project_id: null });
        if (old_project_id != null) {
            socketRef.current.emit("LEAVE_ROOM", old_project_id);
        }

        setCurrentProjectId(null);
        
    }

    function addPeer(incomingSignal, callerID, stream) {
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream,
        })

        peer.on("signal", signal => {
            socketRef.current.emit("RETURNING_SIGNAL", { signal, callerID })
        })

        peer.signal(incomingSignal);

        return peer;
    }
    
    return (
        <>
        {currentProjectId && <div style={{"backgroundColor":"black", "display": "flex", "flexDirection": "row", "flexWrap": "wrap", height: "100vh"}}>
            <div key="cheie" className={`${styles.back_button}`} onClick={back}>
                    <>Inapoi</>
                </div>

            {myStream && <MobileVideoComponent key="my_streamex" stream={myStream.stream} is_mine={myStream.is_mine} />}

            {streams  && streams.map((stream) => {
                return (
                    <MobileVideoComponent key={stream.userId} stream={stream.stream} is_mine={stream.is_mine} />
                );
            })}
        </div >}
        </>
    );

}

export default MobileConferenceComponent;