import { useRef, useEffect, useContext, useState } from 'react';
import io from "socket.io-client"
import MobileLoginComponent from './components/mobile/MobileLoginComponent';
import MobileProjectsComponent from './components/mobile/MobileProjectsComponent';
import MobileConferenceComponent from './components/mobile/MobileConferenceComponent';
import MobileInviteComponent from './components/mobile/MobileInviteComponent';



import { GlobalContext } from "./contexts/globalContext";
import "./main.css";

function AppMobile() {
    const { currentUser, currentProjectId, socketRef, logged } = useContext(GlobalContext);
    const currentUserRef = useRef(null);
    const canSendRef = useRef(true);

    useEffect(() => {
        currentUserRef.current = currentUser;
    }, [currentUser])

    useEffect(() => {
        socketRef.current = io.connect(process.env.REACT_APP_NOTIFICATION_URL, { secure: true, reconnect: true, rejectUnauthorized : false })

        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(function(position) {
                if (currentUserRef.current && canSendRef.current) {
                    canSendRef.current = false;
                    var body = JSON.stringify({  "react_web_age": "type", "id": currentUserRef.current._id, "name": currentUserRef.current.name, "position": [position.coords.latitude, position.coords.longitude] });
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: body
                    };

                    fetch(process.env.REACT_APP_API_URL + '/sendData', requestOptions)
                        .then(data => {
                            canSendRef.current = true;                            
                        }
                    );
                }              
            });
          }
    }, [])
       
    return (
        <>
            {!logged &&<MobileLoginComponent></MobileLoginComponent>}            
            {logged && !currentProjectId && <MobileProjectsComponent></MobileProjectsComponent>}   
            {logged && <MobileConferenceComponent></MobileConferenceComponent>}
            {logged && <MobileInviteComponent></MobileInviteComponent>}
                     
        </>
    );
}

export default AppMobile;
