import React, { useRef, useEffect, useContext, useState, createRef } from 'react';
import styles from './../styles.module.css'
import { GlobalContext } from "../contexts/globalContext"
import { useGesture } from '@use-gesture/react'
import uuid from 'react-uuid';
import Lottie from 'react-lottie-player'
import "@fontsource/roboto";

import PdfIcon from '../graphics/file-picker/file-ic-pdf.svg';
import WordIcon from '../graphics/file-picker/file-ic-word.svg'
import ExcelIcon from '../graphics/file-picker/file-ic-excel.svg'
import VideoIcon from '../graphics/file-picker/file-ic-video.svg'
import PlusIcon from '../graphics/file-picker/btn-add-file.svg'
import PlusAnimation from '../graphics/file-picker/btn-add-animat.json'
import PlusIconActiv from '../graphics/file-picker/btn-add-file-activ.svg'
import Ilustratie from '../graphics/file-picker/add-file-illustratie.png'
import DeleteAnimation from '../graphics/file-picker/anim-cos.json'

import { translate, compose, toString, identity, fromString } from 'transformation-matrix';

import { GetMaxZIndex } from '../utils/Tools'


const NewFilePickerComponent = (props) => {

    const ref = useRef(null);
    const filesRef = useRef(null);
    const filePickerRef = useRef();
    const countdownButtonRef = useRef();
    const deleteFileTimeout = useRef();
    // const deleteBgkRef = useRef();

    const [reRenderIcon, setReRenderIcon] = useState(-1);
    const [reRenderDelete, setReRenderDelete] = useState(-1);

    const [justOpened, setJustOpened] = useState(0);

    const [isPlusAnimationOpen, setIsPlusAnimationOpen] = useState(false);
    const [isPlusReverseAnimationOpen, setIsPlusReverseAnimationOpen] = useState(false);
    const [isButtonActive, setIsButtonActive] = useState(-1);

    const [filesListComponentWidth, setFilesListComponentWidth] = useState(0);
    const [initiatingDelete, setInitiatingDelete] = useState();
    const [fileIndex, setFileIndex] = useState(0);
    const [fileIndexDelete, setFileIndexDelete] = useState(0);

    const [previousDelete, setPreviousDelete] = useState(false);
    const [triggerRecalc, setTriggerRecalc] = useState(false);

    var [fileCompPos, setFileCompPos] = useState([])

    var movement = 0 // -1 sus, 1 jos

    const { setFilesToBeOpen, filesToBeOpen, socketRef, files, setFiles, currentProjectId } = useContext(GlobalContext);

    const fileRefs = useRef(files.map(() => createRef()));
    const secondIconsRef = useRef(files.map(() => createRef()));
    const thirdIconsRef = useRef(files.map(() => createRef()));
    const fileBgkRefs = useRef(files.map(() => createRef()));
    const fileComponentRefs = useRef(files.map(() => createRef()));


    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const deleteCountdown = 2000

    function currentFilesCount(currentFileName) {
        return filesToBeOpen.reduce((count, file) => {
            if (file.name === currentFileName) {
                count += 1;
            }
            return count;
        }, 0);
    }

    const bindOpen = useGesture(
        {
            onDrag: ({ pinching, cancel, delta: [dx, dy], xy: [x, y], first, memo, direction, canceled }) => {
                if (pinching) {
                    return cancel()
                }

                if (!canceled) {
                    if (movement === 0) {
                        movement = direction[1]
                    }

                    var mat = identity();
                    var trans = translate(0, 0);
                    var final = compose(trans, mat);

                    if (!first) {
                        trans = translate(dx, dy)
                        if (movement === -1) {
                            if (fileRefs.current[fileIndex].current.getBoundingClientRect().y / oyDivider >= 2000) {
                                return cancel()
                            }

                            fileRefs.current[fileIndex].current.style.zIndex = GetMaxZIndex() + 1;


                            if (fileRefs.current[fileIndex].current.style.transform !== '') {
                                mat = fromString(fileRefs.current[fileIndex].current.style.transform);
                            }

                            final = compose(trans, mat);

                            fileRefs.current[fileIndex].current.style.transform = toString(final);

                        } else if (movement === 1) {
                            if (fileBgkRefs.current[fileIndex].current.getBoundingClientRect().y / oyDivider < 1900) {
                                fileBgkRefs.current[fileIndex].current.style.transform = "matrix(1, 0, 0, 1, 0, -130)"
                                return cancel()
                            }

                            fileBgkRefs.current[fileIndex].current.style.zIndex = GetMaxZIndex() + 1;

                            if (fileBgkRefs.current[fileIndex].current.style.transform !== '') {
                                mat = fromString(fileBgkRefs.current[fileIndex].current.style.transform);
                            }

                            final = compose(trans, mat);

                            fileBgkRefs.current[fileIndex].current.style.transform = toString(final);
                            fileBgkRefs.current[fileIndex].current.style.opacity = `${(2200 - y) / 200 + 0.2}`
                        }
                        return memo
                    }
                }


            },
            onDragEnd: () => {
                var lastPositionX = fileRefs.current[fileIndex].current.getBoundingClientRect().x / oxDivider
                var lastPositionY = fileRefs.current[fileIndex].current.getBoundingClientRect().y / oyDivider

                if (movement === 1) {
                    lastPositionX = fileBgkRefs.current[fileIndex].current.getBoundingClientRect().x / oxDivider
                    lastPositionY = fileBgkRefs.current[fileIndex].current.getBoundingClientRect().y / oyDivider
                }

                setReRenderIcon(fileIndex)

                if (lastPositionX >= 1900 - filesListComponentWidth / 2 && lastPositionX <= 2000 + filesListComponentWidth / 2 && lastPositionY >= 2000 && movement === 1 && !initiatingDelete) {

                    setInitiatingDelete(files[fileIndex].uid)

                    const fileIndexForDelete = fileIndex
                    setFileIndexDelete(fileIndexForDelete)

                    deleteFileTimeout.current = setTimeout(() => {
                        deleteFile(files[fileIndexForDelete].path)
                        setPreviousDelete(true)
                        setTriggerRecalc(true)
                    }, deleteCountdown + 200)
                } else if (lastPositionY < 1800 && movement === -1) {
                    const countForCurrentFile = currentFilesCount(files[fileIndex].name)

                    if (countForCurrentFile < 3) {
                        setFilesToBeOpen([...filesToBeOpen, {
                            uid: uuid(),
                            name: files[fileIndex].name,
                            path: files[fileIndex].path,
                            mimetype: files[fileIndex].mimetype,
                            x: lastPositionX,
                            y: lastPositionY
                        }])
                    }
                } else if (movement === 1) {
                    setReRenderDelete(fileIndex)
                }
                setTimeout(() => {
                    setReRenderIcon(-1)
                    setReRenderDelete(-1)
                    setFileIndex(null)
                    movement = 0
                }, 0);

            },
        },
        {
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
        }
    )

    useEffect(() => {
        fileRefs.current = files.map(() => createRef());
        secondIconsRef.current = files.map(() => createRef());
        thirdIconsRef.current = files.map(() => createRef());
        fileBgkRefs.current = files.map(() => createRef());
        fileComponentRefs.current = files.map(() => createRef());

        filesRef.current = files;

        if (filesRef.current.length > 0 && filesRef.current.length < 22) {
            setFilesListComponentWidth(125 * filesRef.current.length);
        } else if (filesRef.current.length >= 22) {
            setFilesListComponentWidth(2750);
        } else {
            setFilesListComponentWidth(150);
        }


    }, [files]);

    useEffect(() => {
        if (initiatingDelete) {
            setTimeout(() => {
                countdownButtonRef.current.style.backgroundPosition = "right bottom"
            }, 100)
        }
    }, [initiatingDelete]);

    useEffect(() => {
        if (previousDelete && fileIndexDelete) {
            var filePosArray = Array(files.length - 1).fill(0)
            for (let i = 0; i < files.length; i++) {
                if (i < fileIndexDelete) {
                    filePosArray[i] = i * 125
                } else if (i > fileIndexDelete) {
                    filePosArray[i - 1] = i * 125
                }
            }
            setFileCompPos(filePosArray)
        }
    }, [triggerRecalc]);



    useEffect(() => {
        socketRef.current.on("ADD_FILES_TO_PROJECT", newFiles => {
            setFiles([...filesRef.current, ...newFiles]);
            setJustOpened(newFiles)
            setTimeout(() => {
                setJustOpened(0)
            }, 2000);
        });

        socketRef.current.on("REMOVE_FILE_FROM_PROJECT", filepath => {
            var aux_files = filesRef.current.filter(file => file.path != filepath);
            setFiles(aux_files);
        });

        ref.current.addEventListener("drop", function (event) {
            var file_string = event.dataTransfer.getData("file");
            let file = JSON.parse(file_string);
            setInitiatingDelete(file.uid)

            // fileBgkRefs.current[file.in].current.style.opacity = "0"
            // fileBgkRefs.current[fileIndex].current.style.transition = "opacity 2s ease-in"
        });

        ref.current.addEventListener("dragover", function (event) {
            event.preventDefault();
        });


    }, [])

    function isFileNew(id) {
        if (justOpened.length > 0) {
            for (let i = 0; i < justOpened.length; i++) {
                if (id === justOpened[i].uid) {
                    return true
                }
            }
        }
        return false
    }

    const onFileChange = event => {
        setIsPlusReverseAnimationOpen(true)
        setIsButtonActive(0)

        if (event.target.files.length > 0) {

            ref.current.style.transition = "width 0.3s ease-in, transform 0.3s ease-in"

            var formData = new FormData()
            formData.append('project_id', currentProjectId);

            for (var index = 0; index < event.target.files.length; index++) {
                formData.append('files', event.target.files[index]);
            }

            const requestOptions = {
                method: 'POST',
                body: formData
            };

            fetch(process.env.REACT_APP_API_URL + '/project.add.files', requestOptions)
                .then(response => response.json())
                .then(data => {
                    socketRef.current.emit("ADD_FILES_TO_PROJECT", { project_id: currentProjectId, files: data });
                }).catch(error => {
                    console.error('There was an error!', error);
                });
        }
    };

    const deleteFile = path => {

        ref.current.style.transition = "width 0.5s ease-in, transform 0.5s ease-in"

        var formData = new FormData()
        formData.append('project_id', currentProjectId);
        formData.append('filepath', path);

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        fetch(process.env.REACT_APP_API_URL + '/project.remove.file', requestOptions)
            .then(response => response.json())
            .then(data => {
                socketRef.current.emit("REMOVE_FILE_FROM_PROJECT", { project_id: currentProjectId, filepath: path });

                setTimeout(() => {
                    setInitiatingDelete()
                    setFileIndexDelete()
                    setTriggerRecalc(false)
                    setPreviousDelete(false)
                }, 0);

            }).catch(error => {
                console.error('There was an error!', error);
            });

    };

    const createIcon = (file) => {
        if (file.mimetype.includes("image/")) {
            if (file.thumb) {
                return process.env.REACT_APP_CMS_URL + file.thumb
            } else {
                return process.env.REACT_APP_CMS_URL + file.path
            }

        } else if (file.mimetype.includes("word")) {
            return WordIcon;
        } else if (file.mimetype.includes("pdf")) {
            return PdfIcon;
        } else if (file.mimetype.includes("ms-excel") || file.mimetype.includes("spreadsheetml")) {
            return ExcelIcon;
        } else if (file.mimetype.includes("video/")) {
            return VideoIcon;
        }
    };

    const formatText = (file) => {
        var names = file.split(".");
        var name = names[0];
        if (name.length > 13) {
            name = name.substring(0, 13) + "...";
        }
        return name;

    }

    useEffect(() => {
        const input = filePickerRef.current;
        if (!input) return;

        const onFocus = () => {
            window.removeEventListener('focus', onFocus);
            setIsPlusReverseAnimationOpen(true)
            setIsButtonActive(0)
        };

        const onClick = () => {
            window.addEventListener('focus', onFocus);
        };

        input.addEventListener('click', onClick);

        return () => {
            input.removeEventListener('click', onClick);
            window.removeEventListener('focus', onFocus);
        };
    }, [filePickerRef]);

    const onImageLoaded =(el, opac)=> {

        // console.log(opac);
        var ratio = 80 / el.target.clientHeight;
        var newWidth = el.target.clientWidth * ratio;
        var newHeight = 80;

        if (newWidth > 60) {
            var ratio = 60 / el.target.clientWidth;
            newWidth = 60;
            newHeight = el.target.clientHeight * ratio;
        }

        el.target.width = newWidth;
        el.target.height = newHeight;

        el.target.style.opacity = opac;
    }

    return (
        <div className={`${props.filesMenuOpened ? styles.files_component : styles.files_component_close}`} ref={ref} style={{ fontFamily: 'Roboto', width: `${filesListComponentWidth + 165}px`, height: `${props.height}px` }}>
            <input ref={filePickerRef} type="file" multiple onChange={(event) => onFileChange(event)} style={{ opacity: 1, width: "0px", height: "0px" }}
                onClick={() => {
                    setIsPlusAnimationOpen(true)
                    setIsButtonActive(0)
                }} />

            <div className={`${styles.add_file_button}`}>
                {isButtonActive === -1 && <img src={PlusIcon} style={{ width: "100px", height: "100px", position: "absolute", top: "26px", left: "25px" }} onClick={() => filePickerRef.current.click()}
                    onChange={(event) => {
                        event.preventDefault();
                        filePickerRef.current.click();
                    }} alt={"iconita de plus"} />}
                {isPlusAnimationOpen && <Lottie
                    onComplete={() => {
                        setIsButtonActive(1)
                        setIsPlusAnimationOpen(false)
                    }}
                    loop={false}
                    animationData={PlusAnimation}
                    play={isPlusAnimationOpen}
                    // segments={[-1,11]}
                    style={{ width: "116px", height: "116px", position: "absolute", top: "17px", left: "17px" }}
                />}
                {isPlusReverseAnimationOpen && <Lottie
                    onComplete={() => {
                        setIsButtonActive(-1)
                        setIsPlusReverseAnimationOpen(false)
                    }}
                    loop={false}
                    direction={-1}
                    animationData={PlusAnimation}
                    play={isPlusReverseAnimationOpen}
                    // segments={[0,9]}
                    speed={0.7}
                    style={{ width: "116px", height: "116px", position: "absolute", top: "17px", left: "17px" }}
                />}
                {isButtonActive === 1 && <img alt={"iconita de plus active"} src={PlusIconActiv} style={{ width: "84px", height: "84px", position: "absolute", top: "32px", left: "33px" }} />}
            </div>

            <div className={`${styles.files_list_component}`} style={{ width: `${filesListComponentWidth}px`, paddingBottom: "5px", transform: "matrix(1, 0, 0, 1, 0, 10)" }}>
                {files.length === 0 &&
                    <div style={{ width: "140px", height: "150px" }}>
                        <img alt={"imagine fara files"} src={Ilustratie} style={{ width: "100px", height: "100px", position: "absolute", top: "-5px", left: "35px" }} />
                        <div style={{ fontStyle: 'Roboto', textAlign: "center", width: "140px", height: "12px", position: "absolute", top: "90px", left: "15px", fontSize: "12px", color: "white" }}>
                            Galeria este goală
                        </div>
                        <div style={{ fontStyle: 'Roboto', textAlign: "center", width: "140px", height: "30px", position: "absolute", top: "102px", left: "15px", fontSize: "10px", color: "#A9A9AB" }}>
                            Atingeți butonul + pentru a adăuga fișiere.
                        </div>
                    </div>
                }
                {files && files.map((file, index) => (
                    <div ref={fileComponentRefs.current[index]} style={{ position: "absolute", transform: `matrix(1, 0, 0, 1, ${previousDelete ? fileCompPos[index] : index * 125}, 0)`, transition: `${previousDelete ? "" : "transform 1s"}` }}>
                        <div style={{ width: "110px", height: "130px", marginRight: "15px" }} >
                            {/* <div ref={deleteBgkRef} className={`${styles.file_button_copy}`} key={index} >

                                <div style={{overflow:"visible", width:"110px", height: "130px", touchAction: "none", transform: "matrix(1, 0, 0, 1, -20, 0)"}}>
                                    <div className={styles.div_icon} ref={thirdIconsRef.current[index]} style={{transform: `matrix(1, 0, 0, 1, 40, 5)`}}>
                                        <img className={styles.icon_img} alt="icon" draggable="false" src={createIcon(file)} 
                                        style={{opacity:`0.3`}}/>
                                    </div>
                                    
                                    <div className={styles.div_icon} ref={secondIconsRef.current[index]} style={{transform: `matrix(1, 0, 0, 1, 35, 10`}}>
                                        <img className={styles.icon_img} alt="icon" draggable="false" src={createIcon(file)} style={{opacity:`0.6`}}/>
                                    </div>
                                    
                                    <div style={{ textAlign: "center", overflowErap: "break-word", overflow:"hidden", position: "absolute", height:"50px", width:"110px", transform: "matrix(1, 0, 0, 1, 20, 100)"}}>
                                        <span style={{width:"110px", whiteSpace: "nowrap",  textAlign: "center", fontSize: "12px", color:"white", textShadow:"0px 0px 10px black"}} >{formatText(file.name)}</span>
                                    </div>
                                    
                                </div>
                                <div style={{transform: "matrix(1, 0, 0, 1, -20, -115)", overflow:"visible", width:"150px", height: "150px", touchAction: "none"}}>
                                    <div style={{ position: "absolute", height:"80px", width:"90px", transform:"matrix(1, 0, 0, 1, 30, 0)" }}>
                                        <img className={styles.icon_img} alt="icon" draggable="false" src={createIcon(file)}/>
                                    </div>    
                                </div>
                            </div> */}
                            {(file.uid === initiatingDelete) && <>
                                <button ref={countdownButtonRef} className={styles.countdown_button} style={{ position: "absolute", transform: `matrix(1, 0, 0, 1, 5, 50)` }}
                                    onClick={() => {
                                        setInitiatingDelete()

                                        clearTimeout(deleteFileTimeout.current);
                                        deleteFileTimeout.current = null;

                                    }}>
                                    RESTITUIE
                                </button>

                                <Lottie
                                    onClick={() => {
                                        deleteFile(file.path)
                                    }}
                                    loop={false}
                                    animationData={DeleteAnimation}
                                    play={file.uid === initiatingDelete}
                                    style={{ position: "absolute", top: "140px", left: "-10px", width: "140px" }}
                                /></>}
                        </div>


                        {(file.uid !== initiatingDelete) && reRenderDelete !== index && <div className={`${isFileNew(file.uid) ? styles.file_button_popup : styles.file_button}`} ref={fileBgkRefs.current[index]} key={index} style={{ transform: "matrix(1, 0, 0, 1, 0, -130)" }}>
                            <div style={{ overflow: "visible", width: "110px", height: "130px", touchAction: "none", transform: "matrix(1, 0, 0, 1, -20, 0)" }}>
                                {currentFilesCount(files[index].name) < 2 && <div className={styles.div_icon} ref={thirdIconsRef.current[index]}
                                    style={{ transform: `matrix(1, 0, 0, 1, ${fileIndex === index ? (currentFilesCount(files[index].name) === 0 ? "35, 10" : "30, 15") : (currentFilesCount(files[index].name) === 0 ? "40, 5" : "35, 10")})` }}>
                                    <img className={styles.icon_img} alt="icon" draggable="false" src={createIcon(file)} onLoad={(el) => onImageLoaded(el, `${fileIndex === index ? (currentFilesCount(files[index].name) === 0 ? 0.6 : 1) : (currentFilesCount(files[index].name) === 0 ? 0.3 : 0.6)}`)}
                                        // style={{ opacity: `${fileIndex === index ? (currentFilesCount(files[index].name) === 0 ? 0.6 : 1) : (currentFilesCount(files[index].name) === 0 ? 0.3 : 0.6)}` }}
                                         />
                                </div>}

                                {currentFilesCount(files[index].name) < 1 && <div className={styles.div_icon} ref={secondIconsRef.current[index]}
                                    style={{ transform: `matrix(1, 0, 0, 1, ${fileIndex === index ? "30, 15" : "35, 10"})` }}>
                                    <img className={styles.icon_img} alt="icon" draggable="false" onLoad={(el) => onImageLoaded(el, `${fileIndex === index ? 1 : 0.6}`)} src={createIcon(file)} 
                                    // style={{ opacity: `${fileIndex === index ? 1 : 0.6}` }} 
                                    />
                                </div>}

                                <div style={{ textAlign: "center", overflowErap: "break-word", overflow: "hidden", position: "absolute", height: "50px", width: "110px", transform: "matrix(1, 0, 0, 1, 20, 100)" }}>
                                    <span style={{ width: "110px", whiteSpace: "nowrap", textAlign: "center", fontSize: "12px", color: "white", textShadow: "0px 0px 10px black" }} >{formatText(file.name)}</span>
                                </div>

                            </div>
                            {reRenderIcon !== index && currentFilesCount(files[index].name) < 3 &&
                                <div {...bindOpen()} ref={fileRefs.current[index]} onMouseDown={() => setFileIndex(index)} onTouchStart={() => setFileIndex(index)} style={{ transform: "matrix(1, 0, 0, 1, -20, -115)", overflow: "visible", width: "150px", height: "150px", touchAction: "none" }}>
                                    <div style={{ position: "absolute", height: "80px", width: "90px", transform: "matrix(1, 0, 0, 1, 30, 0)" }}>
                                        <img className={styles.icon_img}  alt="icon" draggable="false" src={createIcon(file)} onLoad={(el) => onImageLoaded(el, "1")} />
                                    </div>
                                </div>}
                        </div>}

                    </div>

                ))}
            </div>
        </div>
    );
}

export default NewFilePickerComponent;