import React, { useRef, useState, useContext, useEffect } from 'react';
import styles from '../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { GetMaxZIndex } from '../../utils/Tools'
import { scale, rotateDEG, translate, compose, applyToPoint, inverse, toString, fromString } from 'transformation-matrix';
import { GlobalContext } from "../../contexts/globalContext"
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const KeyboardComponent = () => {
    const ref = useRef();

    const [layoutName, setLayoutName] = useState("default");
    const [inputName, setInputName] = useState("default");

    const [isMoving, setIsMoving] = useState(false);
    
    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const { setShowKeyboard, currentInputForKeyboard, keyboardRef } = useContext(GlobalContext);    

    useEffect(() => {
        keyboardRef.current.setInput(currentInputForKeyboard.value)
    }, [inputName])

    useEffect(() => {
        if (currentInputForKeyboard != null) {
            setInputName(currentInputForKeyboard.getAttribute("name"));
        } else {
            setInputName("");
        }
    }, [currentInputForKeyboard])    

    useGesture(
        {
            onDrag: ({ pinching, cancel, delta: [dx, dy], first, memo }) => {
                if (pinching) {
                    setIsMoving(true);
                    return cancel()
                }
                return memo
            },
            onPinch: ({ origin: [ox, oy], first, offset: [s, a], memo }) => {
                if (first) {
                    memo = [ox, oy, a, s]
                    ref.current.style.zIndex = GetMaxZIndex() + 1;
                } else {
                    const { width, height, x, y } = ref.current.getBoundingClientRect()

                    // apply translation to center the element at pinch origin
                    var mat = fromString(ref.current.style.transform);
                    var trans = translate((ox - memo[0]) / oxDivider, (oy - memo[1]) / oyDivider);

                    // apply inverse transformation to find the rotated pinch origin
                    var inv = inverse(mat);
                    var pointCentruTranslatat = applyToPoint(inv, [(width / 2 + x) / oxDivider, (height / 2 + y) / oyDivider]);
                    var diffCenterX = pointCentruTranslatat[0] - (ref.current.offsetWidth / 2);
                    var diffCenterY = pointCentruTranslatat[1] - (ref.current.offsetHeight / 2);

                    // convert the pinch origin to the original scale
                    var pointOx = applyToPoint(inv, [ox / oxDivider, oy / oyDivider]);

                    // calculate the point to apply transformations
                    var point = applyToPoint(mat, [pointOx[0] - diffCenterX - ref.current.offsetWidth / 2, pointOx[1] - diffCenterY - ref.current.offsetHeight / 2]);

                    // apply rotation and scaling
                    var rotate = rotateDEG(a - memo[2], point[0], point[1]);
                    var scaleFunction = scale((s / memo[3]), (s / memo[3]), point[0], point[1]);

                    if (s <= 0.5) {
                        setShowKeyboard(false);
                    }

                    var final = compose(rotate, scaleFunction, trans, mat);
                    ref.current.style.transform = toString(final);

                    memo = [ox, oy, a, s]
                }
                return memo;
            },
            onPinchEnd: () => {
                setIsMoving(false);
            },
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
            pinch: { scaleBounds: { min: 0.5, max: 4 }, rubberband: true },
        }
    )

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();
    };

    const handleShift = () => {
        setLayoutName((layoutName == "default") ? "shift" : "default");
    };

    const onChangeAll = inputs => {
        if (isMoving) {
            keyboardRef.current.setInput(currentInputForKeyboard.value);
        } else {
            currentInputForKeyboard.value = inputs[currentInputForKeyboard.getAttribute("name")];
            currentInputForKeyboard.onTextChanged();
            
        }
    };

    return (
        <div className={`${styles.keyboard_component}`} ref={ref} style={{ transform: "matrix(1, 0, 0, 1, 200, 200)" }}>
            <Keyboard
                keyboardRef={r => (keyboardRef.current = r)}
                layoutName={layoutName}
                inputName={inputName}
                onKeyPress={onKeyPress}
                onChangeAll={onChangeAll}
            />
        </div>
    );
}

export default KeyboardComponent;
