import { geomEach, featureEach } from "@turf/meta";
import {
    feature,
    featureCollection,
    radiansToLength,
    lengthToRadians,
    earthRadius,
  } from "@turf/helpers";

  import center from "@turf/center";
import { BufferOp, GeoJSONReader, GeoJSONWriter } from "@turf/jsts";
import { geoAzimuthalEquidistant } from "d3-geo";


export function GetMaxZIndex() {
    return Math.max(
        ...Array.from(document.querySelectorAll('body *'), el =>
            parseFloat(window.getComputedStyle(el).zIndex),
        ).filter(zIndex => !Number.isNaN(zIndex)),
        0,
    );
}

export const RandomNumberInRange = (min, max) => {
    return Math.floor(Math.random()
        * (max - min + 1)) + min;
};

export const between = (x, min, max) => {
    var result = false;
    result = x >= min -0.0001 && x <= max+0.0001;
    if (!result)
        result = x >= max - 0.0001 && x <= min + 0.0001;
    return result;
  };



export function buffer(geojson, radius, options) {
    // Optional params
    options = options || {};
  
    // use user supplied options or default values
    var units = options.units || "kilometers";
    var steps = options.steps || 8;
  
    // validation
    if (!geojson) throw new Error("geojson is required");
    if (typeof options !== "object") throw new Error("options must be an object");
    if (typeof steps !== "number") throw new Error("steps must be an number");
  
    // Allow negative buffers ("erosion") or zero-sized buffers ("repair geometry")
    if (radius === undefined) throw new Error("radius is required");
    if (steps <= 0) throw new Error("steps must be greater than 0");
  
    var results = [];
    switch (geojson.type) {
      case "GeometryCollection":
        geomEach(geojson, function (geometry) {
            
          var buffered = bufferFeature(geometry, radius, units, steps);
          if (buffered) results.push(buffered);
        });
        return featureCollection(results);
      case "FeatureCollection":
        featureEach(geojson, function (feature) {
            
          var multiBuffered = bufferFeature(feature, radius, units, steps);
          if (multiBuffered) {
            featureEach(multiBuffered, function (buffered) {
              if (buffered) results.push(buffered);
            });
          }
        });
        return featureCollection(results);
    }

    return bufferFeature(geojson, radius, units, steps);
  }
  
  function bufferFeature(geojson, radius, units, steps) {
    var properties = geojson.properties || {};
    var geometry = geojson.type === "Feature" ? geojson.geometry : geojson;
  
    // Geometry Types faster than jsts
    if (geometry.type === "GeometryCollection") {
      var results = [];
      geomEach(geojson, function (geometry) {
        var buffered = bufferFeature(geometry, radius, units, steps);
        if (buffered) results.push(buffered);
      });
      return featureCollection(results);
    }
  
    // Project GeoJSON to Azimuthal Equidistant projection (convert to Meters)
    var projection = defineProjection(geometry);
    var projected = {
      type: geometry.type,
      coordinates: projectCoords(geometry.coordinates, projection),
    };

    // var result = {
    //     type: "LineString",
    //     coordinates: unprojectCoords(projected.coordinates, projection),
    //   };

    //   return feature(result, properties);

    //return feature(projected, properties);
  
    // JSTS buffer operation
    var reader = new GeoJSONReader();
    var geom = reader.read(projected);
    var distance = radiansToLength(lengthToRadians(radius, units), "meters");
    var buffered = BufferOp.bufferOp(geom, distance, steps, 2);
    var writer = new GeoJSONWriter();
    buffered = writer.write(buffered);
  
    // Detect if empty geometries
    if (coordsIsNaN(buffered.coordinates)) return undefined;
  
    
    // Unproject coordinates (convert to Degrees)
    var result = {
      type: buffered.type,
      coordinates: unprojectCoords(buffered.coordinates, projection),
    };
  
    return feature(result, properties);
  }

  function coordsIsNaN(coords) {
    if (Array.isArray(coords[0])) return coordsIsNaN(coords[0]);
    return isNaN(coords[0]);
  }
  
  /**
   * Project coordinates to projection
   *
   * @private
   * @param {Array<any>} coords to project
   * @param {GeoProjection} proj D3 Geo Projection
   * @returns {Array<any>} projected coordinates
   */
  function projectCoords(coords, proj) {
    if (typeof coords[0] !== "object") return proj(coords);
    return coords.map(function (coord) {
      return projectCoords(coord, proj);
    });
  }
  
  /**
   * Un-Project coordinates to projection
   *
   * @private
   * @param {Array<any>} coords to un-project
   * @param {GeoProjection} proj D3 Geo Projection
   * @returns {Array<any>} un-projected coordinates
   */
  function unprojectCoords(coords, proj) {
    if (typeof coords[0] !== "object") return proj.invert(coords);
    return coords.map(function (coord) {
      return unprojectCoords(coord, proj);
    });
  }
  
  /**
   * Define Azimuthal Equidistant projection
   *
   * @private
   * @param {Geometry|Feature<any>} geojson Base projection on center of GeoJSON
   * @returns {GeoProjection} D3 Geo Azimuthal Equidistant Projection
   */
  function defineProjection(geojson) {
    var coords = center(geojson).geometry.coordinates;
    var rotation = [-coords[0], -coords[1]];
    return geoAzimuthalEquidistant().rotate(rotation).scale(earthRadius);
  }
  

// export function TransformPoints(points, mapRef) {
//     const latLngPoints = points.map((point) => {
//         const latLngPoint = mapRef.current.containerPointToLatLng(point);
//         return [latLngPoint.lat, latLngPoint.lng];
//     });
//     return latLngPoints;
// }

// export function TransformPoints(points, mapRef) {
//     const latLngPoints = points.map((point) => {
//         const latLngPoint = mapRef.current.unproject(point);
//         return [[latLngPoint.lat, latLngPoint.lng]];
//     });

//     //latLngPoints.push(latLngPoints[0]);
//     latLngPoints.push([[latLngPoints[0][0][0], latLngPoints[0][0][1]]]);
//     return [latLngPoints];
// }

export function TransformPoints(points, mapRef) {
    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const latLngPoints = points.map((point) => {
        const latLngPoint = mapRef.current.unproject([point[0] * oxDivider, point[1] * oyDivider]);
        return [latLngPoint.lng, latLngPoint.lat];
    });

    return [latLngPoints];
}

export function TransformScreenPoints(points, mapRef) {
    const latLngPoints = points.map((point) => {
      const latLngPoint = mapRef.current.unproject([point[0], point[1]]);
      return [latLngPoint.lng, latLngPoint.lat];
  });

  return [latLngPoints];
}

export function PointCoordinates(point, mapRef) {
    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const latLngPoint = mapRef.current.unproject([point[0] * oxDivider, point[1] * oyDivider]);
    return [latLngPoint.lng, latLngPoint.lat];
}

export function PointScreenPosition(point, mapRef) {
    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    var pointPosition = mapRef.current.project(point);
    return [pointPosition.x / oxDivider, pointPosition.y / oyDivider];
}

function equals(coord0, coord1) {
    if (Math.abs(coord0[1] - coord1[1]) > Number.EPSILON) return false;
    if (Math.abs(coord0[0] - coord1[0]) > Number.EPSILON) return false;

    return true;
}

const toRadians = (lngLat) => {
    return (lngLat * Math.PI) / 180;
};

function cross(first, v) {
    const x = first.y * v.z - first.z * v.y;
    const y = first.z * v.x - first.x * v.z;
    const z = first.x * v.y - first.y * v.x;

    return { x, y, z };
}

function toNvector(coord) {

    const φ = toRadians(coord[1]);
    const λ = toRadians(coord[0]);

    const sinφ = Math.sin(φ),
        cosφ = Math.cos(φ);

    const sinλ = Math.sin(λ),
        cosλ = Math.cos(λ);

    // right-handed vector: x -> 0°E,0°N; y -> 90°E,0°N, z -> 90°N
    const x = cosφ * cosλ;
    const y = cosφ * sinλ;
    const z = sinφ;

    return { x, y, z };
}

function minus(first, v) {
    return { x: first.x - v.x, y: first.y - v.y, z: first.z - v.z };
}

function dot(first, v) {
    return first.x * v.x + first.y * v.y + first.z * v.z;
}

function isWithinExtent(coord0, coord1, coord2) {
    if (equals(coord1, coord2)) {
        return equals(coord0, coord1); // null segment
    }

    const n0 = toNvector(coord0),
        n1 = toNvector(coord1),
        n2 = toNvector(coord2); // n-vectors

    // get vectors representing p0->p1, p0->p2, p1->p2, p2->p1
    const δ10 = minus(n0, n1),
        δ12 = minus(n2, n1);
    const δ20 = minus(n0, n2),
        δ21 = minus(n1, n2);

    // dot product δ10⋅δ12 tells us if p0 is on p2 side of p1, similarly for δ20⋅δ21
    const extent1 = dot(δ10, δ12);
    const extent2 = dot(δ20, δ21);

    const isSameHemisphere = dot(n0, n1) >= 0 && dot(n0, n2) >= 0;

    return extent1 >= 0 && extent2 >= 0 && isSameHemisphere;
}

const toDegrees = function (vector3d) {
    return (vector3d * 180) / Math.PI;
};

function nVectorToLatLon(vector) {
    // tanφ = z / √(x²+y²), tanλ = y / x (same as ellipsoidal calculation)

    const x = vector.x,
        y = vector.y,
        z = vector.z;

    const φ = Math.atan2(z, Math.sqrt(x * x + y * y));
    const λ = Math.atan2(y, x);

    return [toDegrees(λ), toDegrees(φ)];
}

function vectorLength(v) {
    return Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);
}

export function distanceTo(coord1, coord2, radius = 6371e3) {
    const R = Number(radius);

    const n1 = toNvector(coord1);
    const n2 = toNvector(coord2);

    const sinθ = vectorLength(cross(n1, n2));
    const cosθ = dot(n1, n2);
    const δ = Math.atan2(sinθ, cosθ); // tanδ = |n₁×n₂| / n₁⋅n₂

    return δ * R;
}

/**
 * Returns closest coordinate on great circle segment between lineCoordOne & lineCoordTwo to coord.
 *
 * If this coord is ‘within’ the extent of the segment, the coord is on the segment between coord1 &
 * coord2; otherwise, it is the closer of the endcoords defining the segment.
 */
export function nearestCoordinateOnSegment(
    coord,
    lineCoordOne,
    lineCoordTwo
) {
    let closestCoords = null;

    const isBetweenLineCoords = isWithinExtent(coord, lineCoordOne, lineCoordTwo);
    const isNotEqualToLineCoords = !equals(lineCoordOne, lineCoordTwo);

    if (isBetweenLineCoords && isNotEqualToLineCoords) {
        // closer to segment than to its endcoords, find closest coord on segment
        const n0 = toNvector(coord),
            n1 = toNvector(lineCoordOne),
            n2 = toNvector(lineCoordTwo);
        const c1 = cross(n1, n2); // n1×n2 = vector representing great circle through p1, p2
        const c2 = cross(n0, c1); // n0×c1 = vector representing great circle through p0 normal to c1
        const n = cross(c1, c2); // c2×c1 = nearest coord on c1 to n0

        closestCoords = nVectorToLatLon(n);
    } else {
        // beyond segment extent, take closer endcoord
        const d1 = distanceTo(coord, lineCoordOne);
        const d2 = distanceTo(coord, lineCoordTwo);
        closestCoords = d1 < d2 ? lineCoordOne : lineCoordTwo;
    }

    return closestCoords;
}

export function generatePathData (lines) {
    return lines.map((line, index) => {
        const [startX, startY] = line[0];
        const [endX, endY] = line[1];
        return `M${startX},${startY} L${endX},${endY}`;
    }).join(' ');
};




