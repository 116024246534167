import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css'

import { GlobalContext } from "../contexts/globalContext"
import RtspPlayerComponent from '../components/RtspPlayerComponent';
import JoystickComponent from '../components/JoystickComponent';
import CompassAndPYRComponent from './CompassAndPYRComponent';
import MSEPlayerComponent from '../components/MSEPlayerComponent';

//import ReactHlsPlayer from 'react-hls-player';

const DeviceForPopupComponent = (props) => {
    const ref = useRef();
    const [top, setTop] = useState(10);
    const [left, setLeft] = useState(10);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [pyr, setPyr] = useState([]);
    //const refCurrentSite = useRef();

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const { mapRef, setDevicesForComponents, setDeviceToShowInAnotherMonitor } = useContext(GlobalContext);

    useEffect(() => {
        const position = mapRef.current.project([props.device.location[1], props.device.location[0]]);
        setLeft(position.x / oxDivider);
        setTop(position.y / oyDivider);

        setWidth(600);

        var new_height = 120;

        if (props.device.speed) {
            new_height += 10;
        }

        if (props.device.altitude) {
            new_height += 10;
        }

        if (props.device.direction) {
            new_height += 300;
        }



        if (props.device.data) {
            Object.keys(props.device.data).map((param) => {
                new_height += 40;
                if (getParameterType(param) == "RTSP_STREAM_URL") {
                    new_height += 300;
                }
                if (getParameterType(param) == "HLS_STREAM_URL") {
                    new_height += 300;
                }
                if (getParameterType(param) == "MSE_URL") {
                    new_height += 300;
                }
                if (getParameterType(param) == "PTZ_URL") {
                    new_height += 50;
                }

                if (getParameterType(param) == "PYR") {
                    setPyr(props.device.data[param])
                }
            });
        }

        if (props.device.data) {
            setHeight(new_height);
        } else {
            setHeight(new_height);
        }

    }, [])

    useEffect(() => {
        if (props.device.data) {
            Object.keys(props.device.data).map((param) => {
                if (getParameterType(param) == "PYR") {
                    setPyr(props.device.data[param])
                }
            });
        }
    }, [props.device])

    const getParameterName = code => {
        let res = props.parameters.filter(a => a.code === code);
        return res[0].name;
    }

    const getParameterType = code => {
        let res = props.parameters.filter(a => a.code === code);
        return res[0].type;
    }

    const getParameterUM = code => {
        let res = props.parameters.filter(a => a.code === code);
        return res[0].um;
    }

    const formatValue = value => {
        if (typeof value == "boolean") {
            value = (value) ? "DA" : "NU";
        }
        //let res = props.parameters.filter(a => a.code === code);
        return value;
    }


    const getComponentForParameter = (param) => {
        switch (getParameterType(param)) {

            case "RTSP_STREAM_URL": return <RtspPlayerComponent key={param} streamUrl={props.device.data[param]} WIDTH="580" ></RtspPlayerComponent>;
            case "MSE_URL": return <MSEPlayerComponent key={param} url={props.device.data[param]} WIDTH="580" ></MSEPlayerComponent>;
            //  case "HLS_STREAM_URL": return <ReactHlsPlayer key={param} src={props.device.data[param]} className={styles.hls_stream_url} autoPlay={true}></ReactHlsPlayer>;          
            case "PTZ_URL": return <JoystickComponent key={param} streamUrl={props.device.data[param]}></JoystickComponent>
            case "PYR": return null;

            default: return <span key={param} className={styles.device_component_properties}><b>{getParameterName(param)}:</b>   {formatValue(props.device.data[param])} {getParameterUM(param)}</span>
        }
    }

    return (
        // <table>
        <div className={styles.device_component} ref={ref} style={{ width: width, height: height }}>
            <span className={styles.device_component_title}>{props.device.name}</span>
            <span className={styles.device_component_subtitle}>{props.device.device_type.name}</span>

            <span className={styles.device_component_properties}><b>Locatie:</b> {props.device.location[0]}  {props.device.location[1]}</span>



            {props.device.speed && <span className={styles.device_component_properties}><b>Viteza:</b> {props.device.speed}</span>}
            {props.device.altitude && <span className={styles.device_component_properties}><b>Altitudine:</b> {props.device.altitude}</span>}
            {props.device.direction && <CompassAndPYRComponent key="direction" direction={props.device.direction} pyrValues={pyr}></CompassAndPYRComponent>}
            {props.device.data && Object.keys(props.device.data).map((param) => (
                getComponentForParameter(param)
            ))}

            <span className={styles.device_component_title}>{props.device.name}</span>
            <button>asds</button>

            {/* props.device.device_type.name !== "Drona" */}
        </div>
        // </table>
    );
}

export default DeviceForPopupComponent;