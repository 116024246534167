import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SymbolsSubcategoriesComponent from './SymbolsSubcategoriesComponent';

const SymbolsCategoryComponent = React.memo( props => {
    const [keys, setKeys] = useState([]);
    
    useEffect(() => {        
        setKeys(Object.keys(props.categories));
    }, [])

    return (       
        <>
            {keys.map((key, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        // aria-controls="panel{index}a-content"
                        // id="panel{index}a-header"
                        >
                        <Typography>{key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SymbolsSubcategoriesComponent subcategories={props.categories[key]}></SymbolsSubcategoriesComponent>                        
                    </AccordionDetails>           
                </Accordion>
            ))}
        </>
               
    );
} 
)

export default SymbolsCategoryComponent;