import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './../styles.module.css'

const PopupWindowComponent = (props) => {
    const [container, setContainer] = useState(null);
    const newWindow = useRef(null);

    function go_full_screen(docElm) {
        if (docElm.requestFullscreen) {

            docElm.requestFullscreen().catch((err) => {
                alert(
                    `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`,
                );
            });
        } else if (docElm.msRequestFullscreen) {
            docElm.msRequestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        }
    }

    function copyStyles(src, dest) {
        Array.from(src.styleSheets).forEach(styleSheet => {
            dest.head.appendChild(styleSheet.ownerNode.cloneNode(true))
        })
        Array.from(src.fonts).forEach(font => dest.fonts.add(font))
    }

    useEffect(() => {
        // Create container element on client-side
        setContainer(document.createElement("div"));
    }, []);

    useEffect(() => {
        // When container is ready
        if (container) {
            // Create window
            // let newLeft = 0;
            // window.getScreenDetails().then((screenDetails) => {
            //     screenDetails.screens.map((screen) => {
            //         if (screen.left != window.screenX) {
            //             newLeft = screen.left;
            //         }

            //     });

            //     console.log(newLeft);


                


            // });
            // console.log("left=" + props.size[0] + ",top=" + props.size[1] + ",fullscreen=yes,location=no,menubar=no,resizable=yes,scrollbars=no,status=no,titlebar=no,toolbar=no");
            newWindow.current = window.open(
                "",
                "_blank",
                "left=" + props.size[0] + ",top=" + props.size[1] + ",fullscreen=yes,location=no,menubar=no,resizable=yes,scrollbars=no,status=no,titlebar=no,toolbar=no"
            );
            // Append container
            newWindow.current.document.body.appendChild(container);
            //const event = new KeyboardEvent('keydown', KeyboardEvent.);
            //newWindow.current.dispatchEvent(event);

            //go_full_screen(newWindow.current.document.documentElement);
            newWindow.current.resizeTo(newWindow.current.screen.width, newWindow.current.screen.height);

            copyStyles(window.document, newWindow.current.document);


            //console.log(newWindow.current.screen.availWidth);

            // Save reference to window for cleanup
            const curWindow = newWindow.current;


            // Return cleanup function
            return () => curWindow.close();
        }
    }, [container]);

    return container && createPortal(props.children, container);
};

export default PopupWindowComponent;