import React, { useRef, useContext, useState, useEffect } from 'react';
import VideoComponent from './VideoComponent';
import Peer from "simple-peer";
import { GlobalContext } from "../contexts/globalContext"
// import axios from "axios"

const ConferenceComponent = (props) => {
    const { socketRef, currentUser } = useContext(GlobalContext);
    const [streams, setStreams] = useState([]);
    const [myStream, setMyStream] = useState();
    const streamRef = useRef([]);
    const currentUserRef = useRef([]);

    //Per To Perr comm
    const [peers, setPeers] = useState([]);
    const peersRef = useRef([]);
    const VideoComponentSize = [300, 170, parseInt(process.env.REACT_APP_WIDTH) - 200];
    //Peer to Peer comm

    useEffect(() => {
        currentUserRef.current = currentUser;
    }, [currentUser])

    useEffect(() => {





        navigator.mediaDevices.getUserMedia({
            video: {
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                facingMode: "environment"
            }, audio: true
        }).then((stream) => {
            setMyStream({ stream, "userId": socketRef.current.id, "is_mine": true, name: currentUser.name });
            // setStreams([{ stream, "userId": socketRef.current.id, "is_mine": true }]);
            //SetStreamsAndRecalculatePosition({ stream, "userId": socketRef.current.id, "is_mine": true });
            const track = stream.getVideoTracks()[0];
            const constraints = track.getConstraints();


            socketRef.current.on("ALL_USERS", users => {
                const peers = [];
                users.forEach(user => {
                    const peer = createPeer(user.socket_id, socketRef.current.id, stream);
                    peersRef.current.push({
                        peerID: user.socket_id,
                        peer,
                    })

                    peer.on("stream", stream => {
                        //setStreams(streams => [...streams, { stream, "userId": userID, "is_mine": false }]);
                        SetStreamsAndRecalculatePosition({ stream, "userId": user.socket_id, "is_mine": false, name: user.name });

                    })

                    peers.push(peer);
                })
                setPeers(peers);
            })

            socketRef.current.on("USER_JOINED", payload => {
                const peer = addPeer(payload.signal, payload.callerID, stream);
                peersRef.current.push({
                    peerID: payload.callerID,
                    peer,
                })

                peer.on("stream", stream => {
                    //setStreams(streams => [...streams, { stream, "userId": payload.callerID, "is_mine": false }]);
                    SetStreamsAndRecalculatePosition({ stream, "userId": payload.callerID, "is_mine": false, name: payload.name });
                })

                setPeers(peers => [...peers, peer]);
            });

            socketRef.current.on("RECEIVING_RETURNED_SIGNAL", payload => {
                const item = peersRef.current.find(p => p.peerID === payload.id);
                item.peer.signal(payload.signal);
            });

            socketRef.current.on('USER_DISCONECTED', (userId) => {
                if (userId == socketRef.current.id) {
                    streamRef.current.forEach(stream => {
                        stream.stream.getTracks().forEach((track) => {
                            track.stop();
                        });
                    })

                    peersRef.current.forEach(peer => {
                        peer.peer.destroy();
                    })


                    setStreams([]);

                    peersRef.current = [];

                    streamRef.current = [];


                } else {
                    const otherStream = streamRef.current.find(pr => pr.userId === userId);
                    if (otherStream && otherStream.stream) {
                        otherStream.stream.getTracks().forEach((track) => {
                            track.stop();
                        });
                    }


                    const peer = peersRef.current.find(p => p.peerID === userId);
                    if (peer && peer.peer)
                        peer.peer.destroy();

                    setStreams(streams => streams.filter(stre => stre.userId !== userId));

                    peersRef.current = peersRef.current.filter(pr => pr.peerID !== userId);

                    //streamRef
                    streamRef.current = streamRef.current.filter(pr => pr.userId !== userId);
                    SetStreamsAndRecalculatePosition();
                }


            });
        });
    }, [])

    function SetStreamsAndRecalculatePosition(new_stream_obj) {
        const screenHeight = parseInt(process.env.REACT_APP_HEIGHT);
        if (new_stream_obj != null)
            streamRef.current.push(new_stream_obj);
        var stream_no = streamRef.current.length;

        const total_height = stream_no * VideoComponentSize[1] + (stream_no - 1) * 20;

        var first_y = (screenHeight - total_height) / 2;

        streamRef.current.forEach((stream_aux, index) => {
            stream_aux.X = VideoComponentSize[2];
            stream_aux.Y = first_y + (index * VideoComponentSize[1]) + 20 * (index - 1);
            stream_aux.WIDTH = VideoComponentSize[0];
            stream_aux.HEIGHT = VideoComponentSize[1];
        });

        setStreams([...streamRef.current]);
    }

    function createPeer(userToSignal, callerID, stream) {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream,
            config: {
                iceServers: [
                    {
                        urls: [
                          'stun:stun.l.google.com:19302',
                          'stun:global.stun.twilio.com:3478'
                        ]      
                    }
                    //,
                    // {
                    //     url: "turn:global.turn.twilio.com:3478?transport=udp",
                    //     username: "86d0245984898ca663845760f4f67c2b671d3782c7530c4eeb9d2f76bfed19c5",
                    //     urls: "turn:global.turn.twilio.com:3478?transport=udp",
                    //     credential: "1whY0feyNnmfGtIl8bN5eAMmsZM9WjIpR5/R2mpqoQ0="
                    // },
                    // {
                    //     url: "turn:global.turn.twilio.com:3478?transport=tcp",
                    //     username: "86d0245984898ca663845760f4f67c2b671d3782c7530c4eeb9d2f76bfed19c5",
                    //     urls: "turn:global.turn.twilio.com:3478?transport=tcp",
                    //     credential: "1whY0feyNnmfGtIl8bN5eAMmsZM9WjIpR5/R2mpqoQ0="
                    // },
                    // {
                    //     url: "turn:global.turn.twilio.com:443?transport=tcp",
                    //     username: "86d0245984898ca663845760f4f67c2b671d3782c7530c4eeb9d2f76bfed19c5",
                    //     urls: "turn:global.turn.twilio.com:443?transport=tcp",
                    //     credential: "1whY0feyNnmfGtIl8bN5eAMmsZM9WjIpR5/R2mpqoQ0="
                    // }
                ]
            }
        });

        peer.on("signal", signal => {
            socketRef.current.emit("SENDING_SIGNAL", { userToSignal, callerID, signal, name: currentUserRef.current.name })
        })

        return peer;
    }

    function addPeer(incomingSignal, callerID, stream) {
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream,
            config: {
                iceServers: [
                    {
                        urls: [
                          'stun:stun.l.google.com:19302',
                          'stun:global.stun.twilio.com:3478'
                        ]      
                    }
                    // ,
                    // {
                    //     url: "turn:global.turn.twilio.com:3478?transport=udp",
                    //     username: "86d0245984898ca663845760f4f67c2b671d3782c7530c4eeb9d2f76bfed19c5",
                    //     urls: "turn:global.turn.twilio.com:3478?transport=udp",
                    //     credential: "1whY0feyNnmfGtIl8bN5eAMmsZM9WjIpR5/R2mpqoQ0="
                    // },
                    // {
                    //     url: "turn:global.turn.twilio.com:3478?transport=tcp",
                    //     username: "86d0245984898ca663845760f4f67c2b671d3782c7530c4eeb9d2f76bfed19c5",
                    //     urls: "turn:global.turn.twilio.com:3478?transport=tcp",
                    //     credential: "1whY0feyNnmfGtIl8bN5eAMmsZM9WjIpR5/R2mpqoQ0="
                    // },
                    // {
                    //     url: "turn:global.turn.twilio.com:443?transport=tcp",
                    //     username: "86d0245984898ca663845760f4f67c2b671d3782c7530c4eeb9d2f76bfed19c5",
                    //     urls: "turn:global.turn.twilio.com:443?transport=tcp",
                    //     credential: "1whY0feyNnmfGtIl8bN5eAMmsZM9WjIpR5/R2mpqoQ0="
                    // }
                ]
            }
            // config: {
            //     iceServers: [
            //         {
            //             urls: [
            //                 'stun:global.stun.twilio.com:3478'
            //             ]
            //         }
            //         ,
            //         {
            //             username: "dc2d2894d5a9023620c467b0e71cfa6a35457e6679785ed6ae9856fe5bdfa269",
            //             credential: "tE2DajzSJwnsSbc123",
            //             urls: "turn:global.turn.twilio.com:3478?transport=udp"
            //         }
            //         // {
            //         //     urls: [
            //         //         'stun:stun.l.google.com:19302',
            //         //         'stun:global.stun.twilio.com:3478'
            //         //     ]
            //         // }
            //         // ,
            //         // {
            //         //     username: "dc2d2894d5a9023620c467b0e71cfa6a35457e6679785ed6ae9856fe5bdfa269",
            //         //     credential: "tE2DajzSJwnsSbc123",
            //         //     urls: "turn:global.turn.twilio.com:3478?transport=udp"
            //         // },
            //         // {
            //         //     username: "dc2d2894d5a9023620c467b0e71cfa6a35457e6679785ed6ae9856fe5bdfa269",
            //         //     credential: "tE2DajzSJwnsSbc123",
            //         //     urls: "turn:global.turn.twilio.com:3478?transport=tcp"
            //         // },
            //         // {
            //         //     username: "dc2d2894d5a9023620c467b0e71cfa6a35457e6679785ed6ae9856fe5bdfa269",
            //         //     credential: "tE2DajzSJwnsSbc123",
            //         //     urls: "turn:global.turn.twilio.com:443?transport=tcp"
            //         // }
            //     ]
            // }
        })

        peer.on("signal", signal => {
            socketRef.current.emit("RETURNING_SIGNAL", { signal, callerID })
        })

        peer.signal(incomingSignal);

        return peer;
    }

    return (
        <>
            {streams && streams.map((stream) => {
                return (
                    <VideoComponent key={stream.userId} stream={stream.stream} name={stream.name} is_mine={stream.is_mine} X={stream.X} Y={stream.Y} WIDTH={stream.WIDTH - 40} HEIGHT={stream.HEIGHT - 40} />
                );
            })}

            {streams.length > 0 && <VideoComponent name={myStream.name} key={myStream.userId} stream={myStream.stream} is_mine={myStream.is_mine} X={streams[0].X} Y={1900} WIDTH={streams[0].WIDTH - 40} HEIGHT={streams[0].HEIGHT - 40} />}
        </>
    );

}

export default ConferenceComponent;