import React, { useRef, useContext, useEffect, useState } from 'react';
// import { MapContainer, Marker, Popup, useMap, LayersControl, LayerGroup } from "react-leaflet";
import "leaflet/dist/leaflet.css"
import mapDrawStyles from '../utils/mapDrawStyles.json'
import styles from './../styles.module.css'

import { GlobalContext } from "../contexts/globalContext"

import mapStylesJson from '../utils/mapStyles.json';

import Map, { Marker, ScaleControl, Source, Layer, NavigationControl } from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import { ApiSaveProject } from "../utils/RestServices"

import SelectPickerMapComponent from "../components/keyboard/SelectPickerMapComponent";

import uuid from 'react-uuid';

import { TransformScreenPoints } from '../utils/Tools'

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
    CircleMode,
    DragCircleMode,
    DirectMode,
    SimpleSelectMode
} from 'mapbox-gl-draw-circle';




// import DirectSelect from '@mapbox/mapbox-gl-draw/src/modes/direct_select';

//import Dropzone from 'react-dropzone';

const MapComponent = React.memo(() => {
    const { isForDesk, devicesForAccesControl, setDevicesForAccesControl, devicesForFireDetection, setDevicesForFireDetection, jsonForMeteoExpand, setJsonForMeteoExpand, setCloseDashboard, projectSites, filesToBeOpen, setFilesToBeOpen, logged, symbolsForEditing, setSymbolsForEditing, setProjectSites, setSeeElevationGraph, setSelectedFeature, setSymbols, symbols, currentUser, currentProjectId, drawingsRef, setDevicesForComponents, devicesForComponents, setAlerts, socketRef, devices, setDevices, mapRef, sites, currentSite, setCurrentSite } = useContext(GlobalContext);
    const projectId = useRef();
    const filesToBeOpenRef = useRef();

    const currentUserAux = useRef();
    const currentSiteAux = useRef();
    const symbolsRef = useRef();
    //const [mapStyleIndex, setMapStyleIndex] = useState(0);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    const skyLayer = {
        id: 'sky',
        type: 'sky',
        paint: {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15
        }
    };

    useEffect(() => {
        filesToBeOpenRef.current = filesToBeOpen;
    }, [filesToBeOpen])


    useEffect(() => {
        if (mapRef.current) {
            // Remove Map Logo and Mapbox link
            let collection = document.getElementsByClassName("mapboxgl-ctrl-attrib");

            for (let element of collection) {
                element.remove();
            };

            collection = document.getElementsByClassName("mapboxgl-ctrl-logo");

            for (let element of collection) {
                element.remove();
            };
            // Remove Map Logo and Mapbox link

            if (!drawingsRef.current) {
                drawingsRef.current = new MapboxDraw({
                    displayControlsDefault: false,
                    userProperties: true,
                    styles: mapDrawStyles,
                    modes: {
                        ...MapboxDraw.modes,
                        draw_circle: CircleMode,
                        drag_circle: DragCircleMode,
                        direct_select: DirectMode,
                        simple_select: SimpleSelectMode
                    }
                });

                mapRef.current.addControl(drawingsRef.current);
                mapRef.current.getMap().touchZoomRotate._tapDragZoom.disable();
                mapRef.current.getMap().doubleClickZoom.disable();

                mapRef.current.getMap().getContainer().addEventListener("drop", function (event) {
                    event.preventDefault();

                    // console.log(event);

                    var icon = event.dataTransfer.getData("icon");
                    var file_string = event.dataTransfer.getData("file");


                    //console.log(points[0][1]);
                    //console.log(points[0][0]);
                    //const points = TransformPoints([[currentRectangle[0][0], currentRectangle[1][1]], mapRef.current);

                    if (icon) {
                        // console.log("e icon");
                        const points = TransformScreenPoints([[event.clientX, event.clientY]], mapRef);
                        const uid = uuid();
                        var symbol = {
                            uid,
                            location: [points[0][0][1], points[0][0][0]],
                            icon,
                            altitude: null,
                            uniqueDesignation: "",
                            speed: null,
                            reinforced: false,
                            reduced: false,
                            staffComments: "",
                            additionalInfo: "",
                            higherFormation: "",
                            reliabilityRatings: "",
                            credibilityRatings: "",
                            effectiveness: "",
                            sifCode: ""
                        };

                        //console.log(event);
                        if (projectId.current == null) {
                            setSymbols(symbols => [...symbols, symbol]);
                        } else {
                            //console.log("drop SYMBOL_UPDATED");
                            // console.log(projectId.current);
                            socketRef.current.emit("SYMBOL_UPDATED", { project_id: projectId.current, symbol });
                        }
                        mapRef.current.getMap().setPitch(0);
                    } else if (file_string) {
                        let file = JSON.parse(file_string);
                        setFilesToBeOpen([...filesToBeOpenRef.current, { uid: uuid(), name: file.name, path: file.path, mimetype: file.mimetype, x: event.clientX / oxDivider, y: event.clientY / oyDivider }]);
                    }
                });

                mapRef.current.getMap().getContainer().addEventListener("dragover", function (event) {
                    event.preventDefault();
                });

                mapRef.current.on('draw.update', (e) => {
                    // console.log(mapRef.current.style.sourceCaches)
                    // console.log(mapRef.current.getSource('wms-test-source'));
                    // console.log("draw update")
                    if (projectId.current != null) {
                        //var body = JSON.stringify({ project_id: projectId.current, data: { drawings: drawingsRef.current.getAll(), symbols:symbolsRef.current } });
                        var body = JSON.stringify({ project_id: projectId.current, drawing: e.features[0] });
                        // e ok
                        ApiSaveProject(body);
                        socketRef.current.emit("DRAWING_UPDATED", { project_id: projectId.current, drawings: e.features });
                    }
                });
                mapRef.current.on('draw.modechange', (e) => {
                    const currentSelection = drawingsRef.current.getSelected().features[0]
                    if (currentSelection) {
                        if (e.mode === "direct_select" && currentSelection.geometry.type === "MultiLineString") {
                            drawingsRef.current.changeMode("simple_select", { featureIds: [currentSelection.id] });
                        }
                    }
                });
                mapRef.current.on('draw.selectionchange', (e) => {
                    setSelectedFeature(e.features[0]);
                    setSeeElevationGraph(false);

                    if (e.features[0]) {
                        if (e.features[0].geometry.type === "MultiLineString") {
                            // console.log(e.features[0].geometry.type)
                            mapRef.current.getMap().setLayoutProperty("gl-draw-polygon-and-line-vertex-inactive.cold", 'visibility', 'none');
                            mapRef.current.getMap().setLayoutProperty("gl-draw-polygon-and-line-vertex-stroke-inactive.cold", 'visibility', 'none');
                        }
                    } else {
                        mapRef.current.getMap().setLayoutProperty("gl-draw-polygon-and-line-vertex-inactive.cold", 'visibility', 'visible');
                        mapRef.current.getMap().setLayoutProperty("gl-draw-polygon-and-line-vertex-stroke-inactive.cold", 'visibility', 'visible');
                    }

                    // mapRef.current.getMap().setLayoutProperty("gl-draw-polygon-and-line-vertex-inactive.cold", 'visibility', 'visible');
                    // mapRef.current.getMap().setLayoutProperty("gl-draw-polygon-and-line-vertex-stroke-inactive.cold", 'visibility', 'visible');


                });

                mapRef.current.on('load', function (e) {
                    e.target.addSource('wms-test-source', {
                        'type': 'raster',
                        'tiles': [
                            'https://geoserver-test.ginsure.terrasigna.io/geoserver/gwc/service/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=bc2a%3ABlackSky_BSG_104_20230911_tiled&STYLE=raster&FORMAT=image%2Fpng&TILEMATRIXSET=EPSG%3A900913&TILEMATRIX=EPSG%3A900913%3A{z}&TILEROW={y}&TILECOL={x}'
                        ],
                        'tileSize': 256,
                        'bounds': [26.055, 44.536, 26.132, 44.607]
                    });
                    e.target.addLayer(
                        {
                            'id': 'wms-test-layer',
                            'type': 'raster',
                            'source': 'wms-test-source',

                        },
                    );

                });


                // mapRef.current.on('load', function(e) {
                //     e.target.addSource('terrain', {
                //         'type': 'raster-dem',
                //         'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                //         'tileSize': 512,
                //         'maxzoom': 14
                //     });
                //     // e.target.setTerrain({ 'source': 'terrain', 'exaggeration': 1, 'layout': {
                //     //                 'visibility': 'none'
                //     //             }, });
                //     e.target.addLayer(
                //         {
                //             'id': 'terrain',
                //             'type': 'hillshade',
                //             'source': 'terrain',
                //             'source-layer': 'terrain',
                //             'layout': {
                //                 'visibility': 'none'
                //             },
                //             'paint': {
                //             }
                //         },
                //         // 'building' // Place layer under labels, roads and buildings.
                //     );


                //});
                // mapRef.current.on('load', function(e) {
                //     e.target.addSource('wms-test-source', {
                //         'type': 'raster',
                //         'tiles': [
                //             'https://geoserver-test.ginsure.terrasigna.io/geoserver/gwc/service/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=bc2a%3ABlackSky_BSG_104_20230911_tiled&STYLE=raster&FORMAT=image%2Fpng&TILEMATRIXSET=EPSG%3A900913&TILEMATRIX=EPSG%3A900913%3A{z}&TILEROW={y}&TILECOL={x}'
                //         ],
                //         'tileSize': 256
                //     });
                //     e.target.addLayer(
                //         {
                //             'id': 'wms-test-layer',
                //             'type': 'raster',
                //             'source': 'wms-test-source',
                //             'paint': {}
                //         },
                //     );
                //     const boundingBox = [
                //         [26.04, 44.52], // Southwest corner
                //         [26.13, 44.61]  // Northeast corner
                //     ];

                //     // Set a filter based on the bounding box
                //     e.target.setFilter('wms-test-layer', ['within', ['geometry-type'], ['literal', boundingBox]]);

                // });
                // mapRef.current.on('tileerror', function (event) {
                //     console.log('Tile error:', event.error);
                //   });




            }
        }
    }, [mapRef.current])

    useEffect(() => {
        projectId.current = currentProjectId;
        currentUserAux.current = currentUser;
        currentSiteAux.current = currentSite;
    }, [currentProjectId, currentUser, currentSite])

    useEffect(() => {
        if (currentProjectId != null) {
            //var body = JSON.stringify({ project_id: currentProjectId, data: { drawings: drawingsRef.current.getAll(), symbols:symbols } });
            var body = JSON.stringify({ project_id: currentProjectId, symbols: symbols });
            // e ok
            ApiSaveProject(body);
        }

        symbolsRef.current = symbols;
    }, [symbols])

    const openDevice = device => {
        if (device.device_type.name == "Statie Meteo") {
            const valuesArray = Object.values(device.data);
            const MeteoElem =
            {
                id: device._id,
                location: device.name,
                celsius: valuesArray[0] + '°',
                max: Math.round(valuesArray[0]) + 3 + '°',
                min: (Math.round(valuesArray[0]) - 5) + '°',
                humidity: valuesArray[1] + '%',
                wind: valuesArray[2] + ' m/s  |  ' + valuesArray[3] + ' m/s',
                precipitation: valuesArray[5] + ' l/zi',
                weather: 'sunny',
                windDirection: valuesArray[4],
                pointLat: device.location[1],
                pointLong: device.location[0],
                temperatures: [15, 17, 20, 21, 23, 20, 17, 15],
                status: 'live'
            }
            console.log(MeteoElem)
            var alreadyExists = jsonForMeteoExpand.filter(a => a.id === MeteoElem.id);
            if (alreadyExists.length > 0) {
                setJsonForMeteoExpand(jsonForMeteoExpand => jsonForMeteoExpand.filter(a => a.id !== MeteoElem.id));
            } else {
                setJsonForMeteoExpand([...jsonForMeteoExpand, MeteoElem]);
            }

        } else if (device.device_type.name == "Fire Sensor") {
            var alreadyExists = devicesForFireDetection.filter(a => a._id === device._id);
            if (alreadyExists.length > 0) {
                setDevicesForFireDetection(devicesForFireDetection => devicesForFireDetection.filter(a => a._id !== device._id));
            } else {
                setDevicesForFireDetection([...devicesForFireDetection, device]);
            }
        } else if (device.device_type.name == "Access Control") {
            var alreadyExists = devicesForAccesControl.filter(a => a._id === device._id);
            if (alreadyExists.length > 0) {
                setDevicesForAccesControl(devicesForAccesControl => devicesForAccesControl.filter(a => a._id !== device._id));
            } else {
                setDevicesForAccesControl([...devicesForAccesControl, device]);
            }
        } else {
            var alreadyExists = devicesForComponents.filter(a => a._id === device._id);
            if (alreadyExists.length > 0) {
                setDevicesForComponents(devicesForComponents => devicesForComponents.filter(a => a._id !== device._id));
            } else {
                setDevicesForComponents([...devicesForComponents, device]);
            }
        }

    };

    const goToSite = site => {

        setCloseDashboard(false)

        setCurrentSite(site);

        mapRef.current.flyTo({
            center: [site.location[1], site.location[0]],
            zoom: 11,
            essential: true,
            bearing: 0
        });

        fetch(process.env.REACT_APP_API_URL + '/devices/' + site._id)
            .then((response) => response.json())
            .then((data) => {
                var foundAlerts = false;
                data.forEach(device => {
                    if (device.alerts.length > 0) {
                        foundAlerts = true;
                        setAlerts(device.alerts);
                    }
                });

                if (!foundAlerts) {
                    setAlerts([]);
                }

                setDevices(data);
            });
        setProjectSites([site._id]);
    };


    const isDragging = useRef(false);
    const onDragStart = () => {
        isDragging.current = true;

        // var auxs = symbols;
        // auxs.map(s => s.fixed = true);
        // setSymbols([...auxs]);
    }

    const onDrag = (event, symbol) => {
        symbol.location = [event.lngLat.lat, event.lngLat.lng];
        setSymbols(symbols => symbols.filter(a => a.uid !== symbol.uid));
        setSymbols(symbols => [...symbols, symbol]);
    }


    const onDragEnd = (event, symbol) => {
        isDragging.current = false;
        symbol.location = [event.lngLat.lat, event.lngLat.lng];

        if (currentProjectId == null) {
            setSymbols(symbols => symbols.filter(a => a.uid !== symbol.uid));
            setSymbols(symbols => [...symbols, symbol]);
        } else {
            socketRef.current.emit("SYMBOL_UPDATED", { project_id: currentProjectId, symbol });
        }

        // var auxs = symbols;
        // auxs.map(s => s.fixed = null);
        // setSymbols([...auxs]);
    }

    const selectSymbol = (symbol) => {
        // console.log("click");
        //if (!wasDragged.current) {

        var alreadyExists = symbolsForEditing.filter(a => a.uid === symbol.uid);
        if (alreadyExists.length > 0) {
            setSymbolsForEditing(symbolsForEditing => symbolsForEditing.filter(a => a.uid !== symbol.uid));

            if (currentProjectId == null) {
                setSymbols(symbols => symbols.filter(a => a.uid !== symbol.uid));
                setSymbols(symbols => [...symbols, symbol]);
            } else {
                socketRef.current.emit("SYMBOL_UPDATED", { project_id: currentProjectId, symbol });
            }
        } else {
            setSymbolsForEditing([...symbolsForEditing, symbol]);
        }

        //}
        //wasDragged.current = false;
    }

    const handleChangeStyle = (newValue) => {
        //setMapStyleIndex(newValue);
        //console.log(newValue);
        mapRef.current.getMap().setStyle(newValue);
    };

    const getAltAndLocForDevice = (device) => {
        var altitude = device.altitude;
        if (!altitude) {
            altitude = mapRef.current.queryTerrainElevation({ lng: device.location[1], lat: device.location[0] });
            // fetch(process.env.REACT_APP_TOPODATA_URL+device.location[0]+","+device.location[1])
            //     .then(response => response.json())
            //     .then((data) => {
            //         altitude = data.results[0].elevation
            //         altitude = Math.round(altitude * 100) / 100;
            //         var lat = ("" + device.location[0]).substring(0, 5);
            //         var lon = ("" + device.location[1]).substring(0, 5);
            //         var result = altitude + " M AMSL " + lat + ", " + lon;
            //         return result; 
            // });
            altitude = Math.round(altitude * 100) / 100;
            var lat = ("" + device.location[0]).substring(0, 5);
            var lon = ("" + device.location[1]).substring(0, 5);
            var result = altitude + " M AMSL " + lat + ", " + lon;
            return result;

        } else {
            var lat = ("" + device.location[0]).substring(0, 5);
            var lon = ("" + device.location[1]).substring(0, 5);
            var result = altitude + " M AMSL " + lat + ", " + lon;
            return result;
        }


    };


    const getAltAndLocForSymbol = (symbol) => {
        var altitude = symbol.altitude;
        if (!altitude) {
            //console.log("no altitude")
            altitude = mapRef.current.queryTerrainElevation({ lng: symbol.location[1], lat: symbol.location[0] });
            altitude = Math.round(altitude * 100) / 100;
            var lat = ("" + symbol.location[0]).substring(0, 5);
            var lon = ("" + symbol.location[1]).substring(0, 5);
            var result = altitude + " " + lat + ", " + lon;
            return result;
        } else {
            var lat = ("" + symbol.location[0]).substring(0, 5);
            var lon = ("" + symbol.location[1]).substring(0, 5);
            var result = altitude + " " + lat + ", " + lon;
            return result;
        }
    };

    const onTouchStart = (event) => {
        if (isDragging.current) {
            event.preventDefault();
        }

        if (event.points.length == 3) {
            event.preventDefault();
        }
    }

    const onTouchMove = (event) => {
        if (isDragging.current) {
            event.preventDefault();
        }

        if (event.points.length == 3) {
            event.preventDefault();
        }
    }

    const onTouchEnd = (event) => {
        if (isDragging.current) {
            event.preventDefault();
        }

        if (event.points.length == 3) {
            event.preventDefault();
        }
    }

    const onDrop = (event) => {
        // console.log("droped");
    }

    const createMarkerForSiteId = (site_id) => {
        var site = sites.find((s) => s._id == site_id);
        return (
            <Marker key={site._id} longitude={site.location[1]} latitude={site.location[0]} width="60px" height="60px">
                <img src={process.env.REACT_APP_CMS_URL + site.icon} width="60px" height="50px" />
            </Marker>
        )
    }

    const createSattelite = (sattelite, device, index) => {
        var latitude = Number(device.location[0]);
        var longitude = Number(device.location[1]);
        var earth = 6378.137,  //radius of the earth in kilometer
            pi = Math.PI,
            m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree
        var new_latitude = latitude + (Number(sattelite.x) * m);

        var earth = 6378.137,  //radius of the earth in kilometer
            pi = Math.PI,
            cos = Math.cos,
            m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree

        var new_longitude = longitude + (Number(sattelite.y) * m) / cos(latitude * (pi / 180));
        // console.log(new_latitude + "  " + new_longitude);
        return <Marker className={`${styles.marker}`} key={index} longitude={new_longitude} latitude={new_latitude} width="10px" height="10px">
            <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "red" }}></div>
        </Marker>
    }

    // const publicTransportationSource = {
    //     id: "source_new",
    //     type: "raster",
    //     tiles: [
    //         "https://geoserver-test.ginsure.terrasigna.io/geoserver/bc2a/wms?bbox={bbox-epsg-32635}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=BlackSky_BSG_104_20230911_tiled%27",
    //     ],
    //     tileSize: 256
    //   };

    // const publicTransportationLayer = {
    //     id: "public-transportation-layer",
    //     type: "raster"
    // };

    return (
        <>
            <div className={`${styles.map_container_component}`} onDrop={() => onDrop()}>
                <Map
                    onDrop={() => onDrop()}
                    ref={mapRef}
                    mapboxAccessToken="pk.eyJ1IjoibHVjYWNpYW5kcmVpIiwiYSI6ImNsdncxYzV0aTFnYW4yaW53MjB1dzE4ZnMifQ.z6_VjCGI6VEU7WE2dChqDw"
                    initialViewState={{
                        longitude: 24.94451813134341,
                        latitude: 46.08037889363541,
                        antialias: true,
                        zoom: 3
                    }}
                    attributionControl={false}
                    logoEnabled={false}
                    projection={"mercator"}
                    // onTouchStart={(e) => onTouchStart(e)}
                    // onTouchMove={(e) => onTouchMove(e)}
                    // onTouchEnd={(e) => onTouchEnd(e)}
                    maxPitch={0}
                    style={{ width: window.innerWidth, height: window.innerHeight }}
                    mapStyle="mapbox://styles/mapbox/dark-v11"
                // terrain={{source: 'mapbox-dem'}}
                >
                    {/* <Source
                        id="mapbox-dem"
                        type="raster-dem"
                        url="mapbox://mapbox.mapbox-terrain-dem-v1"
                        tileSize={512}
                        maxzoom={22}
                    /> */}

                    <Layer {...skyLayer} />

                    {/* <Source {...publicTransportationSource}>
                        <Layer {...publicTransportationLayer} />
                    </Source> */}


                    {!currentSite && sites.map((site) => (
                        <Marker key={site._id} longitude={site.location[1]} latitude={site.location[0]} width="60px" height="60px" onClick={() => goToSite(site)}>
                            <img src={process.env.REACT_APP_CMS_URL + site.icon} width="60px" height="50px" onClick={() => goToSite(site)} />
                            <span style={{ right: "2px", bottom: "-10px" }} className={`${styles.symbol_text}`}>{site.name}</span>
                        </Marker>
                    ))}

                    {currentSite && projectSites.map((site) => (

                        createMarkerForSiteId(site)

                    ))}

                    {currentSite && symbols.map((symbol) => (
                        <Marker className={`${styles.marker}`} key={symbol.uid} longitude={symbol.location[1]} latitude={symbol.location[0]} width="35px" height="35px" draggable="true" onClick={() => selectSymbol(symbol)} onDragStart={event => onDragStart(event)} onDragEnd={event => onDragEnd(event, symbol)} onDrag={event => onDrag(event, symbol)} >
                            {/* <Marker key={symbol.uid} longitude={symbol.location[1]} latitude={symbol.location[0]} width="50px" height="50px" draggable={symbol.fixed?null:"true"} onClick={() => selectSymbol(symbol)} onDragEnd={symbol.fixed?null:event => onDragEnd(event, symbol)} onDragStart={symbol.fixed?null:event => onDragStart(event)} onDrag={symbol.fixed?null:event => onDrag(event)}> */}
                            <img src={process.env.REACT_APP_MILSYMBOLS_URL.replace("{0}", symbol.icon)} width="35px" height="35px" />
                            {/* <span style={{ right: "40px", bottom:"22px" }} className={`${styles.symbol_text}`}> {getAltAndLocForSymbol(symbol)} </span> */}
                            <span style={{ right: "40px", bottom: "22px" }} className={`${styles.symbol_text}`}> {getAltAndLocForSymbol(symbol)} </span>
                            <span style={{ right: "40px", bottom: "2px" }} className={`${styles.symbol_text}`}>{symbol.uniqueDesignation}</span>
                            <span style={{ right: "40px", bottom: "-9px" }} className={`${styles.symbol_text}`}>{symbol.speed}</span>
                            <span style={{ left: "40px", bottom: "35px" }} className={`${styles.symbol_text}`}>{symbol.reinforced ? "+" : ""}</span>
                            <span style={{ left: "40px", bottom: "35px" }} className={`${styles.symbol_text}`}>{symbol.reduced ? "_" : ""}</span>

                            <span style={{ left: "40px", bottom: "22px" }} className={`${styles.symbol_text}`}>{symbol.staffComments}</span>
                            <span style={{ left: "40px", bottom: "12px" }} className={`${styles.symbol_text}`}>{symbol.additionalInfo}</span>
                            <span style={{ left: "40px", bottom: "2px" }} className={`${styles.symbol_text}`}>{symbol.higherFormation}</span>

                            <span style={{ left: "40px", bottom: "-9px" }} className={`${styles.symbol_text}`}>{symbol.reliabilityRatings + "" + symbol.credibilityRatings + " " + symbol.effectiveness + " " + symbol.sifCode}</span>
                        </Marker>
                    ))}

                    {currentSite && devices.map((device) => (
                        <div key={device._id}>
                            <Marker className={`${styles.marker}`} key={device._id} longitude={device.location[1]} latitude={device.location[0]} width="35px" height="35px" onClick={() => openDevice(device)}>
                                {(device.alerts.length > 0) && <div className={`${styles.blob}`}></div>}
                                <img src={process.env.REACT_APP_MILSYMBOLS_DARK_URL.replace("{0}", device.device_type.symbol)} width="35px" height="35px" />
                                {device.device_type.icon && <img className={`${styles.marker_icon}`} src={process.env.REACT_APP_CMS_URL + device.device_type.icon} height={15} width={20} />}

                                <span style={{ right: "40px", bottom: "22px" }} className={`${styles.symbol_text}`}> {getAltAndLocForDevice(device)} </span>
                                <span style={{ right: "40px", bottom: "-9px" }} className={`${styles.symbol_text}`}>{(device.speed) ? device.speed : ""}</span>


                            </Marker>
                            {device.satelites && device.satelites.length > 0 && device.satelites.map((sattelite, index) => (
                                createSattelite(sattelite, device, index)

                            ))}
                        </div>
                    ))}

                    {/* {logged &&
                        <ScaleControl style={{borderRadius:"5px", marginLeft:"450px", backgroundColor:"black", color:"white", border: "0", marginBottom:"30px", zIndex:"1"}} position="bottom-left"/>
                        
                    } */}
                    {logged &&
                        <NavigationControl showZoom={false} style={{ backgroundColor: "black", marginRight: "30px", marginTop: "35px" }} />
                    }

                </Map>
            </div>

            {/* <div style={{position:"absolute", width:"400px", backgroundColor:"red", height:"400px"}} onDrop={()=>console.log("onDrop")} onDragOver={(event)=> {console.log("ondragover1");event.stopPropagation();
    event.preventDefault();}}>
                    
                </div> */}

            {logged &&
                <SelectPickerMapComponent borderRadius="5px" color="white" onChanged={(newStyle) => handleChangeStyle(newStyle)} direction="UP" fontSize="12px" width="100px"
                    textAlign="center" backgroundColor="black" SelectedValue="mapbox://styles/mapbox/dark-v11" Items={mapStylesJson} x={isForDesk?794 * oxDivider + 5: 594 * oxDivider + 5} y={window.innerHeight - 25}
                    VisibleItems="5" Select="name" />}
        </>
    )
})

export default MapComponent; 
