import React, { useRef, useContext, useEffect, useState } from 'react';
import styles from '../../mobile.styles.module.css'

import { GlobalContext } from "../../contexts/globalContext"



const MobileInviteComponent = (props) => {
    const ref = useRef();
    const refCurrentProjectId = useRef();
    const [showInvite, setShowInvite] = useState(false);
    const [projectName, setProjectName] = useState();
    const [projectId, setProjectId] = useState();


    const { inputProjectNameRef, currentUser, projectSites, setProjectSites, setDrawingLayer, drawingsRef, setSymbols, socketRef, currentSite, currentProjectId, setCurrentProjectId, setShowInviteButton } = useContext(GlobalContext);

    useEffect(() => {
        refCurrentProjectId.current = currentProjectId;        
    }, [currentProjectId])

    useEffect(() => {
        console.log("creez socketu");
        socketRef.current.on("INVITE_TO_PROJECT", data => {            
            console.log("am primit inivte");
            if (refCurrentProjectId.current !== data.project_id) {
                
                setProjectName(data.project_name);
                setProjectId(data.project_id);
                setShowInvite(true);
            }
        })
    }, [])

    
      

    const goToProject = () => {
        setShowInvite(false);
        if (currentProjectId != null)
                socketRef.current.emit("LEAVE_ROOM", currentProjectId);

        socketRef.current.emit("PROJECT_CHANGED", { old_project_id: currentProjectId, new_project_id: projectId });
        setCurrentProjectId(projectId);
        socketRef.current.emit("JOIN_ROOM", {roomID: projectId, name: currentUser.name});            

    };

    return (
        <>
        {showInvite && <div className={`${styles.invite_component}`} ref={ref} style={{ height: 80, left:0, bottom:0 }}
            onClick={() => goToProject()}>
                    Ai fost invitat sa participi la proiectul {projectName}                  
        </div>
        }
        </>
    );
}

export default MobileInviteComponent;