import React, { useEffect, useRef, useState } from 'react';
import styles from '../css/dashboard.module.css'
import "@fontsource/roboto";
import "@fontsource/oswald/300.css"

import CallBtn from '../../../graphics/dashboard icons/new icons/btn-call.svg'
import LocationBtn from '../../../graphics/dashboard icons/new icons/btn-location.svg'
import VideocallBtn from '../../../graphics/dashboard icons/new icons/btn-videocam.svg'

const AlertVehicleList = (props) => {

	const [vehicles, setVehicles] = useState(props.vehicule);

    

	return (
		<div className={`${styles.divVehiclesALertBig}`}>
			{vehicles.map((vehicle, index) => (
				<AlertVehicleItem key={index} {...vehicle} />
			))}
		</div>
	);
};

const AlertVehicleItem = ({ status, id, name, location}) => {
	return (
		<div className={`${styles.divAlertsVehicleDiv}`}>
			<label className={`${styles.nameLabel}`} style={{left: '11px'}}> {name} </label>
			<label className= {`${styles.siteLabel}`} style={{left: '11px'}}> {location} </label>
			
            <img className={`${styles.btnsAlerts}`} style={{left: '188px'}} alt="icon" draggable="false" src={VideocallBtn} />     
            <img className={`${styles.btnsAlerts}`} style={{left: '240px'}} alt="icon" draggable="false" src={CallBtn}/>
            <img className={`${styles.btnsAlerts}`} style={{left: '291px'}} alt="icon" draggable="false" src={LocationBtn}/>
        </div>
	);
};

export default AlertVehicleList;