import { useContext } from 'react';

import styles from './../styles.module.css'
import VehiclesComponent from './dashboard-components/vehicles/VehiclesComponent';
import AlertsComponent from './dashboard-components/alerts/AlertsComponent';
import ConnectedMeteoComponent from './dashboard-components/meteo/ConnectedMeteoComponent';

import SipComponent from '../components/sip/SipComponent'
import ConnectedDroneComponent from './dashboard-components/drone/ConnectedDroneComponent';

import { GlobalContext } from "../contexts/globalContext"


const DashboardComponent = () => {
    const { closeDashboard, isForDesk } = useContext(GlobalContext);

    return (
        <div className={`${closeDashboard ? styles.dashboard_close : styles.dashboard}`} style = {{ width:`${isForDesk?780:594}px`, height:"2160px", direction: "flex", flexDirection: "column"}}>
            {/* <MeteoComponent/> */}
            <ConnectedMeteoComponent height={isForDesk?400:400} top={isForDesk?15:8} scale_factor={isForDesk?1.3:1}/>
            <VehiclesComponent height={isForDesk?400:400} top={isForDesk?550:415} scale_factor={isForDesk?1.3:1}/>
            <ConnectedDroneComponent height={isForDesk?400:400} top={isForDesk?1085:824} scale_factor={isForDesk?1.3:1}/>
            {!isForDesk && <SipComponent/>}
            <AlertsComponent height={isForDesk?400:400} top={isForDesk?1620:1233} scale_factor={isForDesk?1.3:1}/>
        </div>
    );
}

export default DashboardComponent;