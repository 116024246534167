import { useRef, useState, useContext, useEffect } from 'react';
import styles from '../../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { TransformPoints} from '../../../utils/Tools'
import { GlobalContext } from "../../../contexts/globalContext"
import uuid from 'react-uuid';
import * as turf from "@turf/turf"
import { ApiSaveProject } from "../../../utils/RestServices"

const CircleGraphicDrawLayer = (props) => {
    const ref = useRef(null);
    const circleRef = useRef(null);

    const {currentSpikes, setCurrentSpikes, currentShape, setCurrentShapeName, mapRef, setDrawingLayer, drawingsRef, currentDrawingColor, currentProjectId, socketRef } = useContext(GlobalContext);
    const [currentCircle, setCurrentCircle] = useState([]);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;
    

    const distance = (points) => {
        var options = {units: 'kilometers'};
        return turf.distance(points[0], points[1], options);
    }

    function createEndingTextShape(point1, bearing, dist, options){
        var coordinatesText = []

        for(let i = 0; i < currentShape.length; i++){
            let line = []
            for(let j = 0; j < 2; j++){
                let oxDist = dist * currentShape[i][j][0]
                let oyDist = dist * currentShape[i][j][1]
                if(oyDist === 0){
                    var point2 = turf.destination(point1, oxDist, bearing, options)
                    line.push(point2.geometry.coordinates)
                } else if(oyDist > 0){
                    let helpingPoint = turf.destination(point1, oyDist, bearing + 90, options)
                    var point3 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point3.geometry.coordinates)
                } else {
                    let helpingPoint = turf.destination(point1, Math.abs(oyDist), bearing + 270, options)
                    var point4 = turf.destination(helpingPoint, oxDist, bearing, options)
                    line.push(point4.geometry.coordinates)
                }
            }
            coordinatesText.push(line)
        }

        return coordinatesText
    }

    useGesture(
        {
            onDrag: ({ pinching, cancel, xy: [ox, oy], first }) => {
                if (pinching) {
                    return cancel()
                }

                if (first) {
                    setCurrentCircle([...currentCircle, [ox, oy]]);
                } else {
                    const updatedPoints = currentCircle.slice(0, -1);
                    updatedPoints.push([ox, oy]);
                    setCurrentCircle(updatedPoints);
                }
            },
            onDragEnd: () => {
                const secondPoint = currentCircle[1];

                if (secondPoint) {
                    const points = TransformPoints(currentCircle, props.map);

                    setCurrentCircle([]);
                    setDrawingLayer(0)       
                    
                    setCurrentShapeName("");             

                    if (currentProjectId == null) {
                        var center = points[0][0];
                        var radius = distance(points[0]);

                        var zoom = mapRef.current.getZoom();
                        var zoomDiff = (11 - zoom) / 5

                        if(0.3 + zoomDiff > 0.1){
                            var dist = 0.4 + zoomDiff
                        } else {
                            var dist = 0.1
                        }

                        var steps = 32
                        var options = {steps: steps, units: 'kilometers', properties: {foo: 'bar'}};
                        var circle = turf.circle(center, radius, options);

                        

                        var coordinates = []
                        var secondCirclePoint = points[0][1];
                        var arrowPoint = []
                        var bearingPoint = []
                        var arrowPointPosition = -1
                        var finishPointPosition = -1

                        for(let i=0; i < steps; i++){
                            let ok = 0
                            if(circle.geometry.coordinates[0][i][0]>=circle.geometry.coordinates[0][i+1][0]){
                                if(circle.geometry.coordinates[0][i][1]>=circle.geometry.coordinates[0][i+1][1]){
                                    if(secondCirclePoint[0]<=circle.geometry.coordinates[0][i][0] && secondCirclePoint[0]>=circle.geometry.coordinates[0][i+1][0] &&
                                        secondCirclePoint[1]<=circle.geometry.coordinates[0][i][1] && secondCirclePoint[1]>=circle.geometry.coordinates[0][i+1][1]){
                                            ok = 1
                                        } 
                                } else {
                                    if(secondCirclePoint[0]<=circle.geometry.coordinates[0][i][0] && secondCirclePoint[0]>=circle.geometry.coordinates[0][i+1][0] &&
                                        secondCirclePoint[1]>=circle.geometry.coordinates[0][i][1] && secondCirclePoint[1]<=circle.geometry.coordinates[0][i+1][1]){
                                            
                                            ok = 1
                                        }
                                }
                            } else {
                                if(circle.geometry.coordinates[0][i][1]>=circle.geometry.coordinates[0][i+1][1]){
                                    if(secondCirclePoint[0]>=circle.geometry.coordinates[0][i][0] && secondCirclePoint[0]<=circle.geometry.coordinates[0][i+1][0] &&
                                        secondCirclePoint[1]<=circle.geometry.coordinates[0][i][1] && secondCirclePoint[1]>=circle.geometry.coordinates[0][i+1][1]){
                                            
                                            ok = 1
                                        }
                                } else {
                                    if(secondCirclePoint[0]>=circle.geometry.coordinates[0][i][0] && secondCirclePoint[0]<=circle.geometry.coordinates[0][i+1][0] &&
                                        secondCirclePoint[1]>=circle.geometry.coordinates[0][i][1] && secondCirclePoint[1]<=circle.geometry.coordinates[0][i+1][1]){
                                            
                                            ok = 1
                                        }
                                }
                            }
                            if(ok){
                                if(i === steps - 2){
                                    arrowPoint = circle.geometry.coordinates[0][0]
                                    bearingPoint = circle.geometry.coordinates[0][1]
                                    arrowPointPosition = 0
                                    finishPointPosition = i
                                } else if (i === steps - 1){
                                    arrowPoint = circle.geometry.coordinates[0][1]
                                    bearingPoint = circle.geometry.coordinates[0][2]
                                    arrowPointPosition = 1
                                    finishPointPosition = i
                                } else {
                                    arrowPoint = circle.geometry.coordinates[0][i+2]
                                    bearingPoint = circle.geometry.coordinates[0][i+3]
                                    arrowPointPosition = i+2
                                    finishPointPosition = i
                                }
                                
                            } 
                        }
                        if(currentSpikes === "line_external"){
                            let secondRadius = radius + dist
                            let secondCircle = turf.circle(center, secondRadius, options); 
                            for(let i=0; i < steps; i++){
                                if(i === 0 && arrowPointPosition === steps - 1){
                                    continue
                                }else if(i === steps - 1 && arrowPointPosition === 0){
                                    continue
                                } else if(arrowPointPosition !== i && arrowPointPosition !== i + 1 && arrowPointPosition !== i - 1){
                                    coordinates.push([circle.geometry.coordinates[0][i], secondCircle.geometry.coordinates[0][i]])
                                }
                            }
                        } else if(currentSpikes === "triangle_internal"){
                            let secondRadius = radius - dist
                            let optionsSecond = {steps: steps*2, units: 'kilometers', properties: {foo: 'bar'}};
                            let secondCircle = turf.circle(center, secondRadius, optionsSecond); 
                            let i = 0
                            if(arrowPointPosition === steps - 1){
                                i = 1
                            } else if(arrowPointPosition === steps){
                                i = 2
                            } else if(arrowPointPosition < steps - 3){
                                i = arrowPointPosition + 2
                            }
                            let ok = 1
                            while(ok){
                                coordinates.push([circle.geometry.coordinates[0][i], secondCircle.geometry.coordinates[0][i*2+1]])
                                coordinates.push([circle.geometry.coordinates[0][i+1], secondCircle.geometry.coordinates[0][i*2+1]])
                                if(i === steps - 3){
                                    i = 0
                                } else if(i === steps - 2){
                                    i = 1
                                } else if(i === steps - 1){
                                    i = 2
                                } else if(i === steps){
                                    i = 3
                                } else {
                                    i += 3
                                }
                                if(i === finishPointPosition - 1 || i === finishPointPosition || i === finishPointPosition - 1 || i === arrowPointPosition - 1 || i === arrowPointPosition){
                                    ok = 0
                                }
                            }
                        }
                        

                        var line1 = []
                        var line2 = []
                        for(let i=0; i < steps + 1; i++){
                            if(i === 0 && arrowPointPosition === 1){
                                // console.log("test")
                            } else if(i === steps - 1 && arrowPointPosition === 0){

                            } else if(i <= finishPointPosition){
                                line1.push(circle.geometry.coordinates[0][i])
                            } else if (i >= arrowPointPosition){
                                line2.push(circle.geometry.coordinates[0][i])
                            }

                        }

                        coordinates.push(line1)
                        coordinates.push(line2)

                        var bearing = turf.bearing(bearingPoint, arrowPoint);
                        var options1 = {units: 'kilometers', bearing};
                        var coordinatesOne = createEndingTextShape(arrowPoint, bearing, dist, options1);

                        for(let i = 0; i < coordinatesOne.length; i ++){
                            coordinates.push(coordinatesOne[i])
                        }

                        var multiline = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'MultiLineString', coordinates }
                        };
    
                        drawingsRef.current.add(multiline)

                    } else {
                        var center = points[0][0];
                        var radius = distance(points[0]);

                        var zoom = mapRef.current.getZoom();
                        var zoomDiff = (11 - zoom) / 5

                        if(0.3 + zoomDiff > 0.1){
                            var dist = 0.4 + zoomDiff
                        } else {
                            var dist = 0.1
                        }

                        var steps = 32
                        var options = {steps: steps, units: 'kilometers', properties: {foo: 'bar'}};
                        var circle = turf.circle(center, radius, options);

                        

                        var coordinates = []
                        var secondCirclePoint = points[0][1];
                        var arrowPoint = []
                        var bearingPoint = []
                        var arrowPointPosition = -1
                        var finishPointPosition = -1

                        for(let i=0; i < steps; i++){
                            let ok = 0
                            if(circle.geometry.coordinates[0][i][0]>=circle.geometry.coordinates[0][i+1][0]){
                                if(circle.geometry.coordinates[0][i][1]>=circle.geometry.coordinates[0][i+1][1]){
                                    if(secondCirclePoint[0]<=circle.geometry.coordinates[0][i][0] && secondCirclePoint[0]>=circle.geometry.coordinates[0][i+1][0] &&
                                        secondCirclePoint[1]<=circle.geometry.coordinates[0][i][1] && secondCirclePoint[1]>=circle.geometry.coordinates[0][i+1][1]){
                                            ok = 1
                                        } 
                                } else {
                                    if(secondCirclePoint[0]<=circle.geometry.coordinates[0][i][0] && secondCirclePoint[0]>=circle.geometry.coordinates[0][i+1][0] &&
                                        secondCirclePoint[1]>=circle.geometry.coordinates[0][i][1] && secondCirclePoint[1]<=circle.geometry.coordinates[0][i+1][1]){
                                            
                                            ok = 1
                                        }
                                }
                            } else {
                                if(circle.geometry.coordinates[0][i][1]>=circle.geometry.coordinates[0][i+1][1]){
                                    if(secondCirclePoint[0]>=circle.geometry.coordinates[0][i][0] && secondCirclePoint[0]<=circle.geometry.coordinates[0][i+1][0] &&
                                        secondCirclePoint[1]<=circle.geometry.coordinates[0][i][1] && secondCirclePoint[1]>=circle.geometry.coordinates[0][i+1][1]){
                                            
                                            ok = 1
                                        }
                                } else {
                                    if(secondCirclePoint[0]>=circle.geometry.coordinates[0][i][0] && secondCirclePoint[0]<=circle.geometry.coordinates[0][i+1][0] &&
                                        secondCirclePoint[1]>=circle.geometry.coordinates[0][i][1] && secondCirclePoint[1]<=circle.geometry.coordinates[0][i+1][1]){
                                            
                                            ok = 1
                                        }
                                }
                            }
                            if(ok){
                                if(i === steps - 2){
                                    arrowPoint = circle.geometry.coordinates[0][0]
                                    bearingPoint = circle.geometry.coordinates[0][1]
                                    arrowPointPosition = 0
                                    finishPointPosition = i
                                } else if (i === steps - 1){
                                    arrowPoint = circle.geometry.coordinates[0][1]
                                    bearingPoint = circle.geometry.coordinates[0][2]
                                    arrowPointPosition = 1
                                    finishPointPosition = i
                                } else {
                                    arrowPoint = circle.geometry.coordinates[0][i+2]
                                    bearingPoint = circle.geometry.coordinates[0][i+3]
                                    arrowPointPosition = i+2
                                    finishPointPosition = i
                                }
                                
                            } 
                        }
                        if(currentSpikes === "line_external"){
                            let secondRadius = radius + dist
                            let secondCircle = turf.circle(center, secondRadius, options); 
                            for(let i=0; i < steps; i++){
                                if(i === 0 && arrowPointPosition === steps - 1){
                                    continue
                                } if(i === steps - 1 && arrowPointPosition === 0){
                                    continue
                                } else if(arrowPointPosition !== i && arrowPointPosition !== i + 1 && arrowPointPosition !== i - 1){
                                    coordinates.push([circle.geometry.coordinates[0][i], secondCircle.geometry.coordinates[0][i]])
                                }
                            }
                        } else if(currentSpikes === "triangle_internal"){
                            let secondRadius = radius - dist
                            let optionsSecond = {steps: steps*2, units: 'kilometers', properties: {foo: 'bar'}};
                            let secondCircle = turf.circle(center, secondRadius, optionsSecond); 
                            let i = 0
                            if(arrowPointPosition === steps - 1){
                                i = 1
                            } else if(arrowPointPosition === steps){
                                i = 2
                            } else if(arrowPointPosition < steps - 3){
                                i = arrowPointPosition + 2
                            }
                            let ok = 1
                            while(ok){
                                coordinates.push([circle.geometry.coordinates[0][i], secondCircle.geometry.coordinates[0][i*2+1]])
                                coordinates.push([circle.geometry.coordinates[0][i+1], secondCircle.geometry.coordinates[0][i*2+1]])
                                if(i === steps - 3){
                                    i = 0
                                } else if(i === steps - 2){
                                    i = 1
                                } else if(i === steps - 1){
                                    i = 2
                                } else if(i === steps){
                                    i = 3
                                } else {
                                    i += 3
                                }
                                if(i === finishPointPosition - 1 || i === finishPointPosition || i === finishPointPosition - 1 || i === arrowPointPosition - 1 || i === arrowPointPosition){
                                    ok = 0
                                }
                            }
                        }
                        

                        var line1 = []
                        var line2 = []
                        for(let i=0; i < steps + 1; i++){
                            if(i === 0 && arrowPointPosition === 1){
                                // console.log("test")
                            } else if(i === steps - 1 && arrowPointPosition === 0){

                            } else if(i <= finishPointPosition){
                                line1.push(circle.geometry.coordinates[0][i])
                            } else if (i >= arrowPointPosition){
                                line2.push(circle.geometry.coordinates[0][i])
                            }

                        }

                        coordinates.push(line1)
                        coordinates.push(line2)

                        var bearing = turf.bearing(bearingPoint, arrowPoint);
                        var options1 = {units: 'kilometers', bearing};
                        var coordinatesOne = createEndingTextShape(arrowPoint, bearing, dist, options1);

                        for(let i = 0; i < coordinatesOne.length; i ++){
                            coordinates.push(coordinatesOne[i])
                        }

                        var multiline = {
                            id: uuid(),
                            type: 'Feature',
                            properties: {
                                "class_id": currentDrawingColor
                            },
                            geometry: { type: 'MultiLineString', coordinates }
                        };
    
                        drawingsRef.current.add(multiline)

                        socketRef.current.emit("DRAWING_UPDATED", {project_id: currentProjectId, drawings: multiline});
                        var body = JSON.stringify({ project_id: currentProjectId, drawing: multiline });
                        ApiSaveProject(body);
                    }
                    
                    setCurrentSpikes()
                    
                }
            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
    })

    const xDrawingPointRectangleSize = props.DrawingPointRectangleSize / oxDivider;
    const yDrawingPointRectangleSize = props.DrawingPointRectangleSize / oyDivider;

    function showLatLng(point) {
        const coord = TransformPoints([point], props.map)
        return `${coord[0][0][0].toFixed(3)}, ${coord[0][0][1].toFixed(3)}`
    }
    function showElevationOld(point) {
        const coord = TransformPoints([point], props.map)
        const el = mapRef.current.queryTerrainElevation([coord[0][0][0], coord[0][0][1]])
        return `${el.toFixed(3)}`
    }

    const [elevations, setElevations] = useState([]);

    async function showElevation(point) {
        try {
          const coord = TransformPoints([point], props.map);
          const response = await fetch(process.env.REACT_APP_TOPODATA_URL + coord[0][0][0] + "," + coord[0][0][1]);
          const data = await response.json();
          const elevationValue = data.results[0].elevation;
          return elevationValue.toFixed(3)
        } catch (error) {
          console.error("Error fetching elevation data:", error);
        }
    }

    async function fetchElevations(points) {
        const elevations = [];
    
        for (const [x, y] of points) {
            const elevation = await showElevation([x, y]);
            elevations.push(elevation);
        }
    
        return elevations;
    }
    
    
    useEffect(() => {
        if (currentCircle.length > 0) {
            fetchElevations(currentCircle).then((elevations) => {
                setElevations(elevations);
            });
        }
    }, [currentCircle]);

    return (
        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT)}} >
            <svg style={{ width: '100%', height: '100%' }}>
                {currentCircle.length > 1 && (
                    <circle 
                        ref={circleRef} 
                        cx = {currentCircle[0][0]}
                        cy = {currentCircle[0][1]}
                        r = {Math.sqrt((currentCircle[1][0] - currentCircle[0][0])**2 + (currentCircle[1][1] - currentCircle[0][1])**2)}
                        style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4" }} 
                    />
                )}
                {currentCircle.map(([x, y], index) => (
                    <g key={index}>
                        <text x={x-5*xDrawingPointRectangleSize} y={y+2*yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >
                            {elevations[index]}m, {showLatLng([x, y])}
                        </text>
                        <circle
                            cx = {x}
                            cy = {y}
                            r = {10}
                            style={{ fill: '#fbb03b', stroke: 'white', strokeWidth: 4 }} 
                        />
                    </g>
                ))}
            </svg>
        </div>
    );
}

export default CircleGraphicDrawLayer;