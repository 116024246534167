

export default function PhoneIcon({ fillColor, angle = 0, style }) {
  return (
    

    <svg  width="32" height="32" viewBox="0 0 32 32" fill={fillColor} style={style}>
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_676" data-name="Rectangle 676" width="32" height="32" fill={fillColor}/>
    </clipPath>
  </defs>
  <g id="call-ic" clipPath="url(#clip-path)">
    <path id="call_FILL1_wght500_GRAD0_opsz24" d="M36.379-17.533a26.417,26.417,0,0,1-10.86-2.395,32.6,32.6,0,0,1-9.773-6.793,32.487,32.487,0,0,1-6.8-9.779A26.453,26.453,0,0,1,6.548-47.365a2.11,2.11,0,0,1,.615-1.548,2.084,2.084,0,0,1,1.543-.62h7.031a2.195,2.195,0,0,1,1.45.5,2.142,2.142,0,0,1,.752,1.285l1.12,5.867a3.465,3.465,0,0,1-.038,1.444,2.218,2.218,0,0,1-.639,1.034L14.1-35.228a20.8,20.8,0,0,0,1.961,2.91,29.6,29.6,0,0,0,2.526,2.766,29.878,29.878,0,0,0,2.669,2.374,24.764,24.764,0,0,0,2.88,1.952L28.3-29.353a2.916,2.916,0,0,1,1.24-.711,3.3,3.3,0,0,1,1.5-.088l5.717,1.186a2.594,2.594,0,0,1,1.306.8,2.018,2.018,0,0,1,.48,1.349V-19.7a2.1,2.1,0,0,1-.62,1.548,2.088,2.088,0,0,1-1.538.62Z" transform="translate(-6.543 49.533)" fill={fillColor}/>
  </g>
</svg>
  );
}
