import { useRef, useState, useContext, useEffect } from 'react';
import styles from '../../styles.module.css'
import { useGesture } from '@use-gesture/react'
import { TransformPoints} from '../../utils/Tools'
import { GlobalContext } from "../../contexts/globalContext"
import uuid from 'react-uuid';
import * as turf from "@turf/turf"
import { ApiSaveProject } from "../../utils/RestServices"

const CircleDrawLayer = (props) => {
    const ref = useRef(null);
    const circleRef = useRef(null);

    const {mapRef, setDrawingLayer, drawingsRef, currentDrawingColor, currentProjectId, socketRef } = useContext(GlobalContext);
    const [currentCircle, setCurrentCircle] = useState([]);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;
    

    const distance = (points) => {
        var options = {units: 'kilometers'};
        return turf.distance(points[0], points[1], options);
    }

    useGesture(
        {
            onDrag: ({ pinching, cancel, xy: [ox, oy], first }) => {
                if (pinching) {
                    return cancel()
                }

                if (first) {
                    setCurrentCircle([...currentCircle, [ox, oy]]);
                } else {
                    const updatedPoints = currentCircle.slice(0, -1);
                    updatedPoints.push([ox, oy]);
                    setCurrentCircle(updatedPoints);
                }
            },
            onDragEnd: () => {
                const secondPoint = currentCircle[1];

                if (secondPoint) {
                    const points = TransformPoints(currentCircle, props.map);

                    setCurrentCircle([]);
                    setDrawingLayer(0)                    

                    if (currentProjectId == null) {
                        let center = points[0][0];
                        let radius = distance(points[0]);
                        let options = {units: 'kilometers', properties: {foo: 'bar'}};
                        let circleTurf = turf.circle(center, radius, options);
                        
                        var circle = {
                            type: 'Feature',
                            id: uuid(),
                            properties: {
                                isCircle: true,
                                center,
                                "class_id": currentDrawingColor,
                                "radiusInKm": radius
                            },
                            geometry: { type: 'Polygon', coordinates: circleTurf.geometry.coordinates }
                          };

                        drawingsRef.current.add(circle)

                    } else {
                        let center = points[0][0];
                        let radius = distance(points[0]);
                        let options = {units: 'kilometers', properties: {foo: 'bar'}};
                        let circleTurf = turf.circle(center, radius, options);

                        var circle = {
                            type: 'Feature',
                            id: uuid(),
                            properties: {
                                isCircle: true,
                                center,
                                "class_id": currentDrawingColor,
                                "radiusInKm": radius
                            },
                            geometry: { type: 'Polygon', coordinates: circleTurf.geometry.coordinates }
                          };

                        
                        drawingsRef.current.add(circle)

                        socketRef.current.emit("DRAWING_UPDATED", {project_id: currentProjectId, drawings: circle});
                        var body = JSON.stringify({ project_id: currentProjectId, drawing: circle });
                        ApiSaveProject(body);
                    }
                }
            }
        },
        {
            target: ref,
            drag: { from: () => [0, 0], transform: ([x, y]) => [x / oxDivider, y / oyDivider] },
    })

    const xDrawingPointRectangleSize = props.DrawingPointRectangleSize / oxDivider;
    const yDrawingPointRectangleSize = props.DrawingPointRectangleSize / oyDivider;

    function showLatLng(point) {
        const coord = TransformPoints([point], props.map)
        return `${coord[0][0][0].toFixed(3)}, ${coord[0][0][1].toFixed(3)}`
    }
    function showElevationOld(point) {
        const coord = TransformPoints([point], props.map)
        const el = mapRef.current.queryTerrainElevation([coord[0][0][0], coord[0][0][1]])
        return `${el.toFixed(3)}`
    }

    const [elevations, setElevations] = useState([]);

    async function showElevation(point) {
        try {
          const coord = TransformPoints([point], props.map);
          const response = await fetch(process.env.REACT_APP_TOPODATA_URL + coord[0][0][0] + "," + coord[0][0][1]);
          const data = await response.json();
          const elevationValue = data.results[0].elevation;
          return elevationValue.toFixed(3)
        } catch (error) {
          console.error("Error fetching elevation data:", error);
        }
    }

    async function fetchElevations(points) {
        const elevations = [];
    
        for (const [x, y] of points) {
            const elevation = await showElevation([x, y]);
            elevations.push(elevation);
        }
    
        return elevations;
    }
    
    
    useEffect(() => {
        if (currentCircle.length > 0) {
            fetchElevations(currentCircle).then((elevations) => {
                setElevations(elevations);
            });
        }
    }, [currentCircle]);

    return (
        <div className={`${styles.los_component}`} ref={ref} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT)}} >
            <svg style={{ width: '100%', height: '100%' }}>
                {currentCircle.length > 1 && (
                    <circle 
                        ref={circleRef} 
                        cx = {currentCircle[0][0]}
                        cy = {currentCircle[0][1]}
                        r = {Math.sqrt((currentCircle[1][0] - currentCircle[0][0])**2 + (currentCircle[1][1] - currentCircle[0][1])**2)}
                        style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 4,  strokeDasharray:"4" }} 
                    />
                )}
                {currentCircle.map(([x, y], index) => (
                    <g key={index}>
                        <text x={x-5*xDrawingPointRectangleSize} y={y+2*yDrawingPointRectangleSize} fontSize={xDrawingPointRectangleSize} fontWeight= "bold" fill="white" >
                            {elevations[index]}m, {showLatLng([x, y])}
                        </text>
                        <circle
                            cx = {x}
                            cy = {y}
                            r = {10}
                            style={{ fill: '#fbb03b', stroke: 'white', strokeWidth: 4 }} 
                        />
                    </g>
                ))}
            </svg>
        </div>
    );
}

export default CircleDrawLayer;